
import React, { useState, useEffect } from 'react';
import Select from 'react-select'


export const NuevoSelectAutocomplete = ({ options, valorInput, textoInput , valorPorDefecto }) => {

    const newArr = []

    if(valorPorDefecto == 0){
        newArr.push({
            label: `-- Todos --`,
            value: 0
        })
    }
    
    if(valorPorDefecto == 1){
        newArr.push({
            label: `-- Seleccione --`,
            value: 0
        })
    }

    options.map(function(data){
        newArr.push(data)
    })

    options = newArr

    const defaultValue = options[0]; // Valor por defecto
    const [selectedValue, setSelectedValue] = useState(options[0]);
    // const [selectedValue, setSelectedValue] = useState(options[0]);

    const funcionCambio = (selectedOption) => {
        setSelectedValue(selectedOption)    // {value: 0,label: '--TODOS--'}
        valorInput(selectedOption.value)
        textoInput(selectedOption.label)

    };
    
    const customStyles = {
        input: (provided) => ({
            ...provided,
            // marginTop:'-7px',
            cursor:'text',
            color:'white',
            fontSize:'13px',
            // border:'2px solid rgba(30,144,255,255)'
            
        }),
        control: (provided, state) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
            borderRadius: '5px',
            width:'250px',
            // height:  '30px',
            // minHeight: '20px',
            border:'1px solid rgba(39,39,42,255)',
            backgroundColor: 'rgba(39,39,42,255)',
        }),
        placeholder:(provided)=>({
            color:'black',
            marginTop: '-30px'
        }),
        singleValue: (provided) => ({
            ...provided,
            lineHeight: '30px', // Ajusta el valor según el alto deseado
            // marginTop:'-10px',
            fontSize:'12px',
            color: 'white',
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            // marginTop:'-10px'
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            marginTop:'-1px',
            display: 'none',
            height:'30px'
        }),
        option: (provided,state) => ({
            ...provided,
            backgroundColor: state.isSelected ? 'rgba(30,144,255,255)' : 'rgba(39,39,42,255)',
            color: 'white',
            fontSize:'12px',
            '&:hover': {
                backgroundColor: 'rgba(30,144,255,255)',
            },
        }),
        menuPortal: (provided) => ({
            ...provided,
            zIndex: 100000000,
        }),
        menu: () => ({
            backgroundColor:'rgba(39,39,42,255)'

        })
    };

    return (
        <Select 
            className='containerScroll' 
            styles={customStyles}
            options={options} 
            placeholder="Selecciona una opción" 
            onChange={funcionCambio} 
            defaultValue={defaultValue} 
            value={selectedValue? selectedValue : defaultValue} 
            menuPortalTarget={document.body}>
        </Select>
    );
};

