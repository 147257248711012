import React, { useEffect, useRef, useState } from 'react'

export const Select2 = (props) => {
    /*
        {
            jsonOpciones,
            textoPrimerValor,
            valorPorDefecto,
            recibirValor,
            recibirTexto,
            ancho,
            alto
        }
    */

    // const jsonOpciones = [
    //     {
    //         valor: 0,
    //         texto: '--Todos--'
    //     },
    //     {
    //         valor: 1,
    //         texto: 'Texto prueba 1'
    //     },
    // ]

    const divRef = useRef()
    const [jsonOpciones,setJsonOpciones] = useState([
        {
            valor: 0,
            texto: `-- ${props.textoPrimerValor || 'Todos'} --` 
        },
        ...props.jsonOpciones ||[]
    ])
    const [desplegado,setDesplegado] = useState(false)
    const [textoInput,setTextoInput] = useState('')
    const [valorSeleccionado,setValorSeleccionado] = useState(props.valorPorDefecto || jsonOpciones[0].valor)

    useEffect(() => {
        props.recibirValor && props.recibirValor(valorSeleccionado)
        props.recibirTexto && props.recibirTexto(jsonOpciones.find(e => e.valor == valorSeleccionado).texto)
    },[valorSeleccionado])

    useEffect(() => {
        const eventoClick = (event) => {
            if(divRef.current && !divRef.current.contains(event.target)){
                setDesplegado(false)
            }
        }
        document.addEventListener('click',eventoClick)

        return () => {
            document.removeEventListener('click',eventoClick)
        }
    })
    
    useEffect(() => {
        if(!desplegado) setTextoInput('')
    },[desplegado])

  return (
    <div ref={divRef} className={`relative ${props.ancho || 'w-[250px]'} h-[30px]`} >
        <div onClick={() => setDesplegado(!desplegado)} className={`h-[30px] ${desplegado && 'border border-[#2684ff]'} text-white rounded px-4 bg-zinc-800 text-xs flex items-center cursor-pointer justify-between gap-2`}>
            <div className='whitespace-nowrap overflow-hidden text-ellipsis'>{jsonOpciones.find(e => e.valor == valorSeleccionado).texto}</div>
            <i className={`fa fa-angle-${desplegado ? 'up' : 'down'}`} aria-hidden="true"></i>
        </div>
        <div className={`${!desplegado && 'hidden'} top-[30px] border border-[#767676] absolute bg-[#27272a] w-full flex flex-col items-center gap-2 pt-2`}>
            <input value={textoInput} type='text' placeholder='(Buscar)' className={`h-[30px] caret-white border px-2 w-[95%] bg-[#3b3b3b] text-white text-xs`} onChange={(e) => setTextoInput(e.target.value)} />
            <ul className='w-full max-h-[150px] overflow-y-auto overflow-x-hidden containerScroll'>
                {
                    jsonOpciones.filter(e => e.texto.toLowerCase().includes(textoInput.toLowerCase())).length != 0 ? 
                        jsonOpciones.filter(e => e.texto.toLowerCase().includes(textoInput.toLowerCase())).map((data,i) => {
                            return(
                                <li 
                                    key={i+1}
                                    onClick={() => {
                                        setValorSeleccionado(data.valor)
                                        setDesplegado(false)
                                    }}
                                    className={`${data.valor == valorSeleccionado && 'bg-[#2152a2]'} hover:bg-[#2152a2] flex items-center p-2 text-xs hover:cursor-pointer`}
                                >
                                    {data.texto}
                                </li>
                            )
                        })
                    :
                    <li className={`flex items-center p-2 text-xs hover:cursor-pointer`}>No hay información</li>
                }
            </ul>
        </div>
    </div>
  )
}
