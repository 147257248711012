import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from 'chart.js';
// import 'chartjs-plugin-datalabels';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

export default function Bars(props) {



    const cantidades = props.datosLateral;
    const fechas = props.datosInferior;
    // Calcular el valor máximo en los datos
    const valorMaximoDatos = Math.max(...cantidades);

    // Agregar 10 al valor máximo
    const valorMaximoEjeY = valorMaximoDatos + 10;

    const opciones = {
        responsive : true,
        animation : true,
        maintainAspectRatio: true,
        indexAxis: 'x',
        
        
        scales : {

            x: {
                display: true,
                // borderColor: 'rgba(0, 0, 0, 0.1)',
                // borderWidth: 1,
                title: {
                    display: true,
                    text: 'Rango Horario',
                    color: 'rgba(75, 192, 192, 1)',
                    font: {
                        size: 14,
                        weight: 'bold',
                    },
                },
                grid: {
                    display: true,
                    borderColor: 'rgba(75, 192, 192)',
                    borderWidth: 10,
                    backgroundColor:"#ff0f0f"
                },
                ticks : {
                    color: '#ffff',
                    font : {
                        size: 12,
                    },
                }
            },
            y : {
                display: true,
                // min : 0,
                // max : valorMaximoEjeY,
                title: {
                    display: true,
                    text: 'Cantidad de Boletos',
                    color: 'rgba(75, 192, 192, 1)',
                    font: {
                        size: 14,
                        weight: 'bold',
                    },
                },
                grid: {
                    display: true,
                    borderColor: 'rgba(0, 0, 0, 0.1)',
                    backgroundColor:"#ff00ff",
                    borderWidth: 1,
                },
                ticks : {
                    color: '#ffff',
                    font : {
                        size: 14,
                        weight:'bold'
                    },
                    beginAtZero: true,
                    stepSize: 5,
                }
            },
            
        },
        
        plugins : {
            title: {
                display: true,
                text: `Cantidad de Boletos por ${props.paradero}`,
                color: '#ffffff',
                font: {
                    size: 16,
                    weight: 'bold',
                    family: 'Arial',
                },
                // padding: {
                //     top: 10,
                //     bottom: 10,
                // },
                datalabels: {
                    color: '#ffffff',
                    anchor: 'end',
                    align: 'top',
                    font: {
                        size: 12,
                    },
                    formatter: function(value, context) {
                        return "dsda"; // Mostrar el valor encima de la barra
                    }
                },
            },
            legend: {
                display: false,

                datalabels: {
                    color: '#ffffff',
                    anchor: 'end',
                    align: 'top',
                    font: {
                        size: 12,
                    },
                    formatter: function(value, context) {
                        return "dsda"; // Mostrar el valor encima de la barra
                    }
                },
            },
            datalabels: {
                color: '#ffffff',
                anchor: 'end',
                align: 'top',
                font: {
                    size: 12,
                },
                formatter: function(value, context) {
                    return "value"; // Mostrar el valor encima de la barra
                }
            },
            datalabels: {
                color: '#ffffff',
                anchor: 'end',
                align: 'top',
                font: {
                    size: 12,
                },
                formatter: function(value, context) {
                    return "dsda"; // Mostrar el valor encima de la barra
                }
            },
        },

        elements: {
            point: {
                radius: 5,
                borderWidth: 2,
                backgroundColor: 'rgba(75, 12, 9)',
                borderColor: 'rgba(75, 192, 192, 1)',
            },
            bar: {
                borderWidth: 2,
                backgroundColor: 'rgba(75, 12, 9)',
                borderColor: 'rgba(75, 192, 192, 1)',
                datalabels: {
                    color: '#ffffff',
                    anchor: 'end',
                    align: 'top',
                    font: {
                        size: 12,
                    },
                    formatter: function(value, context) {
                        return "dsda"; // Mostrar el valor encima de la barra
                    }
                },
            },
            datalabels: {
                color: '#ffffff',
                anchor: 'end',
                align: 'top',
                font: {
                    size: 12,
                },
                formatter: function(value, context) {
                    return "dsda"; // Mostrar el valor encima de la barra
                }
            },
        },
        animations: {
            tension: {
                duration: 1000,
                easing: 'linear',
                from: 1,
                to: 0.5,
                loop: true,
            },
        },
        interaction: {
            mode: 'index',
            intersect: true,
        },
            
        

            datalabels: {
                display : true,
                color: '#ff00ff',
                anchor: 'end',
                align: 'top',
                font: {
                    size: 12,
                },
                formatter: function(value, context) {

                    return "value";
                }
            },
    };

    const data = {
        labels: fechas,
        
        datasets: [
            {
                label: 'Boletos',
                data: cantidades,
                backgroundColor: [
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(75, 192, 192, 1)',
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 2,
                borderSkipped: 'bottom',
                borderRadius: 5,
                borderDashOffset: 10,
                hoverBackgroundColor: [
                    'rgba(75, 192, 192, 0.4)',
                    'rgba(255, 99, 132, 0.4)',
                    'rgba(54, 162, 235, 0.4)',
                    'rgba(255, 206, 86, 0.4)',
                    'rgba(153, 102, 255, 0.4)',
                    'rgba(255, 159, 64, 0.4)',
                ],
                hoverBorderColor: [
                    'rgba(75, 192, 192, 1)',
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                
                
            },
        ],
    };
    return <Bar data={data} datos={props.datos} paradero={props.paradero} options={opciones} />
}



// -------------------------DOCUMENTACION DATA -----------------------------------
// labels: Un array de strings que define las etiquetas para el eje X del gráfico de barras.
// datasets: Un array que contiene un objeto que representa el conjunto de datos del gráfico de barras.
// label: El nombre o etiqueta del conjunto de datos.
// data: Un array de valores numéricos que representan los datos del conjunto.
// backgroundColor: Un array de colores de fondo para las barras del conjunto.
// borderColor: Un array de colores de borde para las barras del conjunto.
// borderWidth: El ancho del borde de las barras del conjunto.
// borderSkipped: La dirección en la que se omitirá el borde (por defecto: 'bottom').
// borderRadius: El radio de las esquinas de las barras.
// borderDashOffset: El desplazamiento del patrón de guiones en el borde.
// hoverBackgroundColor: Un array de colores de fondo al pasar el cursor sobre las barras del conjunto.
// hoverBorderColor: Un array de colores de borde al pasar el cursor sobre las barras del conjunto.

// --------Ejemplo
// const Dashboard = () => {
//     const barChartData = {
//       labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio'],
//       datasets: [
//         {
//           label: 'Ventas',
//           data: [1000, 1500, 1200, 1800, 2000, 1600],
//           backgroundColor: [
//             'rgba(75, 192, 192, 0.2)',
//             'rgba(255, 99, 132, 0.2)',
//             'rgba(54, 162, 235, 0.2)',
//             'rgba(255, 206, 86, 0.2)',
//             'rgba(153, 102, 255, 0.2)',
//             'rgba(255, 159, 64, 0.2)',
//           ],
//           borderColor: [
//             'rgba(75, 192, 192, 1)',
//             'rgba(255, 99, 132, 1)',
//             'rgba(54, 162, 235, 1)',
//             'rgba(255, 206, 86, 1)',
//             'rgba(153, 102, 255, 1)',
//             'rgba(255, 159, 64, 1)',
//           ],
//           borderWidth: 2,
//           borderSkipped: 'bottom',
//           borderRadius: 10,
//           borderDashOffset: 5,
//           hoverBackgroundColor: [
//             'rgba(75, 192, 192, 0.4)',
//             'rgba(255, 99, 132, 0.4)',
//             'rgba(54, 162, 235, 0.4)',
//             'rgba(255, 206, 86, 0.4)',
//             'rgba(153, 102, 255, 0.4)',
//             'rgba(255, 159, 64, 0.4)',
//           ],
//           hoverBorderColor: [
//             'rgba(75, 192, 192, 1)',
//             'rgba(255, 99, 132, 1)',
//             'rgba(54, 162, 235, 1)',
//             'rgba(255, 206, 86, 1)',
//             'rgba(153, 102, 255, 1)',
//             'rgba(255, 159, 64, 1)',
//           ],
//         },
//       ],
//     };



// ---------------------------DOCUMENTACION DE OPCIONES -----------------------------------------
// responsive (booleano): Indica si el gráfico debe ser responsivo y ajustarse al tamaño del contenedor.
// maintainAspectRatio (booleano): Indica si se debe mantener el aspecto del gráfico al cambiar de tamaño.
// indexAxis (string): Especifica el eje para el indexado de datos. En este caso, se establece en 'y' para que el eje Y sea el eje principal.
// scales (objeto): Define las opciones de escala para los ejes X e Y del gráfico.
// x y y (objetos): Propiedades personalizadas para los ejes X e Y, como la visualización de títulos, las líneas de la cuadrícula y los colores de las etiquetas.
// plugins (objeto): Define las opciones de los complementos utilizados en el gráfico, como el título y la leyenda.
// layout (objeto): Define el espaciado interno del gráfico.
// elements (objeto): Propiedades personalizadas para los elementos del gráfico, como los puntos y las barras.
// animations (objeto): Define opciones de animación para el gráfico.
// interaction (objeto): Define opciones de interacción del usuario con el gráfico.
// responsiveAnimationDuration (número): Define la duración de la animación de redimensionamiento del gráfico.
// onResize (función): Una función de devolución de llamada que se ejecuta cuando el gráfico se redimensiona.

// -----------Ejemplo
// const options = {
//     responsive: true,
//     maintainAspectRatio: false,
//     indexAxis: 'y',
//     scales: {
//       x: {
//         display: true,
//         title: {
//           display: true,
//           text: 'Meses',
//           color: 'rgba(75, 192, 192, 1)',
//           font: {
//             size: 14,
//             weight: 'bold',
//           },
//         },
//         grid: {
//           display: true,
//           borderColor: 'rgba(0, 0, 0, 0.1)',
//           borderWidth: 1,
//         },
//         ticks: {
//           color: 'rgba(75, 192, 192, 1)',
//           font: {
//             size: 12,
//           },
//         },
//       },
//       y: {
//         display: true,
//         title: {
//           display: true,
//           text: 'Ventas',
//           color: 'rgba(75, 192, 192, 1)',
//           font: {
//             size: 14,
//             weight: 'bold',
//           },
//         },
//         grid: {
//           display: true,
//           borderColor: 'rgba(0, 0, 0, 0.1)',
//           borderWidth: 1,
//         },
//         ticks: {
//           color: 'rgba(75, 192, 192, 1)',
//           font: {
//             size: 12,
//           },
//           beginAtZero: true,
//           stepSize: 500,
//         },
//       },
//     },
//     plugins: {
//       title: {
//         display: true,
//         text: 'Gráfico de Ventas Mensuales',
//         color: 'rgba(75, 192, 192, 1)',
//         font: {
//           size: 16,
//           weight: 'bold',
//           family: 'Arial',
//         },
//         padding: {
//           top: 10,
//           bottom: 10,
//         },
//       },
//       legend: {
//         display: true,
//         position: 'top',
//         align: 'center',
//         labels: {
//           color: 'rgba(75, 192, 192, 1)',
//           font: {
//             size: 12,
//           },
//         },
//       },
//     },
//     layout: {
//       padding: {
//         left: 20,
//         right: 20,
//         top: 20,
//         bottom: 20,
//       },
//     },
//     elements: {
//       point: {
//         radius: 5,
//         borderWidth: 2,
//         backgroundColor: 'rgba(75, 192, 192, 1)',
//         borderColor: 'rgba(75, 192, 192, 1)',
//       },
//       bar: {
//         borderWidth: 2,
//         backgroundColor: 'rgba(75, 192, 192, 0.5)',
//         borderColor: 'rgba(75, 192, 192, 1)',
//       },
//     },
//     animations: {
//       tension: {
//         duration: 1000,
//         easing: 'linear',
//         from: 1,
//         to: 0.5,
//         loop: true,
//       },
//     },
//     interaction: {
//       mode: 'index',
//       intersect: true,
//     },
//     responsiveAnimationDuration: 200,
//     onResize: function (chart, size) {

//     },
//   };







