import React, { useEffect, useRef, useState } from 'react'
import { Raphael,Image ,Path , Rect, Paper, Set, Circle, Ellipse,Line, Text } from "react-raphael";

import { ContenedorParametros } from '../../components/utils/ContenedorParametros'
import { BotonNuevo } from '../../components/buttons/BotonNuevo'
import { Select2 } from '../../components/forms/Select2'
import { TabsABX } from '../../components/forms/TabsABX'
import { DatePickerABX } from '../../components/pickers/DatePicker'

import { useModal } from '../../../hooks/useModal'
import { Modal } from '../../components/modal/Modal'

import { useFlota } from '../../../hooks/ATU/useFlota'

import groupBy from 'lodash/groupBy';
import _ from 'lodash'

import json from './Frecuencias.json'
import json2 from './ControlesYTiempos.json'
import json3 from './Recorridos.json'
import { dateFormatToString, dateTimeToString, notify, stringToDateFormat, stringToDateTime } from '../../../utils/utils';
import { DateTimePicker } from '../../components/pickers/DateTimePicker';
import { convertDatetoTimeStamp, convertTimestampToDate } from '../../../utils/utilGenerarServicios';
import { ModalSinRenderizacion } from '../../components/modal/ModalSinRenderizacion';

export const GenerarServicios = () => {
    const divRef = useRef()
    
    // let TIPO_INICIO_VIAJES = {
        //     AB: 1,
        //     BA: 2,
        //     AMBOS: 3
    // }

    const parametrosDefault = {     // PARAMETRO DEFAULT PARA EL MODAL
        ruta: 0,
        tipoServicio: 1,
        inicioViajes: 1,
        fechaInicio: '24/05/2019 05:00:00',
        fechaInicioAB: '24/05/2019',
        hInicioA: '05:00:00',
        hInicioB: '04:50:00',
        fechaFin: '24/05/2019 23:00:00',
        minViajes: 10,
        maxViajes: 14
    }

    const TiempoOperacion = {
        inicio: {
            fecha: '24/05/2019',
            hora: '05:00:00',
            // timestamp: convertDatetoTimeStamp('24/05/2019 05:00:00'),
        },
        fin: {
            fecha: '24/05/2019',
            hora: '23:00:00',
            // timestamp: convertDatetoTimeStamp('24/05/2019 23:00:00'),
        },
        diferenciaminutos: null,
        distanciaenpx: null
    }

    const [isOpenGenerar, openModalGenerar, closeModalGenerar] = useModal()
    const { listarEmpresasBDGeneral,
            empresasGeneral,
            listarFrecuencias,
            listarControlesTiempos,
            listarRutasBDGeneral,
            rutasGeneral,
            consultarRecorridosXRuta,
    } = useFlota()

    const dioClickBtnGuardar = useRef(false)
    const [controlesTablaServicios,setControlesTablaServicios] = useState([])
    const [parametrosModal,setParametrosModal] = useState(parametrosDefault)
    const [parametrosModal_Procesar,setParametrosModal_Procesar] = useState(parametrosDefault)
    const [empresaSeleccionada,setEmpresaSeleccionada] = useState(0)

    const [arrayServiciosGenerados,setArrayServiciosGenerados] = useState([])
    
    const [viajesLadoA,setViajesLadoA] = useState([])
    const [viajesLadoB,setViajesLadoB] = useState([])
    const [arcos,setArcos] = useState([])

    const [longitudTiempoServicios,setLongitudTiempoServicios] = useState(1800)
    const [tiempoInicial,setTiempoInicial] = useState(0)
    const [servicioSeleccionado,setServicioSeleccionado] = useState(0)
    const minutesToPixeles = 40     // 1 min -> 40px

    useEffect(() => {
        const funcionReady = async () => {
            await listarEmpresasBDGeneral()
        }
        funcionReady()
    },[])

    useEffect(() => {
        empresaSeleccionada != 0 && listarRutasBDGeneral(empresaSeleccionada)
    },[empresaSeleccionada])

    useEffect(() => {
        if(dioClickBtnGuardar.current){
            dioClickBtnGuardar.current = false
            funcionProcesar_GenerarViajes()
        }
    },[parametrosModal_Procesar])

    //******************************************************* */
    const funcionProcesar_GenerarViajes = async () => {
        const frecuencias = await listarFrecuencias(empresaSeleccionada,parametrosModal_Procesar.fechaInicio.split(' ')[0],parametrosModal_Procesar.ruta)
        const {controles,tiempos} = await listarControlesTiempos(empresaSeleccionada,parametrosModal_Procesar.fechaInicio.split(' ')[0],parametrosModal_Procesar.ruta)
        const recorridos = await consultarRecorridosXRuta(empresaSeleccionada,parametrosModal_Procesar.ruta)

        setControlesTablaServicios(controles)

        FuncionGeneradoraDeServicios({
            frecuencias:    frecuencias,
            controles:      controles,
            tiempos:        tiempos,
            recorridos:     recorridos
        })

        closeModalGenerar()
    }
    //******************************************************* */

    const actualizarParametrosModal = (key,valor) => {
        setParametrosModal(valorAnterior => ({
            ...valorAnterior,
            [key]:valor
        }))
    }
    const tabsContents = () => {
        const TabContenido = ({contenido}) => <>{contenido}</>
        
        const tab1 = () => {
            const altoTotal = 700
            const margenIzquierdoDerecho = 60
            const margenSuperiorInferior = 80
            const anchoTotal = longitudTiempoServicios + 2*margenIzquierdoDerecho //1800 
            const factorConversionMinutosToPixeles = minutesToPixeles
            
            return(
                <div ref={divRef} className='w-full h-[700px] m-auto'>
                    <Paper width={anchoTotal} height={altoTotal} container={{style:{background:'black',border:'1px solid white',width: anchoTotal}}}>
                        {/* LINEAS DELIMITANTES */}
                        <Text 
                            x={margenIzquierdoDerecho - 15} 
                            y={margenSuperiorInferior + 2} 
                            text="B" 
                            attr={{fill:"white","font-size":"25"}}
                        />
                        <Line 
                            x1={margenIzquierdoDerecho} 
                            y1={margenSuperiorInferior} 
                            x2={anchoTotal-margenIzquierdoDerecho} 
                            y2={margenSuperiorInferior} 
                            attr={{stroke:"white",strokeWidth:"2"}}
                        />
                        <Text 
                            x={margenIzquierdoDerecho - 15} 
                            y={altoTotal-margenSuperiorInferior + 2} 
                            text="A" 
                            attr={{fill:"white","font-size":"25"}}
                        />
                        <Line 
                            x1={margenIzquierdoDerecho} 
                            y1={altoTotal-margenSuperiorInferior} 
                            x2={anchoTotal-margenIzquierdoDerecho} 
                            y2={altoTotal-margenSuperiorInferior} 
                            attr={{stroke:"white",strokeWidth:"2"}}
                        />
                        {/* HORAS SUPERIORES */}
                        {
                            viajesLadoB.map((data,i) => {
                                return(
                                    <React.Fragment key={i+1}>
                                        <Text 
                                            x={(Math.floor((stringToDate(data.viaje.hsalida) - tiempoInicial) / 60000) * factorConversionMinutosToPixeles) + margenIzquierdoDerecho} 
                                            y={margenSuperiorInferior - 30} 
                                            text={data.viaje.hsalida.split(' ')[1]}
                                            attr={{fill:"white","font-size":"12",transform:"r-90"}}
                                        />
                                    </React.Fragment>
                                )
                            })
                        }
                        {/* HORAS INFERIORES */}
                        {
                            viajesLadoA.map((data,i) => {
                                return(
                                    <React.Fragment key={i+1}>
                                        <Text 
                                            x={(Math.floor((stringToDate(data.viaje.hsalida) - tiempoInicial) / 60000) * factorConversionMinutosToPixeles) + margenIzquierdoDerecho} 
                                            y={altoTotal - margenIzquierdoDerecho + 5} 
                                            text={data.viaje.hsalida.split(' ')[1]}
                                            attr={{fill:"white","font-size":"12",transform:"r-90"}}
                                        />
                                    </React.Fragment>
                                )
                            })
                        }
                        {/* LINEAS VIAJES A */}
                        {
                            viajesLadoA.map((data,i) => {
                                return(
                                    <React.Fragment key={i+1}>
                                        <Line
                                            x1={(Math.floor((stringToDate(data.viaje.hsalida) - tiempoInicial) / 60000) * factorConversionMinutosToPixeles) + margenIzquierdoDerecho}
                                            y1={altoTotal - margenSuperiorInferior}
                                            x2={(Math.floor((stringToDate(data.viaje.hfin) - tiempoInicial) / 60000) * factorConversionMinutosToPixeles) + margenIzquierdoDerecho}
                                            y2={margenSuperiorInferior}
                                            attr={{stroke: data.nservicio == servicioSeleccionado ? "yellow" : "white","stroke-width": data.nservicio == servicioSeleccionado ? "5" : "2"}}
                                            click={() => {setServicioSeleccionado(data.nservicio)}}
                                        />
                                    </React.Fragment>
                                )
                            })
                        }
                        {/* LINEAS VIAJES B */}
                        {
                            viajesLadoB.map((data,i) => {
                                return(
                                    <React.Fragment key={i+1}>
                                        <Line
                                            x1={(Math.floor((stringToDate(data.viaje.hsalida) - tiempoInicial) / 60000) * factorConversionMinutosToPixeles) + margenIzquierdoDerecho}
                                            y1={margenSuperiorInferior }
                                            x2={(Math.floor((stringToDate(data.viaje.hfin) - tiempoInicial) / 60000) * factorConversionMinutosToPixeles) + margenIzquierdoDerecho}
                                            y2={altoTotal - margenSuperiorInferior}
                                            attr={{stroke: data.nservicio == servicioSeleccionado ? "yellow" : "white","stroke-width": data.nservicio == servicioSeleccionado ? "5" : "2"}}
                                            click={() => {setServicioSeleccionado(data.nservicio)}}
                                        />
                                    </React.Fragment>
                                )
                            })
                        }

                        {/* PARA LOS ARCOS */}
                        {/* {
                            arcos.map((data,i) => {
                                return data.horasArco.map((data2,j) => {
                                    return(
                                        <React.Fragment>
                                            <ComponenteArco
                                                x1={(Math.floor((stringToDate(data2[0]) - tiempoInicial) / 60000) * factorConversionMinutosToPixeles) + margenIzquierdoDerecho}
                                                x2={(Math.floor((stringToDate(data2[1]) - tiempoInicial) / 60000) * factorConversionMinutosToPixeles) + margenIzquierdoDerecho}
                                                y={
                                                    data.ladoInicio == 'A' ? 
                                                        j%2 == 0 ? 
                                                            margenSuperiorInferior
                                                        :   altoTotal + margenSuperiorInferior
                                                    :
                                                        j%2 == 0 ? 
                                                            altoTotal + margenSuperiorInferior
                                                        :   margenSuperiorInferior
                                                }
                                            ></ComponenteArco>
                                        </React.Fragment>
                                    )
                                }).flat()
                            })
                        } */}

                    </Paper>
                </div>
            )
        }
        
        const tab2 = () => {
            const intervalosHoras = () => {
                const horas = []
                for (let i = 0; i < 24; i++) {
                    horas.push(
                        <th key={i+1} className='whitespace-nowrap'>{i.toString().padStart(2,'0')}:00 - {(i+1).toString().padStart(2,'0')}:00</th>
                    )
                }
                return <>{horas}</>
            }

            const diferenciaMilisegundos = (hora) => {
                const medianoche = new Date('1970-01-01T00:00:00');
                const horaProporcionada = new Date(`1970-01-01T${hora}`);
                const diferenciaEnMilisegundos = horaProporcionada - medianoche;
                return diferenciaEnMilisegundos;
            }
            
            return(
                <div>
                    <table className='table'>
                        <thead>
                            <tr className='sticky z-50'>
                                <th className='!sticky !top-0 !left-0 z-50'>Nº SERVICIO</th>
                                {intervalosHoras()}
                            </tr>
                        </thead>
                        <tbody>
                            {arrayServiciosGenerados.map((servicios,i) => {
                                if(i != 0){
                                    return(
                                        <tr key={i+1}>
                                            <td className='text-center sticky left-0 bg-[#222222] z-10'>{i}</td>
                                            <td colSpan={24} className='!p-0'>
                                                <div className='w-full h-full py-1 relative'>
                                                {
                                                    servicios.map((viajes,j) => {
                                                        const porcentajeIzquierda = (diferenciaMilisegundos(viajes.viaje.hsalida.split(' ')[1])*100)/86400000
                                                        const ancho = ((diferenciaMilisegundos(viajes.viaje.hfin.split(' ')[1])-diferenciaMilisegundos(viajes.viaje.hsalida.split(' ')[1]))*100)/86400000
                                                        
                                                        return(
                                                            <div 
                                                                title={`H.INICIO: ${viajes.viaje.hsalida.split(' ')[1]}, H.FIN: ${viajes.viaje.hfin.split(' ')[1]}`} 
                                                                key={j+1} 
                                                                style={{
                                                                    left:`${parseInt(porcentajeIzquierda)}%`,
                                                                    width:`${parseInt(ancho)}%`,
                                                                    background:`${viajes.viaje.lado == 'A' ? '#2e9af9' : '#835cfe'}`,
                                                                    backgroundImage: 'linear-gradient(to left, var(--tw-gradient-stops))'
                                                                }} 
                                                                className={`absolute flex items-center justify-center top-0 h-[15px] border ${viajes.viaje.lado == 'A' ? 'rounded-r-3xl' : 'rounded-l-3xl'} bg-gradient-to-l`}
                                                            >
                                                                <i className={`fa ${viajes.viaje.lado == 'A' ? 'fa-long-arrow-right' : 'fa-long-arrow-left'}`} aria-hidden="true"></i>
                                                            </div>
                                                        )
                                                    })
                                                }
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                }
                            })}
                        </tbody>
                    </table>
                </div>
            )
        }
        
        const tab3 = () => {
            return(
                <div className='w-full h-full '>
                    <table style={{borderSpacing:0}} className='table containerScroll !border-separate'>
                        <thead>
                            <tr>
                                <th rowSpan={2}>Nº SERV</th>
                                <th rowSpan={2}>H.SALIDA</th>
                                <th className='border !bg-[#0d56ab]' colSpan={arrayServiciosGenerados.length != 0 ? controlesTablaServicios.filter(e => e.Lado == 'A').length : 1}>CONTROLES A → B</th>
                                <th rowSpan={2}>H.LLEGADA</th>
                                <th rowSpan={2}>H.SALIDA</th>
                                <th className='border !bg-[#80169b]' colSpan={arrayServiciosGenerados.length != 0 ? controlesTablaServicios.filter(e => e.Lado == 'B').length : 1}>CONTROLES B → A</th>
                                <th rowSpan={2}>H.LLEGADA</th>
                            </tr>
                            {
                                arrayServiciosGenerados.length != 0 && 
                                (<tr>
                                    {
                                        controlesTablaServicios.filter(e => e.Lado == 'A').map((data,i) => {
                                            return (<th key={i+1} className='!bg-[#0d56ab] h-[70px] border'><div className='-rotate-90 whitespace-nowrap max-w-[50px] overflow-hidden text-ellipsis' title={data.NomControl}>{data.NomControl}</div></th>)
                                        })
                                    }
                                    {
                                        controlesTablaServicios.filter(e => e.Lado == 'B').map((data,i) => {
                                            return (<th key={i+1} className='!bg-[#80169b] h-[70px] border'><div className='-rotate-90 whitespace-nowrap max-w-[50px] overflow-hidden text-ellipsis' title={data.NomControl}>{data.NomControl}</div></th>)
                                        })
                                    }
                                </tr>)
                            }
                        </thead>
                        <tbody>
                            {
                                arrayServiciosGenerados.length != 0 
                                    ?
                                    arrayServiciosGenerados.flatMap((data,i) => {
                                        return (data.map((data2,j) => {
                                            if(data2.nservicio != 0 && j%2==0){
                                                return(
                                                        <tr key={`${i+1}-${j+1}`}>
                                                            <td className='text-center'>{data2.nservicio}</td>
                                                            <td className='whitespace-nowrap'>{data2.viaje.hsalida}</td>
                                                            {
                                                                data2.viaje.tiemposcontrol.map((controles,k) => {
                                                                    return(
                                                                        <td key={k+1}>{controles.hprogramada.split(' ')[1]}</td>
                                                                    )
                                                                })
                                                            }
                                                            <td className='whitespace-nowrap border-r'>{data2.viaje.hfin}</td>
                                                            <td className='whitespace-nowrap'>{data[j+1]?.viaje.hsalida}</td>
                                                            {
                                                                data[j+1]?.viaje.tiemposcontrol.map((controles,k) => {
                                                                    return(
                                                                        <td key={k+1}>{controles.hprogramada.split(' ')[1]}</td>
                                                                    )
                                                                })
                                                            }
                                                            <td className='whitespace-nowrap'>{data[j+1]?.viaje.hfin}</td>
                                                        </tr>
                                                )
                                            }
                                        }))
                                    })
                                    :
                                    <tr><td colSpan={8} className='text-center align-middle'>No hay informacion con los parametros seleccionados...</td></tr>
                            }
                        </tbody>
                    </table>
                </div>
            )
        }

        const tab4 = () => {
            return(
                <>
                    <div className='flex-grow flex items-center justify-center'>
                        <div class="w-full max-w-md p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
                            <div class="flex items-center justify-between mb-4">
                                <h5 class="text-xl font-bold leading-none text-gray-900 dark:text-white">Información</h5>
                            </div>
                            <div class="flow-root">
                                    <ul role="list" class="divide-y divide-gray-200 dark:divide-gray-700">
                                        <li class="py-3 sm:py-4">
                                            <div class="flex items-center">
                                                <div class="flex-shrink-0">
                                                </div>
                                                <div class="flex-1 min-w-0 ms-4">
                                                    <p class="text-sm font-medium text-gray-900 truncate dark:text-white">
                                                    RUTA:
                                                    </p>
                                                </div>
                                                <div class="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                                    
                                                </div>
                                            </div>
                                        </li>
                                        <li class="py-3 sm:py-4">
                                            <div class="flex items-center">
                                                <div class="flex-shrink-0">
                                                </div>
                                                <div class="flex-1 min-w-0 ms-4">
                                                    <p class="text-sm font-medium text-gray-900 truncate dark:text-white">
                                                        HORA INICIO:
                                                    </p>
                                                </div>
                                                <div class="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                                    {parametrosModal_Procesar.fechaInicio.split(' ')[1]}
                                                </div>
                                            </div>
                                        </li>
                                        <li class="py-3 sm:py-4">
                                            <div class="flex items-center">
                                                <div class="flex-shrink-0">
                                                </div>
                                                <div class="flex-1 min-w-0 ms-4">
                                                    <p class="text-sm font-medium text-gray-900 truncate dark:text-white">
                                                        HORA FINAL:
                                                    </p>
                                                </div>
                                                <div class="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                                    {parametrosModal_Procesar.fechaFin.split(' ')[1]}
                                                </div>
                                            </div>
                                        </li>
                                        <li class="py-3 sm:py-4">
                                            <div class="flex items-center">
                                                <div class="flex-shrink-0">
                                                </div>
                                                <div class="flex-1 min-w-0 ms-4">
                                                    <p class="text-sm font-medium text-gray-900 truncate dark:text-white">
                                                        CANT. SERVICIOS GENERADOS:
                                                    </p>
                                                </div>
                                                <div class="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                                    {arrayServiciosGenerados?.length - 1}
                                                </div>
                                            </div>
                                        </li>
                                        <li class="py-3 sm:py-4">
                                            <div class="flex items-center">
                                                <div class="flex-shrink-0">
                                                </div>
                                                <div class="flex-1 min-w-0 ms-4">
                                                    <p class="text-sm font-medium text-gray-900 truncate dark:text-white">
                                                    CANT. VIAJES LADO A:
                                                    </p>
                                                </div>
                                                <div class="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                                    {arrayServiciosGenerados.reduce((acumulador,servicio) => {
                                                        return acumulador + servicio.reduce((acumulador2,viajes) => {
                                                            if(viajes.viaje?.lado == 'A' && viajes.nroservicio != 0) return acumulador2 + 1
                                                            else return acumulador2
                                                        },0)
                                                    },0)}
                                                </div>
                                            </div>
                                        </li>
                                        <li class="py-3 sm:py-4">
                                            <div class="flex items-center">
                                                <div class="flex-shrink-0">
                                                </div>
                                                <div class="flex-1 min-w-0 ms-4">
                                                    <p class="text-sm font-medium text-gray-900 truncate dark:text-white">
                                                        CANT. VIAJES LADO B:
                                                    </p>
                                                </div>
                                                <div class="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                                    {arrayServiciosGenerados.reduce((acumulador,servicio) => {
                                                        return acumulador + servicio.reduce((acumulador2,viajes) => {
                                                            if(viajes.viaje?.lado == 'B' && viajes.nroservicio != 0) return acumulador2 + 1
                                                            else return acumulador2
                                                        },0)
                                                    },0)}
                                                </div>
                                            </div>
                                        </li>
                                        <li class="py-3 sm:py-4">
                                            <div class="flex items-center">
                                                <div class="flex-shrink-0">
                                                </div>
                                                <div class="flex-1 min-w-0 ms-4">
                                                    <p class="text-sm font-medium text-gray-900 truncate dark:text-white">
                                                    CANT. VIAJES SIN SERV:
                                                    </p>
                                                </div>
                                                <div class="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                                    {arrayServiciosGenerados[0]?.length || 0}
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                            </div>
                        </div>
                    </div>
                </>
            )
        }

        return [
            <TabContenido contenido={tab1()} />,
            <TabContenido contenido={tab2()} />,
            <TabContenido contenido={tab3()} />,
            <TabContenido contenido={tab4()} />
        ]
    }

    const UpperHalfEllipse = ({ cx, cy, rx, ry }) => {
        // Define el path de la mitad superior de la elipse
        const pathString = `M ${cx - rx}, ${cy} A ${rx}, ${ry} 0 0, 1 ${cx + rx}, ${cy}`;
    
        return (
            <Path
                d={pathString}
                attr={{
                    stroke: 'purple',
                    // fill: 'lightblue',
                }}
            />
        );
    };

    const ComponenteArco = ({x1,x2,y}) => {
        const cx = parseInt((x2 + x1)/2);
        const rx = parseInt((x2 - x1)/2);
        const cy = y;
        const ry = 50;
        const pathString = `M ${cx - rx}, ${cy} A ${rx}, ${ry} 0 0, 1 ${cx + rx}, ${cy}`;

        return (
                <Path
                    d={pathString}
                    attr = {{
                        stroke: 'purple',
                        'stroke-width': 3
                    }}
                ></Path>
        )
    }

    const VariableWidthArc = ({ startX, startY, endX, innerRadius, outerRadius, height }) => {
        // Define the height of the arc
        const arcHeight = height;
    
        // Calculate the points for the inner and outer arcs
        const innerStartX = startX;
        const innerEndX = endX;
        const innerStartY = startY - innerRadius;
        const innerEndY = startY - innerRadius;
    
        const outerStartX = startX;
        const outerEndX = endX;
        const outerStartY = startY - outerRadius;
        const outerEndY = startY - outerRadius;
    
        const largeArcFlag = outerRadius > innerRadius ? 1 : 0;
    
        // Create the path data for the variable width arc
        const pathData = [
            `M${innerStartX},${innerStartY}`,
            `A${innerRadius},${arcHeight} 0 ${largeArcFlag},1 ${innerEndX},${innerEndY}`,
            `L${outerEndX},${outerEndY}`,
            `A${outerRadius},${arcHeight} 0 ${largeArcFlag},0 ${outerStartX},${outerStartY}`,
            'Z'
        ].join(' ');
    
        return (
                <Set>
                    <Path d={pathData} attr={{ stroke: 'none', fill: '#3498db' }} />
                </Set>
        );
    };


    // *************** FUNCION QUE GENERA LOS SERVICIOS ***************
    const FuncionGeneradoraDeServicios = ({
        frecuencias,
        controles,
        tiempos,
        recorridos
    }) => {

        if([frecuencias.length,controles.length,tiempos.length].includes(0)){
            notify('Las frecuencias, controles o tiempos estan vacios','error')
            setArrayServiciosGenerados([])
            setViajesLadoA([])
            setViajesLadoB([])
            return
        }

        const controlesPorLado = groupBy(controles,'Lado')
        const controlesEnGrafico = {}
        
        for(const key in controlesPorLado){
            controlesEnGrafico[key] = controlesPorLado[key].map((data,i) => {
                if(data.CodControlTipo == 2){       //solo los que son de tipo control(2)
                    return {
                        codcontrol: data.CodControl,
                        nombre: data.NomControl,
                        orden: i+1
                    }
                }
            })
        }

        const jsonTiempoControl = tiempos.map((data,i) => {
            const fechahorainicio = parametrosModal_Procesar.fechaInicio.split(' ')[0] + ' ' + data.HoraInicio
            const fechahoraFin = parametrosModal_Procesar.fechaFin.split(' ')[0] + ' ' + data.HoraFin
            return  {
                codcontrol: data.CodControl,
                horaini: data.HoraInicio,
                horafin: data.HoraFin,
                tiempominuto: data.TiempoMinutos,
                timestamphini: convertDatetoTimeStamp(fechahorainicio),
                timestamphfin: convertDatetoTimeStamp(fechahoraFin)
            }
        })

        const jsonAgrupadoPorCodControl = {
            controles: groupBy(jsonTiempoControl,'codcontrol')
        }

        const jsonFrecuencias = frecuencias.map((data,i) => {
            const finicio = parametrosModal_Procesar.fechaInicio.split(' ')[0] + ' ' + data.HoraInicio
            const ffin = parametrosModal_Procesar.fechaFin.split(' ')[0] + ' ' + data.HoraFin
            return {
                lado: data.Lado,
                horainicio: data.HoraInicio,
                horafin: data.HoraFin,
                tiempominuto: data.Minutos,
                timestamptiempominuto: (data.Minutos * 60000),
                timestamphinicio: convertDatetoTimeStamp(finicio),
                timestamphfin: convertDatetoTimeStamp(ffin),
            }
        })

        const grupoFrecuenciasPorLado = {
            frecuencia: groupBy(jsonFrecuencias,'lado')
        }

        //###########################INFORMACION IGUAL HASTA ACA####################################

        const viajesGenerados = {
            A: [],
            B: []
        }

        for(const lado in viajesGenerados){
            let horainicioServicios = 
                parametrosModal_Procesar.inicioViajes == 3 ? 
                    lado == 'A' ?
                        convertDatetoTimeStamp(`${parametrosModal_Procesar.fechaInicioAB} ${parametrosModal_Procesar.hInicioA}`)
                    :
                        convertDatetoTimeStamp(`${parametrosModal_Procesar.fechaInicioAB} ${parametrosModal_Procesar.hInicioB}`)
                :
                convertDatetoTimeStamp(parametrosModal_Procesar.fechaInicio)
            let auxGeneraViajes = true

            while (auxGeneraViajes) {
                const item = {
                    hsalida: 0,
                    hfin: 0,
                    tiempoespera: '',
                    tiempoesperatimestamp: 0,
                    nroservicio: 0,
                    timestamphsalida: 0,
                    timestamphfin: 0,
                    tiemposcontrol: [],
                    lado: '',
                    timestamptiempoaplazamiento: 0,
                    minutostaplazamiento: 0,
                    sentido: '',
                    ladollegada: ''
                };

                const ifrecuencia = obtenerIFrecuenciaPorLadoYHora(lado, horainicioServicios, grupoFrecuenciasPorLado.frecuencia);  //SIEMPRE ESTA DANDO 0 -> CORREGIR
                const viajes = generarViajePorLado(lado, controles, horainicioServicios, jsonAgrupadoPorCodControl);

                item.lado = lado;
                item.hsalida = convertTimestampToDate(horainicioServicios);
                item.timestamphsalida = horainicioServicios;
                item.tiemposcontrol = viajes.viajeprogramado;//el detalle para saber datos del control por el que paso la unidad
                item.hfin = convertTimestampToDate(viajes.ultimahoracontrol);
                item.timestamphfin = viajes.ultimahoracontrol;
                item.tiempoespera = viajes.tiempoespera;
                item.tiempoesperatimestamp = viajes.tiempoespera * 60000;// 6 minutos en timeStamp
                item.sentido = recorridos.find(elem => elem.Lado == lado).Sentido;
                item.timestamptiempoaplazamiento = 0;
                item.minutostaplazamiento = 0;
                item.ladollegada = (viajes.ladofinal == 'A' ? 'B' : 'A'); //seteando el lado del terminal final si es A entonces sale B

                
                horainicioServicios = horainicioServicios + ifrecuencia.milisegundos;
                if ( horainicioServicios >= ( convertDatetoTimeStamp(parametrosModal_Procesar.fechaFin) ) ) {
                    auxGeneraViajes = false;
                    break
                }

                // SOLO INSERTA EN EL ARRAY CUANDO 
                viajesGenerados[lado].push(item);
            }
        }

        // ======
        // AQUI HACER UNA VALIDACION PARA QUE LOS VIAJES QUE NO ESTEN DENTRO DE LOS PARAMETROS NO SE GUARDEN
        if(parametrosModal_Procesar.inicioViajes == 1){     // DESDE A
            const fechaHoraMinimoB = viajesGenerados['A'][0].timestamphfin
            viajesGenerados['B'] = viajesGenerados['B'].filter(e => e.timestamphsalida >= fechaHoraMinimoB)
        }else if(parametrosModal_Procesar.inicioViajes == 2){   // DESDE B
            const fechaHoraMinimoA = viajesGenerados['B'][0].timestamphfin
            viajesGenerados['A'] = viajesGenerados['A'].filter(e => e.timestamphsalida >= fechaHoraMinimoA)
        }else if(parametrosModal_Procesar.inicioViajes == 3){       // DESDE A Y B
            // NADA

        }

        // ======


        //*************************HASTA AQUI LA GENERACION DE VIAJES (SIN SERVICIOS)****************************** */

        let ETIQUETA_NUMERACION_SERVICIO = {
            A: parametrosModal_Procesar.inicioViajes != 2 ? 1 : 0, //Donde empieza el servicio del lado A
            B: parametrosModal_Procesar.inicioViajes == 2 ? 1 : 0
        }
        let serviciosGeneradosTemp = {
            A: null,
            B: null
        }
        let serviciosGenerados = {
            servicios: null
        }
        let primerViajeData = {
            A: viajesGenerados.A[0],
            B: viajesGenerados.B[0]
        }

        let llegadaDelPrimerViajeLadoA = null;
        let llegadaDelPrimerViajeLadoB = null;


        if(parametrosModal_Procesar.inicioViajes == 2){     // CUANDO COMIENZA DE B
            serviciosGeneradosTemp.B = generarServiciosData(viajesGenerados, 'B', ETIQUETA_NUMERACION_SERVICIO.B, primerViajeData, true);
            ETIQUETA_NUMERACION_SERVICIO.A = serviciosGeneradosTemp.B.ncorteservicio + 1;

            serviciosGeneradosTemp.A = generarServiciosData(viajesGenerados, 'A', ETIQUETA_NUMERACION_SERVICIO.A, primerViajeData, true);
        }else{  // CUANDO COMIENZA DE A O ES AMBOS SENTIDOS
            serviciosGeneradosTemp.A = generarServiciosData(viajesGenerados, 'A', ETIQUETA_NUMERACION_SERVICIO.A, primerViajeData, true);
            ETIQUETA_NUMERACION_SERVICIO.B = serviciosGeneradosTemp.A.ncorteservicio + 1;

            serviciosGeneradosTemp.B = generarServiciosData(viajesGenerados, 'B', ETIQUETA_NUMERACION_SERVICIO.B, primerViajeData, true);
        }

        llegadaDelPrimerViajeLadoA = primerViajeData.A.timestamphfin;
        llegadaDelPrimerViajeLadoB = primerViajeData.B.timestamphfin;

        let primerViajeladoB = null;
        let ordernandoPorFechaHoraLadoB = null;

        // // ============================================================================================
        // // ==========================CONDICIONAL PARA EL ORIGEN DE VIAJES==============================
        // if(parametrosModal_Procesar.inicioViajes == 1){
        //     serviciosGeneradosTemp.B.servicios.forEach((data,i) => {
        //         if (llegadaDelPrimerViajeLadoA > data.viaje.timestamphsalida) {
        //             data.nservicio = 0;
        //             data.viaje.nroservicio = 0;
        //         }
        //     });
        // }else if(parametrosModal_Procesar.inicioViajes == 2){
        //     serviciosGeneradosTemp.A.servicios.forEach((data,i) => {
        //         if (llegadaDelPrimerViajeLadoB > data.viaje.timestamphsalida) {
        //             data.nservicio = 0;
        //             data.viaje.nroservicio = 0;
        //         }
        //     });
        // }else if(parametrosModal_Procesar.inicioViajes == 3){
        //     // PERMITIR TODOS LOS VIAJES
            
        // }

        // // ============================================================================================
        

        serviciosGenerados.servicios = serviciosGeneradosTemp.A.servicios.concat(serviciosGeneradosTemp.B.servicios); 

        let dataAgrupadaPorServicios = groupBy(serviciosGenerados.servicios,'nservicio')

        for(const key in dataAgrupadaPorServicios){
            corregirCantidadViajesPorServicio(dataAgrupadaPorServicios[key]); //corrije la cantidad de viajes segun el parametro ingresado en el formulario de nueva programacion
        };

        let viajesSinServiciosRPTA = null;

        let viajesHuerfanosTemporal = [];

        let agrupadoViajesHuerfanos = {
            'A': [],
            'B': []
        };

        for(const key in viajesGenerados){
            const data = viajesGenerados[key]
            data.forEach((data2,i) => {
                if (data2.nroservicio == 0) {
                    viajesHuerfanosTemporal.push(data2);
                }
            })
        };

        viajesHuerfanosTemporal.forEach(data => {
            agrupadoViajesHuerfanos[data.lado].push(data); //agrupado por lado de viajes huerfanos
        });

        let cantidadServiciosGenerados = 0;
        for(const key in dataAgrupadaPorServicios){
            if (Number(key) != 0) { //busco solo los servicios 0 (son los que no tienen viajes asignados)
                cantidadServiciosGenerados++;
            }
        };

        let primerViajeDataGenera = { //primer viaje de los nuevos servicios a generar
            A: agrupadoViajesHuerfanos.A[0],
            B: agrupadoViajesHuerfanos.B[0]
        }

        let serviciosGeneradosDeVacios = null;
        serviciosGeneradosDeVacios = generarServiciosData(agrupadoViajesHuerfanos, 'A', cantidadServiciosGenerados + 1, primerViajeDataGenera, false); //haciendo una pasada para la generacion de servicios

        if (dataAgrupadaPorServicios[0]?.length > 0) {
            viajesSinServiciosRPTA = viajesSinEnlace(dataAgrupadaPorServicios[0]); //obteniendo viajes

            viajesSinServiciosRPTA['A'] = _.orderBy(viajesSinServiciosRPTA['A'], ['timestamphsalida'], ['asc']); // ordena
            viajesSinServiciosRPTA['B'] = _.orderBy(viajesSinServiciosRPTA['B'], ['timestamphsalida'], ['asc']); // ordena
        }

        serviciosGenerados.servicios = [];
        for(const key in dataAgrupadaPorServicios){
            const data = dataAgrupadaPorServicios[key]
            data.forEach((elem) => {
                serviciosGenerados.servicios.push(elem);
            })
        };

        /****** agregando los servicios generados de los viajes huerfanos al grupo de servicios principal para pintarlos *******/
        serviciosGeneradosDeVacios.servicios = groupBy(serviciosGeneradosDeVacios.servicios,'nservicio')
        
        for(const key in serviciosGeneradosDeVacios.servicios){
        const data = serviciosGeneradosDeVacios.servicios[key]
            if (data.length > Number(parametrosModal_Procesar.minViajes)) {
                data.forEach(data2 => {
                    serviciosGenerados.servicios.push(data2); //agregando los viajes de los servicios con viajes huerfanos al principal
                });
            }
        };

        let cant_viajes_srv_cero = 0;
        for(const lado in viajesGenerados){
            const data = viajesGenerados[lado]
            if (lado == 'A') {
                data.forEach(data2 => {
                    if (data2.nroservicio == 0) {
                        cant_viajes_srv_cero++;
                    }
                });
            }
        };

        const grupoPorServicio = {
            servicios: groupBy(serviciosGenerados.servicios,'nservicio')
        }


        encontrarHorasMinMax(Object.values(grupoPorServicio.servicios))
        setArrayServiciosGenerados(Object.values(grupoPorServicio.servicios))
        
        
        //******************************HASTA AQUI LA GENERACION DE SERVICIOS********************************* */
    }

    const encontrarHorasMinMax = (serviciosGenerados) => {
        // const horaMaxima = new Date(`1970-01-01T${}`)
        const agrupadoHorasInicio = serviciosGenerados.slice(1).map((servicios,i) => {
            return servicios.map((viajes,j) => stringToDate(viajes.viaje.hsalida)).flat()
        }).flat()
        const agrupadoHorasFin = serviciosGenerados.slice(1).map((servicios,i) => {
            return servicios.map((viajes,j) => stringToDate(viajes.viaje.hfin)).flat()
        }).flat()

        const fechaMinimaDate = new Date(Math.min(...agrupadoHorasInicio))
        setTiempoInicial(fechaMinimaDate)
        const horaInicioMinima = dateToString(fechaMinimaDate)

        const fechaMaxDate = new Date(Math.max(...agrupadoHorasFin))
        const horaFinMaxima = dateToString(fechaMaxDate)

        const restaMinutos = fechaMaxDate - fechaMinimaDate
        const diferenciaMinutos = Math.floor(restaMinutos / 60000); // ANCHO TOTAL DE LA GRAFICA DE BARRAS -> SE DEBE AGREGAR LOS MARGENES DERECHOS E IZQUIERDOS
        setLongitudTiempoServicios(diferenciaMinutos*minutesToPixeles)    // ACTUALIZA EL TAMAÑO TOTAL DEL RECTANGULO DE RAPHAEL

        console.log("serviciosGenerados -> ",serviciosGenerados)
        if(Object.keys(serviciosGenerados).filter(e => Number(e) != 0).length == 0){    // PARA FILTRAR LOS SERVICIOS VALIDOS DISTINTOS DE CERO
            notify('No se pudo generar servicios con los tiempos de controles e intervalos de frecuencia registrados','info')
            return
        }

        const arcos = 
        serviciosGenerados.slice(1)
        .map((servicio) => {
            return ({
                ladoInicio: servicio[0].viaje.lado,
                horasArco:  servicio
                            .map(viaje => {
                                return [viaje.viaje.hsalida,viaje.viaje.hfin]
                            })
                            .flat()
                            .slice(1,-1)
                            .reduce((result, _, i, arr) => (i%2 === 0 ? [...result, arr.slice(i, i + 2)] : result), [])
            })
        })
        setArcos(arcos)

        setViajesLadoA(serviciosGenerados.slice(1).map((data,i) => data.filter(e => e.viaje.lado == 'A')).flat())

        setViajesLadoB(serviciosGenerados.slice(1).map((data,i) => data.filter(e => e.viaje.lado == 'B')).flat())
    }
    const stringToDate = (fechaHora) => {
        const [fecha,hora] = fechaHora.split(' ')
        const [dia,mes,anio] = fecha.split('/')
        const [horas,minutos] = hora.split(':')
        return new Date(anio, mes - 1, dia, horas, minutos)
    }
    const dateToString = (fecha) => {
        const dia = String(fecha.getDate()).padStart(2, '0');
        const mes = String(fecha.getMonth() + 1).padStart(2, '0'); // Los meses en JS van de 0 a 11
        const año = fecha.getFullYear();
        const horas = String(fecha.getHours()).padStart(2, '0');
        const minutos = String(fecha.getMinutes()).padStart(2, '0');
        return `${dia}/${mes}/${año} ${horas}:${minutos}`;
    }


    // *************** FUNCIONES QUE UTILIZA LA FUNCION PRINCIPAL DE GENERAR SERVICIOS ***************
    const viajesSinEnlace = (data) => {
        let rptaViajesSinServicio = {
            A: [],
            B: []
        };
        data.forEach(data => {
            data.viaje.nroservicio = 0;
            rptaViajesSinServicio[data.viaje.lado].push(data.viaje)
        });
        return rptaViajesSinServicio;
    }
    const generarServiciosData = (dataviajes, lado, etiquetaServicio, dataViajesIniciales, auxEnlazandoPrimeraVez) => {
        etiquetaServicio = etiquetaServicio - 1;
        let rptaServiciosGenerados = {
            servicios: [],
            ncorteservicio: 0
        };
        let generaServicios = true;
        let contadorprueba = 1;
        let GENERA_SRV_LADO = lado;

        let primerViaje = {
            A: {
                lado: dataViajesIniciales["A"].lado,
                hsalida: dataViajesIniciales["A"].hsalida,
                hllegada: dataViajesIniciales["A"].hfin,
                hsalidatimestamp: convertDatetoTimeStamp(dataViajesIniciales["A"].hsalida),
                hllegadatimestamp: convertDatetoTimeStamp(dataViajesIniciales["A"].hfin),
                tiempoesperatimestamp: dataViajesIniciales["A"].tiempoesperatimestamp,
                consideratiempoespera: false,
                sentido: dataViajesIniciales["A"].sentido,
                tiemposcontrol: dataViajesIniciales["A"].tiemposcontrol
            },
            B: {
                lado: dataViajesIniciales["B"].lado,
                hsalida: dataViajesIniciales["B"].hsalida,
                hllegada: dataViajesIniciales["B"].hfin,
                hsalidatimestamp: convertDatetoTimeStamp(dataViajesIniciales["B"].hsalida),
                hllegadatimestamp: convertDatetoTimeStamp(dataViajesIniciales["B"].hfin),
                tiempoesperatimestamp: dataViajesIniciales["B"].tiempoesperatimestamp,
                consideratiempoespera: false,
                sentido: dataViajesIniciales["B"].sentido,
                tiemposcontrol: dataViajesIniciales["B"].tiemposcontrol
            }
        }

        /*enlazando los servicios*/
        for (let i = 0; (i < dataviajes[GENERA_SRV_LADO].length) ; i++) {
            let enlazarServicio = true;
            let viajeInicial = dataviajes[GENERA_SRV_LADO][i];
            let numservicio = i + 1;
            etiquetaServicio = etiquetaServicio + 1;
            let itemServicio = {
                nservicio: etiquetaServicio,
                viaje: viajeInicial
            };

            if (auxEnlazandoPrimeraVez) { //para identificar la generacion de viajes de que tipo es si es uno inicial o es el parche de los huerfanos
                if (viajeInicial.timestamphsalida > primerViaje[lado == 'A' ? 'B' : 'A'].hllegadatimestamp) { //genera los enlaces del lado A hasta que sera menor que el fin del lado A
                    rptaServiciosGenerados.ncorteservicio = etiquetaServicio - 1;//menos uno por que esta dentro del bucle comienza con cero
                    return rptaServiciosGenerados;
                }
            }


            let busqueda = {
                lado: viajeInicial.lado == 'A' ? 'B' : 'A',
                fhorabusqueda: viajeInicial.timestamphfin + viajeInicial.tiempoesperatimestamp,
                nservicio: numservicio
            }

            viajeInicial.nroservicio = numservicio//seteando el num del servicio del primer viaje
            rptaServiciosGenerados.servicios.push(itemServicio); 

            let arregloDataServicios = [];
            while (enlazarServicio) { //aqui enlaza para generar el servicio
                let viajeEncontrado = enlazarViajev2(
                        busqueda.lado,//Busca viaje del lado contrario
                        dataviajes,
                        busqueda.fhorabusqueda,
                        busqueda.nservicio
                );

                itemServicio = {
                    nservicio: etiquetaServicio,
                    viaje: viajeEncontrado
                };

                if (!viajeEncontrado) {//no encuentra enlac entonces aumenta el contador de nservicio servicio
                    arregloDataServicios.push(itemServicio);
                    enlazarServicio = false;
                } else { //encuentra enlac
                    rptaServiciosGenerados.servicios.push(itemServicio);
                    busqueda = { //nueva busqueda
                        lado: viajeEncontrado.lado == 'A' ? 'B' : 'A',
                        fhorabusqueda: viajeEncontrado.timestamphfin + viajeEncontrado.tiempoesperatimestamp,
                        nservicio: numservicio
                    }
                }
                arregloDataServicios.push(itemServicio);
            }
            corregirCantidadViajesPorServicio(arregloDataServicios);
        }
        return rptaServiciosGenerados;
    }
    const corregirCantidadViajesPorServicio = (arregloData) => { //funcion me permite corregir la cantidad de servicio segun el parametro ingresado VIAJES MIN y VIAJES MAX
        let cantidadViajesEnServicio = 0;
        arregloData.forEach(data => {
            let numero_servicio = data.nservicio;
            let Dataviaje = data.viaje;
            if (numero_servicio != 0 && Dataviaje) {
                data.viaje.nroservicio = numero_servicio;
                cantidadViajesEnServicio++;
            }
        });

        let restricciones = {
            cumpleMinimo: false,
            cumpleMaximo: false
        }

        let diferenciaViajes = 0;
        if (cantidadViajesEnServicio >= Number(parametrosModal_Procesar.minViajes)) { // si la cantidad de viajes es mas o igual que el minimo cumple con el minimo
            restricciones.cumpleMinimo = true;
            if (restricciones.cumpleMinimo && cantidadViajesEnServicio < Number(parametrosModal_Procesar.maxViajes)) {//cumple con el minimo y es menor que el maximo
                diferenciaViajes = Number(parametrosModal_Procesar.maxViajes) - cantidadViajesEnServicio;
            }

            if (restricciones.cumpleMinimo && cantidadViajesEnServicio > Number(parametrosModal_Procesar.maxViajes)) { //cumple con el minimo y es mayor que el maximo
                diferenciaViajes = cantidadViajesEnServicio - Number(parametrosModal_Procesar.maxViajes);
                /*******************************************************************************************/
                for (let viaje = 0; viaje < arregloData.length; viaje++) {

                    if (arregloData[viaje].viaje == null) {
                        arregloData[viaje].nservicio = 0;
                    }

                    if (viaje >= Number(parametrosModal_Procesar.maxViajes)) { //si el viaje es igual o mayor a la cantidad maxima configurada de viajes por servicio.
                        if (arregloData[viaje].nservicio && arregloData[viaje].viaje) { //si tiene viaje y tiene un servicio asignado
                            arregloData[viaje].nservicio = 0; //libera el servicio porque sobrepasa la máxima cantidad de viajes
                            arregloData[viaje].viaje.nroservicio = 0; //libera el servicio porque sobrepasa la máxima cantidad de viajes
                        }
                    }

                }
            }
        }

        if (cantidadViajesEnServicio < Number(parametrosModal_Procesar.minViajes)) { //si la cantidad de viajes generados es menor que el minimo
            restricciones.cumpleMinimo = false;
            arregloData.forEach(data => {
                let num_servicio = data.nservicio;
                let viaje = data.viaje;

                if (num_servicio && viaje) {
                    data.nservicio = 0; //se libera el servicio en caso no cumpla con la condición
                    data.viaje.nroservicio = 0; //se libera el servicio en caso no cumpla con la condición
                }

                if (viaje == null) {
                    data.nservicio = 0; //se libera el servicio en caso no cumpla con la condición
                }
            });
        }
    }
    const enlazarViajev2 = (lado, jsonDataViajeLados, hfinalViajeConsulta, nservicio) => { //criterio de enlace los primeros viajes primeros

        let rpta = null;
        jsonDataViajeLados[lado].forEach(data => {
            if(!rpta){  //CUANDO YA SE LLENO UN VALOR EN RPTA YA NO SIGUE (RETURN Y BREAK NO SIRVEN PARA INTERRUMPIR EL FOREACH)
                if (data.timestamphsalida >= hfinalViajeConsulta && (nservicio == null ? true : data.nroservicio == 0)) {
                    let taplaza = 0;
                    data.nroservicio = nservicio;
                    rpta = data;
                }
            }
        });
        return rpta;
    }
    const obtenerIFrecuenciaPorLadoYHora = (lado, fhorainicioservTimestamp, datosFrecuencia) => { //busca el tiempo de frecuencia segun el lado y la hora de inicio del viaje
        // const encontrado = datosFrecuencia[lado].find(data => data.timestamphinicio <= fhorainicioservTimestamp && data.timestamphfin >= fhorainicioservTimestamp)
        // return {
        //     tiempo: encontrado?.tiempominuto || 0,
        //     milisegundos: encontrado?.timestamptiempominuto || 0
        // };

        let rptaFrecuencia = {
            tiempo: 0,
            milisegundos: 0
        };

        datosFrecuencia[lado].forEach(data => {
            if (data.timestamphinicio <= fhorainicioservTimestamp && data.timestamphfin >= fhorainicioservTimestamp) {// si la hora inicio viaje esta dentro del rango de los parametros quew estan en bd
                rptaFrecuencia.tiempo = data.tiempominuto;
                rptaFrecuencia.milisegundos = data.timestamptiempominuto;
            }
        });
        return rptaFrecuencia;
    }
    const generarViajePorLado = (lado, dataJsonControles, fhorainicioOperacion, jsonHorasAgrupadosPorControl) => { // devuelve la lista de horas programadas en los controles segun los tiempos de control
        const rptaArregloViajes = {
            viajeprogramado: [],
            ultimahoracontrol: 0,
            tiempoespera: 0,
            ladofinal: ''
        };
        let fechaHoraViajeTimeStamp = fhorainicioOperacion;
        
        dataJsonControles.forEach((data) => {
            let tiempoControlAdd = 0;
            let item = {};
            let horasalida = new Date(fechaHoraViajeTimeStamp);
            let timestamphsalida = parametrosModal_Procesar.fechaInicio.split(' ')[0] + ' ' + horasalida.getHours() + ':' + horasalida.getMinutes() + ':' + horasalida.getSeconds();
            if (data.Lado == lado) {
                let nuevaFechaHoraTimestamp = '';
                tiempoControlAdd = getTiempoMinutos(convertDatetoTimeStamp(timestamphsalida), data.Lado, data.CodControl, jsonHorasAgrupadosPorControl);
                nuevaFechaHoraTimestamp = fechaHoraViajeTimeStamp + ((data.CodControlTipo == 4 ? 0  : tiempoControlAdd) * 60000); //si es tiempo de espera entonces es = 0
                fechaHoraViajeTimeStamp = nuevaFechaHoraTimestamp;
                item = {
                    nombrecontrol: data.NomControl,
                    codcontrol: data.CodControl,
                    codtipocontrol: data.CodControlTipo,
                    ordencontrol: data.NroOrden,
                    hprogramada: convertTimestampToDate(fechaHoraViajeTimeStamp),
                    hprogramadatimestamp: fechaHoraViajeTimeStamp,
                    tiempominutos: tiempoControlAdd,
                    lado: data.Lado
                }
                rptaArregloViajes.viajeprogramado.push(item);
            }
        });

        rptaArregloViajes.ultimahoracontrol = rptaArregloViajes.viajeprogramado[rptaArregloViajes.viajeprogramado.length - 1].hprogramadatimestamp; // obtiene la hora del ultimo control ( despacho 0 ) + el tiempo de espera
        rptaArregloViajes.ladofinal = rptaArregloViajes.viajeprogramado[rptaArregloViajes.viajeprogramado.length - 1].lado; // 
        rptaArregloViajes.tiempoespera = rptaArregloViajes.viajeprogramado[rptaArregloViajes.viajeprogramado.length - 1].tiempominutos; // obtiene la hora de tiempo de espera
        return rptaArregloViajes;
    }
    const getTiempoMinutos = (timestampFechaHora, lado, codigoControl, jsonTiempoControl) => { // busca en la malla horaria el tiempo de control segun la hora en la que esta
        let rptaTiempo = 0;
        let tiemposDeControl = jsonTiempoControl.controles[codigoControl];
        if (tiemposDeControl) {
            if (tiemposDeControl.length == 1) {
                rptaTiempo = tiemposDeControl[0].tiempominuto;
            } else {
                tiemposDeControl.forEach(data => {
                    if (data.timestamphini <= timestampFechaHora && data.timestamphfin >= timestampFechaHora) {
                        rptaTiempo = data.tiempominuto;
                    }
                });
            }
        } else {
            rptaTiempo = 0;
        }
        return rptaTiempo;
    }


    return (
        <>
            <ContenedorParametros
                titulo='Generación de servicios'
            >
                <div className='flex gap-2 items-center'>
                    <i className="fa fa-home" aria-hidden="true" title='Empresa'></i>
                    {
                        empresasGeneral.length != 0 
                        &&
                        <Select2 
                            jsonOpciones={empresasGeneral.map(data => ({
                                valor: data.codEmpresa,
                                texto: data.nomEmpresa
                            }))}
                            textoPrimerValor='Seleccione'
                            recibirValor={(valor) => setEmpresaSeleccionada(valor)}
                        />
                    }
                </div>
                {
                    empresaSeleccionada != 0 &&
                    <BotonNuevo
                        onClick={() => {
                            openModalGenerar()
                            setParametrosModal(parametrosDefault)
                        }}
                    />
                }
            </ContenedorParametros>

            <div className='h-[90%] pt-3'>
                <TabsABX 
                    nameTabs={['Simulación Gráfica','GANTT','Generado','Resumen']}
                    contentTabs={tabsContents()}
                    tabActivaOpcional={0}
                />
            </div>

            <ModalSinRenderizacion
                title='Generar programación'
                isOpen={isOpenGenerar}
                action={() => {
                    dioClickBtnGuardar.current = true
                    setParametrosModal_Procesar(parametrosModal)
                }}
                closeModal={closeModalGenerar}
                textButtons={{ confirm: 'Generar', denied: 'Cancelar' }}
            >
                <div className='grid grid-cols-2 gap-4 px-5'>
                    <div>Ruta</div>
                    <div>
                        <select 
                            value={parametrosModal.ruta}
                            onChange={(e) => {actualizarParametrosModal('ruta',e.target.value)}}
                            className='w-full classNameInput'
                        >
                            <option value={0}>Seleccione</option>
                            {
                                rutasGeneral?.map((data,i) => (
                                    <option key={i+1} value={data.codRuta}>{data.nomRuta}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div>Tipo servicio</div>
                    <div>
                        <select 
                            value={parametrosModal.tipoServicio}
                            onChange={(e) => {actualizarParametrosModal('tipoServicio',e.target.value)}}
                            className='w-full classNameInput'
                        >
                            <option value={1}>Regular</option>
                        </select>
                    </div>
                    <div>Inicio viajes</div>
                    <div>
                        <select 
                            value={parametrosModal.inicioViajes}
                            onChange={(e) => {actualizarParametrosModal('inicioViajes',e.target.value)}}
                            className='w-full classNameInput'
                        >
                            <option value={1}>Desde A</option>
                            <option value={2}>Desde B</option>
                            <option value={3}>Ambos lados</option>
                        </select>
                    </div>
                    <div className={`${parametrosModal.inicioViajes == 3 && 'hidden'}`}>F.Inicio</div>
                    <div className={`${parametrosModal.inicioViajes == 3 && 'hidden'} flex w-[200px] lg:w-full gap-4`}>
                        {/* <DatePickerABX></DatePickerABX>
                        <input type='time' className='classNameInput'/> */}
                        <DateTimePicker
                            date={stringToDateTime(parametrosModal.fechaInicio)}
                            setDate={(valor) => actualizarParametrosModal('fechaInicio',dateTimeToString(valor))}
                            styles={{width: '100%'}}
                        ></DateTimePicker>
                        {/* <input type='text' className='classNameInput' value={parametrosModal.fechaInicio} onChange={(e) => actualizarParametrosModal('fechaInicio',e.target.value)} disabled/> */}
                    </div>
                    {/*  */}
                    <div className={`${parametrosModal.inicioViajes != 3 && 'hidden'}`}>F.Inicio</div>
                    <div className={`${parametrosModal.inicioViajes != 3 && 'hidden'}`}>
                        <DatePickerABX
                            date={stringToDateFormat(parametrosModal.fechaInicioAB)}
                            setDate={(valor) => actualizarParametrosModal('fechaInicioAB',dateFormatToString(valor))}
                        ></DatePickerABX>
                        {/* <input type='text' className='classNameInput w-[200px]' value={parametrosModal.fechaInicioAB} onChange={(e) => actualizarParametrosModal('fechaInicioAB',e.target.value)} disabled/> */}
                    </div>
                    <div className={`${parametrosModal.inicioViajes != 3 && 'hidden'}`}>Inicio A</div>
                    <div className={`${parametrosModal.inicioViajes != 3 && 'hidden'}`}>
                        <input type='time' className='w-[100px] classNameInput' value={parametrosModal.hInicioA} onChange={(e) => actualizarParametrosModal('hInicioA',e.target.value + ':00')}/>
                    </div>
                    <div className={`${parametrosModal.inicioViajes != 3 && 'hidden'}`}>Inicio B</div>
                    <div className={`${parametrosModal.inicioViajes != 3 && 'hidden'}`}>
                        <input type='time' className='w-[100px] classNameInput' value={parametrosModal.hInicioB} onChange={(e) => actualizarParametrosModal('hInicioB',e.target.value + ':00')}/>
                    </div>
                    {/*  */}
                    <div>F.Final</div>
                    <div className='flex w-[200px] lg:w-full gap-4'>
                        <DateTimePicker
                            date={stringToDateTime(parametrosModal.fechaFin)}
                            setDate={(valor) => actualizarParametrosModal('fechaFin',dateTimeToString(valor))}
                            styles={{width: '100%'}}
                        ></DateTimePicker>
                        {/* <input type='text' className='classNameInput' value={parametrosModal.fechaFin} onChange={(e) => actualizarParametrosModal('fechaFin',e.target.value)} disabled/> */}
                    </div>
                    <div>Viajes Min</div>
                    <div>
                        <input 
                            type='number'
                            value={parametrosModal.minViajes}
                            onChange={(e) => {actualizarParametrosModal('minViajes',e.target.value)}}
                            className='w-full classNameInput' 
                        />
                    </div>
                    <div>Viajes Max</div>
                    <div>
                        <input 
                            type='number'
                            value={parametrosModal.maxViajes}
                            onChange={(e) => {actualizarParametrosModal('maxViajes',e.target.value)}}
                            className='w-full classNameInput' 
                        />
                    </div>
                </div>
            </ModalSinRenderizacion>
        </>
    )
}
