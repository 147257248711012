import React, { useContext, useState, useLazyRef, lazy, useMemo, useEffect } from 'react'
import {
    Switch,
    Route
} from "react-router-dom";
import { UserContext } from '../../context/provider/UserProvider';

import { HeaderProvider } from '../../context/provider/HeaderProvider';
import { LayoutMaster } from '../modules/layout/LayoutMaster';
import { PrivateRoute } from '../components/PrivateRoute';



import {Igo} from '../pages/gestion/Igo';
import {Subsidio} from '../pages/gestion/Subsidio';
import {MapaRutas }  from '../pages/gestion/MapaRutas'
import {MapaTramos}  from '../pages/gestion/MapaTramos'
import { TiemposControles } from '../pages/gestion/TiemposControles';
import {InformacionOperacion} from '../pages/gestion/InformacionOperacion';
import {DemandaParadero} from '../pages/gestion/DemandaParadero';
import PageNotFound from '../pages/PageNotFound';
import { Monitoreo } from '../pages/Monitoreo';
    
import { MapaCalor } from '../pages/gestion/MapaCalor';
import { GestionDisenoRutas } from '../pages/gestionOperacional/GestionDisenoRutas';
import { GestionFlotaVehicular } from '../pages/gestionOperacional/GestionFlotaVehicular';
import { ProcesosProgramacionGeneral } from '../pages/procesosDirectivas/ProcesosProgramacionGeneral';
import { GenerarServicios } from '../pages/procesosDirectivas/GenerarServicios';
import { UnidadesPorRuta } from '../pages/gestion/UnidadesPorRuta';
import principal from '../pages/principal';
import { ProcesoCertificacion } from '../pages/procesosDirectivas/ProcesoCertificacion';


export function PrivateRoutes({ token }) {
    // const { stateUser } = useContext(UserContext);

    return (
        <>
            {
                token !== '' && localStorage.getItem('currentLocation') !== '/'
                && (
                <HeaderProvider>
                    <LayoutMaster>
                        <Switch>
                            <PrivateRoute path='/principal' component={principal} />  

                            <PrivateRoute path='/igo' component={Igo} />  
                            <PrivateRoute path='/subsidio' component={Subsidio} />
                            <PrivateRoute path='/mapa-empresas' component={MapaRutas} />
                            <PrivateRoute path='/mapa-calor' component={MapaCalor} />
                            <PrivateRoute path='/mapa-tramos' component={MapaTramos} />
                            <PrivateRoute path='/tiempos-controles' component={TiemposControles} />
                            <PrivateRoute path='/informacion-operacion' component={InformacionOperacion} />
                            <PrivateRoute path='/demanda-paradero' component={DemandaParadero} />
                            <PrivateRoute path='/monitoreo' component={Monitoreo} />

                            <PrivateRoute path='/gestion-diseño-rutas' component={GestionDisenoRutas}/>
                            <PrivateRoute path='/gestion-flota-vehicular' component={GestionFlotaVehicular}/>
                            
                            <PrivateRoute path='/procesos-programacion-general' component={ProcesosProgramacionGeneral}/>
                            <PrivateRoute path='/procesos-generacion-servicios' component={GenerarServicios}/>
                            <PrivateRoute path='/proceso-certificacion' component={ProcesoCertificacion}/>

                            
                            <PrivateRoute path='/unidades-por-ruta' component={UnidadesPorRuta}/>
                            
                        </Switch>
                    </LayoutMaster>
                </HeaderProvider>
                )
        }
            
        </>
    )
}