import React from 'react';



export const Footer = () => {
    return (
        <div className="footer">
            <footer>
            </footer>
        </div>
    )
}



