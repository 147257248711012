import React, { useState, useEffect, useRef, useContext } from 'react';
import '../../assets/css/views/login.css'
import { useHistory } from "react-router-dom";
import { UserContext } from '../../context/provider/UserProvider'
import { notify } from '../../utils/utils'
import img1 from '../../assets/images/logoTPRO.png';
import img2 from '../../assets/images/logoTPRO.png';
import img3 from '../../assets/images/logoTPRO.png';
import {Slideshow, Slide} from '../components/utils/SliderShow';
import logo from '../../assets/images/LogoATU-MuniCIP .png'


export const iniciarSesion = async (user, password, signIn , signOut  , history) => {

    const singOutSession = () => {        
        signOut()
        history.push('/Login')
        // notify('No cuenta con el permiso para acceder a este modulo', 'error')
    }

    console.log("process.env.REACT_APP_OAUTH_PLAMIN : ", process.env.REACT_APP_OAUTH_PLAMIN)

    const response = await fetch(process.env.REACT_APP_OAUTH_PLAMIN + '/api/Usuario/Login', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            nomUsuario: user,
            password: password
        })
    }).then(res => res.json());

    if (response.isValid) {
        const timeExpire = new Date(new Date().getTime() + response.content.tiempoExpiraMiliSegundos * 1000);
        signIn({
            ...response.content,
            tokenExpire: timeExpire        
        });

        if(response.content.permisos.some(permiso => permiso.codPermiso == 17)){    //PermisoATUGestion
            return true
        }else{
            singOutSession()
            return false
        }
    }
    
    notify('Usuario o contraseña incorrectos', 'error')
    return false
}

const useLogin = () => {
    const inputUsuario = useRef();
    const history = useHistory();
    const { stateUser, signIn, signOut } = useContext(UserContext);
    const [ user, setUser ] = useState();
    const [ password, setPassword ] = useState(); //culqi

    const singOutSession = () => {        
        signOut()
        history.push('/Login')
        // notify('No cuenta con el permiso para acceder a este modulo', 'error')
    }

    useEffect(() => {
        let isLogged = false
        if(stateUser.token != '' ){
            if(stateUser.permisos.some(permiso => permiso.codPermiso == 17)){       //PermisoATUGestion
                isLogged = true
            }else{
                singOutSession()
            }
        }
        isLogged && history.push('/igo')
        // inputUsuario.current.focus()
    }, [])

    const ingresar = async (e) => {
        e.preventDefault();
        const ingreso = await iniciarSesion(user, password, signIn , signOut , history);
        if (ingreso) {
            history.push('/igo')                  
        }
    }

    return {
        user,
        setUser,
        password,
        setPassword,
        ingresar,
        inputUsuario,
        stateUser
    }
}

export const Login = () => {
    const login = useLogin();
    const history = useHistory();


    const iniciarSesionConAbexa = async () => {

        const details = {
            "client_id": process.env.REACT_APP_WSO2IS_CLIENT_ID,
            "url_endpoint": process.env.REACT_APP_WSO2IS_AUTHORIZATION,
            "scopes": process.env.REACT_APP_WSO2IS_SCOPES,
            "redirect_uri": process.env.REACT_APP_REDIRECT_URI
        }
        console.log(details)

        const url = `${details.url_endpoint}/authorize?response_type=code&client_id=${details.client_id}&redirect_uri=${details.redirect_uri}&scope=${details.scopes}&state=ThisIsMyStateValue&id_carga=${Date.now()}`

        // return
        try {
            // localStorage.setItem("scopes",scopes)

            window.location.href = url;
        } catch (error) {
            console.error('Error en la solicitud:', error);
        }
    }   




    return (
    
        <div className="flex flex-col dark:bg-slate-900 h-screen">
                
            {/* BARRA SUPERIOR */}
            <div className="h-[80px] flex items-center justify-start lg:justify-center bg-[#003968]">
                <div className="flex-shrink-0 flex justify-center items-center w-[250px]" >
                    <img className="w-[90px] h-full block sm:w-52 w-auto cursor-pointer" src={logo} onClick={() => history.push('/')} alt="Workflow"/>
                </div>
            </div>
            {/* FIN BARRA SUPERIOR */}

            <div className="container flex justify-center items-center lg:h-[70%] border border-red-600 hidden">
                    <div className='flex flex-col  text-center ' >
                        {/* <div className='text-2xl text-gray-200 flex justify-center text-center border-gray-500 border-b'>
                            Iniciar Sesión
                        </div> */}
                        <div className='flex lg:flex-col '>
                            {/* <div 
                                className='lg:w-[100px] dark:text-white items-center w-[120px] m-[30px] lg:m-[10px] text-center cursor-pointer' 
                                onClick={() => iniciarSesionConAbexa()}
                            >
                                <img className=' boton-web rounded-2xl' src={btn1} alt='' /><label className='text-center'>Administrador</label>
                            </div>

                            <div 
                                className='lg:w-[100px] dark:text-white items-center w-[120px] m-[30px] lg:m-[10px] text-center cursor-pointer' 
                                onClick={() => iniciarSesionConAbexa()}
                            >
                                <img className=' boton-web rounded-2xl' src={btn2} alt='' /><label>C.C.M.</label>
                            </div> */}

{/* <img className='boton-web rounded-2xl' src={btn3} alt=''/><label>Autoridad</label> */}
                            {/* <div 
                                className='lg:w-[100px] dark:text-white items-center w-[120px] m-[30px] lg:m-[10px] text-center cursor-pointer'
                                onClick={() => iniciarSesionConAbexa()}
                            >   
                                <button className='boton-web rounded-2xl' alt=''/><label>Autoridad</label>
                            </div> */}
                        </div>

                    </div>
                </div>

            <div className="grow container flex justify-center items-center gap-x-10">
                {/* <div className='lg:hidden container border flex justify-center items-center m-0 w-[25%] h-[320px] border-2 border-[#002A78] rounded-2xl'>
                    <main > 
                        <Slideshow  controles={true} autoplay={true} velocidad="1500" intervalo="4000">
                            <Slide className='flex justify-center items-center'>
                                <a href=""> <img  src={img1} className='w-[280px] h-[280px]' alt=""/> </a>
                            </Slide>
                            <Slide className='flex justify-center items-center'>
                                <a href=""> <img src={img2} className='w-[280px] h-[280px]' alt=""/> </a>
                                
                            </Slide>
                            <Slide className='flex justify-center items-center'>
                                <a href=""> <img src={img3} className='w-[280px] h-[280px]' alt=""/> </a>
                            </Slide>
                        </Slideshow>
                    </main>
                </div> */}

                <div className="w-[350px] mb-[90px] lg:w-[80%] flex flex-col items-center justify-between">
                    
                    <div className="" style ={{}}>
                        <img className='mb-6' src={logo} alt='' />
                    </div>

                    <div className="w-full border-2 border-[#002A78] rounded-2xl justify-center text-center items-center flex flex-col">
                        
                        <div className='text-center border-b-[#002A78]'>
                            <h2 className="dark:text-white text-2xl font-medium tracking-tight" >Inicio de Sesión A ETU</h2>
                        </div>


                            <div 
                                className='lg:w-[100px] text-xl bg-[#002A78] hover:bg-blue-700 p-2 dark:text-white border items-center w-[120px] m-[20px] rounded items-center text-center justify-center flex text-center cursor-pointer'
                                onClick={() => iniciarSesionConAbexa()}
                            >   
                                {/* <img className='boton-web rounded-2xl' src={btn3} alt=''/><label>Autoridad</label> */}
                                <button className='text-xl cursor-pointer' /><label className=' cursor-pointer ' >Autoridad</label>
                            </div>


                        {/* <form onSubmit={(e)=> login.ingresar(e)}>

                            <div className="form flex flex-col py-10 text-center w-full">
                            
                                <div className="input-wrapper mb-5">
                                    <input ref={login.inputUsuario} onChange={(e) => login.setUser(e.target.value)} defaultValue={login.user} className=" caret-black	 input h-10 focus:outline-none focus:ring focus:ring-blue-500 rounded" placeholder="Usuario"/>
                                    <i className="fa fa-user input-icon" aria-hidden="true"></i>
                                </div>

                                <div className="input-wrapper">
                                    <input type="password" onChange={(e) => login.setPassword(e.target.value)} defaultValue={login.password} className=" caret-black input h-10 focus:outline-none focus:ring focus:ring-blue-500 rounded" placeholder="Contraseña"/>
                                    <i className="fa fa-lock input-icon" aria-hidden="true"></i>
                                </div>

                            </div>

                                <div className="content-buttons mt-4 text-center">
                                    <button type='submit' className="dark:bg-blue-500 hover:bg-blue-600  text-white py-1 rounded-2xl w-3/5 h-10">
                                        Ingresar
                                    </button>
                                <div className='dark:text-white text-center m-5'>
                                    <label className='cursor-pointer'>Olvidé mi Contraseña</label>
                                </div>
                            </div>

                        </form> */}
                    </div>
                </div>
            </div>
        </div>
    )
}


