import React, { useState, useEffect, useContext, useRef, useMemo, useReducer} from 'react';
import { ContenedorParametros } from '../../components/utils/ContenedorParametros';
// import { notify } from '../../../utils/utils';
import { GoogleMap } from '../../../utils/googlemaps';
import { useMapaRutas } from '../../../hooks/GestionSubsidio/useMapaRutas';
import { useATU } from "../../../hooks/ATU/useATU";
import  iconoControl  from '../../../assets/images/iconoControles.png'
import  rutaConMarca  from '../../../assets/images/RutaConMarca.png'
import  rutaSoloLinea  from '../../../assets/images/RutaSoloLinea.png'
import { BotonProcesar } from '../../components/buttons/BotonProcesar';
import { groupBy } from 'lodash';
import { Tooltip } from '../../components/utils/Tooltip';
import { DatePickerABX } from '../../components/pickers/DatePicker';

// import gpx from 'gpx';


import '../../../assets/css/views/maps.css'


import { NuevoSelectAutocomplete } from '../../components/forms/NuevoSelectAutocomplete';
import { notify } from '../../../utils/utils';


const catastroTipo = {
    CAMBIAR_VALOR: 'CAMBIAR_VALOR',
    GENERAR_POLILINEA: 'GENERAR_POLILINEA'
}

const googleMap = new GoogleMap();
//
const google = window.google;
const infowindow = new google.maps.InfoWindow();
//

export const MapaTramos = () => {
    const {empresas,listarRutas,rutas,listarRecorridos,recorridos,listarRecorridosControles,polilineas,listarTramos,tramos,cumplimiento,setCumplimiento} = useATU()
    
    const [verTramosReales, setVerTramosReales] = useState(true);

    const classNameInput = "containerScroll text-white h-[30px] rounded px-4  bg-zinc-800 text-xs focus:outline-none focus:ring-1 focus:ring-blue-500"   
    const mapDiv = useRef();

    const [nombreEmpresa,setNombreEmpresa] = useState('')
    
    const [ empresaSeleccionada, setEmpresaSeleccionada ] = useState(0);
    const [ rutaSeleccionada, setRutaSeleccionada ] = useState('0|0');
    const [ recorridoSeleccionado,setRecorridoSeleccionado ] = useState('0|0|0');

    let arrayTramosSeparados = []
    const fechaActual = new Date(); // Obtiene la fecha actual
    const [startDate,setStartDate] = useState(fechaActual)

    useEffect(() => {
        listarRutas(empresaSeleccionada);
        setRutaSeleccionada(`${empresaSeleccionada}|0`)
    },[empresaSeleccionada])

    useEffect(() => {

    },[cumplimiento])

    useEffect(() => {
        listarRecorridos(rutaSeleccionada)
        setRecorridoSeleccionado(`${rutaSeleccionada}|0`)
    },[rutaSeleccionada])

    useEffect(() => {
        googleMap.inicializarMapa(mapDiv.current, { zoom: 11 });
        document.addEventListener('mouseup', function(e) {
            document.getElementById('content-ruta')?.classList.remove('flex','flex-col')
            document.getElementById('content-ruta')?.classList.add('hidden')
        })
    }, [])

    
    useEffect(() => {
        //limpiando todas las polilineas
        googleMap.polilineas.forEach(element => {
            element.setMap(null);
        });

        if(tramos.length != 0){
            let arrCodigosDistintos = []
            tramos.map(function(data,i){
                if(!arrCodigosDistintos.some(elem => elem == data.codSalidaProgramada)){
                    arrCodigosDistintos.push(data.codSalidaProgramada)
                    arrayTramosSeparados.push(tramos.filter(elem => elem.codSalidaProgramada == arrCodigosDistintos[arrCodigosDistintos.length - 1]))
                }
            })

            if(verTramosReales){
                arrayTramosSeparados.forEach(element => {
                    const puntosRecorridos = element.map(latLng => {
                        return {
                            lat:Number( latLng.latitud) , lng: Number(latLng.longitud)
                        }
                    });
                    const randomColor = getRandomColor();
                    const polylineaCarros = googleMap.crearPolilinea({
                        listLatLng: puntosRecorridos,
                        color: randomColor,
                        strokeWeight: 3,
                        strokeOpacity: 1,
                    });
    
                    //para crear la polilinea a cada recorrido del carro
                    const informacionPolylinea =   `
                                                    <div style="text-align:center; margin:5px"><h1 style="color:white">Numero Placa: ${element[0].placaUnidad}</h1></div>
                                                    <div style="text-align:center; margin:5px"><h1 style="color:white">Codigo Salida: ${element[0].codSalidaProgramada}</h1></div>
                                                    <div style="color: #000"></div>
                                                    `
                    createInfoWindow(polylineaCarros, informacionPolylinea);
                });
            }

            listarRecorridosControles(recorridoSeleccionado)
        }
    },[tramos,verTramosReales])

    const createInfoWindow = (poly, content) => {
        google.maps.event.addListener(poly, 'click', function(event) {
            infowindow.setContent(content);     //contenido 
            infowindow.setPosition(event.latLng);   //posicion del click
            infowindow.open(googleMap.map)      //abrir el info window
        });
    }

    useEffect(() => {
        if(polilineas.length != 0){
            let recorridoAutorizado = polilineas[0].puntosRecorridoLineaMapa.split('*')
            let puntosMandar = []

            recorridoAutorizado.map(function(data,i){
                puntosMandar.push({
                    lat:Number(Number(data.split(',')[1]).toFixed(5)),
                    lng:Number(Number(data.split(',')[0]).toFixed(5))
                })
            })

            googleMap.crearPolilinea({
                listLatLng: puntosMandar,
                color: '#155bb5',
                strokeWeight: 15,
                strokeOpacity: 0.3,
            });
        }
    },[polilineas])

    const listadoTramos = async () => {
        if(recorridoSeleccionado.split('|')[2] == 0){
            notify('Seleccione todos los parametros','info')
            setCumplimiento([])
        }else{
            await listarTramos(startDate,empresaSeleccionada,rutaSeleccionada.split('|')[1],recorridoSeleccionado.split('|')[2])
        }
    }
    
    const getRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    const empresasConvertidas = empresas.map(option => ({
        value: option.codEmpresa,
        label: option.nomEmpresa
    }));

    const generarGPX = () => {
        var togpx = require('togpx')
        var arregloElementosGPX = []

        const vehiculos = groupBy(tramos,'codSalidaProgramada')

        Object.entries(vehiculos).map(([key,listaRecorridos]) => {
            let pathGPX = listaRecorridos.map((data,i) => {
                const [lat,lng] = [data.latitud,data.longitud]
                return [Number(lng),Number(lat)]
            })

            let polilineaGPX = {
                type: "Feature",
                properties: {
                    placa: listaRecorridos[0].placaUnidad,
                    codigosalida: listaRecorridos[0].codSalidaProgramada,
                    empresa: nombreEmpresa,
                    ruta: (rutas.find(elem => elem.codRuta == rutaSeleccionada.split('|')[1])?.nomRuta),
                    recorrido: (recorridos.find(elem => elem.codRecorrido == recorridoSeleccionado.split('|')[2])?.nomRecorrido)
                },
                geometry:{
                    type:"LineString",
                    coordinates:pathGPX
                }
            }

            arregloElementosGPX.push(polilineaGPX)
        })

        let geojson;
        geojson = {
            type: "FeatureCollection",
            features: arregloElementosGPX
        };

        let gpxData = togpx(geojson);
        let downloadLink = document.createElement('a');
        downloadLink.href = 'data:text/xml;charset=utf-8,' + encodeURIComponent(gpxData);
        downloadLink.download = `${eliminarCaracteresEspeciales(nombreEmpresa)}.gpx`;
        downloadLink.style.display = 'none';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }

    const eliminarCaracteresEspeciales = (texto) =>{
        const caracteresEspeciales = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g;
        const textoSinEspeciales = texto.replace(caracteresEspeciales, '');
        return textoSinEspeciales;
    }



    return(
        <>
            <ContenedorParametros titulo="Recorridos Reales vs Autorizados">

                <div className='flex items-center justify-center gap-4'>
                    <i className="fa fa-calendar-alt" data-tip={'Fecha'} data-for={'Fecha'} aria-hidden="true"></i> 
                    <div>
                        <DatePickerABX data-tip data-for={'Fecha'} date={startDate} setDate={setStartDate} clases='lg:w-[250px]'/>
                    </div>
                </div>

                <div className='flex items-center justify-center gap-4'>
                    <i className="fa fa-map-o" data-tip={'Empresas'} data-for={'Empresas'} aria-hidden="true"></i>
                    <NuevoSelectAutocomplete options={empresasConvertidas} textoInput={(valor) => setNombreEmpresa(valor)} valorInput={(valor) => setEmpresaSeleccionada(valor)} valorPorDefecto='0'></NuevoSelectAutocomplete>
                </div>

                <div className='flex items-center justify-center gap-4'>
                    <i className="fa fa-road" data-tip={'Ruta'} data-for={'Ruta'} aria-hidden="true"/>
                    <select className={`${classNameInput} lg:w-[250px] w-[200px] `} value={rutaSeleccionada} onChange={(e) => setRutaSeleccionada(e.target.value)}>
                        <option key={0} value={empresaSeleccionada+'|0'}>-- Seleccione --</option>
                        {
                            rutas.map((ru ,i) => {
                                if(empresaSeleccionada == 0){
                                    return
                                }else{
                                    return <option key={i+1} value={ru.codEmpresaATU + '|' + ru.codRuta}>{ru.nomRuta} { empresaSeleccionada!=0? '' : ' | ' + ru.nomEmpresaATU}</option>
                                }
                            })
                        }
                    </select>
                </div>

                <div className='flex items-center justify-center gap-4'>
                    <i className="fa fa-road" data-tip={'Recorrido'} data-for={'Recorrido'} aria-hidden="true"/>
                    <select className={`${classNameInput} lg:w-[250px] w-[200px]`} value={recorridoSeleccionado} onChange={(e) => setRecorridoSeleccionado(e.target.value)}>
                        <option key={0} value={rutaSeleccionada+"|0"}>-- Seleccione --</option>
                        {
                            recorridos.map((re,i) => {
                                if(rutaSeleccionada.split('|')[1] == '0'){
                                    return
                                }else{
                                    return <option key={i+1} value={re.codEmpresaATU + '|' + re.codRuta + '|' + re.codRecorrido}>{re.nomRecorrido} { rutaSeleccionada != '0|0'? '' : ' | ' + re.nomRuta}</option>
                                }
                            })
                        }
                    </select>
                </div>

                <div className='flex items-center justify-center gap-4 flex-wrap'>
                    <BotonProcesar onClick={listadoTramos}/>
                    <button className='border px-2 py-1 text-xl rounded hover:bg-[#155bb5]' onClick={() => generarGPX()} ><i className='lg:text-[10px] text-[15px] fa fa-download'></i> <span className='text-[18px] lg:text-[15px]'>GPX</span></button>
                </div>
                
            </ContenedorParametros>                    
            {/* CABECERA Y CUERPO */}

                <Tooltip id={'Fecha'}/>
                <Tooltip id={'Empresas'}/>
                <Tooltip id={'Ruta'}/>
                <Tooltip id={'Recorrido'}/>
                <Tooltip id={'BotonOjito'}/>

                <div className="relative mapa-incidentes h-[85%]" >
                    <div ref={mapDiv} className='w-full h-full lg:h-1/2'></div>
                    <div data-tip={verTramosReales ? 'Ocultar recorridos reales' : 'Ver recorridos reales'} data-for={'BotonOjito'} className='absolute bottom-[200px] hover:bg-gray-100 right-2 bg-[#ffffff] rounded p-1 z-10000000 cursor-pointer'  >
                        <div className='text-center w-[40px]'> 
                            <i aria-hidden="true" onClick={() => setVerTramosReales(!verTramosReales)} className={`${verTramosReales ? 'fa fa-eye-slash' : 'fa fa-eye'} text-blue-800 text-2xl`}></i>
                        </div>
                    </div>
                    <div className={`${cumplimiento.length == 0 ? 'hidden' : ''} bg-[#000000] shadow-gray-600 shadow-lg flex flex-col absolute lg:relative z-50 right-[100px] top-[50px] lg:top-12 lg:right-0 h-2/3 lg:h-1/2 w-[400px] lg:w-full lg:text-xs`}>
                        <div className='border w-full h-14 text-center flex flex-none items-center justify-center bg-[#0066cb] text-xl font-bold'>Cumplimiento de recorrido:</div>
                        <div className='overflow-auto'>
                            <div className='flex-grow flex justify-center flex-col px-5 py-6'>
                                <div className='grid grid-cols-2 gap-2 w-full'>
                                    <div>Hoy</div>
                                    <div>{cumplimiento.flota?.hoy || 0}</div>
                                    <div>Flota operativa</div>
                                    <div>{cumplimiento.flota?.flotaOperativa || 0} Unidades</div>
                                    <div>Distancia</div>
                                    <div>{cumplimiento.flota?.distanciaKm || 0} Km</div>
                                    <div>Nº de viajes</div>
                                    <div>{cumplimiento.flota?.nroViajes || 0} viajes</div>
                                </div>
                                <hr className="w-full border border-dashed border-gray-500 my-4"></hr>
                                <div className='w-full text-left'>DETALLES DE VIAJES:</div>
                                <hr className="w-full border border-dashed border-gray-500 my-4"></hr>
                                <div className='grid grid-cols-2 gap-2 w-2/3'>
                                    <div>Viajes al 100%</div>
                                    <div className='text-right'>{cumplimiento.viajes?.find(elem => elem.nomEstado == 'EJECUTADO').cantidadViajes} viajes</div>
                                    <div>Viajes en curso</div>
                                    <div className='text-right'>{cumplimiento.viajes?.find(elem => elem.nomEstado == 'OPERANDO').cantidadViajes} viajes</div>
                                    <div>Viajes con corte</div>
                                    <div className='text-right'>{cumplimiento.viajes?.find(elem => elem.nomEstado == 'FUERA DE RECORRIDO').cantidadViajes} viajes</div>
                                    <div>Viajes truncos</div>
                                    <div className='text-right'>{cumplimiento.viajes?.find(elem => elem.nomEstado == 'TRUNCO').cantidadViajes} viajes</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 

        </>
    )
}