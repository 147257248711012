import React, { useState, useEffect, useContext, useRef, useMemo, useReducer} from 'react';
import { ContenedorParametros } from '../../components/utils/ContenedorParametros';
import { notify } from '../../../utils/utils';
import { useMapaRutas } from '../../../hooks/GestionSubsidio/useMapaRutas';
import { useATU } from "../../../hooks/ATU/useATU";
import  iconoControl  from '../../../assets/images/iconoControles.png'
import { BotonProcesar } from '../../components/buttons/BotonProcesar';


import '../../../assets/css/views/tiempoControles.css';   //algunos estilos


import { SelectAutocomplete } from '../../components/forms/SelectAutocomplete';
import { NuevoSelectAutocomplete } from '../../components/forms/NuevoSelectAutocomplete';
import { ComponenteCarga } from '../../components/forms/ComponenteCarga';

import { tr } from 'date-fns/locale';
import { BuscadorPorSemana } from '../../components/forms/BuscadorPorSemana';
import { Tooltip } from '../../components/utils/Tooltip';

import BotonExcelTest from '../../components/buttons/BotonExcelTest';
import BotonExcelTiempos from '../../components/buttons/BotonExcelTiempos';
import { useStateManager } from 'react-select';

export const TiemposControles = () => {
    const {empresas,listarRutas,rutas,listarRecorridos,recorridos,listarTiempoControles ,tiempoControles,setTiempoControles,listarTiempoParaderos,tiempoParaderos} = useATU()

    const empresasConvertidas = empresas?.map(option => ({
        value: option.codEmpresa,
        label: option.nomEmpresa
    }));

    const classNameInput = "containerScroll text-white h-[30px] rounded px-2  bg-zinc-800 text-xs focus:outline-none focus:ring-1 focus:ring-blue-500"   
    const vertical = {
        "writingMode": "vertical-lr",
        "transform": "rotate(180deg)",
        "height": "auto",
        'minWidth':'50px'
    }

    const nombreDias = ['Lunes','Martes','Miercoles','Jueves','Viernes','Sabado','Domingo']

    const [ empresaSeleccionada, setEmpresaSeleccionada ] = useState('');
    const [ rutaSeleccionada, setRutaSeleccionada ] = useState('');
    const [ recorridoSeleccionado,setRecorridoSeleccionado ] = useState('');

    const [diaSeleccionado,setDiaSeleccionado] = useState(0);
    const [diasSemana,setDiasSemana] = useState([])
    const [valorMinutos, setValorMinutos] = useState(60);

    const [imagenCarga,setImagenCarga] = useState(false);

    const [validarClick,setValidarClick] = useState(false)
    const [tipoVista,setTipoVista] = useState(0)

    useEffect(() => {
        if(empresas.length != 0){
            setEmpresaSeleccionada(empresas[0]?.codEmpresa)
        }
    },[empresas])

    useEffect(() => {
        if(empresaSeleccionada){
            listarRutas(empresaSeleccionada)
        }
    },[empresaSeleccionada])
    
    useEffect(() => {
        if(rutas.length != 0){
            setRutaSeleccionada(empresaSeleccionada + '|' + rutas[0]?.codRuta)
        }
    },[rutas])

    useEffect(() => {
        if(rutaSeleccionada){
            listarRecorridos(rutaSeleccionada)
        }
    },[rutaSeleccionada])

    useEffect(() => {
        if(recorridos.length != 0){
            setRecorridoSeleccionado(rutaSeleccionada + '|' + recorridos[0]?.codRecorrido)
        }
    },[recorridos])

    useEffect(() => {
        if(diasSemana.length != 0 && recorridoSeleccionado){
            obtenerValores()
        }
    },[diaSeleccionado,diasSemana])

    const formatNumberToTwoDigits = (number) => {
        return number.toString().padStart(2, '0');
    }

    const getDatesInRange = (startDate, endDate) => {
        const dates = [];
        const startParts = startDate.split('/');
        const endParts = endDate?.split('/');

        const start = new Date(`${startParts[1]}/${startParts[0]}/${startParts[2]}`);
        const end = new Date(`${endParts[1]}/${endParts[0]}/${endParts[2]}`);
        
        // Ajustar las horas de inicio y fin para evitar problemas con la zona horaria
        start.setHours(0, 0, 0, 0);
        end.setHours(0, 0, 0, 0);
        
        // Iterar desde la fecha de inicio hasta la fecha de fin
        let currentDate = new Date(start);

        while (currentDate <= end) {
            const formattedDate = `${formatNumberToTwoDigits(currentDate.getDate())}/${formatNumberToTwoDigits(currentDate.getMonth() + 1)}/${currentDate.getFullYear()}`;
            dates.push(formattedDate);
            currentDate.setDate(currentDate.getDate() + 1);
        }
    
        return dates;
    }

    const obtenerValores = async () => {
        const partesFecha = diasSemana[diaSeleccionado].split('/'); // Dividir la cadena en partes: día, mes, año
        const fechaObj = new Date(`${partesFecha[2]}-${partesFecha[1]}-${partesFecha[0]}`);
        const fechaActual = new Date(); // Fecha actual
        if (fechaObj > fechaActual) {
            notify('No hay información para fechas posteriores a la fecha de hoy','error')
            setImagenCarga(false)
            setTiempoControles([])
            return
        }

        if(recorridoSeleccionado.split('|')[2] != 0){
            if(tipoVista==0){
                await listarTiempoControles(empresaSeleccionada,recorridoSeleccionado.split('|')[2],valorMinutos,diasSemana[diaSeleccionado])
            }else{
                await listarTiempoParaderos(empresaSeleccionada,recorridoSeleccionado.split('|')[2],valorMinutos,diasSemana[diaSeleccionado])
            }
        }else{
            notify("Seleccione todos los parametros", 'error');
        }
        setImagenCarga(false)
    }

    return(
        <>
            <ComponenteCarga variableCarga={imagenCarga}></ComponenteCarga>

            <ContenedorParametros titulo="Tiempo de Controles/Paraderos">
                <div className='flex items-center justify-center'>
                    <BuscadorPorSemana funcionCambio={() => {setImagenCarga(true);setValidarClick(true)}} fechasCompartidas={(valor) => {setDiasSemana(getDatesInRange(valor[0].fechaInicio,valor[0].fechaFin))}}></BuscadorPorSemana>
                </div>
                
                <div className='flex gap-4 items-center justify-center'>
                    <i className="fa fa-bus" data-tip={'Empresa'} data-for={'Empresas'} aria-hidden="true"></i>
                    <NuevoSelectAutocomplete options={empresasConvertidas} valorInput={(valor) => {setEmpresaSeleccionada(valor);setValidarClick(false)}} valorPorDefecto='1'></NuevoSelectAutocomplete>
                </div>

                <div className='flex gap-4 items-center justify-center'>
                    <i className="fa fa-road" data-tip={'Ruta'} data-for={'Ruta'} aria-hidden="true"/>
                    <select className={`${classNameInput} lg:w-[250px] w-[80px] `} value={rutaSeleccionada} onChange={(event) => {setRutaSeleccionada(event.target.value);setValidarClick(false)}}>
                        {
                            rutas?.map((ru ,i) => {
                                return <option key={i+1} value={`${empresaSeleccionada}|${ru.codRuta}`}>{ru.nomRuta} { empresaSeleccionada !=0 ? '' : ' | ' + ru.nomEmpresaATU}</option>
                            })
                        }
                    </select>
                </div>

                <div className='flex gap-4 items-center justify-center'>
                    <i className="fa fa-map-o" data-tip={'Recorrido'} data-for={'Recorrido'} aria-hidden="true"/>
                    <select className={`${classNameInput} w-[180px] lg:w-[250px]`} value={recorridoSeleccionado} onChange={(event) => {setRecorridoSeleccionado(event.target.value);setValidarClick(false)}}>
                        {
                            recorridos?.map(function(re,j){
                                return <option key={j+1} value={`${rutaSeleccionada}|${re.codRecorrido}`}>{re.nomRecorrido} { rutaSeleccionada != '0|0'? '' : ' | ' + re.nomRuta}</option>
                            })
                        }
                    </select>
                </div>

                <div className='flex gap-4 items-center justify-center'>
                    <i className="fa fa-eye" data-tip={'Tipo de Vista'} data-for={'TipoVista'} aria-hidden="true"/>
                    <select className={`${classNameInput} lg:w-[250px] w-[100px]`} value={tipoVista} onChange={(event) => {setTipoVista(event.target.value);setValidarClick(false)}}>
                        <option value={0}>Controles</option>
                        <option value={1}>Paraderos</option>
                    </select>
                </div>

                <div className='flex gap-4 items-center justify-center flex-wrap'>
                    <i className="fa fa-clock-o" data-tip={'Intervalor de Minutos'} data-for={'Intervalo'} aria-hidden="true"/>
                    <input className={`${classNameInput} w-[50px]`} type='number' value={valorMinutos} onChange={(event) => {setValorMinutos(event.target.value);setValidarClick(false)}} />
                    <span className=''>min.</span>
                    <BotonProcesar onClick={() => {setImagenCarga(true);setValidarClick(true);obtenerValores()}}/>
                    <BotonExcelTiempos 
                        tableId="tableTiemposControles"
                        nombreArchivo="REPORTE TIEMPO CONTROLES"
                        parametrosCabecera={{
                            nomEmpresa: (empresas.find(elem => elem.codEmpresa == empresaSeleccionada))?.nomEmpresa,
                            nomRuta: (rutas.find(elem => elem.codRuta == rutaSeleccionada.split('|')[1]))?.nomRuta,
                            nomRecorrido: (recorridos.find(elem => elem.codRecorrido == recorridoSeleccionado.split('|')[2]))?.nomRecorrido
                        }}
                    />
                </div>
            
            </ContenedorParametros>   

            <Tooltip id={'Empresas'}/>
            <Tooltip id={'Ruta'}/>
            <Tooltip id={'Recorrido'}/>
            <Tooltip id={'Intervalo'}/>
            <Tooltip id={'TipoVista'}/>

            <div className='flex -mb-7 mt-3 w-full overflow-auto'>
                {
                    nombreDias.map(function(data,i){
                        return <div key={i+1} onClick={() => {setImagenCarga(true);setValidarClick(true);setDiaSeleccionado(i)}} className={`claseDia ${diaSeleccionado==i ? 'claseDiaSeleccionado' : 'claseDiaSinSeleccionar'}`}><div className='text-center'>{data}</div><div className='text-center text-xs'>{diasSemana[i]}</div></div>
                    })
                }
            </div>
            <div className="containerScroll h-4/5 mt-7 relative top-0 bottom-0 left-0 right-0 contenedor-tabla ">
                {
                    validarClick?
                        tipoVista==0?
                            tiempoControles.length!=0 ?
                                <table className="table" id='tableTiemposControles'>
                                    <thead>
                                        <tr>
                                            <th className='min-w-[100px] left-0 bg-[#2e5289] primerTH' ref={(node) => {
                                                if (node) {
                                                    node.style.setProperty("position", "sticky", "important");
                                                    node.style.setProperty("z-index", "1000", "important");
                                                }
                                            }}>Hora</th>
                                            {
                                                tiempoControles.map(function(control,i){
                                                    return (
                                                        <th style={vertical} title={control.nomControl} key={i++} className="max-w-[10px]">{ control.abrevControl }</th>
                                                    )
                                                })
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            tiempoControles[0].horasControl.map(function(horarios,i){
                                                return (
                                                    <tr key={i}>
                                                        <td className='text-center sticky left-0 bg-[#121212]'>{horarios.horaControl}</td>
                                                        {
                                                            tiempoControles?.map(function(data,j){
                                                                return(
                                                                    <td key={j++} className="text-center">{data.horasControl[i].promedioControl}</td>
                                                                )
                                                            })
                                                        }
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            :
                                <div className='w-full flex items-center h-12 text-center bg-[#222222]'><p className='m-auto'>No hay información con los parametros seleccionados</p></div>
                        :
                            tiempoParaderos.length!=0 ?
                                <table className="table" id='tableTiemposControles'>
                                    <thead>
                                        <tr>
                                            <th className='min-w-[100px] left-0 bg-[#2e5289] primerTH' ref={(node) => {
                                                if (node) {
                                                    node.style.setProperty("position", "sticky", "important");
                                                    node.style.setProperty("z-index", "1000", "important");
                                                }
                                            }}>Hora</th>
                                            {
                                                tiempoParaderos.map(function(paradero,i){
                                                    return (
                                                        <th style={vertical} title={paradero.nomParadero} key={i++} className="max-w-[10px] max-h-20 whitespace-nowrap 	text-overflow: ellipsis">{ paradero.abrev }</th>
                                                    )
                                                })
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            tiempoParaderos[0].horasParaderos.map(function(horarios,i){
                                                return (
                                                    <tr key={i}>
                                                        <td className='text-center sticky left-0 bg-[#121212]'>{horarios.horaParadero}</td>
                                                        {
                                                            tiempoParaderos?.map(function(data,j){
                                                                return(
                                                                    <td key={j++} className="text-center">{Math.round((data.horasParaderos[i].promedioParadero)/60)}</td>
                                                                )
                                                            })
                                                        }
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            :
                                <div className='w-full flex items-center h-12 text-center bg-[#222222]'><p className='m-auto'>No hay información con los parametros seleccionados</p></div>
                    :
                    <div className='w-full flex items-center h-12 text-center bg-[#222222]'><p className='m-auto'>Presione el botón procesar</p></div>
                }
            </div>
        </>
    )
}