import React, { useEffect, useMemo, useRef, useReducer, useState } from 'react';
import { ContenedorParametros } from '../../components/utils/ContenedorParametros'; 
import { useSubsidio } from '../../../hooks/GestionSubsidio/useSubsidio'; 
import { useATU } from "../../../hooks/ATU/useATU";
import { BotonProcesar } from '../../components/buttons/BotonProcesar';
import { Tooltip } from '../../components/utils/Tooltip';
import { DatePickerABX } from '../../components/pickers/DatePicker';
import BotonExcel from '../../components/buttons/BotonExcel';

import { SelectAutocomplete } from '../../components/forms/SelectAutocomplete';
import { NuevoSelectAutocomplete } from '../../components/forms/NuevoSelectAutocomplete';

import { useDataSubsidios } from '../../../hooks/ATU/useDataSubsidios';


import BotonExcelTest from '../../components/buttons/BotonExcelTest';
import { getTime } from 'date-fns';

export const Subsidio = () => {

    const classNameInput = "containerScroll text-white h-[30px] rounded px-4 w-full bg-zinc-800 text-xs focus:outline-none focus:ring-1 focus:ring-blue-500"   

    const {empresas,listarRutas,rutas,listarSubsidios,subsidios} = useATU()
    const {modificadorDatos} = useDataSubsidios()
    
    let hoydia = new Date()
    let ayer = new Date(hoydia.getTime() - (24*60*60*1000))
    
    const [ empresaSeleccionada , setEmpresaSeleccionada ] = useState(0)    //inicia con el valor de empresa 1
    const [ rutaSeleccionada, setRutaSeleccionada ] = useState(0);
    const [ startDate, setStartDate ] = useState(ayer)  //por que aca si hay datos 
    const [ agrupado, setAgrupado ] = useState(0)
    const [ ejecutado, setEjecutado] = useState(1);

    const [validarClick, setValidarClick] = useState(false)

    const [arrayListado,setArrayListado] = useState([])

    useEffect(() => {
        if(empresas.length != 0){
            setEmpresaSeleccionada(empresas[0].codEmpresa)
        }
    },[empresas])

    useEffect(() => {
        if(empresaSeleccionada){
            setValidarClick(false)
            listarRutas(empresaSeleccionada)
        }
    }, [empresaSeleccionada]);
    
    useEffect(() => {
        if(rutas.length != 0){
            setRutaSeleccionada(rutas[0].codRuta)
        }
    },[rutas])

    useEffect(() => {
        setArrayListado(modificadorDatos(subsidios,agrupado))        //para modificar la tabla dependiendo del combo de agrupados
    },[subsidios])

    const empresasConvertidas = empresas.map(option => ({
        value: option.codEmpresa,
        label: option.nomEmpresa
    }));

    const recibirValor = (valor) => {
        setEmpresaSeleccionada(valor)
    };
    
    return (
        <>
            <ContenedorParametros titulo='Reporte Subsidio' data-tip data-for={'REPORTESUBSIDIO'}>   
                <div className='flex gap-4 items-center justify-center'>
                    <i className="fa fa-bus" data-tip={'Empresas'} data-for={'Empresas'} aria-hidden="true"></i>
                    <NuevoSelectAutocomplete options={empresasConvertidas} valorInput={recibirValor} valorPorDefecto='1'></NuevoSelectAutocomplete>
                </div>
                
                <div className='flex gap-4 items-center justify-center'>
                    <i className="fa fa-road" data-tip={'Ruta'} data-for={'Ruta'} aria-hidden="true"/>
                    <select value={rutaSeleccionada} className={`${classNameInput} lg:w-[250px]`} onChange={(e)=>{setRutaSeleccionada(e.target.value);setValidarClick(false)}}>
                        {
                            rutas?.map(function(r,i){
                                return <option key={i+1} value={r.codRuta}>{r.nomRuta}</option>
                            })
                        }
                    </select>
                </div>
                
                <div className='flex gap-4 items-center justify-center'>
                    <i className="fa fa-calendar-alt" data-tip={'Fecha Inicio'} data-for={'FechaInicio'} aria-hidden="true"></i> 
                    <div>
                        <DatePickerABX data-tip data-for={'FechaInicio'} date={startDate} setDate={setStartDate} clases='lg:w-[250px]'/>
                    </div>
                </div>

                <div className='flex gap-4 items-center justify-center'>
                    <i className="fas fa-map-signs" data-tip={'Tipos de Viaje'} data-for={'TiposViaje'} aria-hidden="true"/>    
                    <select className={`${classNameInput} lg:w-[250px]`} value={ejecutado} onChange={(e) => {setEjecutado(e.target.value);setValidarClick(false)}}>
                        <option value="1">Ejecutado</option>
                        <option value="0">Trunco</option>
                    </select>
                </div>
            
                <div className='flex gap-4 items-center justify-center'>
                    <i className="fa fa-eye" data-tip={'Tipo de Vista'} data-for={'Agrupado'} aria-hidden="true"/>
                    <select className={`${classNameInput} lg:w-[250px]`} onChange={(e)=>{setAgrupado(e.target.value);setValidarClick(false)}}>
                        <option value="0">Detallado</option>
                        <option value="1">Agrupado</option>
                    </select>
                </div>

                <div className='flex gap-4 items-center justify-center flex-wrap'>
                    <BotonProcesar onClick={async() => {await listarSubsidios(rutaSeleccionada, startDate, ejecutado ,empresaSeleccionada);setValidarClick(true)}}></BotonProcesar>
                    <BotonExcelTest tableId="tableSubsidios" nombreArchivo="REPORTE SUBSIDIOS"></BotonExcelTest>
                </div>
            </ContenedorParametros>

            <Tooltip id={'Empresas'}/>
            <Tooltip id={'Ruta'}/>
            <Tooltip id={'FechaInicio'}/>
            <Tooltip id={'TiposViaje'}/>
            <Tooltip id={'Agrupado'}/>

            <div className="containerScroll mt-7 relative top-0 bottom-0 left-0 right-0 contenedor-tabla ">
                <table className="table" id='tableSubsidios'>
                    <thead className='sticky top-0'>
                        
                        <tr>
                            <th className="text-center w-[20px]"></th>
                            <th className="text-center w-[100px]"></th>
                            <th colSpan={13} className='border-x border-b text-center w-[150px]'></th>
                            <th colSpan={6} className='border-x border-b text-center w-[150px]'>SUBSIDIOS</th>
                        </tr>
                        <tr>
                            <th className="text-center w-[20px]"></th>
                            <th className="text-center w-[100px]"></th>
                            <th colSpan={4} className='border-x border-b text-center w-[150px]'>UNIDAD</th>
                            <th colSpan={2} className='border-x border-b text-center w-[150px]'>CONDUCTOR</th>
                            <th colSpan={5} className='border-x border-b text-center w-[150px]'>OPERACION</th>
                            <th colSpan={2} className='border-x border-b text-center w-[150px]'>KILOMETRAJE</th>
                            <th className="text-center w-[100px]"></th>
                            <th colSpan={2} className="border-x border-b text-center w-[150px]">PROPIETARIO</th>
                            <th colSpan={2} className="border-x border-b text-center w-[150px]">OPERADOR</th>
                            <th className="text-center w-[100px]"></th>
                        </tr>
                        <tr>
                            <th className="text-center w-[20px]">N°</th>
                            <th className="text-center w-[100px]">FECHA</th>
                            <th className="text-center w-[120px] border-x">TIPO</th>
                            <th className="text-center w-[120px] border-x">ASIE.</th>
                            <th className="text-center w-[120px] border-x">PADRON</th>
                            <th className="text-center w-[120px] border-x">PLACA</th>
                            <th className="text-center w-[120px] border-x">CODIGO</th>
                            <th className="text-center w-[120px] border-x">NOMBRE</th>
                            <th className="text-center w-[120px] border-x"data-tip='Numero de Servicio' data-for='SRV'>SRV</th>
                            <th className="text-center w-[120px] border-x">RECORRIDO</th>
                            <th className="text-center w-[120px] border-x">H.INICIO</th>
                            <th className="text-center w-[120px] border-x">H.FIN</th>
                            <th className="text-center w-[120px] border-x">N° VIAJES</th>
                            <th className="text-center w-[120px] border-x">COMERCIAL</th>
                            <th className="text-center w-[120px] border-x">NO COMERCIAL</th>
                            <th className="text-center w-[120px] border-x">FACTOR</th>
                            <th className="text-center w-[120px] border-x">FACTOR KM.</th>
                            <th className="text-center w-[120px] border-x">TOTAL</th>
                            <th className="text-center w-[120px] border-x">FACTOR KM.</th>
                            <th className="text-center w-[120px] border-x">TOTAL</th>
                            <th className="text-center w-[120px] border-x">IMPORTE TOTAL</th>
                        </tr>
                    </thead>
        
                    <tbody>
                    {
                        validarClick ? 

                            arrayListado.length > 0 ?
                            arrayListado.map((subsidio, i) => {
                                return (
                                    <tr key={++i}>
                                        <td className="text-center">{++i}</td>
                                        <td className="text-center">{subsidio.fecha}</td>
                                        <td className="text-center">{subsidio.claseUnidad}</td>
                                        <td className="text-center">{subsidio.numAsientos}</td>
                                        <td className="text-center">{subsidio.padronUnidad}</td>
                                        <td className="text-center">{subsidio.placaUnidad}</td>
                                        <td className="text-center">{subsidio.codigoPersona}</td>
                                        <td className="text-center">{subsidio.nomPersona}</td>
                                        <td className="text-center">{subsidio.nroServicio}</td>
                                        <td className="text-center">{subsidio.nomRecorrido}</td>
                                        <td className="text-center">{subsidio.horaInicio}</td>
                                        <td className="text-center">{subsidio.horaFin}</td>
                                        <td className="text-center">{subsidio.nroViaje}</td>
                                        <td className="text-center">{subsidio.distanciaComercial}</td>
                                        <td className="text-center">{subsidio.distanciaNoComercial}</td>
                                        <td className="text-center">{subsidio.montoFactor}</td>      
                                        <td className="text-center">{subsidio.factorPropietario}</td>
                                        <td className="text-center">{parseFloat(subsidio.importePropietario).toFixed(2)}</td>
                                        <td className="text-center">{subsidio.factorOperador}</td>
                                        <td className="text-center">{parseFloat(subsidio.importeOperador).toFixed(2)}</td>
                                        <td className="text-center">{parseFloat(subsidio.importe).toFixed(2)}</td>
                                    </tr>
                                )
                            })
                            :
                            <tr><td colSpan='20' className='text-center'>No hay información para mostrar con los parametros seleccionados</td></tr>

                        :

                        <tr><td colSpan='20' className='text-center'>Presione el botón procesar ...</td></tr>
                    }
                    </tbody>
                </table>

                <Tooltip id={'SRV'}/>
            </div>
        </>
    )
}