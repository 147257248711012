import React, { useState, useEffect, useContext, useRef, useMemo, useReducer} from 'react';
import { ContenedorParametros } from '../../components/utils/ContenedorParametros';
import { GoogleMap } from '../../../utils/googlemaps';
import { useMapaRutas } from '../../../hooks/GestionSubsidio/useMapaRutas';
import { useATU } from "../../../hooks/ATU/useATU";
import  iconoControl  from '../../../assets/images/iconoControles.png'
import  rutaConMarca  from '../../../assets/images/RutaConMarca.png'
import  rutaSoloLinea  from '../../../assets/images/RutaSoloLinea.png'
import { BotonProcesar } from '../../components/buttons/BotonProcesar';
import { groupBy } from 'lodash';
import { Tooltip } from '../../components/utils/Tooltip';

import '../../../assets/css/views/maps.css'

import { NuevoSelectAutocomplete } from '../../components/forms/NuevoSelectAutocomplete';

const googleMap = new GoogleMap();

export const MapaRutas = () => {
    const {empresas,listarRutas,rutas,listarRecorridos,recorridos,listarRecorridosControles,controles,paraderos,polilineas} = useATU()
    
    const [nombreEmpresaSeleccionada,setNombreEmpresaSeleccionada] = useState('')   //sacar el nombre de la empresa para el nombre del archivo gpx
    
    const [ empresaSeleccionada, setEmpresaSeleccionada ] = useState(0);
    const [ rutaSeleccionada, setRutaSeleccionada ] = useState('0|0');
    const [ recorridoSeleccionado,setRecorridoSeleccionado ] = useState('0|0|0');

    const [isImage1, setIsImage1] = useState(false)

    const [verMarcadores, setVerMarcadores] = useState(false)

    const [vistaParaderos,setVistaParaderos] = useState(0)

    const classNameInput = "containerScroll text-white h-[30px] rounded px-4  bg-zinc-800 text-xs focus:outline-none focus:ring-1 focus:ring-blue-500"   
    const mapDiv = useRef();

    let arrColoresLineas = []

    useEffect(() => {
        listarRutas(empresaSeleccionada);
        setRutaSeleccionada(`${empresaSeleccionada}|0`)
    },[empresaSeleccionada])

    useEffect(() => {
        listarRecorridos(rutaSeleccionada)
        setRecorridoSeleccionado(`${rutaSeleccionada}|0`)
    },[rutaSeleccionada])

    useEffect(() => {
        if(controles.length != 0 && polilineas.length != 0){
            mostrarMapa()
        }
    },[verMarcadores])

    useEffect(() => {
        mostrarMapa()
    },[polilineas])

    const funcionEsperaRecorridos = async () => {
        let arrTest = ''
        recorridos.map(function(data){
            arrTest += `${data.codRecorrido},`
        })
        arrTest = arrTest.slice(0,arrTest.length - 1)

        let parameters = ''
        
        if(recorridoSeleccionado.split('|')[2]==0){
            parameters = `${rutaSeleccionada}|${arrTest}`
        }else{
            parameters = recorridoSeleccionado
        }

        await listarRecorridosControles(parameters)
    }
    
    const getRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    function generarColorAleatorio() {
        var color = '#'; // Prefijo para representar un color hexadecimal

        // Generar los componentes de color (rojo, verde y azul) aleatoriamente
        var componenteRojo = Math.floor(Math.random() * 256); // Valor entre 0 y 255
        var componenteVerde = Math.floor(Math.random() * 256);
        var componenteAzul = Math.floor(Math.random() * 256);

        // Convertir los componentes a formato hexadecimal y concatenarlos al color
        color += componenteRojo.toString(16).padStart(2, '0'); // Convertir a hexadecimal y añadir al color
        color += componenteVerde.toString(16).padStart(2, '0');
        color += componenteAzul.toString(16).padStart(2, '0');

        return color;
    }

    const mostrarMapa = async()=>{
        
        // limpiando marcadores
        googleMap.marcadores.forEach(element => {
            element.setMap(null);
        });

        //limpiando polylineas 
        googleMap.polilineas.forEach(element => {
            element.setMap(null);
        });

        const agrupadoPorEmpresa = groupBy(polilineas, 'codEmpresa');
        arrColoresLineas = []   //setea el array 
        Object.entries(agrupadoPorEmpresa).map(([key, listaRutas]) => {

            const agrupadoPorRuta = groupBy(listaRutas, 'codRuta');
            Object.entries(agrupadoPorRuta).map(([keyRuta, listaRecorridos]) => {
                
                listaRecorridos.forEach(element => {
                    const puntosRecorridos = element.puntosRecorridoLineaMapa?.split('*').map(latLng => {
                        const [ lat, lng ] = latLng.split(',');
                        return {
                            lat:Number(lng) , lng: Number(lat)
                        }
                    });

                    let randomColor = ''
                    do {
                        randomColor = generarColorAleatorio()
                    } while (arrColoresLineas.some(elem => elem == randomColor));

                    arrColoresLineas.push({
                        color:randomColor,
                        empresa:element.codEmpresa,
                        ruta:element.codRuta,
                        recorrido:element.codRecorrido
                    })
                    
                    googleMap.crearPolilinea({
                        listLatLng: puntosRecorridos,
                        color: arrColoresLineas[arrColoresLineas.length-1].color,
                        strokeWeight: 3,
                        strokeOpacity: 1,
                    });
                });
            });
        });

        if (verMarcadores){
            (vistaParaderos==1 ? paraderos : controles).forEach(function(c,index){
                const marcador = googleMap.crearMarcador({
                    latLng: { lat: c.latitud, lng: c.longitud },
                    icon: "puntos",
                    animation: false,        //no se esta usando
                    color: arrColoresLineas.find(elem => (elem.empresa == c.codEmpresa && elem.ruta == c.codRuta && elem.recorrido == c.codRecorrido)).color,
                })

                const infoWindow = googleMap.crearInfowindow({});
                marcador.addListener('click', () => {
                    infoWindow.setContent(`
                        <div style="text-align:center; margin:5px"><h1 style="color:white">Empresa: ${c.nomEmpresa}</h1></div>
                        <div style="text-align:center; margin:5px"><h1 style="color:white">Ruta: ${c.nomRuta}</h1></div>
                        <div style="text-align:center; margin:5px"><h1 style="color:white">Recorrido: ${c.nomRecorrido}</h1></div>
                        <div style="text-align:center; margin:5px"><h1 className="font-extrabold text-white" >${vistaParaderos == 1 ? `Paradero: ${c.nomParadero}` : `Control: ${c.nomControl}`}</h1></div>
                        <div style="color: #000"></div>
                    `);

                    infoWindow.open({
                        anchor: marcador,
                        map: googleMap.map
                    })
                })
            })
        }
    }


    const funcionBtn = () =>{
        //imprimelo porfa pruebalo
        var togpx = require('togpx');
        var arregloElementosGPX = [];

        const agrupadoPorEmpresa = groupBy(polilineas, 'codEmpresa');
        Object.entries(agrupadoPorEmpresa).map(([key, listaRutas]) => {

            const agrupadoPorRuta = groupBy(listaRutas, 'codRuta');
            Object.entries(agrupadoPorRuta).map(([keyRuta, listaRecorridos]) => {

                listaRecorridos.forEach(element => {
                    var pathGPX = element.puntosRecorridoLineaMapa?.split('*').map(latLng => {
                        const [ lat, lng ] = latLng.split(',');
                        return [Number(lat),Number(lng)]
                        
                    });
                    //

                    var  polilineaGPX = {
                        type: "Feature",
                        properties: {},
                        geometry: {
                            type: "LineString",
                            coordinates:pathGPX
                        }
                    }
                    arregloElementosGPX.push(polilineaGPX);
                });
            });
        });

        (vistaParaderos==1 ? paraderos : controles).forEach(element => {
            var latitud = element.longitud;
            var longitud = element.latitud;
            var nombreEmpresa = element.nomEmpresa;

            var itemMarcadorgpx = {
                type: "Feature",
                properties: {
                    tipo: vistaParaderos==1 ? 'Control' : 'Paradero',
                    name: vistaParaderos==1 ? element.nomParadero : element.nomControl,
                    empresa: nombreEmpresa,
                },
                geometry: {
                    type: "Point",
                    coordinates: [latitud, longitud]
                }
            };

            arregloElementosGPX.push(itemMarcadorgpx);
        });
        var geojson;
        geojson = {
            type: "FeatureCollection",
            features: arregloElementosGPX
        };

        var gpxData = togpx(geojson);
        var downloadLink = document.createElement('a');
        downloadLink.href = 'data:text/xml;charset=utf-8,' + encodeURIComponent(gpxData);
        downloadLink.download = `${eliminarCaracteresEspeciales(nombreEmpresaSeleccionada)}.gpx`;
        downloadLink.style.display = 'none';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }

    const eliminarCaracteresEspeciales = (texto) =>{
        const caracteresEspeciales = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g;
        const textoSinEspeciales = texto.replace(caracteresEspeciales, '');
        return textoSinEspeciales;
    }

    useEffect(() => {
        googleMap.inicializarMapa(mapDiv.current, { zoom: 11 });
        // document.addEventListener('mouseup', function(e) {
        //     document.getElementById('content-ruta')?.classList.remove('flex','flex-col')
        //     document.getElementById('content-ruta')?.classList.add('hidden')
        // })
    }, [])

    const empresasConvertidas = empresas.map(option => ({
        value: option.codEmpresa,
        label: option.nomEmpresa
    }));

    return(
        <>
            <ContenedorParametros titulo="Mapa de Rutas">

                <div className='flex gap-4 items-center justify-center'>
                    <i className="fa fa-bus" data-tip={'Empresas'} data-for={'Empresas'} aria-hidden="true"></i>
                    <NuevoSelectAutocomplete 
                        options={empresasConvertidas}
                        valorInput={(valor) => setEmpresaSeleccionada(valor)} 
                        textoInput={(texto) => setNombreEmpresaSeleccionada(texto)} 
                        valorPorDefecto='0'>
                    </NuevoSelectAutocomplete>
                </div>

                <div className='flex gap-4 items-center justify-center'>
                    <i className="fa fa-road" data-tip={'Ruta'} data-for={'Ruta'} aria-hidden="true"/>
                    <select className={`${classNameInput} lg:w-[250px]  w-[200px] `} value={rutaSeleccionada} onChange={(e) => setRutaSeleccionada(e.target.value)}>
                        <option value={empresaSeleccionada+'|0'}>-- Todos --</option>
                            {
                                rutas.map((ru ,i) => {
                                    if(empresaSeleccionada == 0){
                                        return
                                    }else{
                                        return <option key={i+1} value={ru.codEmpresaATU + '|' + ru.codRuta}>{ru.nomRuta} { empresaSeleccionada!=0? '' : ' | ' + ru.nomEmpresaATU}</option>
                                    }
                                })
                            }
                    </select>
                </div>

                <div className='flex gap-4 items-center justify-center'>
                    <i className="fa fa-map-o" data-tip={'Recorrido'} data-for={'Recorrido'} aria-hidden="true"/>
                    <select className={`${classNameInput} lg:w-[250px] w-[200px]`} value={recorridoSeleccionado} onChange={(e) => setRecorridoSeleccionado(e.target.value)}>
                        <option value={rutaSeleccionada+"|0"}>-- Todos --</option>
                            {
                                recorridos.map((re,i) => {
                                    if(rutaSeleccionada.split('|')[1] == 0){
                                        return
                                    }else{
                                        return <option key={i+1} value={re.codEmpresaATU + '|' + re.codRuta + '|' + re.codRecorrido}>{re.nomRecorrido} { rutaSeleccionada != '0|0'? '' : ' | ' + re.nomRuta}</option>
                                    }
                                })
                            }
                    </select>
                </div>

                <div className='flex gap-4 items-center justify-center'>
                    <i className="fa fa-eye" data-tip={'Tipo de Vista'} data-for={'TipoVista'} aria-hidden="true"/>
                    <select className={`${classNameInput} lg:w-[250px] w-[120px]`} value={vistaParaderos} onChange={(e) => setVistaParaderos(e.target.value)}>
                        <option value={0}>Controles</option>
                        <option value={1}>Paraderos</option>
                    </select>
                </div>
                
                <div className='flex gap-4 items-center justify-center flex-wrap'>
                    <BotonProcesar onClick={funcionEsperaRecorridos} />
                    <button className='border px-2 py-1 text-xl rounded hover:bg-[#155bb5]' onClick={()=>funcionBtn()} ><i className='lg:text-[10px] text-[15px] fa fa-download'></i> <span className='text-[18px] lg:text-[15px]'>GPX</span></button>
                </div>
                
            </ContenedorParametros>                    

            <Tooltip id={'Empresas'}/>
            <Tooltip id={'Ruta'}/>
            <Tooltip id={'Recorrido'}/>
            <Tooltip id={'VerMarcadores'}/>
            <Tooltip id={'TipoVista'}/>


            <div className="relative mapa-incidentes h-[85%]" >
                <div ref={mapDiv} className='w-full h-full'></div>

                <div data-tip={isImage1 ? 'Ocultar marcadores' : 'Ver marcadores'} data-for={'VerMarcadores'} className='absolute bottom-[200px] hover:bg-gray-100 right-2 bg-[#ffffff] rounded p-1 z-10000000 cursor-pointer' onClick={(e) => {setVerMarcadores(!verMarcadores); setIsImage1(!isImage1)}} >
                    <div className='text-right w-[40px]'> 
                    <img id='imagenCambioVista' src={isImage1 ? rutaSoloLinea : rutaConMarca } onClick={() => setIsImage1(!isImage1)} className=' w-[240px] h-[40px]' alt=""/>
                    </div>
                </div>
            </div> 
        </>
    )
}