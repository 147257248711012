import React, { useState, useEffect, useContext, useRef, useMemo, useReducer} from 'react';
import { ContenedorParametros } from '../../components/utils/ContenedorParametros';
import { notify } from '../../../utils/utils';
import { useATU } from "../../../hooks/ATU/useATU";
import { BotonProcesar } from '../../components/buttons/BotonProcesar';
import '../../../assets/css/views/tiempoControles.css';   //algunos estilos
import { DatePickerABX } from '../../components/pickers/DatePicker';
import { NuevoSelectAutocomplete } from '../../components/forms/NuevoSelectAutocomplete';
import { ComponenteCarga } from '../../components/forms/ComponenteCarga';
import { Tooltip } from '../../components/utils/Tooltip';
import BotonExcelTest from '../../components/buttons/BotonExcelTest';
import BotonExcelTiempos from '../../components/buttons/BotonExcelTiempos';
import { Modal } from '../../components/modal/Modal';
import { useModal } from '../../../hooks/useModal';
import BarsChart from '../../components/BarsChart';



export const DemandaParadero = () => {
    const { listarDemandaParaderos,demandaParaderos,empresas,listarRutas,rutas,listarRecorridos,recorridos } = useATU()
    const [ isOpen, openModal, closeModal ] = useModal()
    const [ isOpenBars , openModalBars, closeModalBars ] = useModal()
    const [ horasBars, setHorasBars ] = useState()
    const [ boletosBars, setBoletosBars ] = useState()
    const [ paraderoSeleccionado, setParaderoSeleccionado ] = useState()
    const empresasConvertidas = empresas?.map(option => ({
        value: option.codEmpresa,
        label: option.nomEmpresa
    }));

    const classNameInput = "containerScroll text-white h-[30px] rounded px-1  bg-zinc-800 text-xs focus:outline-none focus:ring-1 focus:ring-blue-500"

    const currentDate = new Date(); // Obtiene la fecha actual
    
    const [ empresaSeleccionada, setEmpresaSeleccionada ] = useState('');
    const [ rutaSeleccionada, setRutaSeleccionada ] = useState('');
    const [ recorridoSeleccionado,setRecorridoSeleccionado ] = useState('');

    const [imagenCarga,setImagenCarga] = useState(false);

    const [ validarClick,setValidarClick ] = useState(false)
    const [ valorMinutos, setValorMinutos ] = useState(60);
    const [ startDate, setStartDate ] = useState(currentDate)

    //para el modal
    const [ datosBoletos,setDatosBoletos ] = useState([])
    const [ tituloModal,setTituloModal ] = useState('')
    const [ cantBoletosModal,setCantBoletosModal ] = useState([])

    const fijo = 'sticky left-0 z-10 whitespace-nowrap bg-black'

    useEffect(() => {
        if(empresas.length != 0){
            setEmpresaSeleccionada(empresas[0]?.codEmpresa)
        }
    },[empresas])
    useEffect(() => {
        if(empresaSeleccionada){
            listarRutas(empresaSeleccionada)
        }
    },[empresaSeleccionada])
    useEffect(() => {
        if(rutas.length != 0){
            setRutaSeleccionada(empresaSeleccionada + '|' + rutas[0]?.codRuta)
        }
    },[rutas])
    useEffect(() => {
        if(rutaSeleccionada){
            listarRecorridos(rutaSeleccionada)
        }
    },[rutaSeleccionada])
    useEffect(() => {
        if(recorridos.length != 0){
            setRecorridoSeleccionado(rutaSeleccionada + '|' + recorridos[0]?.codRecorrido)
        }
    },[recorridos])

    useEffect(() => {
        if(datosBoletos.length != 0){
            openModal()
        }
    },[datosBoletos])


    const cargarDatosTabla = async() => {
        if(recorridoSeleccionado.split('|')[2] != 0){
            let fecha = new Date(startDate)
            let dia = fecha.getDate();
            let mes = fecha.getMonth() + 1; // Los meses comienzan desde 0, por lo que se suma 1
            let anio = fecha.getFullYear();
            let fechaConvertida = `${dia < 10 ? '0' : ''}${dia}/${mes < 10 ? '0' : ''}${mes}/${anio}`

            await listarDemandaParaderos(empresaSeleccionada,recorridoSeleccionado.split('|')[2],valorMinutos,fechaConvertida)
            setImagenCarga(false)
        }else{
            notify('Seleccionar todos los parametros','info')
        }
    }

    const mostrarDetalleBoletos = (infoBoletos,horaParadero,nombre,montoTotal,ticketsTotales) => {
        setDatosBoletos(infoBoletos.ticketsDemanda.split(','))
        setTituloModal(`${nombre} (${horaParadero})`)
        setCantBoletosModal([ticketsTotales,montoTotal])
    }



    return(
        <>
            <ComponenteCarga variableCarga={imagenCarga}></ComponenteCarga>

            <ContenedorParametros titulo="Demanda por Paraderos">

                <div className='flex gap-4 items-center justify-center'>
                    <i className="fa fa-calendar-alt" data-tip={'Fecha'} data-for={'FechaInicio'} aria-hidden="true"></i>
                    <div>
                        <DatePickerABX data-for={'FechaInicio'} date={startDate} setDate={setStartDate} clases='lg:w-[250px]'/>
                    </div>
                </div>

                <div className='flex gap-4 items-center justify-center'>
                    <i className="fa fa-bus" data-tip={'Empresa'} data-for={'Empresas'} aria-hidden="true"></i>
                    <NuevoSelectAutocomplete options={empresasConvertidas} valorInput={(valor) => {setEmpresaSeleccionada(valor);setValidarClick(false)}} valorPorDefecto='1'></NuevoSelectAutocomplete>
                </div>

                <div className='flex gap-4 items-center justify-center'>
                    <i className="fa fa-road " data-tip={'Ruta'} data-for={'Ruta'} aria-hidden="true"/>
                    <select className={`${classNameInput} w-[60px] lg:w-[250px] text-center `} value={rutaSeleccionada} onChange={(event) => {setRutaSeleccionada(event.target.value);setValidarClick(false)}}>
                        {
                            rutas?.map((ru ,i) => {
                                return <option key={i+1} value={`${empresaSeleccionada}|${ru.codRuta}`}>{ru.nomRuta} { empresaSeleccionada !=0 ? '' : ' | ' + ru.nomEmpresaATU}</option>
                            })
                        }
                    </select>
                </div>

                <div className='flex gap-4 items-center justify-center'>
                    <i className="fa fa-map-o " data-tip={'Recorrido'} data-for={'Recorrido'} aria-hidden="true"/>
                    <select className={`${classNameInput} w-[130px] lg:w-[250px]`} value={recorridoSeleccionado} onChange={(event) => {setRecorridoSeleccionado(event.target.value);setValidarClick(false)}}>
                        {
                            recorridos?.map(function(re,j){
                                return <option key={j+1} value={`${rutaSeleccionada}|${re.codRecorrido}`}>{re.nomRecorrido} { rutaSeleccionada != '0|0'? '' : ' | ' + re.nomRuta}</option>
                            })
                        }
                    </select>
                </div>

                <div className='flex gap-4 items-center justify-center flex-wrap'>
                    <i className="fa fa-clock-o " data-tip={'Intervalor de Minutos'} data-for={'Intervalo'} aria-hidden="true"/>
                    <input className={`${classNameInput} w-[40px]`} type='number' value={valorMinutos} onChange={(event) => {setValorMinutos(event.target.value);setValidarClick(false)}} />
                    <span className='-ml-3'>min.</span>
                    <BotonProcesar onClick={async() => {setImagenCarga(true);setValidarClick(true);await cargarDatosTabla()}}/>
                    <BotonExcelTiempos
                        tableId="tableDemanda"
                        nombreArchivo="REPORTE DEMANDA POR PARADEROS"
                        parametrosCabecera={{
                            nomEmpresa: (empresas.find(elem => elem.codEmpresa == empresaSeleccionada))?.nomEmpresa,
                            nomRuta: (rutas.find(elem => elem.codRuta == rutaSeleccionada.split('|')[1]))?.nomRuta,
                            nomRecorrido: (recorridos.find(elem => elem.codRecorrido == recorridoSeleccionado.split('|')[2]))?.nomRecorrido
                        }}
                    />
                </div>

            </ContenedorParametros>

            <Tooltip id={'Empresas'}/>
            <Tooltip id={'Ruta'}/>
            <Tooltip id={'Recorrido'}/>
            <Tooltip id={'Intervalo'}/>
            <Tooltip id={'FechaInicio'}/>

            <div className="containerScroll mt-7 relative top-0 bottom-0 left-0 right-0 contenedor-tabla">
                {
                    <div>
                        <table className="table" id='tableDemanda'>
                            <thead>
                                {
                                validarClick ?
                                <>
                                    <tr className='sticky top-0 z-20'>
                                        <th rowSpan={{}} className=' text-center w-[120px] sticky left-0' ref={(node) => {
                                            if(node){
                                                node.style.setProperty("z-index","1000000000000000000000000000000000000000000","important")
                                                node.style.setProperty("position","sticky","important")
                                            }
                                        }}>HORA</th>
                                        {
                                            demandaParaderos.map(function(data,i){
                                                return (
                                                    <>
                                                        <th key={i+1} className='text-center border border-gray-500' title={data.nomParadero} colSpan="3" 
                                                        onClick={()=>{
                                                                setParaderoSeleccionado(data.nomParadero)
                                                                const nuevaHoraParadero = [];
                                                                const nuevosTotalesTickets  = [];

                                                                data.horasDemandas.forEach(obj => {
                                                                    const horaParadero = obj.horaParadero;
                                                                    nuevaHoraParadero.push(horaParadero);
                                                                    const ticketsDemanda = obj.ticketsDemanda.split('|')[0];
                                                                    const ticketsDemanda2 = ticketsDemanda.split(',')
                                                                    let totalSum = 0;

                                                                    ticketsDemanda2.forEach(ticket => {
                                                                        const valores = ticket.split('-');
                                                                        totalSum += parseInt(valores[0], 10);
                                                                    });

                                                                    nuevosTotalesTickets.push(totalSum);
                                                                });

                                                                setHorasBars(nuevaHoraParadero);
                                                                setBoletosBars(nuevosTotalesTickets);
                                                        
                                                            openModalBars()
                                                            
                                                            }}>
                                                            <div className='hover:text-green-200 text-center max-h-9 overflow-hidden text-ellipsis '>
                                                                {/* {data.abrev} */}
                                                                <i className="fa fa-bar-chart text-green-500 mr-1 text-lg " aria-hidden="true"></i>
                                                                {data.nomParadero}
                                                            </div>
                                                        </th>
                                                    </>
                                                    )
                                            })

                                        }

                                    </tr>
                                    <tr className='sticky top-0 z-10'>
                                        {/* <th  className='text-center  sticky left-0' ref={(node) => {
                                            if(node){
                                                node.style.setProperty("z-index","1000000000000000000000000000000000000000000","important")
                                                node.style.setProperty("position","sticky","important")
                                            }
                                        }}></th> */}
                                        <th></th>
                                        {
                                            demandaParaderos.map(function(data,i){
                                                return (
                                                    <>
                                                        <th className=' border-gray-500 border-l  '  key={i+1} title={data.nomParadero}>
                                                            <div className='text-center max-h-9 overflow-hidden text-ellipsis max-w-[100px] '>
                                                                Buses
                                                            </div>
                                                        </th>

                                                        <th className=' border-gray-500 border-r border-l' key={i+1} title={data.nomParadero}>
                                                            <div className='text-center max-h-9 overflow-hidden text-ellipsis max-w-[100px]'>
                                                                Boletos
                                                            </div>
                                                        </th>
                                                        <th className=' border-gray-500 border-r' key={i+1} title={data.nomParadero}>
                                                            <div className='text-center max-h-9 overflow-hidden text-ellipsis max-w-[100px]'>
                                                                Prod.
                                                            </div>
                                                        </th>
                                                    </>
                                                    )
                                            })

                                        }

                                    </tr>
                                </>


                                :
                                    ''
                                }
                            </thead>
                            <tbody>
                            {
                                validarClick ?
                                    demandaParaderos.length!=0 ?

                                        demandaParaderos[0].horasDemandas.length!= 0 ?
                                            demandaParaderos[0].horasDemandas.map(function(data,i){
                                                return  (
                                                            <>
                                                                <tr key={i+1}>
                                                                    <td className={`${fijo}`} >{data.horaParadero}</td>
                                                                    {
                                                                        demandaParaderos.map((data2,j) => {
                                                                            let test = data2.horasDemandas.find(elem => elem.horaParadero == data.horaParadero)
                                                                            let nroBuses = test.ticketsDemanda.split('|')[1]
                                                                            let tickets = test.ticketsDemanda.split('|')[0].split(',')
                                                                            let ticketsTotales = 0
                                                                            let montoTotal = 0

                                                                            tickets.map((data3,k) => {
                                                                                ticketsTotales += Number(data3.split('-')[0])
                                                                                montoTotal += Number(data3.split('-')[1] * data3.split('-')[0])
                                                                            })

                                                                            return (
                                                                                <>
                                                                                    <td key={j+1} onClick={() => {mostrarDetalleBoletos(test,data.horaParadero,data2.nomParadero,montoTotal,ticketsTotales);}} className='whitespace-nowrap text-center'>
                                                                                        {nroBuses || 0}
                                                                                    </td>
                                                                                    <td key={j+1} onClick={() => {mostrarDetalleBoletos(test,data.horaParadero,data2.nomParadero,montoTotal,ticketsTotales);}} className='whitespace-nowrap text-center'>
                                                                                        {ticketsTotales}
                                                                                    </td>
                                                                                    <td key={j+1} onClick={() => {mostrarDetalleBoletos(test,data.horaParadero,data2.nomParadero,montoTotal,ticketsTotales);}} className='whitespace-nowrap text-center'>
                                                                                        {montoTotal || 0}
                                                                                    </td>


                                                                                </>
                                                                                )
                                                                        })
                                                                    }
                                                                </tr>
                                                                
                                                            </>
                                                        )
                                            })
                                        :
                                            <tr className='text-center'><td colSpan={demandaParaderos.length+1}>No hay información para mostrar con los parametros seleccionados</td></tr>
                                    :
                                        <tr><td className='text-center'>No hay información para mostrar con los parametros seleccionados</td></tr>
                                :
                                    <tr><td className='text-center'>Presione el botón procesar ...</td></tr>
                            }
                            </tbody>
                        </table>
                    </div>
                }
            </div>

            <Modal
                isOpen={isOpen} closeModal={closeModal}
                title={tituloModal} validButton={{denied:true}}
                textButtons={{denied:'Cerrar'}}
            >
                <div className="flex gap-8 items-center flex-wrap justify-center">
                    <div className="w-[450px] px-4">
                        <div className="flex flex-col justify-between w-full form-content">
                            <div>
                                <table className='table'>
                                    <thead>
                                        <tr>
                                            <th>Nom. Boleto</th>
                                            <th>Cant. Boletos</th>
                                            <th>Denominacion</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            datosBoletos.map((data,i) => {
                                                let boletosTemp = data.split('|')[0].split('-')
                                                return  (<tr key={i+1}>
                                                            <td className='text-center'>{boletosTemp[2] || '-'}</td>
                                                            <td className='text-center'>{boletosTemp[0] || 0}</td>
                                                            <td className='text-center'>S/. {boletosTemp[1] || 0}</td>
                                                        </tr>)
                                            })
                                        }
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th></th>
                                            <th>{cantBoletosModal[0]} Boletos</th>
                                            <th>S/. {cantBoletosModal[1] || 0}</th>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>


            <Modal
                isOpen={isOpenBars} closeModal={closeModalBars}
                title={`Detalle de Paradero [${paraderoSeleccionado}]`} validButton={{denied:true}}
                textButtons={{denied:'Cerrar'}}
            >
                <div className="flex h-[400px]  w-[650px] items-center justify-center">
                    <BarsChart datosInferior={horasBars} datosLateral={boletosBars} paradero={paraderoSeleccionado}/>
                </div>
            </Modal>









        </>
    )
}