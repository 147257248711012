import React, { useEffect, useState } from 'react';
import { useATU } from "../../../hooks/ATU/useATU";



export const BuscadorPorSemana = (props) => {

    const {obtenerFechas,fechas} = useATU()

    const anioActual = new Date().getFullYear();

    const obtenerNumeroSemana = () => {
        const fechaActual = new Date();
        const inicioAnio = new Date(fechaActual.getFullYear(), 0, 1);
        const milisegundosPorSemana = 604800000; // 7 días en milisegundos
    
        const diferencia = fechaActual - inicioAnio;
        const numeroSemanas = Math.ceil(diferencia / milisegundosPorSemana);
        
        return numeroSemanas;
    };

    const numeroSemanaActual = obtenerNumeroSemana()
    
    const [valorSemana, setValorSemana] = useState(numeroSemanaActual);
    const [valorAnio, setValorAnio] = useState(anioActual);
    
    useEffect(() => {
        obtenerFechas(valorSemana,valorAnio)
    }, [valorSemana,valorAnio]);

    useEffect(() => {
        if(fechas.length!=0)
        props.fechasCompartidas(fechas)
    },[fechas])

    const aumentarSemana = () => {
        if(valorSemana + 1 < 53){
            setValorSemana(valorSemana + 1)
        }else{
            setValorSemana(1)
            setValorAnio(valorAnio + 1)
        }
    }

    const disminuirSemana = () => {
        if(valorSemana - 1 > 0){
            setValorSemana(valorSemana - 1)
        }else{
            setValorSemana(52)
            setValorAnio(valorAnio - 1)
        }
    }

    return (
        <>
            <div className='rounded border px-2 py-[2px] bg-[#2E5289]'>
                <div className="flex">
                    <button onClick={() => {disminuirSemana();props.funcionCambio()}}  className="border px-2 rounded mr-1 ">
                        <i className="fa fa-chevron-left" aria-hidden="true"></i>
                    </button>
                    <div className='mr-2'>
                        <span className='text-l'>Semana: </span>
                    </div>
                    <select id="selectValorSemana" value={valorSemana} onChange={(event) => {setValorSemana(event.target.value);props.funcionCambio()}} className="rounded text-black text-[14px] text-center w-[50px] mr-2 h-[23px] " >
                        {
                            Array.from({ length: 52 }, (_, i) => (
                                <option value={i + 1} key={i++}>{i + 1}</option>
                            ))
                        }
                    </select>
                    <select id="selectValorAnio" value={valorAnio} onChange={(event) => {setValorAnio(event.target.value);props.funcionCambio()}} className="rounded text-black text-[14px] text-center w-[70px] mr-2 h-[23px] ">
                        {
                            Array.from({ length: 6 }, (_, i) => (
                                <option value={i + 2019} key={i++}>{i + 2019}</option>
                            ))
                        }
                    </select>
                    <button onClick={() => {aumentarSemana();props.funcionCambio()}} className="border px-2 rounded ">
                        <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </button>
                </div>
                <div className='text-center text-xs'>
                    <div id="divDeFechas">{fechas[0]?.fechaInicio} - {fechas[0]?.fechaFin}</div>
                </div>
            </div>
        </>
    )
}
