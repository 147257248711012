import React, { useEffect, useMemo, useRef, useReducer, useState } from 'react';
import { ContenedorParametros } from '../../components/utils/ContenedorParametros'; 
import { useATU } from "../../../hooks/ATU/useATU";
import { BotonProcesar } from '../../components/buttons/BotonProcesar';
import { Tooltip } from '../../components/utils/Tooltip';
import { DatePickerABX } from '../../components/pickers/DatePicker';
import { NuevoSelectAutocomplete } from '../../components/forms/NuevoSelectAutocomplete';
import {BotonExcel} from '../../components/buttons/BotonExcel';

import  ReactTooltip  from 'react-tooltip';

import BotonExcelTest from '../../components/buttons/BotonExcelTest';


export const InformacionOperacion = () => {
    const {empresas,listarRutas,rutas,listarRecorridos,recorridos} = useATU()
    const classNameInput = "containerScroll text-white h-[30px] rounded px-4 w-full bg-zinc-800 text-xs focus:outline-none focus:ring-1 focus:ring-blue-500"   

    const [ empresaSeleccionada , setEmpresaSeleccionada ] = useState(0)
    const [ rutaSeleccionada, setRutaSeleccionada ] = useState('0|0');
    const [ recorridoSeleccionado, setRecorridoSeleccionado ] = useState(0);
    
    const [imagenCarga,setImagenCarga] = useState(false)

    const [ date, setDate ] = useState(new Date())

    useEffect(() => {
        listarRutas(empresaSeleccionada);
        setRutaSeleccionada(`${empresaSeleccionada}|0`)
    },[empresaSeleccionada])

    useEffect(() => {
        listarRecorridos(rutaSeleccionada)
        setRecorridoSeleccionado(`${rutaSeleccionada}|0`)
    },[rutaSeleccionada])

    const empresasConvertidas = empresas.map(option => ({
        value: option.codEmpresa,
        label: option.nomEmpresa
    }));

    
    return (
        <>
            <div className={`w-full h-screen fixed bg-black bg-opacity-60 items-center justify-center z-[1000000000] ${imagenCarga ? 'flex' : 'hidden'}`}>
                <img className='bg-no-repeat bg-center w-60' src='https://img.pikbest.com/png-images/20190918/cartoon-snail-loading-loading-gif-animation_2734139.png!bw700'></img>
            </div>

            <ContenedorParametros
                titulo='Informacion de Operacion (Despacho)'
            >   
                <i className="fa fa-calendar-alt" data-tip={'Fecha Inico'} data-for={'FechaInicio'} aria-hidden="true"></i> 
                <div>
                    <DatePickerABX data-for={'FechaInicio'} date={date} setDate={setDate}/>
                </div>

                <i className="fa fa-bus" data-tip={'Empresa'} data-for={'Empresa'} aria-hidden="true"></i>
                <div>
                    <NuevoSelectAutocomplete options={empresasConvertidas} valorInput={(valor) => setEmpresaSeleccionada(valor)} valorPorDefecto='0'></NuevoSelectAutocomplete>
                </div>

                <i className="fa fa-road" data-tip={'Ruta'} data-for={'Ruta'} aria-hidden="true"/>
                <div>
                    <select value={rutaSeleccionada} className={`${classNameInput} max-w-[100px]`} onChange={(e)=>setRutaSeleccionada(e.target.value)}>
                        <option value={`${empresaSeleccionada}|0`}>- Todos -</option>
                        {
                            rutas.map(function(r,i){
                                if(empresaSeleccionada == 0){
                                    return
                                }else{
                                    return <option key={i+1} value={`${r.codEmpresaATU}|${r.codRuta}`}>{empresaSeleccionada == 0 ? `${r.nomRuta} | ${r.nomEmpresaATU}` : r.nomRuta}</option>
                                }
                            })
                        }
                    </select>
                </div>

                <i className="fa fa-map-o" data-tip={'Recorrido'} data-for={'Recorrido'} aria-hidden="true"/>
                <div>
                <select className={`${classNameInput} lg:w-[100px] w-[200px]`} value={recorridoSeleccionado} onChange={(e) => setRecorridoSeleccionado(e.target.value)}>
                    <option value={rutaSeleccionada+"|0"}>-- Todos --</option>
                        {
                            recorridos.map((re,i) => {
                                if(rutaSeleccionada.split('|')[1] == 0){
                                    return
                                }else{
                                    return <option key={i+1} value={re.codEmpresaATU + '|' + re.codRuta + '|' + re.codRecorrido}>{re.nomRecorrido} { rutaSeleccionada != '0|0'? '' : ' | ' + re.nomRuta}</option>
                                }
                            })
                        }
                    </select>
                </div>

                <BotonProcesar onClick={async() => setImagenCarga(true)} ></BotonProcesar>       
                <BotonExcelTest tableId="tableOperacion" nombreArchivo="REPORTE INFORMACION DE OPERACION"></BotonExcelTest>

                <Tooltip id={'Empresa'}/>
                <Tooltip id={'Ruta'}/>
                <Tooltip id={'Recorrido'}/>
                <Tooltip id={'FechaInicio'}/>
            </ContenedorParametros>

            <div className="containerScroll mt-7 relative top-0 bottom-0 left-0 right-0 contenedor-tabla">
            {
                <div>
                    <table className="table" id='tableOperacion'>
                        <thead>
                            <tr>
                                <th>Nº</th>
                                <th>PADRON</th>
                                <th>PLACA</th>
                                <th data-tip={'Numero de Viaje'} data-for={'Nviaje'}>N. VIAJE</th>
                                <th>H.INICIO</th>
                                <th>I</th>
                                <th>CONDUCTOR</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                false?
                                    ''
                                : 
                                    <tr><td className='text-center' colSpan='8'>No hay informacion</td></tr>
                            }
                        
                        </tbody>
                    </table>

                    <Tooltip id={'Nviaje'}></Tooltip>
                </div>
            }
            </div>
        </>
    )
}