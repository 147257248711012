import { toast } from 'react-toastify';

const padTo2Digits = (num) => {
    return num.toString().padStart(2, '0');
}

export const classNameInput = 'containerScroll text-white h-[30px] rounded px-4  bg-zinc-800 text-xs focus:outline-none focus:ring-1 focus:ring-blue-500 caret-white'

export const formatDate = (date, format = 103) => {
    let stringFormat = ''
    switch  (format) {
        case 23:
            stringFormat = [
                date.getFullYear(),                
                padTo2Digits(date.getMonth() + 1),
                padTo2Digits(date.getDate())
            ].join('-');
            break;
        case 103:
            stringFormat = [
                padTo2Digits(date.getDate()),
                padTo2Digits(date.getMonth() + 1),
                date.getFullYear()
            ].join('/');
            break;
        default:
            console.error('Ingreso un formato no valido mapeado')
    }

    return stringFormat;
}

export const notify = (title, type, options = {}) => {
    toast[type](title, {
        position: options.position ?? "top-right",
        autoClose: options.autoClose ?? 5000,
        hideProgressBar: options.hideProgressBar ?? false,
        closeOnClick: options.closeOnClick ?? true,
        pauseOnHover: options.pauseOnHover ?? true,
        draggable: true,
        progress: undefined,
    });
}

export const soloCelular = (event, element) => {
    const key = event.keyCode ? event.keyCode : event.which
    if (key === 8) return true
    if (key > 47 && key < 58) {
        if (element.value === "") return true
        const regexp = /.[0-9]{8}$/
        if(!(regexp.test(element.value))) {
            return true
        }
    }
    event.preventDefault()
}

export const soloNumero = (event, element) => {
    const key = event.keyCode ? event.keyCode : event.which
    if (key === 8) return true
    if (key > 47 && key < 58) {
        if (element.value === "") return true
        const regexp = /.[0-9]{20}$/
        if(!(regexp.test(element.value))) {
            return true
        }
    }
    event.preventDefault()
}

export const soloDNI = (event, element) => {
    const key = event.keyCode ? event.keyCode : event.which
    if (key === 8) return true
    if (key > 47 && key < 58) {
        if (element.value === "") return true
        const regexp = /.[0-9]{7}$/
        if(!(regexp.test(element.value))) {
            return true
        }
    }
    event.preventDefault()
}

export const soloDecimal = (e, elemento) => {
    const key = e.keyCode ? e.keyCode : e.which

    if (key === 8) return true
    
    if (key > 47 && key < 58) {
        if (elemento.value === "") return true
        const regexp = /.[0-9]{9}$/

        if(!(regexp.test(elemento.value))) {
            return true
        }
    }

    if (key === 46) {
        if (elemento.value === "") return false
        const regexp = /^[0-9]+$/
        // console.log("s ", regexp.test(elemento.value))
        if (regexp.test(elemento.value)) {
            return true
        }
    }
    
    e.preventDefault()
}

export const formatState = (codState, nameState) => {
    let htmlrespuesta = '';
    let className = ''
    if ([2].includes(codState)) className = `cursor-pointer bg-yellow-600 px-2 py-[2px] rounded uppercase text-[11px] font-bold `
    else if ([45].includes(codState)) className = 'cursor-pointer bg-blue-600 px-2 py-[2px] rounded uppercase text-[11px] font-bold'
    else if ([7].includes(codState)) className = 'cursor-pointer bg-green-600 px-2 py-[2px] rounded uppercase text-[11px] font-bold'
    return className;
}


export const primeraLetraMayuscula = (string) => {
    var str = string;

    str = str.toLowerCase().replace(/\b[a-z]/g, function (letter) {
        return letter.toUpperCase();
    });

    return str; 
}

export const todoMayuscula = (string)=> {
    var str = string;
    str = str.toUpperCase().replace(/\b[a-z]/g, function (letter) {
        return letter.toUpperCase();
    });
    return str;
}

export const quitarTildes = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
} 


// PARA LOS DATE
export const dateFormatToString = (date) => {
    const dia = String(date.getDate()).padStart(2, '0');
    const mes = String(date.getMonth() + 1).padStart(2, '0'); // Los meses en Date se indexan desde 0
    const anio = date.getFullYear();

    return `${dia}/${mes}/${anio}`;
}
export const stringToDateFormat = (string) => {
    const [dia,mes,anio] = string.split('/').map(Number)

    const date = new Date(anio,mes-1,dia)
    return date
}

// PARA LOS DATETIME
export const dateTimeToString = (date) => {
    const dia = String(date.getDate()).padStart(2, '0');
    const mes = String(date.getMonth() + 1).padStart(2, '0'); // Los meses en Date se indexan desde 0
    const anio = date.getFullYear();

    const hora = String(date.getHours()).padStart(2, '0');
    const minuto = String(date.getMinutes()).padStart(2, '0');
    const segundo = String(date.getSeconds()).padStart(2, '0');

    return `${dia}/${mes}/${anio} ${hora}:${minuto}:${segundo}`;
}
export const stringToDateTime = (string) => {
    const [datePart,timePart] = string.split(' ')
    const [dia,mes,anio] = datePart.split('/').map(Number)
    const [hora,minuto,segundo] = timePart.split(':').map(Number)

    const date = new Date(anio,mes-1,dia,hora,minuto,segundo)
    return date
}

