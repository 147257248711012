import React, { Suspense } from "react";
import { BrowserRouter } from 'react-router-dom'
import { RoutesJSX } from './routing/Routes'
import { UserProvider } from '../context/provider/UserProvider'

import CacheBuster from "react-cache-buster";
import packageJson from '../../package.json'

export const App = () => {
    const isProduction = process.env.NODE_ENV === 'production'
    console.log("isProduction -> ",isProduction)
    console.log("version -> ", packageJson.version)
    return (
        <>
            <CacheBuster
                currentVersion={packageJson.version}
                isEnabled={isProduction}
            >
                <UserProvider>
                        <Suspense fallback={<h1>Cargando...</h1>}>
                            <BrowserRouter basename={process.env.REACT_APP_SUB_DIRECTORY}>
                                <RoutesJSX />
                            </BrowserRouter>
                        </Suspense>
                </UserProvider>
            </CacheBuster>
        </>
    )
}
