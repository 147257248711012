import React from 'react';
import * as XLSX from 'xlsx';

// class BotonExcel extends React.Component {
//     exportToExcel = () => {

//     const { tableData, fileName, sheetName } = this.props;

//     const worksheet = XLSX.utils.table_to_sheet(tableData);
//     const workbook = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
//     XLSX.writeFile(workbook, fileName + '.xlsx');
//     };

//     render() {
//         return (
//         <div className='hidden'>
//             <table>
//             <thead>
//                 <tr className="table-header">
//                 <th>Nombre</th>
//                 <th>Edad</th>
//                 <th>Email</th>
//                 </tr>
//             </thead>
//             <tbody>
//                 <tr>
//                 <td>John</td>
//                 <td>25</td>
//                 <td>john@example.com</td>
//                 </tr>
//                 <tr>
//                 <td>Jane</td>
//                 <td>30</td>
//                 <td>jane@example.com</td>
//                 </tr>
//                 {/* Agrega aquí tus filas de datos */}
//             </tbody>
//             </table>
            
//             <button className='bg-green-800 rounded text-[#ffff]' onClick={this.BotonExcel}>
//             <i className=' fa fa-file-excel-o bg-green-800  px-4 py-1 text-2xl rounded hover:bg-[#155bb5]'></i></button>
//         </div>
//         );
//     }
// }

// export default BotonExcel;

// class BotonExcel extends React.Component {
//     BotonExcel = () => {
//     const { data, fileName, sheetName } = this.props;

//     const workbook = XLSX.utils.book_new();
//     const worksheet = XLSX.utils.json_to_sheet(data);
//     XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
//     XLSX.writeFile(workbook, fileName + '.xlsx');
// };

const BotonExcel = () => {

    const handleDownload = () => {
        // Obtener el elemento de la tabla
        const table = document.getElementById('tableIGO');
        
        // Convertir la tabla a formato Excel
        const workbook = XLSX.utils.table_to_book(table);

        // Obtener la hoja de cálculo
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];


        
        
        // Obtener las celdas de la cabecera
        const headerRange = XLSX.utils.decode_range(sheet['!ref']);
        for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
            const headerCell = XLSX.utils.encode_cell({ r: headerRange.s.r, c: col });
            if (sheet[headerCell]) {
                sheet[headerCell].s = {
                  fill: {
                    fgColor: { rgb: 'FF0000FF' } // Color de fondo en formato hexadecimal
                  },
                  font: {
                    color: { rgb: 'FFFFFFFF' } // Color de texto en formato hexadecimal
                  }
                  // Otros estilos de la cabecera si es necesario
                };
              }
        }

        // Descargar el archivo Excel
        XLSX.writeFile(workbook, 'tableIGO.xlsx');
    };

    return (
            <button className='bg-green-800 rounded text-[#ffff]' onClick={handleDownload}>
            <i className=' fa fa-file-excel-o bg-green-800  px-4 py-1 text-2xl rounded hover:bg-[#155bb5]'></i></button>
        );
}

export default BotonExcel;
