import React from 'react'
import { ContenedorParametros } from '../../components/utils/ContenedorParametros'
import { BotonNuevo } from '../../components/buttons/BotonNuevo'
import { BotonProcesar } from '../../components/buttons/BotonProcesar'
import BotonExcel from '../../components/buttons/BotonExcel'

export const GestionFlotaVehicular = () => {

    const data = [
        {
            "CodUnidad": 395,
            "PadronUnidad": "",
            "PlacaUnidad": "S5S-909",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": "",
            "CodPersonaPropietario": null,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "rlarosa@cefesa",
            "FechaCreacion": "11/10/2021 10:01:27",
            "CodConcesionario": 1,
            "NomUsuario": null,
            "FechaEdicion": null,
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 10,
            "PadronUnidad": "001",
            "PlacaUnidad": "B2U-728",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "IVECO",
            "AñoUnidad": 2008,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "CC 170E22",
            "AñoUnidad1": 2008,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "F4AE0681D6015800",
            "SerieUnidad": "8ATA1PF009X067241",
            "ColoresUnidad": "MORADO,BLANCO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.72,
            "PesoSecoUnidad": 9.28,
            "LongitudUnidad": 12.20,
            "AnchoUnidad": 2.53,
            "AlturaUnidad": 3.18,
            "NumAsientos": 49,
            "NumPasajeros": 48,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "23/04/2016 00:00:00",
            "CodConcesionario": 1,
            "NomUsuario": "jguerra@sjl",
            "FechaEdicion": "06/01/2021 16:56:03",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "09/08/2010",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 13,
            "PadronUnidad": "002",
            "PlacaUnidad": "A0G-723",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2010,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF 1721/59",
            "AñoUnidad1": 2010,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377989U0907254",
            "SerieUnidad": "9BM384075BB749639",
            "ColoresUnidad": "MORADO,AMARILLO,BLANCO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.87,
            "PesoSecoUnidad": 9.13,
            "LongitudUnidad": 12.25,
            "AnchoUnidad": 2.52,
            "AlturaUnidad": 3.37,
            "NumAsientos": 49,
            "NumPasajeros": 48,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "23/04/2016 00:00:00",
            "CodConcesionario": 1,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:08:38",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "24/02/2012",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 15,
            "PadronUnidad": "003",
            "PlacaUnidad": "F1W-756",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLKSWAGEN",
            "AñoUnidad": 2013,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "17.210 OD",
            "AñoUnidad1": 2013,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "73421808",
            "SerieUnidad": "9532X82W2DR331101",
            "ColoresUnidad": "ROJO,BLANCO,VERDE CLARO,VERDE OSCURO",
            "PesoBrutoUnidad": 16.00,
            "CargaUtilUnidad": 7.04,
            "PesoSecoUnidad": 10.16,
            "LongitudUnidad": 12.02,
            "AnchoUnidad": 2.60,
            "AlturaUnidad": 3.40,
            "NumAsientos": 49,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "23/04/2016 00:00:00",
            "CodConcesionario": 1,
            "NomUsuario": "wcapillo@sjl",
            "FechaEdicion": "06/01/2021 16:41:15",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "31/10/2013",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 9,
            "PadronUnidad": "004",
            "PlacaUnidad": "B2U-730",
            "CodEstado": 1,
            "Codigo": 7,
            "Nombre": "409",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "IVECO",
            "AñoUnidad": 2008,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "CC 170E22",
            "AñoUnidad1": 2008,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "F4AE0681D6017245",
            "SerieUnidad": "8ATA1PF009X068065",
            "ColoresUnidad": "MORADO,BLANCO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.70,
            "PesoSecoUnidad": 9.30,
            "LongitudUnidad": 12.20,
            "AnchoUnidad": 2.53,
            "AlturaUnidad": 3.18,
            "NumAsientos": 49,
            "NumPasajeros": 48,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "23/04/2016 00:00:00",
            "CodConcesionario": 1,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:08:56",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "09/08/2010",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 16,
            "PadronUnidad": "005",
            "PlacaUnidad": "A6T-954",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2008,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF 1721/59",
            "AñoUnidad1": 2008,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377989U0788911",
            "SerieUnidad": "9BM3840759B610134",
            "ColoresUnidad": "MORADO,AMARILLO,BLANCO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.53,
            "PesoSecoUnidad": 9.47,
            "LongitudUnidad": 12.01,
            "AnchoUnidad": 2.50,
            "AlturaUnidad": 3.26,
            "NumAsientos": 53,
            "NumPasajeros": 52,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "23/04/2016 00:00:00",
            "CodConcesionario": 1,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:09:22",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "30/12/2009",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 14,
            "PadronUnidad": "006",
            "PlacaUnidad": "F2Y-703",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLKSWAGEN",
            "AñoUnidad": 2013,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "17.210.OD",
            "AñoUnidad1": 2013,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "73424864",
            "SerieUnidad": "9532X82W9DR330110",
            "ColoresUnidad": "ROJO,BLANCO,VERDE OSCURO,VERDE CLARO",
            "PesoBrutoUnidad": 16.00,
            "CargaUtilUnidad": 7.04,
            "PesoSecoUnidad": 10.16,
            "LongitudUnidad": 12.02,
            "AnchoUnidad": 2.60,
            "AlturaUnidad": 3.40,
            "NumAsientos": 49,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "23/04/2016 00:00:00",
            "CodConcesionario": 1,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:09:34",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "13/11/2013",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 12,
            "PadronUnidad": "007",
            "PlacaUnidad": "A6T-953",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2008,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF 1721/59",
            "AñoUnidad1": 2008,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377989U0788709",
            "SerieUnidad": "9BM3840759B610047",
            "ColoresUnidad": "MORADO,AMARILLO,BLANCO",
            "PesoBrutoUnidad": 17.05,
            "CargaUtilUnidad": 7.59,
            "PesoSecoUnidad": 9.41,
            "LongitudUnidad": 12.01,
            "AnchoUnidad": 2.50,
            "AlturaUnidad": 3.26,
            "NumAsientos": 53,
            "NumPasajeros": 52,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "23/04/2016 00:00:00",
            "CodConcesionario": 1,
            "NomUsuario": "wcapillo@sjl",
            "FechaEdicion": "06/01/2021 16:38:38",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "29/12/2009",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 32,
            "PadronUnidad": "008",
            "PlacaUnidad": "A6T-955",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2008,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF 1721/59",
            "AñoUnidad1": 2008,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377973U0779440",
            "SerieUnidad": "9BM3840739B599447",
            "ColoresUnidad": "MORADO,AMARILLO,BLANCO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.53,
            "PesoSecoUnidad": 9.47,
            "LongitudUnidad": 12.01,
            "AnchoUnidad": 2.50,
            "AlturaUnidad": 3.26,
            "NumAsientos": 53,
            "NumPasajeros": 52,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "23/04/2016 00:00:00",
            "CodConcesionario": 1,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:00:04",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "16/04/2009",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 34,
            "PadronUnidad": "009",
            "PlacaUnidad": "B2B-745",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2010,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF-1721/59",
            "AñoUnidad1": 2010,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377989U0893074",
            "SerieUnidad": "9BM384075BB732170",
            "ColoresUnidad": "MORADO,BLANCO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.86,
            "PesoSecoUnidad": 9.14,
            "LongitudUnidad": 12.33,
            "AnchoUnidad": 2.60,
            "AlturaUnidad": 3.16,
            "NumAsientos": 53,
            "NumPasajeros": 52,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "23/04/2016 00:00:00",
            "CodConcesionario": 1,
            "NomUsuario": "wcapillo@sjl",
            "FechaEdicion": "06/01/2021 16:37:17",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "08/11/2011",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 19,
            "PadronUnidad": "010",
            "PlacaUnidad": "B2B-782",
            "CodEstado": 2,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2010,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF-1721/59",
            "AñoUnidad1": 2010,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377989U0894019",
            "SerieUnidad": "9BM384075BB733178",
            "ColoresUnidad": "MORADO,BLANCO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.82,
            "PesoSecoUnidad": 9.18,
            "LongitudUnidad": 12.33,
            "AnchoUnidad": 2.60,
            "AlturaUnidad": 3.16,
            "NumAsientos": 53,
            "NumPasajeros": 52,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "23/04/2016 00:00:00",
            "CodConcesionario": 1,
            "NomUsuario": "SCHAVEZ@SJL",
            "FechaEdicion": "10/11/2021 16:51:36",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "03/11/2011",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": ""
        },
        {
            "CodUnidad": 11,
            "PadronUnidad": "011",
            "PlacaUnidad": "A8Z-782",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2010,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF-1721/59",
            "AñoUnidad1": 2010,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377989U0899390",
            "SerieUnidad": "9BM384075BB737878",
            "ColoresUnidad": "MORADO,AMARILLO,BLANCO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.87,
            "PesoSecoUnidad": 9.13,
            "LongitudUnidad": 12.25,
            "AnchoUnidad": 2.52,
            "AlturaUnidad": 3.37,
            "NumAsientos": 49,
            "NumPasajeros": 48,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "23/04/2016 00:00:00",
            "CodConcesionario": 1,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:10:28",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "25/02/2011",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 20,
            "PadronUnidad": "012",
            "PlacaUnidad": "B2U-729",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "IVECO",
            "AñoUnidad": 2008,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "CC 170E22",
            "AñoUnidad1": 2008,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "F4AE0681D6015781",
            "SerieUnidad": "8ATA1PF009X067863",
            "ColoresUnidad": "MORADO,BLANCO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.50,
            "PesoSecoUnidad": 9.50,
            "LongitudUnidad": 12.20,
            "AnchoUnidad": 2.53,
            "AlturaUnidad": 3.18,
            "NumAsientos": 48,
            "NumPasajeros": 49,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "23/04/2016 00:00:00",
            "CodConcesionario": 1,
            "NomUsuario": "acliente@sjl",
            "FechaEdicion": "11/02/2022 12:00:29",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "09/08/2010",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 28,
            "PadronUnidad": "014",
            "PlacaUnidad": "AMR-847",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2015,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF.1721/59",
            "AñoUnidad1": 2015,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "924998U1168203",
            "SerieUnidad": "9BM384078GB027850",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.74,
            "PesoSecoUnidad": 9.26,
            "LongitudUnidad": 12.26,
            "AnchoUnidad": 2.52,
            "AlturaUnidad": 3.38,
            "NumAsientos": 49,
            "NumPasajeros": 48,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "23/04/2016 00:00:00",
            "CodConcesionario": 3,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:11:03",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "10/04/2017",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 30,
            "PadronUnidad": "015",
            "PlacaUnidad": "AMS-928",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2015,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF 1721/59",
            "AñoUnidad1": 2015,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "924998U1168397",
            "SerieUnidad": "9BM384078GB027913",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.74,
            "PesoSecoUnidad": 9.26,
            "LongitudUnidad": 12.26,
            "AnchoUnidad": 2.52,
            "AlturaUnidad": 3.38,
            "NumAsientos": 49,
            "NumPasajeros": 48,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "23/04/2016 00:00:00",
            "CodConcesionario": 3,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:11:31",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "10/04/2017",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 26,
            "PadronUnidad": "016",
            "PlacaUnidad": "AMS-749",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2015,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF-1721/59",
            "AñoUnidad1": 2015,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "924998U1168248",
            "SerieUnidad": "9BM384078GB027875",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.74,
            "PesoSecoUnidad": 9.26,
            "LongitudUnidad": 12.26,
            "AnchoUnidad": 2.52,
            "AlturaUnidad": 3.38,
            "NumAsientos": 49,
            "NumPasajeros": 48,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "23/04/2016 00:00:00",
            "CodConcesionario": 3,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:11:45",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2^0~0^0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "10/04/2017",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 29,
            "PadronUnidad": "017",
            "PlacaUnidad": "AMR-932",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2015,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF.1721/59",
            "AñoUnidad1": 2015,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "924998U1167657",
            "SerieUnidad": "9BM38478GB027405",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 1.74,
            "PesoSecoUnidad": 9.26,
            "LongitudUnidad": 12.26,
            "AnchoUnidad": 2.52,
            "AlturaUnidad": 3.38,
            "NumAsientos": 49,
            "NumPasajeros": 48,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "23/04/2016 00:00:00",
            "CodConcesionario": 3,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:12:02",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2^0~0^0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "10/04/2017",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 27,
            "PadronUnidad": "018",
            "PlacaUnidad": "AMT-805",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2015,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF-1721/59",
            "AñoUnidad1": 2015,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "924998U1167685",
            "SerieUnidad": "9BM384078GB027419",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.74,
            "PesoSecoUnidad": 9.26,
            "LongitudUnidad": 12.26,
            "AnchoUnidad": 2.52,
            "AlturaUnidad": 3.38,
            "NumAsientos": 49,
            "NumPasajeros": 48,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "23/04/2016 00:00:00",
            "CodConcesionario": 3,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:12:15",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "10/04/2017",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 31,
            "PadronUnidad": "019",
            "PlacaUnidad": "AMS-712",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2015,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF-1721/59",
            "AñoUnidad1": 2015,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "924998U1167672",
            "SerieUnidad": "9BM384078GB027413",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.74,
            "PesoSecoUnidad": 9.26,
            "LongitudUnidad": 12.26,
            "AnchoUnidad": 2.52,
            "AlturaUnidad": 3.38,
            "NumAsientos": 49,
            "NumPasajeros": 48,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "23/04/2016 00:00:00",
            "CodConcesionario": 3,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:12:32",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "10/04/2017",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 22,
            "PadronUnidad": "020",
            "PlacaUnidad": "A0C-748",
            "CodEstado": 2,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2010,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF 1721/59",
            "AñoUnidad1": 2010,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377989U0901488",
            "SerieUnidad": "9BM384075BB742140",
            "ColoresUnidad": "BLANCO,ANARANJADO,ROJO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.87,
            "PesoSecoUnidad": 9.13,
            "LongitudUnidad": 12.25,
            "AnchoUnidad": 2.52,
            "AlturaUnidad": 3.37,
            "NumAsientos": 49,
            "NumPasajeros": 48,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "23/04/2016 00:00:00",
            "CodConcesionario": 3,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:12:49",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "14/04/2011",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": ""
        },
        {
            "CodUnidad": 23,
            "PadronUnidad": "021",
            "PlacaUnidad": "B8M-724",
            "CodEstado": 2,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2011,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF 1721/59",
            "AñoUnidad1": 2011,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377989U0952119",
            "SerieUnidad": "9BM384075CB802892",
            "ColoresUnidad": "ROJO,ANARANJADO,BLANCO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.87,
            "PesoSecoUnidad": 9.13,
            "LongitudUnidad": 12.25,
            "AnchoUnidad": 2.52,
            "AlturaUnidad": 3.37,
            "NumAsientos": 49,
            "NumPasajeros": 48,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "23/04/2016 00:00:00",
            "CodConcesionario": 3,
            "NomUsuario": "SCHAVEZ@SJL",
            "FechaEdicion": "10/11/2021 16:53:50",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "06/06/2012",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": ""
        },
        {
            "CodUnidad": 24,
            "PadronUnidad": "022",
            "PlacaUnidad": "A0G-763",
            "CodEstado": 1,
            "Codigo": 7,
            "Nombre": "409",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2010,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF 1721/59",
            "AñoUnidad1": 2010,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377989U0900805",
            "SerieUnidad": "9BM384075BB741632",
            "ColoresUnidad": "BLANCO,ANARANJADO,ROJO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.87,
            "PesoSecoUnidad": 9.13,
            "LongitudUnidad": 12.25,
            "AnchoUnidad": 2.52,
            "AlturaUnidad": 3.37,
            "NumAsientos": 49,
            "NumPasajeros": 48,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "23/04/2016 00:00:00",
            "CodConcesionario": 3,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:13:18",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "18/04/2011",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 25,
            "PadronUnidad": "023",
            "PlacaUnidad": "C7I-763",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "SHUCHI",
            "AñoUnidad": 2012,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "YTK6128G1",
            "AñoUnidad1": 2012,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "1612F073149",
            "SerieUnidad": "LBZ6E2241CC001308",
            "ColoresUnidad": "AZUL,ROJO",
            "PesoBrutoUnidad": 18.00,
            "CargaUtilUnidad": 5.89,
            "PesoSecoUnidad": 12.11,
            "LongitudUnidad": 12.16,
            "AnchoUnidad": 2.55,
            "AlturaUnidad": 3.32,
            "NumAsientos": 50,
            "NumPasajeros": 49,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "23/04/2016 00:00:00",
            "CodConcesionario": 3,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:13:27",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "11/10/2012",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 21,
            "PadronUnidad": "024",
            "PlacaUnidad": "D5D-741",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "SHUCHI",
            "AñoUnidad": 2012,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "YTK6128G1",
            "AñoUnidad1": 2012,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "1612F073149",
            "SerieUnidad": "LBZ6E2241CC001308",
            "ColoresUnidad": "AZUL,ROJO",
            "PesoBrutoUnidad": 18.00,
            "CargaUtilUnidad": 5.89,
            "PesoSecoUnidad": 12.11,
            "LongitudUnidad": 12.16,
            "AnchoUnidad": 2.55,
            "AlturaUnidad": 3.32,
            "NumAsientos": 50,
            "NumPasajeros": 49,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "23/04/2016 00:00:00",
            "CodConcesionario": 3,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:13:38",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "11/10/2012",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 18,
            "PadronUnidad": "025",
            "PlacaUnidad": "D5E-703",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2011,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF-1722/59",
            "AñoUnidad1": 2011,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "924919U0991521",
            "SerieUnidad": "9BM384078CB846937",
            "ColoresUnidad": "AMARILLO,BEIGE,BLANCO,CREMA",
            "PesoBrutoUnidad": 16.00,
            "CargaUtilUnidad": 8.01,
            "PesoSecoUnidad": 8.99,
            "LongitudUnidad": 12.25,
            "AnchoUnidad": 2.52,
            "AlturaUnidad": 3.37,
            "NumAsientos": 49,
            "NumPasajeros": 48,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "23/04/2016 00:00:00",
            "CodConcesionario": 3,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:13:47",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "19/04/2013",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 33,
            "PadronUnidad": "026",
            "PlacaUnidad": "C9S-708",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2011,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF-1722/59",
            "AñoUnidad1": 2011,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "924919U0989097",
            "SerieUnidad": "9BM384078CB843967",
            "ColoresUnidad": "AMARILLO,BEIGE,CREMA,BLANCO",
            "PesoBrutoUnidad": 16.00,
            "CargaUtilUnidad": 8.01,
            "PesoSecoUnidad": 8.99,
            "LongitudUnidad": 12.25,
            "AnchoUnidad": 2.52,
            "AlturaUnidad": 3.37,
            "NumAsientos": 49,
            "NumPasajeros": 48,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "23/04/2016 00:00:00",
            "CodConcesionario": 3,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:13:55",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "05/06/2013",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 5,
            "PadronUnidad": "027",
            "PlacaUnidad": "AMI-738",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": "HUAMAN HUAMAN, EDGAR",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2015,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF 1721/59",
            "AñoUnidad1": 2015,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "924998U1153357",
            "SerieUnidad": "9BM384078GB014323",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.20,
            "PesoSecoUnidad": 9.71,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.50,
            "AlturaUnidad": 3.20,
            "NumAsientos": 49,
            "NumPasajeros": 48,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "23/04/2016 00:00:00",
            "CodConcesionario": 2,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:14:04",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "03/11/2016",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 2,
            "PadronUnidad": "028",
            "PlacaUnidad": "AMI-835",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": "GUADAÑA FUENTES, PACIFICO",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2015,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF 1721/59",
            "AñoUnidad1": 2015,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "924998U1155018",
            "SerieUnidad": "9BM384078GB015964",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.31,
            "PesoSecoUnidad": 9.69,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.50,
            "AlturaUnidad": 3.20,
            "NumAsientos": 49,
            "NumPasajeros": 48,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "23/04/2016 00:00:00",
            "CodConcesionario": 2,
            "NomUsuario": "jguerra@sjl",
            "FechaEdicion": "06/01/2021 17:04:25",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "03/11/2016",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 4,
            "PadronUnidad": "029",
            "PlacaUnidad": "AMI-871",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": "RAMOS VALDEZ, JAIME OSWALDO",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2015,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF 1721/59",
            "AñoUnidad1": 2015,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "924998U1155261",
            "SerieUnidad": "9BM384078GB016163",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.27,
            "PesoSecoUnidad": 9.73,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.50,
            "AlturaUnidad": 3.20,
            "NumAsientos": 49,
            "NumPasajeros": 48,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "23/04/2016 00:00:00",
            "CodConcesionario": 2,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:14:23",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "03/11/2016",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 7,
            "PadronUnidad": "030",
            "PlacaUnidad": "AMI-753",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": "POLO ALCANTARA, LUZ MERY",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2015,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF 1721/59",
            "AñoUnidad1": 2015,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "924998U1155140",
            "SerieUnidad": "9BM384078GB015984",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.20,
            "PesoSecoUnidad": 9.70,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.50,
            "AlturaUnidad": 3.20,
            "NumAsientos": 49,
            "NumPasajeros": 48,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "23/04/2016 00:00:00",
            "CodConcesionario": 2,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:14:36",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "03/11/2016",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 8,
            "PadronUnidad": "031",
            "PlacaUnidad": "AMI-921",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": "REYES CACHIQUE, DAVID MARCIAL",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2015,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF-1721/59",
            "AñoUnidad1": 2015,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "924998U1153356",
            "SerieUnidad": "9MB384078GB014321",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.25,
            "PesoSecoUnidad": 9.75,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.50,
            "AlturaUnidad": 3.20,
            "NumAsientos": 49,
            "NumPasajeros": 48,
            "NumEjes": 2,
            "NumRuedas": 4,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "23/04/2016 00:00:00",
            "CodConcesionario": 2,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:14:48",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 4,
            "FechaInscripcion": "16/06/2016",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 6,
            "PadronUnidad": "032",
            "PlacaUnidad": "AMI-893",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": "VILLA GUILLERMO, WILLIAM",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2015,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF 1721/59",
            "AñoUnidad1": 2015,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "924998U1155221",
            "SerieUnidad": "9BM384078GB015988",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.25,
            "PesoSecoUnidad": 9.75,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.50,
            "AlturaUnidad": 3.20,
            "NumAsientos": 49,
            "NumPasajeros": 48,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "23/04/2016 00:00:00",
            "CodConcesionario": 2,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:14:56",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "03/11/2016",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 3,
            "PadronUnidad": "033",
            "PlacaUnidad": "AMJ-851",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2015,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF 1721/59",
            "AñoUnidad1": 2015,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "924998U1155061",
            "SerieUnidad": "9BM384078GB015940",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.27,
            "PesoSecoUnidad": 9.73,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.50,
            "AlturaUnidad": 3.20,
            "NumAsientos": 49,
            "NumPasajeros": 48,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "23/04/2016 00:00:00",
            "CodConcesionario": 2,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:15:18",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "03/11/2016",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 36,
            "PadronUnidad": "034",
            "PlacaUnidad": "F1P-833",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "YUTONG",
            "AñoUnidad": 2013,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "ZK6120NG2",
            "AñoUnidad1": 2013,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "G3EYDD00113",
            "SerieUnidad": "LZYTAGD99D1004287",
            "ColoresUnidad": "ROJO,VERDE,BLANCO",
            "PesoBrutoUnidad": 16.00,
            "CargaUtilUnidad": 6.20,
            "PesoSecoUnidad": 11.80,
            "LongitudUnidad": 12.25,
            "AnchoUnidad": 2.50,
            "AlturaUnidad": 3.25,
            "NumAsientos": 51,
            "NumPasajeros": 52,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "23/04/2016 00:00:00",
            "CodConcesionario": 4,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:15:30",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "23/09/2013",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 37,
            "PadronUnidad": "035",
            "PlacaUnidad": "F1R-877",
            "CodEstado": 2,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "YUTONG",
            "AñoUnidad": 2013,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "ZK6120NG2",
            "AñoUnidad1": 2013,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "G3EYDD00108",
            "SerieUnidad": "LZYTAGD97D1004269",
            "ColoresUnidad": "ROJO,VERDE,BLANCO",
            "PesoBrutoUnidad": 18.00,
            "CargaUtilUnidad": 6.20,
            "PesoSecoUnidad": 11.80,
            "LongitudUnidad": 12.25,
            "AnchoUnidad": 2.50,
            "AlturaUnidad": 3.25,
            "NumAsientos": 52,
            "NumPasajeros": 51,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "23/04/2016 00:00:00",
            "CodConcesionario": 4,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:15:41",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "23/09/2013",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": ""
        },
        {
            "CodUnidad": 35,
            "PadronUnidad": "036",
            "PlacaUnidad": "F5G-838",
            "CodEstado": 2,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "YUTONG",
            "AñoUnidad": 2013,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "ZK6120NG2",
            "AñoUnidad1": 2013,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "G3EYDC00088",
            "SerieUnidad": "LZYTAGD99D1001566",
            "ColoresUnidad": "VERDE,ROJO,BLANCO",
            "PesoBrutoUnidad": 16.00,
            "CargaUtilUnidad": 6.25,
            "PesoSecoUnidad": 11.75,
            "LongitudUnidad": 12.25,
            "AnchoUnidad": 2.50,
            "AlturaUnidad": 3.25,
            "NumAsientos": 52,
            "NumPasajeros": 51,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "23/04/2016 00:00:00",
            "CodConcesionario": 4,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:15:51",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "13/12/2013",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": ""
        },
        {
            "CodUnidad": 39,
            "PadronUnidad": "037",
            "PlacaUnidad": "F1O-845",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "YUTONG",
            "AñoUnidad": 2013,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "ZK6120NG2",
            "AñoUnidad1": 2013,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "G3EYDD00056",
            "SerieUnidad": "LZYTAGD90D1004274",
            "ColoresUnidad": "VERDE,ROJO,BLANCO",
            "PesoBrutoUnidad": 16.00,
            "CargaUtilUnidad": 6.21,
            "PesoSecoUnidad": 11.79,
            "LongitudUnidad": 12.25,
            "AnchoUnidad": 2.50,
            "AlturaUnidad": 3.25,
            "NumAsientos": 52,
            "NumPasajeros": 51,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "23/04/2016 00:00:00",
            "CodConcesionario": 4,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:16:01",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "27/09/2013",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 42,
            "PadronUnidad": "038",
            "PlacaUnidad": "F1P-925",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "YUTONG",
            "AñoUnidad": 2013,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "ZK6120NG2",
            "AñoUnidad1": 2013,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "G3EYDD00074",
            "SerieUnidad": "LZYTAGD95D1004447",
            "ColoresUnidad": "ROJO,VERDE,BLANCO",
            "PesoBrutoUnidad": 18.00,
            "CargaUtilUnidad": 6.20,
            "PesoSecoUnidad": 11.80,
            "LongitudUnidad": 12.25,
            "AnchoUnidad": 2.50,
            "AlturaUnidad": 3.25,
            "NumAsientos": 52,
            "NumPasajeros": 51,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "23/04/2016 00:00:00",
            "CodConcesionario": 4,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:16:11",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "27/09/2013",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 38,
            "PadronUnidad": "039",
            "PlacaUnidad": "F2R-754",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "YUTONG",
            "AñoUnidad": 2013,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "ZK6120NG2",
            "AñoUnidad1": 2013,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "G3EYDD00030",
            "SerieUnidad": "LZYTAGD95D1004397",
            "ColoresUnidad": "VERDE,ROJO,BLANCO",
            "PesoBrutoUnidad": 18.00,
            "CargaUtilUnidad": 6.17,
            "PesoSecoUnidad": 11.83,
            "LongitudUnidad": 12.25,
            "AnchoUnidad": 2.50,
            "AlturaUnidad": 3.25,
            "NumAsientos": 52,
            "NumPasajeros": 51,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "23/04/2016 00:00:00",
            "CodConcesionario": 4,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:16:43",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "13/11/2013",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 41,
            "PadronUnidad": "040",
            "PlacaUnidad": "D6Y-931",
            "CodEstado": 2,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "ZHONGTONG BUS",
            "AñoUnidad": 2012,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "LCK6125GC",
            "AñoUnidad1": 2012,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "73429203",
            "SerieUnidad": "LDY6GS2U7C0001903",
            "ColoresUnidad": "AMARILLO,ROJO,AZUL,CREMA",
            "PesoBrutoUnidad": 16.00,
            "CargaUtilUnidad": 6.49,
            "PesoSecoUnidad": 11.66,
            "LongitudUnidad": 11.99,
            "AnchoUnidad": 2.54,
            "AlturaUnidad": 3.38,
            "NumAsientos": 50,
            "NumPasajeros": 49,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "23/04/2016 00:00:00",
            "CodConcesionario": 4,
            "NomUsuario": "SCHAVEZ@SJL",
            "FechaEdicion": "10/11/2021 16:54:42",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "02/04/2013",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": ""
        },
        {
            "CodUnidad": 40,
            "PadronUnidad": "041",
            "PlacaUnidad": "ANT-907",
            "CodEstado": 1,
            "Codigo": 7,
            "Nombre": "409",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "23/04/2016 00:00:00",
            "CodConcesionario": 4,
            "NomUsuario": null,
            "FechaEdicion": null,
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 43,
            "PadronUnidad": "042",
            "PlacaUnidad": "ANV-851",
            "CodEstado": 1,
            "Codigo": 7,
            "Nombre": "409",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "23/04/2016 00:00:00",
            "CodConcesionario": 4,
            "NomUsuario": null,
            "FechaEdicion": null,
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 44,
            "PadronUnidad": "043",
            "PlacaUnidad": "ANT-717",
            "CodEstado": 1,
            "Codigo": 7,
            "Nombre": "409",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "23/04/2016 00:00:00",
            "CodConcesionario": 4,
            "NomUsuario": null,
            "FechaEdicion": null,
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 45,
            "PadronUnidad": "044",
            "PlacaUnidad": "ANS-870",
            "CodEstado": 2,
            "Codigo": 7,
            "Nombre": "409",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "23/04/2016 00:00:00",
            "CodConcesionario": 4,
            "NomUsuario": "acliente@sjl",
            "FechaEdicion": "24/04/2023 10:38:48",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 53,
            "PadronUnidad": "045",
            "PlacaUnidad": "ANU-865",
            "CodEstado": 1,
            "Codigo": 6,
            "Nombre": "406",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "23/04/2016 00:00:00",
            "CodConcesionario": 4,
            "NomUsuario": null,
            "FechaEdicion": null,
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 62,
            "PadronUnidad": "046",
            "PlacaUnidad": "ANT-740",
            "CodEstado": 2,
            "Codigo": 6,
            "Nombre": "406",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "04/10/2016 14:37:00",
            "CodConcesionario": 4,
            "NomUsuario": "SCHAVEZ@SJL",
            "FechaEdicion": "28/03/2023 17:16:13",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 63,
            "PadronUnidad": "047",
            "PlacaUnidad": "ANX-820",
            "CodEstado": 2,
            "Codigo": 7,
            "Nombre": "409",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "21/01/2017 14:38:43",
            "CodConcesionario": 4,
            "NomUsuario": "SCHAVEZ@SJL",
            "FechaEdicion": "28/03/2023 17:15:36",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 64,
            "PadronUnidad": "048",
            "PlacaUnidad": "ANV-849",
            "CodEstado": 2,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "wcapillo@sjl",
            "FechaCreacion": "21/01/2017 07:11:47",
            "CodConcesionario": 4,
            "NomUsuario": "SCHAVEZ@SJL",
            "FechaEdicion": "28/03/2023 17:05:56",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 65,
            "PadronUnidad": "049",
            "PlacaUnidad": "ANW-892",
            "CodEstado": 2,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "wcapillo@sjl",
            "FechaCreacion": "21/01/2017 13:27:32",
            "CodConcesionario": 4,
            "NomUsuario": "SCHAVEZ@SJL",
            "FechaEdicion": "28/03/2023 17:04:35",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 66,
            "PadronUnidad": "050",
            "PlacaUnidad": "ANV-850",
            "CodEstado": 2,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2016,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "OF. 1721/59",
            "AñoUnidad1": 2016,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "73728105",
            "SerieUnidad": "9BM384075FB966739",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 6.80,
            "PesoSecoUnidad": 10.16,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.55,
            "AlturaUnidad": 3.32,
            "NumAsientos": 39,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "wcapillo@sjl",
            "FechaCreacion": "21/01/2017 06:57:19",
            "CodConcesionario": 4,
            "NomUsuario": "SCHAVEZ@SJL",
            "FechaEdicion": "28/03/2023 17:04:04",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "12/10/2016",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 67,
            "PadronUnidad": "051",
            "PlacaUnidad": "ANS-791",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2016,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "OF 1721/59",
            "AñoUnidad1": 2016,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "73728359",
            "SerieUnidad": "9BM384075FB976062",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 6.48,
            "PesoSecoUnidad": 10.16,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.90,
            "AlturaUnidad": 3.32,
            "NumAsientos": 39,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "wcapillo@sjl",
            "FechaCreacion": "21/01/2017 06:10:11",
            "CodConcesionario": 4,
            "NomUsuario": "mquispe@sjl",
            "FechaEdicion": "09/06/2023 13:53:37",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "04/10/2016",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 68,
            "PadronUnidad": "052",
            "PlacaUnidad": "ANZ-835",
            "CodEstado": 2,
            "Codigo": 7,
            "Nombre": "409",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2016,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "OF 1721/59",
            "AñoUnidad1": 2016,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "73728201",
            "SerieUnidad": "9BM384075FB978830",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 6.84,
            "PesoSecoUnidad": 10.16,
            "LongitudUnidad": 12.60,
            "AnchoUnidad": 2.55,
            "AlturaUnidad": 3.32,
            "NumAsientos": 39,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "wcapillo@sjl",
            "FechaCreacion": "21/01/2017 06:08:35",
            "CodConcesionario": 4,
            "NomUsuario": "SCHAVEZ@SJL",
            "FechaEdicion": "28/03/2023 17:09:18",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "29/11/2016",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 69,
            "PadronUnidad": "053",
            "PlacaUnidad": "ANW-876",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2016,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "OF 1721/59",
            "AñoUnidad1": 2016,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "73731712",
            "SerieUnidad": "9BM384075FB975840",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 6.84,
            "PesoSecoUnidad": 10.16,
            "LongitudUnidad": 12.06,
            "AnchoUnidad": 2.55,
            "AlturaUnidad": 3.32,
            "NumAsientos": 39,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "wcapillo@sjl",
            "FechaCreacion": "23/01/2017 06:41:17",
            "CodConcesionario": 4,
            "NomUsuario": "mquispe@sjl",
            "FechaEdicion": "09/06/2023 13:58:37",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "29/11/2016",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 70,
            "PadronUnidad": "054",
            "PlacaUnidad": "ANW-875",
            "CodEstado": 2,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2016,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "OF 1721/59",
            "AñoUnidad1": 2016,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "73732929",
            "SerieUnidad": "9BM384075FB978179",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 6.84,
            "PesoSecoUnidad": 10.16,
            "LongitudUnidad": 12.06,
            "AnchoUnidad": 2.55,
            "AlturaUnidad": 3.32,
            "NumAsientos": 39,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "wcapillo@sjl",
            "FechaCreacion": "21/01/2017 05:47:05",
            "CodConcesionario": 4,
            "NomUsuario": "SCHAVEZ@SJL",
            "FechaEdicion": "10/11/2021 16:56:00",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "29/11/2016",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": ""
        },
        {
            "CodUnidad": 71,
            "PadronUnidad": "055",
            "PlacaUnidad": "APA-821",
            "CodEstado": 2,
            "Codigo": 7,
            "Nombre": "409",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2016,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "OF 1721/59",
            "AñoUnidad1": 2016,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "73727972",
            "SerieUnidad": "9BM384075FB970714",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 6.84,
            "PesoSecoUnidad": 10.16,
            "LongitudUnidad": 12.06,
            "AnchoUnidad": 2.55,
            "AlturaUnidad": 3.32,
            "NumAsientos": 39,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "wcapillo@sjl",
            "FechaCreacion": "21/01/2017 06:42:58",
            "CodConcesionario": 4,
            "NomUsuario": "acliente@sjl",
            "FechaEdicion": "24/04/2023 10:39:58",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "29/11/2016",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 72,
            "PadronUnidad": "056",
            "PlacaUnidad": "A7X-781",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2010,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF. 1721/59",
            "AñoUnidad1": 2010,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377989U0892721",
            "SerieUnidad": "9BM384075BB731412",
            "ColoresUnidad": "AMARILLO,BLANCO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.69,
            "PesoSecoUnidad": 9.31,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.50,
            "AlturaUnidad": 3.20,
            "NumAsientos": 51,
            "NumPasajeros": 50,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "wcapillo@sjl",
            "FechaCreacion": "31/01/2017 05:45:18",
            "CodConcesionario": 2,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:21:05",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "06/12/2011",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 73,
            "PadronUnidad": "057",
            "PlacaUnidad": "B3I-740",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": "HUAMAN POMA, FIDEL",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2011,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF. 1721/59",
            "AñoUnidad1": 2011,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377989U0960732",
            "SerieUnidad": "9BM384075CB811895",
            "ColoresUnidad": "AMARILLO,BLANCO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.77,
            "PesoSecoUnidad": 9.23,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.50,
            "AlturaUnidad": 3.20,
            "NumAsientos": 51,
            "NumPasajeros": 50,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "wcapillo@sjl",
            "FechaCreacion": "25/01/2017 06:15:51",
            "CodConcesionario": 2,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:21:18",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "08/03/2012",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 74,
            "PadronUnidad": "058",
            "PlacaUnidad": "B3I-769",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2011,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF 1721/59",
            "AñoUnidad1": 2011,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377989U0960409",
            "SerieUnidad": "9BM384075CB811410",
            "ColoresUnidad": "AMARILLO,BLANCO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.78,
            "PesoSecoUnidad": 9.22,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.50,
            "AlturaUnidad": 3.20,
            "NumAsientos": 51,
            "NumPasajeros": 50,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "wcapillo@sjl",
            "FechaCreacion": "21/01/2017 17:59:28",
            "CodConcesionario": 2,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:21:28",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "08/03/2012",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 75,
            "PadronUnidad": "059",
            "PlacaUnidad": "B3I-780",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": "ARROYO CABALLERO, JOHN WILLIAM",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2011,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF. 1721/59",
            "AñoUnidad1": 2011,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377989U0960173",
            "SerieUnidad": "9BM384075CB811364",
            "ColoresUnidad": "AMARILLO,BLANCO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.77,
            "PesoSecoUnidad": 9.23,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.50,
            "AlturaUnidad": 3.20,
            "NumAsientos": 51,
            "NumPasajeros": 50,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "wcapillo@sjl",
            "FechaCreacion": "04/02/2017 06:54:32",
            "CodConcesionario": 2,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:21:38",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "08/03/2012",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 76,
            "PadronUnidad": "060",
            "PlacaUnidad": "B3I-790",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": "MIRAVAL SOLIS, JOSE MANUEL",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2011,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF. 1721/59",
            "AñoUnidad1": 2011,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377989U0953253",
            "SerieUnidad": "9BM384075CB803917",
            "ColoresUnidad": "AMARILLO,BLANCO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.81,
            "PesoSecoUnidad": 9.19,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.50,
            "AlturaUnidad": 3.20,
            "NumAsientos": 51,
            "NumPasajeros": 50,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "wcapillo@sjl",
            "FechaCreacion": "28/01/2017 06:39:35",
            "CodConcesionario": 2,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:21:47",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "08/03/2012",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 77,
            "PadronUnidad": "061",
            "PlacaUnidad": "B3J-722",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2011,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF. 1721/59",
            "AñoUnidad1": 2011,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377989U0961391",
            "SerieUnidad": "9BM384075CB812672",
            "ColoresUnidad": "AMARILLO,BLANCO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.77,
            "PesoSecoUnidad": 9.23,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.50,
            "AlturaUnidad": 3.20,
            "NumAsientos": 51,
            "NumPasajeros": 50,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "wcapillo@sjl",
            "FechaCreacion": "21/01/2017 06:59:34",
            "CodConcesionario": 2,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:21:57",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "08/03/2012",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 78,
            "PadronUnidad": "062",
            "PlacaUnidad": "B3J-756",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2011,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF. 1721/59",
            "AñoUnidad1": 2011,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377989U0952907",
            "SerieUnidad": "9BM384075CB803639",
            "ColoresUnidad": "AMARILLO,BLANCO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.80,
            "PesoSecoUnidad": 9.20,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.50,
            "AlturaUnidad": 3.20,
            "NumAsientos": 51,
            "NumPasajeros": 50,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "wcapillo@sjl",
            "FechaCreacion": "21/01/2017 06:26:49",
            "CodConcesionario": 2,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:22:09",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "08/03/2012",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 79,
            "PadronUnidad": "063",
            "PlacaUnidad": "C7E-792",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": "LUNA QUISPE, RONALD ELEJALDER",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2011,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF-1721/59",
            "AñoUnidad1": 2011,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377989U0953587",
            "SerieUnidad": "9BM384075CB803942",
            "ColoresUnidad": "AMARILLO,BLANCO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.76,
            "PesoSecoUnidad": 9.24,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.50,
            "AlturaUnidad": 3.20,
            "NumAsientos": 51,
            "NumPasajeros": 50,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "wcapillo@sjl",
            "FechaCreacion": "21/01/2017 05:27:38",
            "CodConcesionario": 2,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:22:33",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "26/10/2012",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 80,
            "PadronUnidad": "064",
            "PlacaUnidad": "C7F-734",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": "ROMERO ROSALES, EDYN DENISS",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2011,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF-1721/59",
            "AñoUnidad1": 2011,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377989U0954870",
            "SerieUnidad": "9BM384075CB805996",
            "ColoresUnidad": "AMARILLO,BLANCO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.76,
            "PesoSecoUnidad": 9.24,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.50,
            "AlturaUnidad": 3.20,
            "NumAsientos": 51,
            "NumPasajeros": 50,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "wcapillo@sjl",
            "FechaCreacion": "21/01/2017 05:50:08",
            "CodConcesionario": 2,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:22:46",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "25/10/2012",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 81,
            "PadronUnidad": "065",
            "PlacaUnidad": "C7G-772",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": "HUARCAYA BAUTISTA, ELIO",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2011,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF-1721/59",
            "AñoUnidad1": 2011,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377989U0952334",
            "SerieUnidad": "9BM384075CB801965",
            "ColoresUnidad": "AMARILLO,BLANCO",
            "PesoBrutoUnidad": 16.00,
            "CargaUtilUnidad": 7.78,
            "PesoSecoUnidad": 9.22,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.50,
            "AlturaUnidad": 3.20,
            "NumAsientos": 51,
            "NumPasajeros": 50,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "wcapillo@sjl",
            "FechaCreacion": "21/01/2017 07:11:57",
            "CodConcesionario": 2,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:23:06",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "25/10/2012",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 82,
            "PadronUnidad": "066",
            "PlacaUnidad": "C7K-780",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": "HUAMANI HUILLCA, JUAN CANCIO",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2011,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF-1721/59",
            "AñoUnidad1": 2011,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377989U0954852",
            "SerieUnidad": "9BM384075CB801171",
            "ColoresUnidad": "AMARILLO,BLANCO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.70,
            "PesoSecoUnidad": 9.30,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.50,
            "AlturaUnidad": 3.20,
            "NumAsientos": 51,
            "NumPasajeros": 50,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "wcapillo@sjl",
            "FechaCreacion": "21/01/2017 06:06:07",
            "CodConcesionario": 2,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:23:17",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "25/10/2012",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 85,
            "PadronUnidad": "067",
            "PlacaUnidad": "APA-820",
            "CodEstado": 1,
            "Codigo": 7,
            "Nombre": "409",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2016,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "OF 1721/59",
            "AñoUnidad1": 2016,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "73728536",
            "SerieUnidad": "9BM384075FB975217",
            "ColoresUnidad": "GRIS,MORADO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 6.48,
            "PesoSecoUnidad": 10.10,
            "LongitudUnidad": 12.06,
            "AnchoUnidad": 2.55,
            "AlturaUnidad": 3.32,
            "NumAsientos": 39,
            "NumPasajeros": 80,
            "NumEjes": 0,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "21/01/2017 09:50:59",
            "CodConcesionario": 4,
            "NomUsuario": "mquispe@sjl",
            "FechaEdicion": "09/06/2023 13:36:19",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 86,
            "PadronUnidad": "068",
            "PlacaUnidad": "ANX-851",
            "CodEstado": 1,
            "Codigo": 7,
            "Nombre": "409",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2016,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "OF 1721/59",
            "AñoUnidad1": 2016,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "73728251",
            "SerieUnidad": "9BM384075FB968919",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 6.84,
            "PesoSecoUnidad": 10.16,
            "LongitudUnidad": 12.06,
            "AnchoUnidad": 2.55,
            "AlturaUnidad": 3.32,
            "NumAsientos": 39,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "21/01/2017 10:29:39",
            "CodConcesionario": 4,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:23:26",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "29/11/2016",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 87,
            "PadronUnidad": "069",
            "PlacaUnidad": "ANT-716",
            "CodEstado": 1,
            "Codigo": 7,
            "Nombre": "409",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2016,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "OF. 1721/59",
            "AñoUnidad1": 2016,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "73728044",
            "SerieUnidad": "9BM384075FB970787",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 6.84,
            "PesoSecoUnidad": 10.16,
            "LongitudUnidad": 12.06,
            "AnchoUnidad": 2.55,
            "AlturaUnidad": 3.32,
            "NumAsientos": 39,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "21/01/2017 10:55:17",
            "CodConcesionario": 4,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:23:37",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "05/10/2016",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 88,
            "PadronUnidad": "070",
            "PlacaUnidad": "ANT-842",
            "CodEstado": 1,
            "Codigo": 7,
            "Nombre": "409",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2016,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF. 1721/59",
            "AñoUnidad1": 2016,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "73727836",
            "SerieUnidad": "9MB384075FB965427",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 6.84,
            "PesoSecoUnidad": 10.16,
            "LongitudUnidad": 12.06,
            "AnchoUnidad": 2.65,
            "AlturaUnidad": 3.32,
            "NumAsientos": 35,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "21/01/2017 12:08:54",
            "CodConcesionario": 4,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:23:52",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "05/10/2016",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 89,
            "PadronUnidad": "071",
            "PlacaUnidad": "ANV-852",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2016,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "OF. 1721/59",
            "AñoUnidad1": 2016,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "73728300",
            "SerieUnidad": "9BM384075FB966779",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 6.54,
            "PesoSecoUnidad": 10.16,
            "LongitudUnidad": 12.06,
            "AnchoUnidad": 2.55,
            "AlturaUnidad": 3.32,
            "NumAsientos": 39,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "22/01/2017 11:22:31",
            "CodConcesionario": 4,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:24:14",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "12/10/2016",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 90,
            "PadronUnidad": "072",
            "PlacaUnidad": "B8M-727",
            "CodEstado": 2,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2011,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF-1721/59",
            "AñoUnidad1": 2011,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377989U0949839",
            "SerieUnidad": "9BM384075CB800405",
            "ColoresUnidad": "ROJO,ANARANJADO,BLANCO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.67,
            "PesoSecoUnidad": 9.13,
            "LongitudUnidad": 12.25,
            "AnchoUnidad": 2.52,
            "AlturaUnidad": 3.37,
            "NumAsientos": 49,
            "NumPasajeros": 48,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "24/01/2017 17:55:16",
            "CodConcesionario": 3,
            "NomUsuario": "SCHAVEZ@SJL",
            "FechaEdicion": "10/11/2021 16:57:48",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "29/05/2012",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": ""
        },
        {
            "CodUnidad": 91,
            "PadronUnidad": "073",
            "PlacaUnidad": "A0C-754",
            "CodEstado": 2,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2010,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF 1721/59",
            "AñoUnidad1": 2010,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377989U0899740",
            "SerieUnidad": "9BM384075BB740853",
            "ColoresUnidad": "ANARANJADO,BLANCO,ROJO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.87,
            "PesoSecoUnidad": 9.13,
            "LongitudUnidad": 12.25,
            "AnchoUnidad": 2.52,
            "AlturaUnidad": 3.37,
            "NumAsientos": 49,
            "NumPasajeros": 48,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "24/01/2017 15:31:47",
            "CodConcesionario": 3,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:24:30",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "20/04/2011",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": ""
        },
        {
            "CodUnidad": 92,
            "PadronUnidad": "074",
            "PlacaUnidad": "A2B-722",
            "CodEstado": 2,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "IVECO",
            "AñoUnidad": 2009,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "CC 170E22",
            "AñoUnidad1": 2009,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "F4AE0681D6020564",
            "SerieUnidad": "SATA1PF00AX070123",
            "ColoresUnidad": "BLANCO,ANARANJADO,ROJO,NEGRO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.50,
            "PesoSecoUnidad": 9.50,
            "LongitudUnidad": 12.20,
            "AnchoUnidad": 2.55,
            "AlturaUnidad": 3.22,
            "NumAsientos": 50,
            "NumPasajeros": 49,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "01/02/2017 13:07:17",
            "CodConcesionario": 3,
            "NomUsuario": "SCHAVEZ@SJL",
            "FechaEdicion": "10/11/2021 16:59:37",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "30/04/2010",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": ""
        },
        {
            "CodUnidad": 93,
            "PadronUnidad": "075",
            "PlacaUnidad": "B8Q-746",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2011,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF 1721/59",
            "AñoUnidad1": 2011,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377989U0954133",
            "SerieUnidad": "9BM384075CB804793",
            "ColoresUnidad": "ROJO,ANARANJADO,BLANCO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.87,
            "PesoSecoUnidad": 9.13,
            "LongitudUnidad": 12.25,
            "AnchoUnidad": 2.52,
            "AlturaUnidad": 3.37,
            "NumAsientos": 49,
            "NumPasajeros": 48,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "23/01/2017 10:55:05",
            "CodConcesionario": 3,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:24:51",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "05/06/2012",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 94,
            "PadronUnidad": "076",
            "PlacaUnidad": "C4Y-770",
            "CodEstado": 2,
            "Codigo": 7,
            "Nombre": "409",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2011,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF-1721/59",
            "AñoUnidad1": 2011,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377989U0958305",
            "SerieUnidad": "9BM384075CB809156",
            "ColoresUnidad": "ANARANJADO,ROJO,BLANCO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.87,
            "PesoSecoUnidad": 9.13,
            "LongitudUnidad": 12.26,
            "AnchoUnidad": 2.52,
            "AlturaUnidad": 3.38,
            "NumAsientos": 49,
            "NumPasajeros": 48,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "24/01/2017 07:00:02",
            "CodConcesionario": 3,
            "NomUsuario": "SCHAVEZ@SJL",
            "FechaEdicion": "10/11/2021 17:00:43",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "04/10/2012",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": ""
        },
        {
            "CodUnidad": 95,
            "PadronUnidad": "077",
            "PlacaUnidad": "C4Y-786",
            "CodEstado": 2,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2011,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF. 1721/59",
            "AñoUnidad1": 2011,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377989U958458",
            "SerieUnidad": "9BM384075CB809631",
            "ColoresUnidad": "ROJO,ANARANJADO,BLANCO",
            "PesoBrutoUnidad": 16.00,
            "CargaUtilUnidad": 7.87,
            "PesoSecoUnidad": 9.13,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.52,
            "AlturaUnidad": 3.37,
            "NumAsientos": 49,
            "NumPasajeros": 48,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "23/01/2017 14:05:10",
            "CodConcesionario": 3,
            "NomUsuario": "SCHAVEZ@SJL",
            "FechaEdicion": "10/11/2021 17:27:03",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "28/09/2012",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": ""
        },
        {
            "CodUnidad": 96,
            "PadronUnidad": "078",
            "PlacaUnidad": "C4Z-793",
            "CodEstado": 2,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2011,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF. 1721/59",
            "AñoUnidad1": 2011,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377989U0958450",
            "SerieUnidad": "9BM384075CB809200",
            "ColoresUnidad": "ANARANJADO,ROJO,BLANCO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.87,
            "PesoSecoUnidad": 9.13,
            "LongitudUnidad": 12.25,
            "AnchoUnidad": 2.52,
            "AlturaUnidad": 3.37,
            "NumAsientos": 49,
            "NumPasajeros": 48,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "23/01/2017 10:09:59",
            "CodConcesionario": 3,
            "NomUsuario": "SCHAVEZ@SJL",
            "FechaEdicion": "10/11/2021 17:03:45",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "09/10/2012",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": ""
        },
        {
            "CodUnidad": 97,
            "PadronUnidad": "079",
            "PlacaUnidad": "ARE-709",
            "CodEstado": 2,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "01/02/2017 07:02:50",
            "CodConcesionario": 3,
            "NomUsuario": "SCHAVEZ@SJL",
            "FechaEdicion": "10/11/2021 17:03:55",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": ""
        },
        {
            "CodUnidad": 98,
            "PadronUnidad": "080",
            "PlacaUnidad": "ARD-881",
            "CodEstado": 2,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "01/02/2017 06:39:44",
            "CodConcesionario": 3,
            "NomUsuario": "SCHAVEZ@SJL",
            "FechaEdicion": "10/11/2021 17:26:40",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": ""
        },
        {
            "CodUnidad": 99,
            "PadronUnidad": "081",
            "PlacaUnidad": "ARD-754",
            "CodEstado": 2,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "01/02/2017 07:09:41",
            "CodConcesionario": 3,
            "NomUsuario": "SCHAVEZ@SJL",
            "FechaEdicion": "10/11/2021 17:26:20",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": ""
        },
        {
            "CodUnidad": 100,
            "PadronUnidad": "082",
            "PlacaUnidad": "ARC-790",
            "CodEstado": 2,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "01/02/2017 15:09:28",
            "CodConcesionario": 3,
            "NomUsuario": "SCHAVEZ@SJL",
            "FechaEdicion": "10/11/2021 17:25:57",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": ""
        },
        {
            "CodUnidad": 101,
            "PadronUnidad": "083",
            "PlacaUnidad": "ARD-879",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "01/02/2017 06:25:17",
            "CodConcesionario": 3,
            "NomUsuario": null,
            "FechaEdicion": null,
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 102,
            "PadronUnidad": "084",
            "PlacaUnidad": "ARD-878",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "01/02/2017 07:03:43",
            "CodConcesionario": 3,
            "NomUsuario": null,
            "FechaEdicion": null,
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 103,
            "PadronUnidad": "085",
            "PlacaUnidad": "ARB-769",
            "CodEstado": 2,
            "Codigo": 7,
            "Nombre": "409",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "01/02/2017 07:17:40",
            "CodConcesionario": 3,
            "NomUsuario": "SCHAVEZ@SJL",
            "FechaEdicion": "10/11/2021 17:25:41",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": ""
        },
        {
            "CodUnidad": 104,
            "PadronUnidad": "086",
            "PlacaUnidad": "ARE-724",
            "CodEstado": 2,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "03/02/2017 18:36:52",
            "CodConcesionario": 3,
            "NomUsuario": "SCHAVEZ@SJL",
            "FechaEdicion": "10/11/2021 17:25:24",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": ""
        },
        {
            "CodUnidad": 105,
            "PadronUnidad": "087",
            "PlacaUnidad": "ARC-717",
            "CodEstado": 2,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "03/02/2017 17:19:05",
            "CodConcesionario": 3,
            "NomUsuario": "SCHAVEZ@SJL",
            "FechaEdicion": "10/11/2021 17:25:10",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": ""
        },
        {
            "CodUnidad": 106,
            "PadronUnidad": "088",
            "PlacaUnidad": "ARE-738",
            "CodEstado": 2,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "03/02/2017 17:52:37",
            "CodConcesionario": 3,
            "NomUsuario": "SCHAVEZ@SJL",
            "FechaEdicion": "10/11/2021 17:24:45",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": ""
        },
        {
            "CodUnidad": 107,
            "PadronUnidad": "089",
            "PlacaUnidad": "ARE-739",
            "CodEstado": 2,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "07/02/2017 07:46:59",
            "CodConcesionario": 3,
            "NomUsuario": "SCHAVEZ@SJL",
            "FechaEdicion": "10/11/2021 17:24:26",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": ""
        },
        {
            "CodUnidad": 108,
            "PadronUnidad": "090",
            "PlacaUnidad": "ARE-737",
            "CodEstado": 2,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "08/02/2017 07:31:22",
            "CodConcesionario": 3,
            "NomUsuario": "SCHAVEZ@SJL",
            "FechaEdicion": "10/11/2021 17:24:04",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": ""
        },
        {
            "CodUnidad": 109,
            "PadronUnidad": "091",
            "PlacaUnidad": "ARD-880",
            "CodEstado": 2,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "03/02/2017 16:40:28",
            "CodConcesionario": 3,
            "NomUsuario": "SCHAVEZ@SJL",
            "FechaEdicion": "10/11/2021 17:23:51",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": ""
        },
        {
            "CodUnidad": 110,
            "PadronUnidad": "092",
            "PlacaUnidad": "ARD-934",
            "CodEstado": 2,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "04/02/2017 06:49:30",
            "CodConcesionario": 3,
            "NomUsuario": "SCHAVEZ@SJL",
            "FechaEdicion": "10/11/2021 17:23:33",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": ""
        },
        {
            "CodUnidad": 111,
            "PadronUnidad": "093",
            "PlacaUnidad": "ARE-740",
            "CodEstado": 2,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "07/02/2017 07:52:45",
            "CodConcesionario": 3,
            "NomUsuario": "SCHAVEZ@SJL",
            "FechaEdicion": "10/11/2021 17:23:09",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": ""
        },
        {
            "CodUnidad": 112,
            "PadronUnidad": "094",
            "PlacaUnidad": "T1W-731",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2012,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF-1722/59",
            "AñoUnidad1": 2012,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "924919U1018701",
            "SerieUnidad": "9BM384078DB874192",
            "ColoresUnidad": "ROJO,VERDE,BLANCO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 8.01,
            "PesoSecoUnidad": 8.99,
            "LongitudUnidad": 12.25,
            "AnchoUnidad": 2.52,
            "AlturaUnidad": 3.37,
            "NumAsientos": 49,
            "NumPasajeros": 48,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "04/02/2017 12:45:26",
            "CodConcesionario": 1,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:28:35",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "07/06/2013",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 114,
            "PadronUnidad": "095",
            "PlacaUnidad": "A4V-736",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2010,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF 1721/59",
            "AñoUnidad1": 2010,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377989U0882983",
            "SerieUnidad": "9BM384075BB719680",
            "ColoresUnidad": "VERDE,BLANCO,ROJO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.45,
            "PesoSecoUnidad": 9.55,
            "LongitudUnidad": 12.60,
            "AnchoUnidad": 2.50,
            "AlturaUnidad": 3.25,
            "NumAsientos": 50,
            "NumPasajeros": 49,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "17/02/2017 18:08:50",
            "CodConcesionario": 4,
            "NomUsuario": "mquispe@sjl",
            "FechaEdicion": "09/06/2023 13:34:07",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "01/12/2010",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 115,
            "PadronUnidad": "096",
            "PlacaUnidad": "A4V-739",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2010,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF-1721/59",
            "AñoUnidad1": 2010,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377989U0882579",
            "SerieUnidad": "9BM384075BB719596",
            "ColoresUnidad": "ROJO,VERDE,BLANCO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.47,
            "PesoSecoUnidad": 9.53,
            "LongitudUnidad": 12.60,
            "AnchoUnidad": 2.50,
            "AlturaUnidad": 3.25,
            "NumAsientos": 50,
            "NumPasajeros": 49,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "15/02/2017 10:47:41",
            "CodConcesionario": 4,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:28:57",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "03/11/2010",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 116,
            "PadronUnidad": "097",
            "PlacaUnidad": "C4I-725",
            "CodEstado": 2,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2008,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF. 1721/59",
            "AñoUnidad1": 2008,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377989U0804293",
            "SerieUnidad": "9BM3840759B629212",
            "ColoresUnidad": "ROJO,VERDE,BLANCO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 6.00,
            "PesoSecoUnidad": 11.00,
            "LongitudUnidad": 12.30,
            "AnchoUnidad": 2.56,
            "AlturaUnidad": 3.30,
            "NumAsientos": 50,
            "NumPasajeros": 49,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "01/03/2017 11:30:27",
            "CodConcesionario": 4,
            "NomUsuario": "SCHAVEZ@SJL",
            "FechaEdicion": "28/03/2023 17:06:33",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "11/02/2010",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 117,
            "PadronUnidad": "098",
            "PlacaUnidad": "C8O-720",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2008,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF 1721/59",
            "AñoUnidad1": 2008,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377989U0795794",
            "SerieUnidad": "9BM3840759N618826",
            "ColoresUnidad": "ROJO,VERDE,BLANCO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.80,
            "PesoSecoUnidad": 9.20,
            "LongitudUnidad": 12.20,
            "AnchoUnidad": 2.53,
            "AlturaUnidad": 3.18,
            "NumAsientos": 49,
            "NumPasajeros": 48,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "01/03/2017 10:30:07",
            "CodConcesionario": 4,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:29:20",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "03/02/2009",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 118,
            "PadronUnidad": "099",
            "PlacaUnidad": "C8O-721",
            "CodEstado": 1,
            "Codigo": 7,
            "Nombre": "409",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2008,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF 1721/59",
            "AñoUnidad1": 2008,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377989UO797364",
            "SerieUnidad": "9BM3840759B620947",
            "ColoresUnidad": "ROJO,VERDE,BLANCO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.74,
            "PesoSecoUnidad": 9.26,
            "LongitudUnidad": 12.20,
            "AnchoUnidad": 2.53,
            "AlturaUnidad": 3.18,
            "NumAsientos": 49,
            "NumPasajeros": 48,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "27/02/2017 17:53:55",
            "CodConcesionario": 4,
            "NomUsuario": "mquispe@sjl",
            "FechaEdicion": "09/06/2023 13:47:51",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "03/02/2009",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 119,
            "PadronUnidad": "100",
            "PlacaUnidad": "D0B-910",
            "CodEstado": 2,
            "Codigo": 7,
            "Nombre": "409",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2009,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF 1721/59",
            "AñoUnidad1": 2009,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377989U0846531",
            "SerieUnidad": "9BM384075AB677798",
            "ColoresUnidad": "ROJO,VERDE,BLANCO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.48,
            "PesoSecoUnidad": 9.52,
            "LongitudUnidad": 12.60,
            "AnchoUnidad": 2.50,
            "AlturaUnidad": 3.25,
            "NumAsientos": 50,
            "NumPasajeros": 49,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "15/02/2017 08:13:26",
            "CodConcesionario": 4,
            "NomUsuario": "acliente@sjl",
            "FechaEdicion": "24/04/2023 10:39:47",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "26/11/2010",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 368,
            "PadronUnidad": "1000",
            "PlacaUnidad": "NIL-SON",
            "CodEstado": 2,
            "Codigo": 8,
            "Nombre": "SE02",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": "",
            "CodPersonaPropietario": null,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "26/08/2019 10:35:26",
            "CodConcesionario": 1,
            "NomUsuario": null,
            "FechaEdicion": null,
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": ""
        },
        {
            "CodUnidad": 113,
            "PadronUnidad": "1001",
            "PlacaUnidad": "PRB-001",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": "PAZ VALLE, JORGE",
            "CodPersonaPropietario": 3,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "01/01/2017 00:00:00",
            "CodConcesionario": 4,
            "NomUsuario": "RFLORES@SJL",
            "FechaEdicion": "22/02/2021 12:09:40",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 120,
            "PadronUnidad": "101",
            "PlacaUnidad": "D0B-911",
            "CodEstado": 2,
            "Codigo": 7,
            "Nombre": "409",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2009,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF 1721/59",
            "AñoUnidad1": 2009,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377989U0846510",
            "SerieUnidad": "9BM384075AB677781",
            "ColoresUnidad": "ROJO,VERDE,BLANCO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.46,
            "PesoSecoUnidad": 9.54,
            "LongitudUnidad": 12.60,
            "AnchoUnidad": 2.50,
            "AlturaUnidad": 3.25,
            "NumAsientos": 50,
            "NumPasajeros": 49,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "15/02/2017 06:04:48",
            "CodConcesionario": 4,
            "NomUsuario": "acliente@sjl",
            "FechaEdicion": "24/04/2023 10:39:36",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "06/02/2012",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 121,
            "PadronUnidad": "102",
            "PlacaUnidad": "D0B-912",
            "CodEstado": 1,
            "Codigo": 7,
            "Nombre": "409",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2009,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF 1721/59",
            "AñoUnidad1": 2009,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377989U0846469",
            "SerieUnidad": "9BM384075AB677500",
            "ColoresUnidad": "ROJO,VERDE,BLANCO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.46,
            "PesoSecoUnidad": 9.54,
            "LongitudUnidad": 12.60,
            "AnchoUnidad": 2.50,
            "AlturaUnidad": 3.25,
            "NumAsientos": 50,
            "NumPasajeros": 49,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "15/02/2017 07:26:15",
            "CodConcesionario": 4,
            "NomUsuario": "mquispe@sjl",
            "FechaEdicion": "09/06/2023 13:57:20",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "06/02/2012",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 122,
            "PadronUnidad": "103",
            "PlacaUnidad": "C8R-792",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2008,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF-1721/59",
            "AñoUnidad1": 2008,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377989U0802543",
            "SerieUnidad": "9BM3840759B627050",
            "ColoresUnidad": "ROJO,VERDE,BLANCO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 6.00,
            "PesoSecoUnidad": 11.00,
            "LongitudUnidad": 12.30,
            "AnchoUnidad": 2.56,
            "AlturaUnidad": 3.30,
            "NumAsientos": 50,
            "NumPasajeros": 49,
            "NumEjes": 0,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "08/03/2017 07:06:58",
            "CodConcesionario": 4,
            "NomUsuario": "mquispe@sjl",
            "FechaEdicion": "09/06/2023 13:51:23",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 123,
            "PadronUnidad": "104",
            "PlacaUnidad": "A4V-787",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2010,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF-1721/59",
            "AñoUnidad1": 2010,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377989U0882461",
            "SerieUnidad": "9BM384075BB719317",
            "ColoresUnidad": "ROJO,VERDE,BLANCO",
            "PesoBrutoUnidad": 16.00,
            "CargaUtilUnidad": 7.48,
            "PesoSecoUnidad": 9.52,
            "LongitudUnidad": 12.60,
            "AnchoUnidad": 2.50,
            "AlturaUnidad": 3.25,
            "NumAsientos": 50,
            "NumPasajeros": 49,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "15/02/2017 07:17:15",
            "CodConcesionario": 4,
            "NomUsuario": "mquispe@sjl",
            "FechaEdicion": "09/06/2023 13:45:50",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "18/02/2013",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 124,
            "PadronUnidad": "105",
            "PlacaUnidad": "T1W-741",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2012,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF-1722/59",
            "AñoUnidad1": 2012,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "924919U1019876",
            "SerieUnidad": "9BM384078DB875271",
            "ColoresUnidad": "ROJO,VERDE,BLANCO",
            "PesoBrutoUnidad": 16.00,
            "CargaUtilUnidad": 8.01,
            "PesoSecoUnidad": 8.99,
            "LongitudUnidad": 12.25,
            "AnchoUnidad": 2.52,
            "AlturaUnidad": 3.37,
            "NumAsientos": 49,
            "NumPasajeros": 48,
            "NumEjes": 0,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "14/02/2017 13:16:55",
            "CodConcesionario": 1,
            "NomUsuario": null,
            "FechaEdicion": null,
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 125,
            "PadronUnidad": "106",
            "PlacaUnidad": "D6Y-843",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "ZHONGTONG BUS",
            "AñoUnidad": 2012,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "LCK6125GC",
            "AñoUnidad1": 2012,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "73429196",
            "SerieUnidad": "LDY6GS2U3C0001901",
            "ColoresUnidad": "AZUL,AMARILLO,ROJO,CREMA",
            "PesoBrutoUnidad": 16.00,
            "CargaUtilUnidad": 6.49,
            "PesoSecoUnidad": 11.66,
            "LongitudUnidad": 11.99,
            "AnchoUnidad": 2.54,
            "AlturaUnidad": 3.38,
            "NumAsientos": 50,
            "NumPasajeros": 49,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "02/03/2017 08:04:45",
            "CodConcesionario": 4,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:30:55",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "14/05/2013",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 126,
            "PadronUnidad": "107",
            "PlacaUnidad": "D6Y-754",
            "CodEstado": 2,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "ZHONGTONG BUS",
            "AñoUnidad": 2012,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "LCK6125GC",
            "AñoUnidad1": 2012,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "73429175",
            "SerieUnidad": "LDY6GS2U9C0001904",
            "ColoresUnidad": "AMARILLO,ROJO,AZUL,CREMA",
            "PesoBrutoUnidad": 18.16,
            "CargaUtilUnidad": 6.49,
            "PesoSecoUnidad": 11.66,
            "LongitudUnidad": 11.99,
            "AnchoUnidad": 2.54,
            "AlturaUnidad": 3.38,
            "NumAsientos": 50,
            "NumPasajeros": 49,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "15/03/2017 16:52:32",
            "CodConcesionario": 4,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:31:05",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "01/04/2013",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": ""
        },
        {
            "CodUnidad": 127,
            "PadronUnidad": "108",
            "PlacaUnidad": "D6Y-780",
            "CodEstado": 2,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "ZHONGTONG BUS",
            "AñoUnidad": 2012,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "LCK6125GC",
            "AñoUnidad1": 2012,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "73429292",
            "SerieUnidad": "LDY6GS2U5C0001902",
            "ColoresUnidad": "AMARILLO,ROJO,AZUL,CREMA",
            "PesoBrutoUnidad": 16.00,
            "CargaUtilUnidad": 6.49,
            "PesoSecoUnidad": 11.66,
            "LongitudUnidad": 11.99,
            "AnchoUnidad": 2.54,
            "AlturaUnidad": 3.38,
            "NumAsientos": 50,
            "NumPasajeros": 49,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "17/03/2017 06:59:01",
            "CodConcesionario": 4,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:31:14",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "01/04/2013",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": ""
        },
        {
            "CodUnidad": 128,
            "PadronUnidad": "109",
            "PlacaUnidad": "D6Y-927",
            "CodEstado": 2,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "ZHONGTONG BUS",
            "AñoUnidad": 2012,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "LCK6125GC",
            "AñoUnidad1": 2012,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "73425310",
            "SerieUnidad": "LDY6GS2U0C0001905",
            "ColoresUnidad": "AMARILLO,ROJO,AZUL,CREMA",
            "PesoBrutoUnidad": 16.00,
            "CargaUtilUnidad": 6.49,
            "PesoSecoUnidad": 11.66,
            "LongitudUnidad": 11.99,
            "AnchoUnidad": 2.54,
            "AlturaUnidad": 3.38,
            "NumAsientos": 50,
            "NumPasajeros": 49,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "28/02/2017 10:29:03",
            "CodConcesionario": 4,
            "NomUsuario": "SCHAVEZ@SJL",
            "FechaEdicion": "28/03/2023 17:06:13",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "01/04/2013",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 129,
            "PadronUnidad": "110",
            "PlacaUnidad": "B3F-785",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "HYUNDAI",
            "AñoUnidad": 2010,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "AERO CITY",
            "AñoUnidad1": 2010,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "C6ACA186365",
            "SerieUnidad": "KMJTA18GPAC900924",
            "ColoresUnidad": "MORADO,BLANCO",
            "PesoBrutoUnidad": 16.00,
            "CargaUtilUnidad": 5.02,
            "PesoSecoUnidad": 10.98,
            "LongitudUnidad": 11.65,
            "AnchoUnidad": 2.54,
            "AlturaUnidad": 3.20,
            "NumAsientos": 46,
            "NumPasajeros": 45,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "18/02/2017 12:12:21",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "14/12/2020 08:32:28",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "02/02/2012",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 130,
            "PadronUnidad": "111",
            "PlacaUnidad": "B3G-705",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "HYUNDAI",
            "AñoUnidad": 2010,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "AERO CITY",
            "AñoUnidad1": 2010,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "C6ACA186447",
            "SerieUnidad": "KMJTA18GPAC900923",
            "ColoresUnidad": "CRIS METALICO MORADO",
            "PesoBrutoUnidad": 16.00,
            "CargaUtilUnidad": 4.95,
            "PesoSecoUnidad": 11.05,
            "LongitudUnidad": 11.65,
            "AnchoUnidad": 2.54,
            "AlturaUnidad": 3.20,
            "NumAsientos": 46,
            "NumPasajeros": 45,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "06/04/2017 13:49:18",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "14/12/2020 08:32:50",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "02/02/2012",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 131,
            "PadronUnidad": "112",
            "PlacaUnidad": "C1C-798",
            "CodEstado": 2,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2009,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF.1721/59",
            "AñoUnidad1": 2009,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377989U0844863",
            "SerieUnidad": "9BM384075AB675657",
            "ColoresUnidad": "ROJO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.60,
            "PesoSecoUnidad": 9.30,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.50,
            "AlturaUnidad": 3.20,
            "NumAsientos": 51,
            "NumPasajeros": 50,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "27/03/2017 14:33:00",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "14/12/2020 08:33:03",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "21/12/2009",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": ""
        },
        {
            "CodUnidad": 132,
            "PadronUnidad": "113",
            "PlacaUnidad": "C8L-725",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLSKWAGEN",
            "AñoUnidad": 2012,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "17.210 OD",
            "AñoUnidad1": 2012,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "73393690",
            "SerieUnidad": "9532X82W2DR251426",
            "ColoresUnidad": "CRIS METÁLICO MORADO",
            "PesoBrutoUnidad": 16.00,
            "CargaUtilUnidad": 6.64,
            "PesoSecoUnidad": 10.16,
            "LongitudUnidad": 12.02,
            "AnchoUnidad": 2.60,
            "AlturaUnidad": 3.40,
            "NumAsientos": 49,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "17/04/2017 11:36:55",
            "CodConcesionario": 1,
            "NomUsuario": "JefePatio@Cefesa",
            "FechaEdicion": "10/09/2020 18:51:25",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "19/10/2012",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 133,
            "PadronUnidad": "114",
            "PlacaUnidad": "B3G-763",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "HYUNDAI",
            "AñoUnidad": 2010,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "AERO CITY",
            "AñoUnidad1": 2010,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "C6ACA187701",
            "SerieUnidad": "KMJTA18GPBC900946",
            "ColoresUnidad": "MORADO,BLANCO",
            "PesoBrutoUnidad": 16.00,
            "CargaUtilUnidad": 5.02,
            "PesoSecoUnidad": 10.98,
            "LongitudUnidad": 11.65,
            "AnchoUnidad": 2.54,
            "AlturaUnidad": 3.20,
            "NumAsientos": 46,
            "NumPasajeros": 45,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "12/04/2017 00:48:54",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "14/12/2020 08:33:21",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "02/02/2012",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 134,
            "PadronUnidad": "115",
            "PlacaUnidad": "T1W-738",
            "CodEstado": 2,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2012,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF-1722/59",
            "AñoUnidad1": 2012,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "924919U1022552",
            "SerieUnidad": "9BM384078DB877916",
            "ColoresUnidad": "ROJO,VERDE,BLANCO",
            "PesoBrutoUnidad": 16.00,
            "CargaUtilUnidad": 8.01,
            "PesoSecoUnidad": 8.99,
            "LongitudUnidad": 12.26,
            "AnchoUnidad": 2.52,
            "AlturaUnidad": 3.38,
            "NumAsientos": 49,
            "NumPasajeros": 48,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "12/04/2017 06:39:53",
            "CodConcesionario": 1,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:32:36",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "14/06/2013",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": ""
        },
        {
            "CodUnidad": 135,
            "PadronUnidad": "116",
            "PlacaUnidad": "T1W-754",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2012,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF-1722/59",
            "AñoUnidad1": 2012,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "924919U1021929",
            "SerieUnidad": "9BM384078DB877116",
            "ColoresUnidad": "ROJO,VERDE,BLANCO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 8.01,
            "PesoSecoUnidad": 8.99,
            "LongitudUnidad": 12.25,
            "AnchoUnidad": 2.52,
            "AlturaUnidad": 3.37,
            "NumAsientos": 49,
            "NumPasajeros": 48,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "12/04/2017 07:43:43",
            "CodConcesionario": 1,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:32:51",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "02/07/2013",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 136,
            "PadronUnidad": "117",
            "PlacaUnidad": "T1W-743",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2012,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF-1722/59",
            "AñoUnidad1": 2012,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "924919U1020773",
            "SerieUnidad": "9BM384078DB875983",
            "ColoresUnidad": "ROJO,VERDE,BLANCO",
            "PesoBrutoUnidad": 16.00,
            "CargaUtilUnidad": 8.01,
            "PesoSecoUnidad": 8.99,
            "LongitudUnidad": 12.26,
            "AnchoUnidad": 2.52,
            "AlturaUnidad": 3.38,
            "NumAsientos": 49,
            "NumPasajeros": 48,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "12/04/2017 06:44:12",
            "CodConcesionario": 1,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:33:11",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "02/07/2013",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 137,
            "PadronUnidad": "118",
            "PlacaUnidad": "T1W-739",
            "CodEstado": 2,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2012,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF-1722/59",
            "AñoUnidad1": 2012,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "924919U1022059",
            "SerieUnidad": "9BM384078DB877306",
            "ColoresUnidad": "ROJO,VERDE,BLANCO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 8.01,
            "PesoSecoUnidad": 8.99,
            "LongitudUnidad": 12.25,
            "AnchoUnidad": 2.52,
            "AlturaUnidad": 3.37,
            "NumAsientos": 49,
            "NumPasajeros": 48,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "12/04/2017 07:17:22",
            "CodConcesionario": 1,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:33:29",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "18/06/2013",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": ""
        },
        {
            "CodUnidad": 138,
            "PadronUnidad": "119",
            "PlacaUnidad": "ARY-846",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": "MELGAR LEIVA, MILTON PABLO",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLSKWAGEN",
            "AñoUnidad": 2017,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "17.210OD",
            "AñoUnidad1": 2017,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "1267929",
            "SerieUnidad": "9532X82W3HR703566",
            "ColoresUnidad": "GRIS METALICO MOTADO",
            "PesoBrutoUnidad": 16000.00,
            "CargaUtilUnidad": 5340.00,
            "PesoSecoUnidad": 10160.00,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.55,
            "AlturaUnidad": 3.32,
            "NumAsientos": 39,
            "NumPasajeros": 80,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "17/04/2017 06:59:47",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "14/12/2020 08:39:55",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "11/06/2018",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 139,
            "PadronUnidad": "120",
            "PlacaUnidad": "ARY-847",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": "VASQUEZ VASQUEZ, ALEX OMAR",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLSKWAGEN",
            "AñoUnidad": 2017,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "17.210OD",
            "AñoUnidad1": 2017,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "1267930",
            "SerieUnidad": "9532X82W1HR703601",
            "ColoresUnidad": "GRIS METALICO MOTADO",
            "PesoBrutoUnidad": 16000.00,
            "CargaUtilUnidad": 5840.00,
            "PesoSecoUnidad": 10160.00,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.55,
            "AlturaUnidad": 3.32,
            "NumAsientos": 39,
            "NumPasajeros": 80,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "17/04/2017 07:34:32",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "14/12/2020 08:42:53",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 3,
            "FechaInscripcion": "11/06/2018",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 140,
            "PadronUnidad": "121",
            "PlacaUnidad": "ARZ-823",
            "CodEstado": 1,
            "Codigo": 7,
            "Nombre": "409",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLSKWAGEN",
            "AñoUnidad": 2017,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "17.210OD",
            "AñoUnidad1": 2017,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "1268327",
            "SerieUnidad": "9532X82W8HR705863",
            "ColoresUnidad": "GRIS METALICO MOTADO",
            "PesoBrutoUnidad": 16000.00,
            "CargaUtilUnidad": 5840.00,
            "PesoSecoUnidad": 10160.00,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.55,
            "AlturaUnidad": 3.32,
            "NumAsientos": 39,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "17/04/2017 06:21:24",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "14/12/2020 08:45:08",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 3,
            "FechaInscripcion": "11/06/2018",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 141,
            "PadronUnidad": "122",
            "PlacaUnidad": "ARY-843",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": "IPARRAGUIRRE GOMEZ, CESAR JUAN",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLSKWAGEN",
            "AñoUnidad": 2017,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "17.210OD",
            "AñoUnidad1": 2017,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "1267942",
            "SerieUnidad": "9532X82W6HR703609",
            "ColoresUnidad": "GRIS METALICO MOTADO",
            "PesoBrutoUnidad": 16000.00,
            "CargaUtilUnidad": 5840.00,
            "PesoSecoUnidad": 10160.00,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.55,
            "AlturaUnidad": 3.32,
            "NumAsientos": 39,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "17/04/2017 05:07:04",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "14/12/2020 08:51:23",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 3,
            "FechaInscripcion": "11/06/2018",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 142,
            "PadronUnidad": "123",
            "PlacaUnidad": "ARY-844",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": "ROSALES FLORES, NELINO VICTOR",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLSKWAGEN",
            "AñoUnidad": 2017,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "17.210OD",
            "AñoUnidad1": 2017,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "1268328",
            "SerieUnidad": "9532X82W0HR706358",
            "ColoresUnidad": "GRIS METALICO MOTADO",
            "PesoBrutoUnidad": 16000.00,
            "CargaUtilUnidad": 5840.00,
            "PesoSecoUnidad": 10160.00,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.55,
            "AlturaUnidad": 3.32,
            "NumAsientos": 39,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "17/04/2017 08:14:13",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "14/12/2020 08:53:53",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2^0~0^0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 3,
            "FechaInscripcion": "11/06/2018",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 143,
            "PadronUnidad": "124",
            "PlacaUnidad": "ARY-839",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLSKWAGEN",
            "AñoUnidad": 2017,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "17.210OD",
            "AñoUnidad1": 2017,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "1267581",
            "SerieUnidad": "9532X82W0HR706425",
            "ColoresUnidad": "GRIS METALICO MOTADO",
            "PesoBrutoUnidad": 16000.00,
            "CargaUtilUnidad": 5840.00,
            "PesoSecoUnidad": 10160.00,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.55,
            "AlturaUnidad": 3.32,
            "NumAsientos": 39,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "11/04/2017 21:39:52",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "14/12/2020 08:55:17",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 3,
            "FechaInscripcion": "11/06/2018",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 144,
            "PadronUnidad": "125",
            "PlacaUnidad": "ARZ-758",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLSKWAGEN",
            "AñoUnidad": 2017,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "17.210OD",
            "AñoUnidad1": 2017,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "1267941",
            "SerieUnidad": "9532X82W4HR703768",
            "ColoresUnidad": "GRIS METALICO MOTADO",
            "PesoBrutoUnidad": 16000.00,
            "CargaUtilUnidad": 5840.00,
            "PesoSecoUnidad": 10140.00,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.55,
            "AlturaUnidad": 3.32,
            "NumAsientos": 39,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "17/04/2017 07:13:46",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "14/12/2020 08:56:44",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 3,
            "FechaInscripcion": "11/06/2018",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 145,
            "PadronUnidad": "126",
            "PlacaUnidad": "ARZ-757",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": "AZALDE GONZALES, JUAN CARLOS",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLSKWAGEN",
            "AñoUnidad": 2017,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "17.210OD",
            "AñoUnidad1": 2017,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "1267585",
            "SerieUnidad": "9532X82W7HR704834",
            "ColoresUnidad": "GRIS METALICO MOTADO",
            "PesoBrutoUnidad": 1600.00,
            "CargaUtilUnidad": 5840.00,
            "PesoSecoUnidad": 10160.00,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.55,
            "AlturaUnidad": 3.32,
            "NumAsientos": 39,
            "NumPasajeros": 80,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "17/04/2017 07:13:36",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "14/12/2020 08:57:35",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 3,
            "FechaInscripcion": "11/06/2018",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 146,
            "PadronUnidad": "127",
            "PlacaUnidad": "B3F-721",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "HYUNDAI",
            "AñoUnidad": 2010,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "AERO CITY",
            "AñoUnidad1": 2010,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "C6ACA186331",
            "SerieUnidad": "KMJTA18GPAC900937",
            "ColoresUnidad": "MORADO,BLANCO",
            "PesoBrutoUnidad": 16.00,
            "CargaUtilUnidad": 5.01,
            "PesoSecoUnidad": 10.99,
            "LongitudUnidad": 11.65,
            "AnchoUnidad": 2.54,
            "AlturaUnidad": 3.20,
            "NumAsientos": 46,
            "NumPasajeros": 45,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "10/04/2017 16:44:12",
            "CodConcesionario": 1,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:34:41",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "02/02/2012",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 147,
            "PadronUnidad": "128",
            "PlacaUnidad": "ARZ-762",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLSKWAGEN",
            "AñoUnidad": 2017,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "17.210OD",
            "AñoUnidad1": 2017,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "1267584",
            "SerieUnidad": "9532X82W0HR704237",
            "ColoresUnidad": "GRIS METALICO MOTADO",
            "PesoBrutoUnidad": 16000.00,
            "CargaUtilUnidad": 5840.00,
            "PesoSecoUnidad": 10160.00,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.55,
            "AlturaUnidad": 3.32,
            "NumAsientos": 39,
            "NumPasajeros": 80,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "17/04/2017 05:56:07",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "14/12/2020 09:02:09",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 3,
            "FechaInscripcion": "11/06/2018",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 148,
            "PadronUnidad": "129",
            "PlacaUnidad": "ARY-845",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLSKWAGEN",
            "AñoUnidad": 2017,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "17.210OD",
            "AñoUnidad1": 2017,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "1267583",
            "SerieUnidad": "9532X82W7HR705921",
            "ColoresUnidad": "GRIS METALICO MOTADO",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "17/04/2017 05:03:09",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "11/12/2020 12:27:58",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "11/06/2018",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 149,
            "PadronUnidad": "130",
            "PlacaUnidad": "ARY-841",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLSKWAGEN",
            "AñoUnidad": 2017,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "17.210OD",
            "AñoUnidad1": 2017,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "1267928",
            "SerieUnidad": "9532X82W4HR704760",
            "ColoresUnidad": "GRIS METALICO MOTADO",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "17/04/2017 06:22:33",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "11/12/2020 12:30:07",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 150,
            "PadronUnidad": "131",
            "PlacaUnidad": "ARZ-756",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLSKWAGEN",
            "AñoUnidad": 2017,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "17.210OD",
            "AñoUnidad1": 2017,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "1267927",
            "SerieUnidad": "9532X82W7HR704803",
            "ColoresUnidad": "GRIS METALICO MOTADO",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "17/04/2017 12:12:04",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "11/12/2020 12:33:37",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "11/06/2018",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 151,
            "PadronUnidad": "132",
            "PlacaUnidad": "C9G-747",
            "CodEstado": 1,
            "Codigo": 7,
            "Nombre": "409",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLKSWAGEN",
            "AñoUnidad": 2012,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "17.210 OD",
            "AñoUnidad1": 2012,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "73390266",
            "SerieUnidad": "9532X82W8DR251446",
            "ColoresUnidad": "BLANCO,MORADO",
            "PesoBrutoUnidad": 16.00,
            "CargaUtilUnidad": 6.60,
            "PesoSecoUnidad": 10.10,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.60,
            "AlturaUnidad": 3.40,
            "NumAsientos": 49,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "17/04/2017 05:32:59",
            "CodConcesionario": 1,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:35:19",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "14/12/2012",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 152,
            "PadronUnidad": "133",
            "PlacaUnidad": "ARR-833",
            "CodEstado": 2,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "15/04/2017 07:00:13",
            "CodConcesionario": 3,
            "NomUsuario": "SCHAVEZ@SJL",
            "FechaEdicion": "10/11/2021 17:22:50",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": ""
        },
        {
            "CodUnidad": 153,
            "PadronUnidad": "134",
            "PlacaUnidad": "ARW-781",
            "CodEstado": 2,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "12/04/2017 17:50:31",
            "CodConcesionario": 3,
            "NomUsuario": "SCHAVEZ@SJL",
            "FechaEdicion": "10/11/2021 17:22:35",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": ""
        },
        {
            "CodUnidad": 154,
            "PadronUnidad": "135",
            "PlacaUnidad": "ARR-835",
            "CodEstado": 2,
            "Codigo": 7,
            "Nombre": "409",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "12/04/2017 18:15:16",
            "CodConcesionario": 3,
            "NomUsuario": "SCHAVEZ@SJL",
            "FechaEdicion": "10/11/2021 17:22:21",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": ""
        },
        {
            "CodUnidad": 155,
            "PadronUnidad": "136",
            "PlacaUnidad": "ARR-836",
            "CodEstado": 2,
            "Codigo": 7,
            "Nombre": "409",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "16/04/2017 14:24:36",
            "CodConcesionario": 3,
            "NomUsuario": "SCHAVEZ@SJL",
            "FechaEdicion": "10/11/2021 17:22:10",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": ""
        },
        {
            "CodUnidad": 156,
            "PadronUnidad": "137",
            "PlacaUnidad": "ARR-808",
            "CodEstado": 2,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "17/04/2017 08:27:49",
            "CodConcesionario": 3,
            "NomUsuario": "SCHAVEZ@SJL",
            "FechaEdicion": "10/11/2021 17:21:57",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": ""
        },
        {
            "CodUnidad": 157,
            "PadronUnidad": "138",
            "PlacaUnidad": "ARS-804",
            "CodEstado": 2,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "12/04/2017 19:56:58",
            "CodConcesionario": 3,
            "NomUsuario": "SCHAVEZ@SJL",
            "FechaEdicion": "10/11/2021 17:21:43",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": ""
        },
        {
            "CodUnidad": 158,
            "PadronUnidad": "139",
            "PlacaUnidad": "ARR-834",
            "CodEstado": 2,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "17/04/2017 08:54:13",
            "CodConcesionario": 3,
            "NomUsuario": "SCHAVEZ@SJL",
            "FechaEdicion": "10/11/2021 17:21:25",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": ""
        },
        {
            "CodUnidad": 159,
            "PadronUnidad": "140",
            "PlacaUnidad": "ARR-832",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "17/04/2017 06:58:07",
            "CodConcesionario": 3,
            "NomUsuario": null,
            "FechaEdicion": null,
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 160,
            "PadronUnidad": "141",
            "PlacaUnidad": "ARR-772",
            "CodEstado": 2,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "14/04/2017 09:46:17",
            "CodConcesionario": 3,
            "NomUsuario": "SCHAVEZ@SJL",
            "FechaEdicion": "10/11/2021 17:21:08",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": ""
        },
        {
            "CodUnidad": 161,
            "PadronUnidad": "142",
            "PlacaUnidad": "ARR-778",
            "CodEstado": 2,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "17/04/2017 07:13:06",
            "CodConcesionario": 3,
            "NomUsuario": "SCHAVEZ@SJL",
            "FechaEdicion": "10/11/2021 17:20:53",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": ""
        },
        {
            "CodUnidad": 162,
            "PadronUnidad": "143",
            "PlacaUnidad": "D1N-735",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2011,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF-1722/59",
            "AñoUnidad1": 2011,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "924919U0980899",
            "SerieUnidad": "9BM384078CB834508",
            "ColoresUnidad": "AMARILLO,BLANCO",
            "PesoBrutoUnidad": 16.00,
            "CargaUtilUnidad": 8.00,
            "PesoSecoUnidad": 8.90,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.50,
            "AlturaUnidad": 3.37,
            "NumAsientos": 49,
            "NumPasajeros": 48,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "23/05/2017 06:57:00",
            "CodConcesionario": 2,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:36:46",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "12/12/2012",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 163,
            "PadronUnidad": "144",
            "PlacaUnidad": "D1P-724",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": "Tello Leon, Wulber",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2011,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF-1722/59",
            "AñoUnidad1": 2011,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "924919U0978692",
            "SerieUnidad": "9BM384078CB832122",
            "ColoresUnidad": "AMARILLO,BLANCO",
            "PesoBrutoUnidad": 16.00,
            "CargaUtilUnidad": 8.00,
            "PesoSecoUnidad": 8.90,
            "LongitudUnidad": 12.25,
            "AnchoUnidad": 2.52,
            "AlturaUnidad": 3.37,
            "NumAsientos": 49,
            "NumPasajeros": 48,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "23/05/2017 07:01:22",
            "CodConcesionario": 2,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:37:04",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "12/12/2012",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 164,
            "PadronUnidad": "145",
            "PlacaUnidad": "T1W-729",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2012,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF-1722/59",
            "AñoUnidad1": 2012,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "924919U1022653",
            "SerieUnidad": "9BM384078DB877968",
            "ColoresUnidad": "ROJO,VERDE,BLANCO",
            "PesoBrutoUnidad": 16.00,
            "CargaUtilUnidad": 8.01,
            "PesoSecoUnidad": 8.99,
            "LongitudUnidad": 12.25,
            "AnchoUnidad": 2.52,
            "AlturaUnidad": 3.37,
            "NumAsientos": 49,
            "NumPasajeros": 48,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "02/06/2017 16:08:57",
            "CodConcesionario": 1,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:37:12",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "02/07/2013",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 165,
            "PadronUnidad": "146",
            "PlacaUnidad": "B0E-777",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": "SANTOS ANTONIO, CLAUDIO DOMINGUEZ",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2008,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF. 1721/59",
            "AñoUnidad1": 2008,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377989U0805403",
            "SerieUnidad": "9BM38407859B630535",
            "ColoresUnidad": "AMARILLO,BLANCO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.97,
            "PesoSecoUnidad": 9.03,
            "LongitudUnidad": 12.20,
            "AnchoUnidad": 2.53,
            "AlturaUnidad": 3.18,
            "NumAsientos": 49,
            "NumPasajeros": 48,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "12/06/2017 18:46:27",
            "CodConcesionario": 2,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:37:21",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "11/03/2009",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 166,
            "PadronUnidad": "147",
            "PlacaUnidad": "B0G-748",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2008,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF 1721/59",
            "AñoUnidad1": 2008,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377989U0791298",
            "SerieUnidad": "9BM3840759B613133",
            "ColoresUnidad": "AMARILLO,BLANCO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.78,
            "PesoSecoUnidad": 9.22,
            "LongitudUnidad": 12.20,
            "AnchoUnidad": 2.53,
            "AlturaUnidad": 3.18,
            "NumAsientos": 49,
            "NumPasajeros": 48,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "12/06/2017 07:00:03",
            "CodConcesionario": 2,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:37:33",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "23/04/2009",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 167,
            "PadronUnidad": "148",
            "PlacaUnidad": "B3I-712",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2008,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF 1721/59",
            "AñoUnidad1": 2008,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377989U0791298",
            "SerieUnidad": "9BM3840759B613133",
            "ColoresUnidad": "AMARILLO,BLANCO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.78,
            "PesoSecoUnidad": 9.22,
            "LongitudUnidad": 12.20,
            "AnchoUnidad": 2.53,
            "AlturaUnidad": 3.18,
            "NumAsientos": 49,
            "NumPasajeros": 48,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "12/06/2017 07:12:50",
            "CodConcesionario": 2,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:37:41",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "23/04/2009",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 168,
            "PadronUnidad": "149",
            "PlacaUnidad": "D1N-706",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2011,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF-1722/59",
            "AñoUnidad1": 2011,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "924919U0976819",
            "SerieUnidad": "9BM384078CB829987",
            "ColoresUnidad": "AMARILLO,BLANCO",
            "PesoBrutoUnidad": 16.00,
            "CargaUtilUnidad": 8.01,
            "PesoSecoUnidad": 8.99,
            "LongitudUnidad": 12.25,
            "AnchoUnidad": 2.52,
            "AlturaUnidad": 3.37,
            "NumAsientos": 49,
            "NumPasajeros": 48,
            "NumEjes": 0,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "12/06/2017 07:22:04",
            "CodConcesionario": 2,
            "NomUsuario": null,
            "FechaEdicion": null,
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 169,
            "PadronUnidad": "150",
            "PlacaUnidad": "D1O-790",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": "Fernandez Rojas, Gilmer Cesar",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2011,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF-1722/59",
            "AñoUnidad1": 2011,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "924919U0980238",
            "SerieUnidad": "9BM384078CB834016",
            "ColoresUnidad": "AMARILLO,BLANCO",
            "PesoBrutoUnidad": 16.00,
            "CargaUtilUnidad": 8.01,
            "PesoSecoUnidad": 8.90,
            "LongitudUnidad": 12.25,
            "AnchoUnidad": 2.52,
            "AlturaUnidad": 3.37,
            "NumAsientos": 49,
            "NumPasajeros": 48,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "12/06/2017 07:31:05",
            "CodConcesionario": 2,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:38:03",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "12/12/2012",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 170,
            "PadronUnidad": "151",
            "PlacaUnidad": "D1P-778",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2011,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF-1722/59",
            "AñoUnidad1": 2011,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "924919U0976582",
            "SerieUnidad": "9BM384078CB829692",
            "ColoresUnidad": "AMARILLO,BLANCO",
            "PesoBrutoUnidad": 16.00,
            "CargaUtilUnidad": 8.01,
            "PesoSecoUnidad": 8.99,
            "LongitudUnidad": 12.25,
            "AnchoUnidad": 2.52,
            "AlturaUnidad": 3.37,
            "NumAsientos": 49,
            "NumPasajeros": 48,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "12/06/2017 06:56:20",
            "CodConcesionario": 2,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:38:35",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "12/12/2012",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 171,
            "PadronUnidad": "152",
            "PlacaUnidad": "D1P-791",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2011,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF-1722/59",
            "AñoUnidad1": 2011,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "924919U0975850",
            "SerieUnidad": "9BM384078CB828991",
            "ColoresUnidad": "AMARILLO,BLANCO",
            "PesoBrutoUnidad": 16.00,
            "CargaUtilUnidad": 8.01,
            "PesoSecoUnidad": 8.99,
            "LongitudUnidad": 12.25,
            "AnchoUnidad": 2.52,
            "AlturaUnidad": 3.37,
            "NumAsientos": 49,
            "NumPasajeros": 48,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "12/06/2017 06:46:39",
            "CodConcesionario": 2,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:38:51",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "12/12/2012",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 172,
            "PadronUnidad": "153",
            "PlacaUnidad": "D1U-700",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": "CACERES ROJAS, MARK EDSON",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2011,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF-1722/59",
            "AñoUnidad1": 2011,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "924919U0977790",
            "SerieUnidad": "9BM384078CB831175",
            "ColoresUnidad": "AMARILLO,BLANCO",
            "PesoBrutoUnidad": 16.00,
            "CargaUtilUnidad": 8.01,
            "PesoSecoUnidad": 8.99,
            "LongitudUnidad": 12.25,
            "AnchoUnidad": 2.52,
            "AlturaUnidad": 3.37,
            "NumAsientos": 49,
            "NumPasajeros": 48,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "12/06/2017 07:12:54",
            "CodConcesionario": 2,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:39:12",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "28/12/2012",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 173,
            "PadronUnidad": "154",
            "PlacaUnidad": "F1M-867",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2007,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF-1721/59",
            "AñoUnidad1": 2007,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377973U0735104",
            "SerieUnidad": "9BM3840738B543984",
            "ColoresUnidad": "AMARILLO,BLANCO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 8.01,
            "PesoSecoUnidad": 8.99,
            "LongitudUnidad": 12.20,
            "AnchoUnidad": 2.53,
            "AlturaUnidad": 3.18,
            "NumAsientos": 49,
            "NumPasajeros": 48,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "12/06/2017 18:36:09",
            "CodConcesionario": 2,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:38:59",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "03/10/2007",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 174,
            "PadronUnidad": "155",
            "PlacaUnidad": "ASP-815",
            "CodEstado": 2,
            "Codigo": 7,
            "Nombre": "409",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "03/07/2017 06:58:32",
            "CodConcesionario": 4,
            "NomUsuario": "acliente@sjl",
            "FechaEdicion": "24/04/2023 10:39:16",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 175,
            "PadronUnidad": "156",
            "PlacaUnidad": "ASP-809",
            "CodEstado": 2,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "22/06/2017 13:18:35",
            "CodConcesionario": 4,
            "NomUsuario": "SCHAVEZ@SJL",
            "FechaEdicion": "28/03/2023 17:06:48",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 176,
            "PadronUnidad": "157",
            "PlacaUnidad": "ASP-804",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "01/07/2017 18:46:10",
            "CodConcesionario": 4,
            "NomUsuario": null,
            "FechaEdicion": null,
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 177,
            "PadronUnidad": "158",
            "PlacaUnidad": "ASP-814",
            "CodEstado": 2,
            "Codigo": 6,
            "Nombre": "406",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "03/07/2017 06:55:52",
            "CodConcesionario": 4,
            "NomUsuario": "acliente@sjl",
            "FechaEdicion": "24/04/2023 10:40:08",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 178,
            "PadronUnidad": "159",
            "PlacaUnidad": "ASP-817",
            "CodEstado": 2,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "03/07/2017 07:48:47",
            "CodConcesionario": 4,
            "NomUsuario": "SCHAVEZ@SJL",
            "FechaEdicion": "28/03/2023 17:07:01",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 179,
            "PadronUnidad": "160",
            "PlacaUnidad": "ASP-819",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "22/06/2017 12:50:33",
            "CodConcesionario": 4,
            "NomUsuario": null,
            "FechaEdicion": null,
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 180,
            "PadronUnidad": "161",
            "PlacaUnidad": "ASP-820",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "03/07/2017 07:07:30",
            "CodConcesionario": 4,
            "NomUsuario": "mquispe@sjl",
            "FechaEdicion": "09/08/2023 16:59:58",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 181,
            "PadronUnidad": "162",
            "PlacaUnidad": "ASP-949",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "03/07/2017 17:55:19",
            "CodConcesionario": 4,
            "NomUsuario": null,
            "FechaEdicion": null,
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 182,
            "PadronUnidad": "163",
            "PlacaUnidad": "ASP-946",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "03/07/2017 06:44:33",
            "CodConcesionario": 4,
            "NomUsuario": null,
            "FechaEdicion": null,
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 183,
            "PadronUnidad": "164",
            "PlacaUnidad": "ASP-948",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "03/07/2017 06:52:41",
            "CodConcesionario": 4,
            "NomUsuario": null,
            "FechaEdicion": null,
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 184,
            "PadronUnidad": "165",
            "PlacaUnidad": "ASP-775",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": "LOZANO PINEDO, MIGDONIO",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLSKWAGEN",
            "AñoUnidad": 2017,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "17.210OD",
            "AñoUnidad1": 2017,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "1218966",
            "SerieUnidad": "9532X82W6HR705859",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": 16000.00,
            "CargaUtilUnidad": 5840.00,
            "PesoSecoUnidad": 10160.00,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.60,
            "AlturaUnidad": 3.35,
            "NumAsientos": 39,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "03/07/2017 05:31:57",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "14/12/2020 09:08:08",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 3,
            "FechaInscripcion": "30/05/2017",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 185,
            "PadronUnidad": "166",
            "PlacaUnidad": "ASQ-779",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": "BARRIENTOS PALOMINO, JUAN",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLSKWAGEN",
            "AñoUnidad": 2017,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "17.210 OD",
            "AñoUnidad1": 2017,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "1038603",
            "SerieUnidad": "9532X82W1HR704800",
            "ColoresUnidad": "GRIS METALICO MOTADO",
            "PesoBrutoUnidad": 16000.00,
            "CargaUtilUnidad": 5800.00,
            "PesoSecoUnidad": 10160.00,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.60,
            "AlturaUnidad": 3.35,
            "NumAsientos": 39,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "03/07/2017 07:16:41",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "14/12/2020 09:13:25",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 3,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 186,
            "PadronUnidad": "167",
            "PlacaUnidad": "ASO-843",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": "VALVERDE PALOMINO, JORGE LUIS",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLSKWAGEN",
            "AñoUnidad": 2017,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "17.210 OD",
            "AñoUnidad1": 2017,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "1038603",
            "SerieUnidad": "9532X82W1HR704800",
            "ColoresUnidad": "GRIS METALICO MOTADO",
            "PesoBrutoUnidad": 16000.00,
            "CargaUtilUnidad": 5840.00,
            "PesoSecoUnidad": 10160.00,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.60,
            "AlturaUnidad": 3.35,
            "NumAsientos": 39,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "03/07/2017 05:56:50",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "14/12/2020 09:18:08",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 3,
            "FechaInscripcion": "11/06/2018",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 187,
            "PadronUnidad": "168",
            "PlacaUnidad": "ASP-706",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": "CALDERON VARA, JORGE LUIS",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLSKWAGEN",
            "AñoUnidad": 2017,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "17.210 OD",
            "AñoUnidad1": 2017,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "1462281",
            "SerieUnidad": "9532X82WXHR704777",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": 16000.00,
            "CargaUtilUnidad": 5840.00,
            "PesoSecoUnidad": 10160.00,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.60,
            "AlturaUnidad": 3.35,
            "NumAsientos": 39,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "03/07/2017 07:10:26",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "14/12/2020 09:22:14",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 3,
            "FechaInscripcion": "11/06/2018",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 188,
            "PadronUnidad": "169",
            "PlacaUnidad": "ASO-841",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLSKWAGEN",
            "AñoUnidad": 2017,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "17.210 OD",
            "AñoUnidad1": 2017,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "1463539",
            "SerieUnidad": "9532X82WXHR704821",
            "ColoresUnidad": "GRIS METALICO MOTADO",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "03/07/2017 05:51:55",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "14/12/2020 09:24:46",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "11/06/2018",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 189,
            "PadronUnidad": "170",
            "PlacaUnidad": "ASO-840",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLSKWAGEN",
            "AñoUnidad": 2017,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "17.210 OD",
            "AñoUnidad1": 2017,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "1461417",
            "SerieUnidad": "9532X82W7HR706504",
            "ColoresUnidad": "GRIS METALICO MOTADO",
            "PesoBrutoUnidad": 16000.00,
            "CargaUtilUnidad": 5860.00,
            "PesoSecoUnidad": 10160.00,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.60,
            "AlturaUnidad": 3.35,
            "NumAsientos": 39,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "03/07/2017 06:01:58",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "14/12/2020 09:29:36",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 3,
            "FechaInscripcion": "11/06/2018",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 190,
            "PadronUnidad": "171",
            "PlacaUnidad": "ASO-844",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": "VELA -, JORGE LUIS",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": 16000.00,
            "CargaUtilUnidad": 5840.00,
            "PesoSecoUnidad": 10160.00,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.60,
            "AlturaUnidad": 3.35,
            "NumAsientos": 39,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "03/07/2017 06:14:19",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "14/12/2020 09:53:29",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 3,
            "FechaInscripcion": "26/05/2017",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 191,
            "PadronUnidad": "172",
            "PlacaUnidad": "A3R-753",
            "CodEstado": 1,
            "Codigo": 7,
            "Nombre": "409",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2009,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF-1721/59",
            "AñoUnidad1": 2009,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377989U0854085",
            "SerieUnidad": "9BM384075AB686624",
            "ColoresUnidad": "GRIS METALICO MOTADO",
            "PesoBrutoUnidad": 17000.00,
            "CargaUtilUnidad": 1610.00,
            "PesoSecoUnidad": 9390.00,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.50,
            "AlturaUnidad": 3.20,
            "NumAsientos": 50,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "06/10/2017 17:09:57",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "14/12/2020 09:59:36",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "11/06/2018",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 192,
            "PadronUnidad": "173",
            "PlacaUnidad": "A2K-732",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": "SALAS NIEVA, CARLOS ALBERTO",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2009,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF-1721/59",
            "AñoUnidad1": 2009,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377989U8846500",
            "SerieUnidad": "9BM384075AB677511",
            "ColoresUnidad": "GRIS METALICO MOTADO",
            "PesoBrutoUnidad": 17000.00,
            "CargaUtilUnidad": 7620.00,
            "PesoSecoUnidad": 9380.00,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.50,
            "AlturaUnidad": 3.20,
            "NumAsientos": 50,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "06/07/2017 07:09:34",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "14/12/2020 10:05:53",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "11/06/2018",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 193,
            "PadronUnidad": "174",
            "PlacaUnidad": "A8R-700",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2010,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF-1721/59",
            "AñoUnidad1": 2010,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "37798U0878788",
            "SerieUnidad": "9BM3840739B599447",
            "ColoresUnidad": "GRIS METALICO MOTADO",
            "PesoBrutoUnidad": 17000.00,
            "CargaUtilUnidad": 7690.00,
            "PesoSecoUnidad": 9310.00,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.50,
            "AlturaUnidad": 3.20,
            "NumAsientos": 50,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "05/07/2017 17:35:58",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "14/12/2020 10:09:44",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "11/06/2018",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 194,
            "PadronUnidad": "175",
            "PlacaUnidad": "F0E-838",
            "CodEstado": 1,
            "Codigo": 7,
            "Nombre": "409",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "03/07/2017 07:57:07",
            "CodConcesionario": 3,
            "NomUsuario": null,
            "FechaEdicion": null,
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 195,
            "PadronUnidad": "176",
            "PlacaUnidad": "F0D-895",
            "CodEstado": 2,
            "Codigo": 7,
            "Nombre": "409",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "03/07/2017 06:55:17",
            "CodConcesionario": 3,
            "NomUsuario": "SCHAVEZ@SJL",
            "FechaEdicion": "10/11/2021 17:27:31",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": ""
        },
        {
            "CodUnidad": 196,
            "PadronUnidad": "177",
            "PlacaUnidad": "F0F-768",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "03/07/2017 09:06:14",
            "CodConcesionario": 3,
            "NomUsuario": null,
            "FechaEdicion": null,
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 197,
            "PadronUnidad": "178",
            "PlacaUnidad": "F0E-907",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "03/07/2017 06:53:34",
            "CodConcesionario": 3,
            "NomUsuario": null,
            "FechaEdicion": null,
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 198,
            "PadronUnidad": "179",
            "PlacaUnidad": "F0A-886",
            "CodEstado": 1,
            "Codigo": 7,
            "Nombre": "409",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "KING LONG",
            "AñoUnidad": 2014,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "XMQ6120C2",
            "AñoUnidad1": 2014,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "73585610",
            "SerieUnidad": "EB400038",
            "ColoresUnidad": "AMARILLO,BLANCO,VERDE",
            "PesoBrutoUnidad": 19.05,
            "CargaUtilUnidad": 6.80,
            "PesoSecoUnidad": 12.25,
            "LongitudUnidad": 11.95,
            "AnchoUnidad": 2.55,
            "AlturaUnidad": 3.30,
            "NumAsientos": 43,
            "NumPasajeros": 42,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "03/07/2017 07:02:24",
            "CodConcesionario": 3,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:41:52",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "21/08/2014",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 199,
            "PadronUnidad": "180",
            "PlacaUnidad": "F0B-776",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "KING LONG",
            "AñoUnidad": 2014,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "XMQ6120C2",
            "AñoUnidad1": 2014,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "73585470",
            "SerieUnidad": "EB400046",
            "ColoresUnidad": "AMARILLO,VERDE,BLANCO",
            "PesoBrutoUnidad": 19.05,
            "CargaUtilUnidad": 6.80,
            "PesoSecoUnidad": 12.25,
            "LongitudUnidad": 11.95,
            "AnchoUnidad": 2.55,
            "AlturaUnidad": 3.30,
            "NumAsientos": 43,
            "NumPasajeros": 42,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "05/07/2017 07:25:56",
            "CodConcesionario": 3,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:42:03",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "15/07/2014",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 200,
            "PadronUnidad": "181",
            "PlacaUnidad": "F0A-779",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "KING LONG",
            "AñoUnidad": 2014,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "XMQ6120C2",
            "AñoUnidad1": 2014,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "73585527",
            "SerieUnidad": "EB400049",
            "ColoresUnidad": "AMARILLO,VERDE,BLANCO",
            "PesoBrutoUnidad": 19.03,
            "CargaUtilUnidad": 6.80,
            "PesoSecoUnidad": 12.23,
            "LongitudUnidad": 11.95,
            "AnchoUnidad": 2.55,
            "AlturaUnidad": 3.30,
            "NumAsientos": 43,
            "NumPasajeros": 42,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "05/07/2017 06:16:16",
            "CodConcesionario": 3,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:42:13",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "15/07/2014",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 201,
            "PadronUnidad": "182",
            "PlacaUnidad": "F0B-707",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "KING LONG",
            "AñoUnidad": 2014,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "XMQ6120C2",
            "AñoUnidad1": 2014,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "73585542",
            "SerieUnidad": "EB400050",
            "ColoresUnidad": "AMARILLO,BLANCO,VERDE",
            "PesoBrutoUnidad": 19.47,
            "CargaUtilUnidad": 6.80,
            "PesoSecoUnidad": 12.67,
            "LongitudUnidad": 11.95,
            "AnchoUnidad": 2.55,
            "AlturaUnidad": 3.30,
            "NumAsientos": 43,
            "NumPasajeros": 42,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "04/07/2017 07:07:07",
            "CodConcesionario": 3,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:42:35",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "15/07/2014",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 202,
            "PadronUnidad": "183",
            "PlacaUnidad": "F0J-799",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "KING LONG",
            "AñoUnidad": 2014,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "XMQ6120C2",
            "AñoUnidad1": 2014,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "73585511",
            "SerieUnidad": "EB400063",
            "ColoresUnidad": "AMARILLO,BLANCO,VERDE",
            "PesoBrutoUnidad": 19.31,
            "CargaUtilUnidad": 6.87,
            "PesoSecoUnidad": 12.51,
            "LongitudUnidad": 11.95,
            "AnchoUnidad": 2.55,
            "AlturaUnidad": 3.30,
            "NumAsientos": 43,
            "NumPasajeros": 42,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "03/07/2017 07:16:16",
            "CodConcesionario": 3,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:42:43",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "15/07/2014",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 203,
            "PadronUnidad": "184",
            "PlacaUnidad": "F0B-729",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "KING LONG",
            "AñoUnidad": 2014,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "XMQ6120C2",
            "AñoUnidad1": 2014,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "73587124",
            "SerieUnidad": "EB400062",
            "ColoresUnidad": "AMARILLO,VERDE,BLANCO",
            "PesoBrutoUnidad": 19.04,
            "CargaUtilUnidad": 6.80,
            "PesoSecoUnidad": 12.24,
            "LongitudUnidad": 11.95,
            "AnchoUnidad": 2.25,
            "AlturaUnidad": 3.30,
            "NumAsientos": 43,
            "NumPasajeros": 42,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "06/07/2017 08:21:52",
            "CodConcesionario": 3,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:43:22",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2^0~0^0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "15/07/2014",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 204,
            "PadronUnidad": "185",
            "PlacaUnidad": "A2K-738",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": "UTRILLA TREJO, LENIN AMADOR",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2009,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF-1721/59",
            "AñoUnidad1": 2009,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377989U0846543",
            "SerieUnidad": "9BM384075AB677811",
            "ColoresUnidad": "BLANCO,CELESTE",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.62,
            "PesoSecoUnidad": 9.38,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.50,
            "AlturaUnidad": 3.20,
            "NumAsientos": 51,
            "NumPasajeros": 50,
            "NumEjes": 2,
            "NumRuedas": 6,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "05/07/2017 18:34:45",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "14/12/2020 10:10:24",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "16/11/2010",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 205,
            "PadronUnidad": "186",
            "PlacaUnidad": "ACE-721",
            "CodEstado": 1,
            "Codigo": 7,
            "Nombre": "409",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "KING LONG",
            "AñoUnidad": 2014,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "XMQ6120C2",
            "AñoUnidad1": 2014,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "73598776",
            "SerieUnidad": "EB400091",
            "ColoresUnidad": "AZUL",
            "PesoBrutoUnidad": 18.00,
            "CargaUtilUnidad": 6.80,
            "PesoSecoUnidad": 12.00,
            "LongitudUnidad": 11.95,
            "AnchoUnidad": 2.55,
            "AlturaUnidad": 3.30,
            "NumAsientos": 43,
            "NumPasajeros": 42,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "19/07/2017 07:20:18",
            "CodConcesionario": 3,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:45:01",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2^0~0^0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "17/10/2014",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 206,
            "PadronUnidad": "187",
            "PlacaUnidad": "F0A-931",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "KING LONG",
            "AñoUnidad": 2014,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "XMQ6120C2",
            "AñoUnidad1": 2014,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "73585459",
            "SerieUnidad": "EB400064",
            "ColoresUnidad": "AMARILLO,BLANCO,VERDE",
            "PesoBrutoUnidad": 19.40,
            "CargaUtilUnidad": 6.80,
            "PesoSecoUnidad": 12.60,
            "LongitudUnidad": 11.95,
            "AnchoUnidad": 2.55,
            "AlturaUnidad": 3.30,
            "NumAsientos": 43,
            "NumPasajeros": 42,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "29/09/2017 11:02:14",
            "CodConcesionario": 3,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:45:09",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "15/07/2014",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 207,
            "PadronUnidad": "188",
            "PlacaUnidad": "F0A-934",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "KING LONG",
            "AñoUnidad": 2014,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "XMQ6120C2",
            "AñoUnidad1": 2014,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "73585574",
            "SerieUnidad": "EB400059",
            "ColoresUnidad": "AMARILLO,BLANCO,VERDE",
            "PesoBrutoUnidad": 19.02,
            "CargaUtilUnidad": 6.80,
            "PesoSecoUnidad": 12.22,
            "LongitudUnidad": 11.95,
            "AnchoUnidad": 2.55,
            "AlturaUnidad": 3.30,
            "NumAsientos": 43,
            "NumPasajeros": 42,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "21/07/2017 18:23:20",
            "CodConcesionario": 3,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:45:20",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "15/07/2014",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 208,
            "PadronUnidad": "189",
            "PlacaUnidad": "F0B-763",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "KING LONG",
            "AñoUnidad": 2014,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "XMQ6120C2",
            "AñoUnidad1": 2014,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "73583741",
            "SerieUnidad": "EB400065",
            "ColoresUnidad": "AMARILLO,VERDE,BLANCO",
            "PesoBrutoUnidad": 19.02,
            "CargaUtilUnidad": 6.80,
            "PesoSecoUnidad": 12.22,
            "LongitudUnidad": 11.95,
            "AnchoUnidad": 2.55,
            "AlturaUnidad": 3.30,
            "NumAsientos": 43,
            "NumPasajeros": 42,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "20/07/2017 18:32:57",
            "CodConcesionario": 3,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:45:28",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "15/07/2014",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 209,
            "PadronUnidad": "190",
            "PlacaUnidad": "F0B-881",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "KING LONG",
            "AñoUnidad": 2014,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "XMQ6120C2",
            "AñoUnidad1": 2014,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "73587836",
            "SerieUnidad": "EB400043",
            "ColoresUnidad": "AMARILLO,BLANCO,VERDE",
            "PesoBrutoUnidad": 19.02,
            "CargaUtilUnidad": 6.80,
            "PesoSecoUnidad": 12.22,
            "LongitudUnidad": 11.95,
            "AnchoUnidad": 2.55,
            "AlturaUnidad": 3.30,
            "NumAsientos": 43,
            "NumPasajeros": 42,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "20/07/2017 18:51:54",
            "CodConcesionario": 3,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:45:41",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "15/07/2014",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 210,
            "PadronUnidad": "191",
            "PlacaUnidad": "F0D-831",
            "CodEstado": 1,
            "Codigo": 7,
            "Nombre": "409",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "02/08/2017 08:03:59",
            "CodConcesionario": 3,
            "NomUsuario": null,
            "FechaEdicion": null,
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 211,
            "PadronUnidad": "192",
            "PlacaUnidad": "F0D-892",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "17/07/2017 19:15:29",
            "CodConcesionario": 3,
            "NomUsuario": "Admin@sjl",
            "FechaEdicion": "26/02/2018 09:50:06",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 212,
            "PadronUnidad": "193",
            "PlacaUnidad": "F0E-773",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "18/07/2017 10:09:48",
            "CodConcesionario": 3,
            "NomUsuario": null,
            "FechaEdicion": null,
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 213,
            "PadronUnidad": "194",
            "PlacaUnidad": "F0E-822",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "KING LONG",
            "AñoUnidad": 2014,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "XMQ6120C2",
            "AñoUnidad1": 2014,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "73587138",
            "SerieUnidad": "LA6R3HSE8EB400055",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": 19.00,
            "CargaUtilUnidad": 6.80,
            "PesoSecoUnidad": 12.20,
            "LongitudUnidad": 11.95,
            "AnchoUnidad": 2.50,
            "AlturaUnidad": 3.30,
            "NumAsientos": 43,
            "NumPasajeros": 42,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "20/07/2017 19:09:56",
            "CodConcesionario": 3,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:47:37",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "31/01/2020",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 214,
            "PadronUnidad": "195",
            "PlacaUnidad": "F0E-842",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "27/12/2017 06:01:46",
            "CodConcesionario": 3,
            "NomUsuario": "Admin@sjl",
            "FechaEdicion": "26/02/2018 09:51:41",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 215,
            "PadronUnidad": "196",
            "PlacaUnidad": "F0F-822",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "21/07/2017 08:00:43",
            "CodConcesionario": 3,
            "NomUsuario": null,
            "FechaEdicion": null,
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 216,
            "PadronUnidad": "197",
            "PlacaUnidad": "A7J-755",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2010,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF-1721/59",
            "AñoUnidad1": 2010,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377989U0891952",
            "SerieUnidad": "9BM384075BB730398",
            "ColoresUnidad": "AMARILLO,BLANCO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.65,
            "PesoSecoUnidad": 9.35,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.50,
            "AlturaUnidad": 3.20,
            "NumAsientos": 51,
            "NumPasajeros": 50,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "06/09/2017 08:10:00",
            "CodConcesionario": 2,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:47:56",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "13/12/2012",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 217,
            "PadronUnidad": "198",
            "PlacaUnidad": "B3I-758",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2011,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF. 1721/59",
            "AñoUnidad1": 2011,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377989U0960647",
            "SerieUnidad": "9BM384075CB911831",
            "ColoresUnidad": "AMARILLO,BLANCO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.75,
            "PesoSecoUnidad": 9.25,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.50,
            "AlturaUnidad": 3.20,
            "NumAsientos": 51,
            "NumPasajeros": 50,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "06/09/2017 08:05:33",
            "CodConcesionario": 2,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:48:21",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "08/03/2012",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 218,
            "PadronUnidad": "199",
            "PlacaUnidad": "B3I-711",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2011,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF. 1721/59",
            "AñoUnidad1": 2011,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377989U0961125",
            "SerieUnidad": "9BM384075CB812192",
            "ColoresUnidad": "AMARILLO,BLANCO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.77,
            "PesoSecoUnidad": 9.23,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.50,
            "AlturaUnidad": 3.20,
            "NumAsientos": 51,
            "NumPasajeros": 50,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "27/09/2017 08:46:54",
            "CodConcesionario": 2,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:48:36",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "08/03/2012",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 219,
            "PadronUnidad": "200",
            "PlacaUnidad": "B3I-791",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2011,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF. 1721/59",
            "AñoUnidad1": 2011,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377989U0961319",
            "SerieUnidad": "9BM384075CB812226",
            "ColoresUnidad": "AMARILLO,BLANCO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.79,
            "PesoSecoUnidad": 9.21,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.50,
            "AlturaUnidad": 3.20,
            "NumAsientos": 51,
            "NumPasajeros": 50,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "28/09/2017 07:07:13",
            "CodConcesionario": 2,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:48:49",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "08/03/2012",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 220,
            "PadronUnidad": "201",
            "PlacaUnidad": "A7J-752",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": "TOLEDO ALVA, ANIBAL",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2010,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF-1721/59",
            "AñoUnidad1": 2010,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377989U0892603",
            "SerieUnidad": "9BM384075BB731146",
            "ColoresUnidad": "AMARILLO,BLANCO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.66,
            "PesoSecoUnidad": 9.34,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.50,
            "AlturaUnidad": 3.20,
            "NumAsientos": 51,
            "NumPasajeros": 50,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "29/09/2017 11:19:47",
            "CodConcesionario": 2,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:49:04",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "13/12/2012",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 221,
            "PadronUnidad": "202",
            "PlacaUnidad": "D1K-785",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2010,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF-1721/59",
            "AñoUnidad1": 2010,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377989U0892603",
            "SerieUnidad": "9BM384075BB731146",
            "ColoresUnidad": "AMARILLO,BLANCO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.66,
            "PesoSecoUnidad": 9.34,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.50,
            "AlturaUnidad": 3.20,
            "NumAsientos": 51,
            "NumPasajeros": 50,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "29/09/2017 11:59:03",
            "CodConcesionario": 2,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:49:13",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "13/12/2012",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 399,
            "PadronUnidad": "2026",
            "PlacaUnidad": "PRU-EBA",
            "CodEstado": 1,
            "Codigo": 6,
            "Nombre": "406",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": "",
            "CodPersonaPropietario": null,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "SCHAVEZ@SJL",
            "FechaCreacion": "17/06/2022 15:08:39",
            "CodConcesionario": 1,
            "NomUsuario": null,
            "FechaEdicion": null,
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 222,
            "PadronUnidad": "203",
            "PlacaUnidad": "A3U-735",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2007,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF. 1721/59",
            "AñoUnidad1": 2007,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377973U0720632",
            "SerieUnidad": "9BM3840737B526218",
            "ColoresUnidad": "AMARILLO,BLANCO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 6.00,
            "PesoSecoUnidad": 11.00,
            "LongitudUnidad": 12.30,
            "AnchoUnidad": 2.56,
            "AlturaUnidad": 3.30,
            "NumAsientos": 54,
            "NumPasajeros": 53,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "04/10/2017 07:35:46",
            "CodConcesionario": 2,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:49:28",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "15/07/2010",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 223,
            "PadronUnidad": "204",
            "PlacaUnidad": "A7J-753",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": "MELGAREJO DAGA, FREDDY MANUEL",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2010,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF-1721/59",
            "AñoUnidad1": 2010,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377989U0890659",
            "SerieUnidad": "9BM384075BB729273",
            "ColoresUnidad": "AMARILLO,BLANCO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.64,
            "PesoSecoUnidad": 9.36,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.50,
            "AlturaUnidad": 3.20,
            "NumAsientos": 51,
            "NumPasajeros": 50,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "27/09/2017 21:30:52",
            "CodConcesionario": 2,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:49:39",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "17/12/2012",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 224,
            "PadronUnidad": "205",
            "PlacaUnidad": "A1M-748",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2009,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF 1721/59",
            "AñoUnidad1": 2009,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377989U0857542",
            "SerieUnidad": "9BM384075AB690501",
            "ColoresUnidad": "BLANCO,CELESTE",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.87,
            "PesoSecoUnidad": 9.13,
            "LongitudUnidad": 12.20,
            "AnchoUnidad": 2.53,
            "AlturaUnidad": 3.18,
            "NumAsientos": 49,
            "NumPasajeros": 48,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "06/10/2017 18:35:00",
            "CodConcesionario": 1,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:49:50",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "16/11/2010",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 225,
            "PadronUnidad": "206",
            "PlacaUnidad": "A3U-732",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2007,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF 1721/59",
            "AñoUnidad1": 2007,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377973U0719455",
            "SerieUnidad": "9BM3840737B524642",
            "ColoresUnidad": "AMARILLO,BLANCO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 6.00,
            "PesoSecoUnidad": 11.00,
            "LongitudUnidad": 12.30,
            "AnchoUnidad": 2.56,
            "AlturaUnidad": 3.30,
            "NumAsientos": 54,
            "NumPasajeros": 53,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "06/12/2017 16:26:58",
            "CodConcesionario": 2,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:50:12",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "15/07/2010",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 226,
            "PadronUnidad": "207",
            "PlacaUnidad": "A3U-734",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": "GUERRA MORALES, JHON LAY",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2007,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF 1721/59",
            "AñoUnidad1": 2007,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377973U0721033",
            "SerieUnidad": "9BM3840737B526518",
            "ColoresUnidad": "AMARILLO,BLANCO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 6.00,
            "PesoSecoUnidad": 11.00,
            "LongitudUnidad": 12.30,
            "AnchoUnidad": 2.56,
            "AlturaUnidad": 3.30,
            "NumAsientos": 54,
            "NumPasajeros": 54,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "11/12/2017 18:22:19",
            "CodConcesionario": 2,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:50:23",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "15/07/2010",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 227,
            "PadronUnidad": "208",
            "PlacaUnidad": "C7J-738",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2009,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF 1721/59",
            "AñoUnidad1": 2009,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377989U0828299",
            "SerieUnidad": "9BM384075AB657591",
            "ColoresUnidad": "AMARILLO,BLANCO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.97,
            "PesoSecoUnidad": 9.03,
            "LongitudUnidad": 12.20,
            "AnchoUnidad": 2.53,
            "AlturaUnidad": 3.18,
            "NumAsientos": 49,
            "NumPasajeros": 48,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "04/12/2017 07:41:54",
            "CodConcesionario": 2,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:50:31",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "30/07/2009",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 228,
            "PadronUnidad": "209",
            "PlacaUnidad": "A3U-731",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2007,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF-1721/59",
            "AñoUnidad1": 2007,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377973U0718277",
            "SerieUnidad": "9BM3840737B523195",
            "ColoresUnidad": "AMARILLO,BLANCO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 6.00,
            "PesoSecoUnidad": 11.00,
            "LongitudUnidad": 12.30,
            "AnchoUnidad": 2.56,
            "AlturaUnidad": 3.30,
            "NumAsientos": 54,
            "NumPasajeros": 53,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "20/12/2017 08:06:41",
            "CodConcesionario": 2,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:50:41",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "15/07/2010",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 229,
            "PadronUnidad": "210",
            "PlacaUnidad": "A3U-733",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": "MORALES GARRO, JORGE LUIS",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2007,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF 1721/59",
            "AñoUnidad1": 2007,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377973U0721229",
            "SerieUnidad": "9BM3840737B526728",
            "ColoresUnidad": "AMARILLO,BLANCO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 6.00,
            "PesoSecoUnidad": 11.00,
            "LongitudUnidad": 12.30,
            "AnchoUnidad": 2.56,
            "AlturaUnidad": 3.30,
            "NumAsientos": 54,
            "NumPasajeros": 53,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "05/12/2017 06:21:53",
            "CodConcesionario": 2,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:50:50",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "15/07/2010",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 230,
            "PadronUnidad": "211",
            "PlacaUnidad": "A7V-706",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2010,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF-1721/59",
            "AñoUnidad1": 2010,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377989U0892467",
            "SerieUnidad": "9BM384075BB730170",
            "ColoresUnidad": "AMARILLO,BLANCO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.66,
            "PesoSecoUnidad": 9.34,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.50,
            "AlturaUnidad": 3.20,
            "NumAsientos": 51,
            "NumPasajeros": 50,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "04/12/2017 15:22:00",
            "CodConcesionario": 2,
            "NomUsuario": "chumpitaz@juntaoperadora",
            "FechaEdicion": "10/12/2020 09:50:58",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "30/11/2011",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 231,
            "PadronUnidad": "212",
            "PlacaUnidad": "A8R-743",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2010,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF-1721/59",
            "AñoUnidad1": 2010,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377989U0883189",
            "SerieUnidad": "9BM384075BB719900",
            "ColoresUnidad": "BLANCO,CELESTE",
            "PesoBrutoUnidad": 17000.00,
            "CargaUtilUnidad": 7.67,
            "PesoSecoUnidad": 9.33,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.50,
            "AlturaUnidad": 3.20,
            "NumAsientos": 51,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "21/01/2018 09:42:02",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "14/12/2020 10:12:16",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "11/01/2012",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 232,
            "PadronUnidad": "213",
            "PlacaUnidad": "A8R-745",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": "JEREMIAS TARAZONA, GREGORIO AURELIO",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2010,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF-1721/59",
            "AñoUnidad1": 2010,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377989U0884208",
            "SerieUnidad": "9BM384075BB721525",
            "ColoresUnidad": "GRIS METALICO MOTADO",
            "PesoBrutoUnidad": 17000.00,
            "CargaUtilUnidad": 7610.00,
            "PesoSecoUnidad": 9390.00,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.50,
            "AlturaUnidad": 3.20,
            "NumAsientos": 50,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "28/12/2017 11:40:58",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "14/12/2020 10:14:05",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "11/06/2018",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 233,
            "PadronUnidad": "214",
            "PlacaUnidad": "A9C-760",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2010,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF-1721/59",
            "AñoUnidad1": 2010,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377989U0884208",
            "SerieUnidad": "9BM384075BB721525",
            "ColoresUnidad": "GRIS METALICO MOTADO",
            "PesoBrutoUnidad": 17000.00,
            "CargaUtilUnidad": 7640.00,
            "PesoSecoUnidad": 9360.00,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.50,
            "AlturaUnidad": 3.20,
            "NumAsientos": 50,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "21/01/2018 11:23:26",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "14/12/2020 10:25:37",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "11/06/2018",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 234,
            "PadronUnidad": "215",
            "PlacaUnidad": "AUO-774",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": "DOMINGUEZ DIAZ, DEYVI DANIEL",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLSKWAGEN",
            "AñoUnidad": 2017,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "17.210OD",
            "AñoUnidad1": 2017,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "1466626",
            "SerieUnidad": "9532X82W9JR804102",
            "ColoresUnidad": "GRIS METALICO MOTADO",
            "PesoBrutoUnidad": 16000.00,
            "CargaUtilUnidad": 5840.00,
            "PesoSecoUnidad": 10160.00,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.60,
            "AlturaUnidad": 3.35,
            "NumAsientos": 39,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "22/01/2018 09:17:39",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "14/12/2020 10:28:04",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 3,
            "FechaInscripcion": "11/06/2018",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 235,
            "PadronUnidad": "216",
            "PlacaUnidad": "AUR-726",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": "GOMEZ LUJAN, STIVEN JHON",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLSKWAGEN",
            "AñoUnidad": 2017,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "17.210OD",
            "AñoUnidad1": 2017,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "1465181",
            "SerieUnidad": "9532X82W3JR803964",
            "ColoresUnidad": "GRIS METALICO MOTADO",
            "PesoBrutoUnidad": 16000.00,
            "CargaUtilUnidad": 5840.00,
            "PesoSecoUnidad": 10160.00,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.60,
            "AlturaUnidad": 3.35,
            "NumAsientos": 39,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "21/01/2018 12:05:45",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "14/12/2020 10:29:30",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 3,
            "FechaInscripcion": "11/06/2018",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 236,
            "PadronUnidad": "217",
            "PlacaUnidad": "AUR-727",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": "TORRES CHECCLLO, WILLIAMS ROBERT",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLSKWAGEN",
            "AñoUnidad": 2017,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "17.210OD",
            "AñoUnidad1": 2017,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "1424456",
            "SerieUnidad": "9532X82W2JR804118",
            "ColoresUnidad": "GRIS METALICO MOTADO",
            "PesoBrutoUnidad": 16000.00,
            "CargaUtilUnidad": 6.00,
            "PesoSecoUnidad": 10160.00,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.60,
            "AlturaUnidad": 3.35,
            "NumAsientos": 39,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "21/01/2018 11:36:30",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "14/12/2020 10:49:25",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 3,
            "FechaInscripcion": "11/06/2018",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 237,
            "PadronUnidad": "218",
            "PlacaUnidad": "AUU-890",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": "RAMIREZ CABALLERO, KELLER ORLANDO",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLSKWAGEN",
            "AñoUnidad": 2017,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "17.210OD",
            "AñoUnidad1": 2017,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "1466633",
            "SerieUnidad": "9532X82W5JR804193",
            "ColoresUnidad": "GRIS METALICO MOTADO",
            "PesoBrutoUnidad": 16000.00,
            "CargaUtilUnidad": 5840.00,
            "PesoSecoUnidad": 10160.00,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.60,
            "AlturaUnidad": 3.35,
            "NumAsientos": 39,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "21/01/2018 13:39:32",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "14/12/2020 10:50:39",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 3,
            "FechaInscripcion": "11/06/2018",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 238,
            "PadronUnidad": "219",
            "PlacaUnidad": "AUQ-715",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLSKWAGEN",
            "AñoUnidad": 2017,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "17.210OD",
            "AñoUnidad1": 2017,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "1462497",
            "SerieUnidad": "9532X82W1JR805759",
            "ColoresUnidad": "GRIS METALICO MOTADO",
            "PesoBrutoUnidad": 16000.00,
            "CargaUtilUnidad": 5840.00,
            "PesoSecoUnidad": 10160.00,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.60,
            "AlturaUnidad": 3.35,
            "NumAsientos": 39,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "21/01/2018 10:11:13",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "14/12/2020 10:51:53",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 3,
            "FechaInscripcion": "11/06/2018",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 239,
            "PadronUnidad": "220",
            "PlacaUnidad": "AUO-875",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": "CHAVEZ CUELLAR, SERGIO",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLSKWAGEN",
            "AñoUnidad": 2017,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "17.210OD",
            "AñoUnidad1": 2017,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "1466355",
            "SerieUnidad": "9532X82W6JR805224",
            "ColoresUnidad": "GRIS METALICO MOTADO",
            "PesoBrutoUnidad": 16000.00,
            "CargaUtilUnidad": 5840.00,
            "PesoSecoUnidad": 10160.00,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.60,
            "AlturaUnidad": 3.35,
            "NumAsientos": 39,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "22/01/2018 12:12:46",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "14/12/2020 10:53:21",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 3,
            "FechaInscripcion": "11/06/2018",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 240,
            "PadronUnidad": "221",
            "PlacaUnidad": "AUO-777",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": "LOZA ROJAS, LUIS ALBERTO",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLSKWAGEN",
            "AñoUnidad": 2017,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "17.210OD",
            "AñoUnidad1": 2017,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "1138021",
            "SerieUnidad": "9532X82W5JR804002",
            "ColoresUnidad": "GRIS METALICO MOTADO",
            "PesoBrutoUnidad": 16000.00,
            "CargaUtilUnidad": 5840.00,
            "PesoSecoUnidad": 10160.00,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.60,
            "AlturaUnidad": 3.35,
            "NumAsientos": 39,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "24/01/2018 16:30:36",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "14/12/2020 10:54:20",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 3,
            "FechaInscripcion": "11/06/2018",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 241,
            "PadronUnidad": "222",
            "PlacaUnidad": "AUO-776",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLSKWAGEN",
            "AñoUnidad": 2017,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "17.210OD",
            "AñoUnidad1": 2017,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "1256489",
            "SerieUnidad": "9532X82W0JR804215",
            "ColoresUnidad": "GRIS METALICO MOTADO",
            "PesoBrutoUnidad": 16000.00,
            "CargaUtilUnidad": 5840.00,
            "PesoSecoUnidad": 10160.00,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.60,
            "AlturaUnidad": 3.35,
            "NumAsientos": 39,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "21/01/2018 09:58:43",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "14/12/2020 10:56:56",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 3,
            "FechaInscripcion": "11/06/2018",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 242,
            "PadronUnidad": "223",
            "PlacaUnidad": "AUO-873",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLSKWAGEN",
            "AñoUnidad": 2017,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "17.210OD",
            "AñoUnidad1": 2017,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "1236882",
            "SerieUnidad": "9532X82W6JR803988",
            "ColoresUnidad": "GRIS METALICO MOTADO",
            "PesoBrutoUnidad": 16000.00,
            "CargaUtilUnidad": 5840.00,
            "PesoSecoUnidad": 10160.00,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.60,
            "AlturaUnidad": 3.35,
            "NumAsientos": 39,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "22/01/2018 08:16:26",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "14/12/2020 11:09:28",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 3,
            "FechaInscripcion": "11/06/2018",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 243,
            "PadronUnidad": "224",
            "PlacaUnidad": "AUR-809",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": "JAMANCA RODRIGUEZ, FRANKLIN PASIANO",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLSKWAGEN",
            "AñoUnidad": 2017,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "17.210OD",
            "AñoUnidad1": 2017,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "1465183",
            "SerieUnidad": "9532X82W8JR804026",
            "ColoresUnidad": "GRIS METALICO MOTADO",
            "PesoBrutoUnidad": 16000.00,
            "CargaUtilUnidad": 5840.00,
            "PesoSecoUnidad": 10160.00,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.60,
            "AlturaUnidad": 3.35,
            "NumAsientos": 39,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "21/01/2018 11:37:14",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "14/12/2020 11:11:13",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 3,
            "FechaInscripcion": "11/06/2018",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 244,
            "PadronUnidad": "225",
            "PlacaUnidad": "AUL-763",
            "CodEstado": 2,
            "Codigo": 6,
            "Nombre": "406",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "09/01/2018 21:30:46",
            "CodConcesionario": 4,
            "NomUsuario": "SCHAVEZ@SJL",
            "FechaEdicion": "28/03/2023 17:07:14",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 245,
            "PadronUnidad": "226",
            "PlacaUnidad": "AUP-730",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "08/01/2018 12:24:43",
            "CodConcesionario": 4,
            "NomUsuario": "mquispe@sjl",
            "FechaEdicion": "09/06/2023 14:01:16",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 246,
            "PadronUnidad": "227",
            "PlacaUnidad": "AUL-787",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "08/01/2018 13:11:00",
            "CodConcesionario": 4,
            "NomUsuario": "mquispe@sjl",
            "FechaEdicion": "09/06/2023 13:59:39",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 247,
            "PadronUnidad": "228",
            "PlacaUnidad": "AUO-876",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": "CAJAHUAMAN MUCHA, ANGEL ALBERTO",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLSKWAGEN",
            "AñoUnidad": 2017,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "17.210OD",
            "AñoUnidad1": 2017,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "1504774",
            "SerieUnidad": "9532X82W6JR805224",
            "ColoresUnidad": "GRIS METALICO MOTADO",
            "PesoBrutoUnidad": 16000.00,
            "CargaUtilUnidad": 5840.00,
            "PesoSecoUnidad": 10160.00,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.60,
            "AlturaUnidad": 3.35,
            "NumAsientos": 39,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "21/01/2018 15:50:19",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "14/12/2020 11:13:43",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 3,
            "FechaInscripcion": "11/06/2018",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 248,
            "PadronUnidad": "229",
            "PlacaUnidad": "AUO-773",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLSKWAGEN",
            "AñoUnidad": 2017,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "17.210OD",
            "AñoUnidad1": 2017,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "1464758",
            "SerieUnidad": "9532X82W7JR804342",
            "ColoresUnidad": "GRIS METALICO MOTADO",
            "PesoBrutoUnidad": 16000.00,
            "CargaUtilUnidad": 5840.00,
            "PesoSecoUnidad": 10160.00,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.60,
            "AlturaUnidad": 3.35,
            "NumAsientos": 39,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "21/01/2018 11:45:51",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "14/12/2020 11:17:22",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 3,
            "FechaInscripcion": "11/06/2018",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 249,
            "PadronUnidad": "230",
            "PlacaUnidad": "AUO-775",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": "GUEVARA QUISPE, RODOLFO",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLSKWAGEN",
            "AñoUnidad": 2017,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "17.210OD",
            "AñoUnidad1": 2017,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "1466636",
            "SerieUnidad": "9532X82W6JR804347",
            "ColoresUnidad": "GRIS METALICO MOTADO",
            "PesoBrutoUnidad": 1600.00,
            "CargaUtilUnidad": 5840.00,
            "PesoSecoUnidad": 10160.00,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.60,
            "AlturaUnidad": 3.35,
            "NumAsientos": 39,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "22/01/2018 09:59:15",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "14/12/2020 11:18:52",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 3,
            "FechaInscripcion": "11/06/2018",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 250,
            "PadronUnidad": "231",
            "PlacaUnidad": "AUO-726",
            "CodEstado": 1,
            "Codigo": 6,
            "Nombre": "406",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLSKWAGEN",
            "AñoUnidad": 2017,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "17.210OD",
            "AñoUnidad1": 2017,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "1465184",
            "SerieUnidad": "9532X82W3JR805245",
            "ColoresUnidad": "GRIS METALICO MOTADO",
            "PesoBrutoUnidad": 16000.00,
            "CargaUtilUnidad": 5840.00,
            "PesoSecoUnidad": 10160.00,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.60,
            "AlturaUnidad": 3.35,
            "NumAsientos": 39,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "21/01/2018 13:06:13",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "14/12/2020 11:20:00",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 3,
            "FechaInscripcion": "11/06/2018",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 251,
            "PadronUnidad": "232",
            "PlacaUnidad": "AUR-791",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": "LURITA DAVIRAN, WILLIAMS ALBERTO",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLSKWAGEN",
            "AñoUnidad": 2017,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "17.210OD",
            "AñoUnidad1": 2017,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "1466354",
            "SerieUnidad": "9532X82W0JR805235",
            "ColoresUnidad": "GRIS METALICO MOTADO",
            "PesoBrutoUnidad": 16000.00,
            "CargaUtilUnidad": 5840.00,
            "PesoSecoUnidad": 10160.00,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.60,
            "AlturaUnidad": 3.35,
            "NumAsientos": 39,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "21/01/2018 11:36:23",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "14/12/2020 11:23:16",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 3,
            "FechaInscripcion": "11/06/2018",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 252,
            "PadronUnidad": "233",
            "PlacaUnidad": "AUR-825",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": "HUILLCA CCOSCCO, EDGAR",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLSKWAGEN",
            "AñoUnidad": 2017,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "17.210OD",
            "AñoUnidad1": 2017,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "1462284",
            "SerieUnidad": "9532X82W3JR804337",
            "ColoresUnidad": "GRIS METALICO MOTADO",
            "PesoBrutoUnidad": 16000.00,
            "CargaUtilUnidad": 5840.00,
            "PesoSecoUnidad": 10160.00,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.60,
            "AlturaUnidad": 3.35,
            "NumAsientos": 39,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "22/01/2018 17:02:53",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "14/12/2020 11:24:36",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 3,
            "FechaInscripcion": "11/06/2018",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 253,
            "PadronUnidad": "234",
            "PlacaUnidad": "AUP-751",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLSKWAGEN",
            "AñoUnidad": 2017,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "17.210OD",
            "AñoUnidad1": 2017,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "1466363",
            "SerieUnidad": "9532X82W4JR803861",
            "ColoresUnidad": "GRIS METALICO MOTADO",
            "PesoBrutoUnidad": 16000.00,
            "CargaUtilUnidad": 5840.00,
            "PesoSecoUnidad": 10160.00,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.60,
            "AlturaUnidad": 3.35,
            "NumAsientos": 39,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "21/01/2018 11:32:22",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "14/12/2020 11:25:28",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 3,
            "FechaInscripcion": "11/06/2018",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 254,
            "PadronUnidad": "235",
            "PlacaUnidad": "AUT-753",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLSKWAGEN",
            "AñoUnidad": 2017,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "17.210OD",
            "AñoUnidad1": 2017,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "1464759",
            "SerieUnidad": "9532X82W8JR804351",
            "ColoresUnidad": "GRIS METALICO MOTADO",
            "PesoBrutoUnidad": 16000.00,
            "CargaUtilUnidad": 5840.00,
            "PesoSecoUnidad": 10160.00,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.60,
            "AlturaUnidad": 3.35,
            "NumAsientos": 39,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "22/01/2018 14:29:56",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "14/12/2020 11:30:08",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 3,
            "FechaInscripcion": "11/06/2018",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 255,
            "PadronUnidad": "236",
            "PlacaUnidad": "AUT-750",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": "TORRES MEZA, NILS ROBERT",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLSKWAGEN",
            "AñoUnidad": 2017,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "17.210OD",
            "AñoUnidad1": 2017,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "1464761",
            "SerieUnidad": "9532X82W9JR805380",
            "ColoresUnidad": "GRIS METALICO MOTADO",
            "PesoBrutoUnidad": 16000.00,
            "CargaUtilUnidad": 5840.00,
            "PesoSecoUnidad": 10160.00,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.60,
            "AlturaUnidad": 3.35,
            "NumAsientos": 39,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "21/01/2018 11:36:37",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "14/12/2020 11:32:01",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 3,
            "FechaInscripcion": "11/06/2018",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 256,
            "PadronUnidad": "237",
            "PlacaUnidad": "AUT-757",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": "LEON ALDABA, TITO",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLSKWAGEN",
            "AñoUnidad": 2017,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "17.210OD",
            "AñoUnidad1": 2017,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "1464760",
            "SerieUnidad": "9532X82W1JR805440",
            "ColoresUnidad": "GRIS METALICO MOTADO",
            "PesoBrutoUnidad": 16000.00,
            "CargaUtilUnidad": 5840.00,
            "PesoSecoUnidad": 10160.00,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.60,
            "AlturaUnidad": 3.35,
            "NumAsientos": 39,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "21/01/2018 12:17:12",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "14/12/2020 11:34:27",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 3,
            "FechaInscripcion": "11/06/2018",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 257,
            "PadronUnidad": "238",
            "PlacaUnidad": "AUT-755",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLSKWAGEN",
            "AñoUnidad": 2017,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "17.210OD",
            "AñoUnidad1": 2017,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "1461415",
            "SerieUnidad": "9532X82W1JR805714",
            "ColoresUnidad": "GRIS METALICO MOTADO",
            "PesoBrutoUnidad": 16000.00,
            "CargaUtilUnidad": 5840.00,
            "PesoSecoUnidad": 10160.00,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.60,
            "AlturaUnidad": 3.35,
            "NumAsientos": 39,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "22/01/2018 16:38:54",
            "CodConcesionario": 1,
            "NomUsuario": "sfuentes@juntaoperadora",
            "FechaEdicion": "24/01/2023 06:16:13",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 3,
            "FechaInscripcion": "11/06/2018",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 258,
            "PadronUnidad": "239",
            "PlacaUnidad": "AUT-754",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLSKWAGEN",
            "AñoUnidad": 2017,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "17.210OD",
            "AñoUnidad1": 2017,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "1463540",
            "SerieUnidad": "9532X82W0JR809415",
            "ColoresUnidad": "GRIS METALICO MOTADO",
            "PesoBrutoUnidad": 16000.00,
            "CargaUtilUnidad": 5840.00,
            "PesoSecoUnidad": 10160.00,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.60,
            "AlturaUnidad": 3.35,
            "NumAsientos": 39,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "24/01/2018 17:23:38",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "14/12/2020 11:36:33",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 3,
            "FechaInscripcion": "11/06/2018",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 259,
            "PadronUnidad": "240",
            "PlacaUnidad": "AUT-949",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": "URQUIA ZELADA, JOSE WALTER",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLSKWAGEN",
            "AñoUnidad": 2017,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "17.210OD",
            "AñoUnidad1": 2017,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "1516061",
            "SerieUnidad": "9532X82W1JR805812",
            "ColoresUnidad": "GRIS METALICO MOTADO",
            "PesoBrutoUnidad": 16000.00,
            "CargaUtilUnidad": 5840.00,
            "PesoSecoUnidad": 10160.00,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.60,
            "AlturaUnidad": 3.35,
            "NumAsientos": 39,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "21/01/2018 19:58:39",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "14/12/2020 11:37:23",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 3,
            "FechaInscripcion": "11/06/2018",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 260,
            "PadronUnidad": "241",
            "PlacaUnidad": "AUY-776",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": "SALCEDO GARCIA, LUIS HOMERO",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "26/01/2018 15:52:51",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "30/01/2018 17:57:57",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 261,
            "PadronUnidad": "242",
            "PlacaUnidad": "AUZ-923",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "26/01/2018 18:16:17",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "26/01/2018 18:17:28",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 262,
            "PadronUnidad": "243",
            "PlacaUnidad": "AUY-777",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "29/01/2018 15:30:32",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "30/01/2018 17:58:30",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 263,
            "PadronUnidad": "244",
            "PlacaUnidad": "AUY-843",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": "COLLANTES PICHON, JUAN ALBERTO",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "29/01/2018 15:31:47",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "30/01/2018 17:58:51",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 264,
            "PadronUnidad": "245",
            "PlacaUnidad": "AUY-844",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": "SILVA ALARCON, CESAR",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "29/01/2018 15:32:18",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "30/01/2018 17:59:08",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 265,
            "PadronUnidad": "246",
            "PlacaUnidad": "AUY-845",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": "GIRON BONIFACIO, DANIEL RAUL",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "29/01/2018 15:33:17",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "30/01/2018 17:59:24",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 266,
            "PadronUnidad": "247",
            "PlacaUnidad": "AUY-879",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": "Oviedo Marquez, Wilmer Wilfredo",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "29/01/2018 15:33:51",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "30/01/2018 17:59:38",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 267,
            "PadronUnidad": "248",
            "PlacaUnidad": "AUY-880",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": "ALVA OLIVARES, FREDY ROLY",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "29/01/2018 15:34:25",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "30/01/2018 17:59:59",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 268,
            "PadronUnidad": "249",
            "PlacaUnidad": "AUY-887",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": "SALVADOR MENDOZA, PERCY",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "29/01/2018 15:34:59",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "30/01/2018 18:00:20",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 269,
            "PadronUnidad": "250",
            "PlacaUnidad": "AUY-888",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": "BENVENUTTO MAGALLANES, EDUARDO NOEL",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "29/01/2018 15:35:41",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "30/01/2018 18:00:39",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 270,
            "PadronUnidad": "251",
            "PlacaUnidad": "AUY-889",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": "REYNOSO PAUCAR, CAYO",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "30/01/2018 10:16:04",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "30/01/2018 18:00:55",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 271,
            "PadronUnidad": "252",
            "PlacaUnidad": "AUY-907",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": "RIZABAL BASALDUA, JOSEPH ENRIQUE",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "30/01/2018 10:16:37",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "30/01/2018 18:01:17",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 272,
            "PadronUnidad": "253",
            "PlacaUnidad": "AUY-916",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "30/01/2018 10:17:05",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "30/01/2018 18:01:32",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 273,
            "PadronUnidad": "254",
            "PlacaUnidad": "AUY-917",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": "FERNANDEZ ZAMORA, JOSYMAR",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "30/01/2018 10:17:38",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "30/01/2018 18:01:48",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 274,
            "PadronUnidad": "255",
            "PlacaUnidad": "AUY-920",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": "TARMEÑO PAREDES, BRIAN",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "30/01/2018 10:18:15",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "30/01/2018 18:02:08",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 275,
            "PadronUnidad": "256",
            "PlacaUnidad": "AUY-921",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": "ALARCON LEGUIA, DAVID SAMUEL",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "30/01/2018 10:18:42",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "30/01/2018 18:02:23",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 276,
            "PadronUnidad": "257",
            "PlacaUnidad": "AUY-924",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": "ROMO CHINCHON, CRISTIHAN JONATAN",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "30/01/2018 13:10:14",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "30/01/2018 18:02:41",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 277,
            "PadronUnidad": "258",
            "PlacaUnidad": "AUZ-700",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "30/01/2018 13:10:42",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "30/01/2018 18:02:56",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 278,
            "PadronUnidad": "259",
            "PlacaUnidad": "AUZ-701",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": "ZARATE MANRIQUE, ALBERT FREDDY",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "30/01/2018 13:12:15",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "30/01/2018 18:03:12",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 279,
            "PadronUnidad": "260",
            "PlacaUnidad": "AUZ-744",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "30/01/2018 13:12:39",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "30/01/2018 18:03:26",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 280,
            "PadronUnidad": "261",
            "PlacaUnidad": "AUZ-745",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": "SILVA ZAMORA, LUIS ALBERTO",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "30/01/2018 13:13:09",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "30/01/2018 18:03:40",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 281,
            "PadronUnidad": "262",
            "PlacaUnidad": "AUZ-746",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": "CORDOVA GARCIA, ROQUELIN",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "30/01/2018 13:14:01",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "30/01/2018 18:04:25",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 282,
            "PadronUnidad": "263",
            "PlacaUnidad": "AUZ-797",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": "SEDANO CUNIBERTTI, ATILIO DANTE",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "30/01/2018 13:14:55",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "30/01/2018 18:04:40",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 283,
            "PadronUnidad": "264",
            "PlacaUnidad": "AUZ-846",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": "ROSADO DEXTRE, VICTOR JESIAS 0",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "30/01/2018 13:15:52",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "30/01/2018 18:05:03",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 284,
            "PadronUnidad": "265",
            "PlacaUnidad": "AUZ-847",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": "MORALES CHINGUEL, FRANKLIN",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "30/01/2018 13:16:20",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "30/01/2018 18:05:21",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 285,
            "PadronUnidad": "266",
            "PlacaUnidad": "AUZ-868",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": "SANCHEZ CERNA, EDWAR JUNIOR",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "30/01/2018 13:16:48",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "30/01/2018 18:05:33",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 286,
            "PadronUnidad": "267",
            "PlacaUnidad": "AUZ-869",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": "Evangelista Roman, Juan Oswaldo",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "30/01/2018 13:17:34",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "30/01/2018 18:05:53",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 287,
            "PadronUnidad": "268",
            "PlacaUnidad": "AUZ-870",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": "CARDENAS TOVAR, JHON EVER",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "30/01/2018 13:17:59",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "30/01/2018 18:06:06",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 288,
            "PadronUnidad": "269",
            "PlacaUnidad": "AUZ-892",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": "LAULATE CUMARI, JORGE ADALBERTO",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "30/01/2018 13:18:27",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "30/01/2018 18:06:18",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 289,
            "PadronUnidad": "270",
            "PlacaUnidad": "AUZ-893",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "30/01/2018 13:18:48",
            "CodConcesionario": 2,
            "NomUsuario": "kbaltazar@sjl",
            "FechaEdicion": "16/11/2018 09:41:25",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 290,
            "PadronUnidad": "271",
            "PlacaUnidad": "AUZ-899",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": "SALAZAR CARRANZA, MIGUEL ANGEL",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "30/01/2018 13:19:12",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "30/01/2018 18:06:50",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 291,
            "PadronUnidad": "272",
            "PlacaUnidad": "AUZ-900",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "30/01/2018 13:20:00",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "30/01/2018 18:07:09",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 292,
            "PadronUnidad": "273",
            "PlacaUnidad": "AUZ-901",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "30/01/2018 13:20:27",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "30/01/2018 18:07:23",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 293,
            "PadronUnidad": "274",
            "PlacaUnidad": "AUZ-907",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": "ALALUNA NAVARRO, WALTER ERNESTO",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "30/01/2018 13:20:59",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "30/01/2018 18:10:35",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 294,
            "PadronUnidad": "275",
            "PlacaUnidad": "AUZ-908",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": "ALMONACID PARAPAR, ELMER JHONNY",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "30/01/2018 13:21:35",
            "CodConcesionario": 2,
            "NomUsuario": "manaya@sjl",
            "FechaEdicion": "19/02/2018 21:42:27",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 295,
            "PadronUnidad": "276",
            "PlacaUnidad": "AUZ-922",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": "CHAVEZ CANO, MIGUEL ANGEL",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "30/01/2018 13:22:00",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "30/01/2018 18:08:21",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 296,
            "PadronUnidad": "277",
            "PlacaUnidad": "AUZ-926",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "30/01/2018 13:23:29",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "30/01/2018 18:08:36",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 297,
            "PadronUnidad": "278",
            "PlacaUnidad": "AUZ-927",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": "QUISPE ASTETE, ARIZ MENDY",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "30/01/2018 13:23:50",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "30/01/2018 18:08:48",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 298,
            "PadronUnidad": "279",
            "PlacaUnidad": "AVA-707",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "30/01/2018 13:24:56",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "30/01/2018 18:09:02",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 299,
            "PadronUnidad": "280",
            "PlacaUnidad": "ACG-759",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "25/06/2018 10:39:50",
            "CodConcesionario": 3,
            "NomUsuario": "Admin@sjl",
            "FechaEdicion": "25/06/2018 10:49:35",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 300,
            "PadronUnidad": "281",
            "PlacaUnidad": "ACF-713",
            "CodEstado": 1,
            "Codigo": 8,
            "Nombre": "SE02",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "25/06/2018 10:56:09",
            "CodConcesionario": 3,
            "NomUsuario": "jore@juntaoperadora",
            "FechaEdicion": "20/07/2018 15:08:28",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 303,
            "PadronUnidad": "282",
            "PlacaUnidad": "AUU-931",
            "CodEstado": 1,
            "Codigo": 6,
            "Nombre": "406",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "21/08/2018 13:36:29",
            "CodConcesionario": 4,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "21/08/2018 13:37:31",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 304,
            "PadronUnidad": "283",
            "PlacaUnidad": "AVH-926",
            "CodEstado": 2,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "21/08/2018 13:38:08",
            "CodConcesionario": 4,
            "NomUsuario": "SCHAVEZ@SJL",
            "FechaEdicion": "28/03/2023 17:05:11",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 305,
            "PadronUnidad": "284",
            "PlacaUnidad": "AUP-795",
            "CodEstado": 2,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "21/08/2018 13:39:31",
            "CodConcesionario": 4,
            "NomUsuario": "SCHAVEZ@SJL",
            "FechaEdicion": "28/03/2023 17:11:41",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 306,
            "PadronUnidad": "285",
            "PlacaUnidad": "AVG-813",
            "CodEstado": 2,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "21/08/2018 13:40:04",
            "CodConcesionario": 4,
            "NomUsuario": "SCHAVEZ@SJL",
            "FechaEdicion": "10/11/2021 17:20:28",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": ""
        },
        {
            "CodUnidad": 307,
            "PadronUnidad": "286",
            "PlacaUnidad": "AUT-761",
            "CodEstado": 1,
            "Codigo": 6,
            "Nombre": "406",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "21/08/2018 13:40:35",
            "CodConcesionario": 4,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "21/08/2018 13:44:58",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 308,
            "PadronUnidad": "287",
            "PlacaUnidad": "AVG-817",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "21/08/2018 13:41:13",
            "CodConcesionario": 4,
            "NomUsuario": "mquispe@sjl",
            "FechaEdicion": "09/06/2023 14:03:12",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 309,
            "PadronUnidad": "288",
            "PlacaUnidad": "AVG-815",
            "CodEstado": 2,
            "Codigo": 7,
            "Nombre": "409",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "21/08/2018 13:41:41",
            "CodConcesionario": 4,
            "NomUsuario": "SCHAVEZ@SJL",
            "FechaEdicion": "28/03/2023 17:16:00",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 310,
            "PadronUnidad": "289",
            "PlacaUnidad": "AUT-764",
            "CodEstado": 2,
            "Codigo": 7,
            "Nombre": "409",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "21/08/2018 13:42:31",
            "CodConcesionario": 4,
            "NomUsuario": "acliente@sjl",
            "FechaEdicion": "24/04/2023 10:39:26",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 311,
            "PadronUnidad": "290",
            "PlacaUnidad": "AVH-927",
            "CodEstado": 2,
            "Codigo": 7,
            "Nombre": "409",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "21/08/2018 13:43:56",
            "CodConcesionario": 4,
            "NomUsuario": "acliente@sjl",
            "FechaEdicion": "24/04/2023 10:39:04",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 301,
            "PadronUnidad": "291",
            "PlacaUnidad": "B3G-707",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "HYUNDAI",
            "AñoUnidad": 2010,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "AERO CITY",
            "AñoUnidad1": 2010,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "C6ACA187692",
            "SerieUnidad": "KMJTA18GPBC900950",
            "ColoresUnidad": "GRIS METALICO MOTADO",
            "PesoBrutoUnidad": 16900.00,
            "CargaUtilUnidad": 5810.00,
            "PesoSecoUnidad": 10990.00,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.53,
            "AlturaUnidad": 3.20,
            "NumAsientos": 45,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "20/08/2018 16:51:59",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "15/12/2020 09:14:03",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "16/08/2019",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 302,
            "PadronUnidad": "292",
            "PlacaUnidad": "B3F-748",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "HYUNDAI",
            "AñoUnidad": 2010,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "AERO CITY",
            "AñoUnidad1": 2010,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "C6ACA185144",
            "SerieUnidad": "KMJTA18GPAC900921",
            "ColoresUnidad": "GRIS METALICO MOTADO",
            "PesoBrutoUnidad": 16900.00,
            "CargaUtilUnidad": 5530.00,
            "PesoSecoUnidad": 10470.00,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.56,
            "AlturaUnidad": 3.30,
            "NumAsientos": 49,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "20/08/2018 16:52:44",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "15/12/2020 09:19:57",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "16/08/2019",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 312,
            "PadronUnidad": "293",
            "PlacaUnidad": "AVC-706",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLSKWAGEN",
            "AñoUnidad": 2017,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "17.210 OD",
            "AñoUnidad1": 2017,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "1516716",
            "SerieUnidad": "9532X82W5JR804193",
            "ColoresUnidad": "GRIS METALICO MOTADO",
            "PesoBrutoUnidad": 16000.00,
            "CargaUtilUnidad": 5840.00,
            "PesoSecoUnidad": 10160.00,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.60,
            "AlturaUnidad": 3.35,
            "NumAsientos": 39,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "24/08/2018 23:03:22",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "15/12/2020 09:32:47",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 3,
            "FechaInscripcion": "04/01/2019",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 313,
            "PadronUnidad": "294",
            "PlacaUnidad": "AVD-754",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLSKWAGEN",
            "AñoUnidad": 2017,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "17.210 OD",
            "AñoUnidad1": 2017,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "1504776",
            "SerieUnidad": "9532X82W2JR806225",
            "ColoresUnidad": "GRIS METALICO MOTADO",
            "PesoBrutoUnidad": 16000.00,
            "CargaUtilUnidad": 5840.00,
            "PesoSecoUnidad": 10160.00,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.60,
            "AlturaUnidad": 3.35,
            "NumAsientos": 39,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "24/08/2018 23:04:59",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "15/12/2020 09:36:34",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 3,
            "FechaInscripcion": "04/01/2019",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 314,
            "PadronUnidad": "295",
            "PlacaUnidad": "AVD-752",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": "LLAMOCCA CANALES, RAUL IGNACIO",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLSKWAGEN",
            "AñoUnidad": 2017,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "17.210 OD",
            "AñoUnidad1": 2017,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "1510707",
            "SerieUnidad": "9532X82W1JR809391",
            "ColoresUnidad": "GRIS METALICO MOTADO",
            "PesoBrutoUnidad": 16000.00,
            "CargaUtilUnidad": 5840.00,
            "PesoSecoUnidad": 10160.00,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.60,
            "AlturaUnidad": 3.35,
            "NumAsientos": 39,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "24/08/2018 23:05:56",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "15/12/2020 09:39:41",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 3,
            "FechaInscripcion": "04/01/2019",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 315,
            "PadronUnidad": "296",
            "PlacaUnidad": "AVD-755",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLSKWAGEN",
            "AñoUnidad": 2017,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "17.210 OD",
            "AñoUnidad1": 2017,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "1515510",
            "SerieUnidad": "9532X82W2JR809609",
            "ColoresUnidad": "GRIS METALICO MOTADO",
            "PesoBrutoUnidad": 16000.00,
            "CargaUtilUnidad": 5840.00,
            "PesoSecoUnidad": 10160.00,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.60,
            "AlturaUnidad": 3.35,
            "NumAsientos": 39,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "24/08/2018 23:17:45",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "15/12/2020 09:53:00",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 3,
            "FechaInscripcion": "04/01/2019",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 316,
            "PadronUnidad": "297",
            "PlacaUnidad": "AVC-727",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": "LAMAS DURAND, DAVID JOSE",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLSKWAGEN",
            "AñoUnidad": 2017,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "17.210 OD",
            "AñoUnidad1": 2017,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "1509550",
            "SerieUnidad": "9532X82W3JR809408",
            "ColoresUnidad": "GRIS METALICO MOTADO",
            "PesoBrutoUnidad": 16000.00,
            "CargaUtilUnidad": 5840.00,
            "PesoSecoUnidad": 10160.00,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.60,
            "AlturaUnidad": 3.35,
            "NumAsientos": 39,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "24/08/2018 23:18:35",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "15/12/2020 09:48:45",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 3,
            "FechaInscripcion": "04/01/2019",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 317,
            "PadronUnidad": "298",
            "PlacaUnidad": "AVD-756",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLSKWAGEN",
            "AñoUnidad": 2017,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "17.210 OD",
            "AñoUnidad1": 2017,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "1516062",
            "SerieUnidad": "9532X82W1JR809987",
            "ColoresUnidad": "GRIS METALICO MOTADO",
            "PesoBrutoUnidad": 16000.00,
            "CargaUtilUnidad": 5840.00,
            "PesoSecoUnidad": 10160.00,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.60,
            "AlturaUnidad": 3.35,
            "NumAsientos": 39,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "24/08/2018 23:19:24",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "15/12/2020 09:46:04",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 3,
            "FechaInscripcion": "04/01/2019",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 318,
            "PadronUnidad": "299",
            "PlacaUnidad": "AVD-753",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLSKWAGEN",
            "AñoUnidad": 2017,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "17.210 OD",
            "AñoUnidad1": 2017,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "1515509",
            "SerieUnidad": "9532X82W2JR806600",
            "ColoresUnidad": "GRIS METALICO MOTADO",
            "PesoBrutoUnidad": 16000.00,
            "CargaUtilUnidad": 5840.00,
            "PesoSecoUnidad": 10160.00,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.60,
            "AlturaUnidad": 3.35,
            "NumAsientos": 39,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "24/08/2018 23:20:56",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "15/12/2020 09:42:35",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 3,
            "FechaInscripcion": "04/01/2019",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 319,
            "PadronUnidad": "300",
            "PlacaUnidad": "F8G-736",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "04/09/2018 17:14:04",
            "CodConcesionario": 3,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "06/09/2018 11:45:14",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 320,
            "PadronUnidad": "301",
            "PlacaUnidad": "F8H-720",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "04/09/2018 17:14:30",
            "CodConcesionario": 3,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "06/09/2018 11:45:27",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 321,
            "PadronUnidad": "302",
            "PlacaUnidad": "F8H-764",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "04/09/2018 17:14:54",
            "CodConcesionario": 3,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "06/09/2018 11:45:38",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 322,
            "PadronUnidad": "303",
            "PlacaUnidad": "F8J-833",
            "CodEstado": 1,
            "Codigo": 7,
            "Nombre": "409",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "04/09/2018 17:17:04",
            "CodConcesionario": 3,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "06/09/2018 11:45:51",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 323,
            "PadronUnidad": "304",
            "PlacaUnidad": "F8K-808",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "04/09/2018 17:17:39",
            "CodConcesionario": 3,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "06/09/2018 11:46:08",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 324,
            "PadronUnidad": "305",
            "PlacaUnidad": "F8H-736",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., PROCERES INTERNACIONAL S.A",
            "CodPersonaPropietario": 338,
            "MarcaUnidad": "GOLDEN DRAGON",
            "AñoUnidad": 2013,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "",
            "AñoUnidad1": 2013,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jore@juntaoperadora",
            "FechaCreacion": "25/09/2018 20:19:00",
            "CodConcesionario": 3,
            "NomUsuario": "jore@juntaoperadora",
            "FechaEdicion": "25/09/2018 20:19:21",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 326,
            "PadronUnidad": "306",
            "PlacaUnidad": "AYY-792",
            "CodEstado": 2,
            "Codigo": 2,
            "Nombre": "999",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "15/01/2019 22:22:07",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "03/08/2019 12:08:43",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": ""
        },
        {
            "CodUnidad": 369,
            "PadronUnidad": "307",
            "PlacaUnidad": "B2B-764",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2010,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF-1721/59",
            "AñoUnidad1": 2010,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377989U0894746",
            "SerieUnidad": "9BM384075BB734068",
            "ColoresUnidad": "MORADO,BLANCO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.84,
            "PesoSecoUnidad": 9.16,
            "LongitudUnidad": 12.33,
            "AnchoUnidad": 2.60,
            "AlturaUnidad": 3.16,
            "NumAsientos": 53,
            "NumPasajeros": 52,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "09/01/2020 17:12:37",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "17/12/2020 15:35:06",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 370,
            "PadronUnidad": "308",
            "PlacaUnidad": "B2C-742",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2010,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF-1721/59",
            "AñoUnidad1": 2010,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377989U0895007",
            "SerieUnidad": "9BM384075BB734331",
            "ColoresUnidad": "MORADO,BLANCO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.83,
            "PesoSecoUnidad": 9.17,
            "LongitudUnidad": 12.33,
            "AnchoUnidad": 2.60,
            "AlturaUnidad": 3.16,
            "NumAsientos": 53,
            "NumPasajeros": 52,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "09/01/2020 17:13:28",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "17/12/2020 15:35:46",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 371,
            "PadronUnidad": "309",
            "PlacaUnidad": "B3G-726",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "HYUNDAI",
            "AñoUnidad": 2010,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "AERO CITY",
            "AñoUnidad1": 2010,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "C6ACA186332",
            "SerieUnidad": "KMJTA18GPBAC900939",
            "ColoresUnidad": "MORADO,BLANCO",
            "PesoBrutoUnidad": 16000.00,
            "CargaUtilUnidad": 5.01,
            "PesoSecoUnidad": 10.99,
            "LongitudUnidad": 11.65,
            "AnchoUnidad": 2.54,
            "AlturaUnidad": 3.20,
            "NumAsientos": 46,
            "NumPasajeros": 45,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "09/01/2020 17:14:44",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "17/12/2020 15:36:34",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 372,
            "PadronUnidad": "310",
            "PlacaUnidad": "AMV-873",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": "LLALLIRE BALDEON, EMIGIO",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2015,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF. 1721/59",
            "AñoUnidad1": 2015,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "924998U1157678",
            "SerieUnidad": "9BM384078GB018276",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": 17000.00,
            "CargaUtilUnidad": 7.32,
            "PesoSecoUnidad": 9.68,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.90,
            "AlturaUnidad": 3.20,
            "NumAsientos": 49,
            "NumPasajeros": 48,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "09/01/2020 18:56:25",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "17/12/2020 15:32:33",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 373,
            "PadronUnidad": "311",
            "PlacaUnidad": "BBB-749",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLSKWAGEN",
            "AñoUnidad": 2019,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "17.210 OD",
            "AñoUnidad1": 2019,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "0165038A295034",
            "SerieUnidad": "9532G82W1KR905863",
            "ColoresUnidad": "GRIS METALICO MOTADO",
            "PesoBrutoUnidad": 17200.00,
            "CargaUtilUnidad": 8854.00,
            "PesoSecoUnidad": 8346.00,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.56,
            "AlturaUnidad": 3.39,
            "NumAsientos": 53,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "28/01/2020 16:17:20",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "18/12/2020 08:58:45",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "07/01/2020",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 374,
            "PadronUnidad": "312",
            "PlacaUnidad": "BBH-898",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLSKWAGEN",
            "AñoUnidad": 2019,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "17.210 OD",
            "AñoUnidad1": 2019,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "0165051A505050",
            "SerieUnidad": "9532G82W4KR906800",
            "ColoresUnidad": "GRIS METALICO MOTADO",
            "PesoBrutoUnidad": 17200.00,
            "CargaUtilUnidad": 8854.00,
            "PesoSecoUnidad": 8346.00,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.56,
            "AlturaUnidad": 3.39,
            "NumAsientos": 53,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "28/01/2020 16:24:25",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "18/12/2020 09:04:43",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 375,
            "PadronUnidad": "313",
            "PlacaUnidad": "BBB-862",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLSKWAGEN",
            "AñoUnidad": 2019,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "07/01/2020",
            "AñoUnidad1": 2019,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "0165038A195034",
            "SerieUnidad": "9532G82W2KR905760",
            "ColoresUnidad": "GRIS METALICO MOTADO",
            "PesoBrutoUnidad": 17200.00,
            "CargaUtilUnidad": 8854.00,
            "PesoSecoUnidad": 8346.00,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.56,
            "AlturaUnidad": 3.39,
            "NumAsientos": 53,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "28/01/2020 19:47:56",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "18/12/2020 09:04:51",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 376,
            "PadronUnidad": "314",
            "PlacaUnidad": "BBB-780",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLSKWAGEN",
            "AñoUnidad": 2019,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "17.210 OD",
            "AñoUnidad1": 2019,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "0165038A185034",
            "SerieUnidad": "9532G82W6KR905986",
            "ColoresUnidad": "GRIS METALICO MOTADO",
            "PesoBrutoUnidad": 17200.00,
            "CargaUtilUnidad": 8854.00,
            "PesoSecoUnidad": 8346.00,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.56,
            "AlturaUnidad": 3.29,
            "NumAsientos": 53,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "28/01/2020 19:49:22",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "18/12/2020 09:06:26",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 377,
            "PadronUnidad": "315",
            "PlacaUnidad": "BBB-778",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLSKWAGEN",
            "AñoUnidad": 2019,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "17.210 OD",
            "AñoUnidad1": 2019,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "0165021A605017",
            "SerieUnidad": "9532G82W6KR903865",
            "ColoresUnidad": "GRIS METALICO MOTADO",
            "PesoBrutoUnidad": 17200.00,
            "CargaUtilUnidad": 8854.00,
            "PesoSecoUnidad": 8346.00,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.56,
            "AlturaUnidad": 3.39,
            "NumAsientos": 83,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "28/01/2020 19:51:12",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "18/12/2020 09:05:05",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "07/01/2020",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 378,
            "PadronUnidad": "316",
            "PlacaUnidad": "BBA-945",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLSKWAGEN",
            "AñoUnidad": 2019,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "17.210 OD",
            "AñoUnidad1": 2019,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "016505A335034",
            "SerieUnidad": "9532G82W7KR905995",
            "ColoresUnidad": "GRIS METALICO MOTADO",
            "PesoBrutoUnidad": 17200.00,
            "CargaUtilUnidad": 8854.00,
            "PesoSecoUnidad": 8346.00,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.56,
            "AlturaUnidad": 3.39,
            "NumAsientos": 53,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "28/01/2020 19:52:41",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "18/12/2020 09:06:05",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "08/01/2020",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 379,
            "PadronUnidad": "317",
            "PlacaUnidad": "BBB-856",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLSKWAGEN",
            "AñoUnidad": 2019,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "17.210 OD",
            "AñoUnidad1": 2019,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "0165033A495026",
            "SerieUnidad": "9532G82W9KR905674",
            "ColoresUnidad": "GRIS METALICO MOTADO",
            "PesoBrutoUnidad": 17200.00,
            "CargaUtilUnidad": 8854.00,
            "PesoSecoUnidad": 8354.00,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.56,
            "AlturaUnidad": 3.39,
            "NumAsientos": 53,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "28/01/2020 19:54:08",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "18/12/2020 09:05:30",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "07/01/2020",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 380,
            "PadronUnidad": "318",
            "PlacaUnidad": "BBC-700",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLSKWAGEN",
            "AñoUnidad": 2019,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "17.210 OD",
            "AñoUnidad1": 2019,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "0165033A525026",
            "SerieUnidad": "9532G82W8KR905634",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": 17200.00,
            "CargaUtilUnidad": 8854.00,
            "PesoSecoUnidad": 8346.00,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.56,
            "AlturaUnidad": 3.39,
            "NumAsientos": 53,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "28/01/2020 19:55:56",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "18/12/2020 09:16:25",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "07/01/2020",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 381,
            "PadronUnidad": "319",
            "PlacaUnidad": "BBC-701",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLSKWAGEN",
            "AñoUnidad": 2019,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "17.210 OD",
            "AñoUnidad1": 2019,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "0165038A205034",
            "SerieUnidad": "9532G82W7KR905771",
            "ColoresUnidad": "GRIS METALICO MOTADO",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "28/01/2020 19:57:16",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "18/12/2020 09:41:34",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "07/01/2020",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 382,
            "PadronUnidad": "321",
            "PlacaUnidad": "A6D-701",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": "LURITA DAVIRAN, MANUEL ERNESTO",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "HYUNDAI",
            "AñoUnidad": 2010,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "AERO CITY",
            "AñoUnidad1": 2010,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "C6ACA186404",
            "SerieUnidad": "KMJTA18GPAC900926",
            "ColoresUnidad": "MORADO,BLANCO",
            "PesoBrutoUnidad": 16.00,
            "CargaUtilUnidad": 5.01,
            "PesoSecoUnidad": 10.99,
            "LongitudUnidad": 11.65,
            "AnchoUnidad": 2.54,
            "AlturaUnidad": 3.20,
            "NumAsientos": 46,
            "NumPasajeros": 45,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "14/02/2020 17:11:20",
            "CodConcesionario": 1,
            "NomUsuario": "ZROMERO@SJL",
            "FechaEdicion": "22/12/2021 14:41:56",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 383,
            "PadronUnidad": "321",
            "PlacaUnidad": "B3I-709",
            "CodEstado": 2,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "HYUNDAI",
            "AñoUnidad": 2010,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "AERO CITY",
            "AñoUnidad1": 2010,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "C6ACA186404",
            "SerieUnidad": "KMJTA18GPAC900926",
            "ColoresUnidad": "MORADO,BLANCO",
            "PesoBrutoUnidad": 16.00,
            "CargaUtilUnidad": 5.01,
            "PesoSecoUnidad": 10.99,
            "LongitudUnidad": 11.65,
            "AnchoUnidad": 2.54,
            "AlturaUnidad": 3.20,
            "NumAsientos": 46,
            "NumPasajeros": 45,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "14/02/2020 17:19:14",
            "CodConcesionario": 1,
            "NomUsuario": "SCHAVEZ@SJL",
            "FechaEdicion": "10/11/2021 17:18:00",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "27/12/2019",
            "EstadoGTU": "",
            "NomSituacion": ""
        },
        {
            "CodUnidad": 384,
            "PadronUnidad": "322",
            "PlacaUnidad": "AMU-737",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": "ROMERO ARCE, JULIO CESIL",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2015,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF-1721/59",
            "AñoUnidad1": 2015,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "924998U1158185",
            "SerieUnidad": "9BM384078GB019179",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.20,
            "PesoSecoUnidad": 9.81,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.56,
            "AlturaUnidad": 3.20,
            "NumAsientos": 49,
            "NumPasajeros": 48,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "14/02/2020 17:20:51",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "17/12/2020 15:29:53",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "12/02/2020",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 385,
            "PadronUnidad": "323",
            "PlacaUnidad": "AMV-816",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": "HUILLCA LUNA, VICTOR ARMANDO",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2015,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF-1721/59",
            "AñoUnidad1": 2015,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "924998U1157878",
            "SerieUnidad": "9BM384078GB018556",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.32,
            "PesoSecoUnidad": 9.69,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.50,
            "AlturaUnidad": 3.28,
            "NumAsientos": 49,
            "NumPasajeros": 48,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "14/02/2020 17:29:34",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "17/12/2020 15:32:06",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "12/02/2020",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 386,
            "PadronUnidad": "324",
            "PlacaUnidad": "AMW-808",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2015,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF 1721/59",
            "AñoUnidad1": 2015,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "924998U1157747",
            "SerieUnidad": "9BM384078GB018558",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.32,
            "PesoSecoUnidad": 9.66,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.50,
            "AlturaUnidad": 3.20,
            "NumAsientos": 49,
            "NumPasajeros": 48,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "14/02/2020 17:36:21",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "17/12/2020 15:33:12",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "12/02/2020",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 387,
            "PadronUnidad": "325",
            "PlacaUnidad": "AMU-875",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": "TINTA HILARIO, LUIS ENRIQUE",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2015,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF-1721/59",
            "AñoUnidad1": 2015,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "924998U1157868",
            "SerieUnidad": "9BM384078GB018546",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 1.32,
            "PesoSecoUnidad": 9.68,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.80,
            "AlturaUnidad": 3.20,
            "NumAsientos": 49,
            "NumPasajeros": 48,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "14/02/2020 17:38:35",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "17/12/2020 15:31:04",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 388,
            "PadronUnidad": "326",
            "PlacaUnidad": "AMU-897",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2015,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF. 1721/59",
            "AñoUnidad1": 2015,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "924998U1157742",
            "SerieUnidad": "9BM384078CB018294",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.32,
            "PesoSecoUnidad": 9.88,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.50,
            "AlturaUnidad": 3.20,
            "NumAsientos": 49,
            "NumPasajeros": 48,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "14/02/2020 17:40:19",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "17/12/2020 15:31:40",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "12/02/2020",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 389,
            "PadronUnidad": "327",
            "PlacaUnidad": "AMX-840",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2015,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF 1721/59",
            "AñoUnidad1": 2015,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "924998U1157690",
            "SerieUnidad": "9BM384078GB018263",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.32,
            "PesoSecoUnidad": 9.68,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.50,
            "AlturaUnidad": 3.20,
            "NumAsientos": 49,
            "NumPasajeros": 48,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "14/02/2020 17:46:57",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "17/12/2020 15:33:43",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "12/02/2020",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 390,
            "PadronUnidad": "328",
            "PlacaUnidad": "AMX-847",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2015,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF.1721/59",
            "AñoUnidad1": 2015,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "924998U1158211",
            "SerieUnidad": "9BM384078GB019186",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.32,
            "PesoSecoUnidad": 9.68,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.50,
            "AlturaUnidad": 3.20,
            "NumAsientos": 19,
            "NumPasajeros": 18,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "14/02/2020 17:48:21",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "17/12/2020 15:34:03",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "12/02/2020",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 391,
            "PadronUnidad": "329",
            "PlacaUnidad": "AMU-782",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": "VASQUEZ BAUTISTA, RICARDO",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2015,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF. 1721/59",
            "AñoUnidad1": 2015,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "924998U1157845",
            "SerieUnidad": "924998U1157845",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.32,
            "PesoSecoUnidad": 9.68,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.50,
            "AlturaUnidad": 3.20,
            "NumAsientos": 49,
            "NumPasajeros": 48,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "14/02/2020 17:50:18",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "17/12/2020 15:30:23",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "12/02/2020",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 392,
            "PadronUnidad": "330",
            "PlacaUnidad": "BBC-702",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLSKWAGEN",
            "AñoUnidad": 2019,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "17.230.OD",
            "AñoUnidad1": 2019,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "0165035A395034",
            "SerieUnidad": "9532G82W3KR905671",
            "ColoresUnidad": "GRIS METALICO MOTADO",
            "PesoBrutoUnidad": 17200.00,
            "CargaUtilUnidad": 8854.00,
            "PesoSecoUnidad": 8346.00,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.56,
            "AlturaUnidad": 3.27,
            "NumAsientos": 53,
            "NumPasajeros": 80,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "17/02/2020 14:40:03",
            "CodConcesionario": 1,
            "NomUsuario": "rlarosa@cefesa",
            "FechaEdicion": "18/12/2020 09:42:25",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "07/01/2020",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 393,
            "PadronUnidad": "331",
            "PlacaUnidad": "AUV-787",
            "CodEstado": 2,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., SANTA CATALINA S.A",
            "CodPersonaPropietario": 339,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "24/06/2021 11:05:15",
            "CodConcesionario": 4,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "30/06/2021 09:27:39",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": ""
        },
        {
            "CodUnidad": 394,
            "PadronUnidad": "332",
            "PlacaUnidad": "FSS-909",
            "CodEstado": 2,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "ryalta@sjl",
            "FechaCreacion": "25/09/2021 10:52:32",
            "CodConcesionario": 1,
            "NomUsuario": "ryalta@sjl",
            "FechaEdicion": "16/10/2021 10:27:11",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "2",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": ""
        },
        {
            "CodUnidad": 396,
            "PadronUnidad": "333",
            "PlacaUnidad": "F5S-909",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "MERCEDES BENZ",
            "AñoUnidad": 2005,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "OF-1721/59",
            "AñoUnidad1": 2005,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "377973U0659574",
            "SerieUnidad": "9BM3840735B454612",
            "ColoresUnidad": "AMARILLO,BLANCO,ANARANJADO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.40,
            "PesoSecoUnidad": 9.60,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": 2.55,
            "AlturaUnidad": 3.20,
            "NumAsientos": 47,
            "NumPasajeros": 46,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "ryalta@sjl",
            "FechaCreacion": "16/10/2021 10:23:30",
            "CodConcesionario": 1,
            "NomUsuario": "avillarreal@sjl",
            "FechaEdicion": "25/08/2022 21:25:24",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "null",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "02/05/2006",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 397,
            "PadronUnidad": "334",
            "PlacaUnidad": "F3S-873",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": "SOSA CONCHA, JORGE ANTONIO",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "IVECO",
            "AñoUnidad": 2008,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "CC 170E22",
            "AñoUnidad1": 2008,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "F4AE0681D6009795",
            "SerieUnidad": "8ATA1PF008X065320",
            "ColoresUnidad": "ROJO,CREMA,ANARANJADO,AMARILLO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.81,
            "PesoSecoUnidad": 9.19,
            "LongitudUnidad": 12.20,
            "AnchoUnidad": 2.53,
            "AlturaUnidad": 3.18,
            "NumAsientos": 48,
            "NumPasajeros": 49,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "acliente@sjl",
            "FechaCreacion": "01/03/2022 15:58:00",
            "CodConcesionario": 1,
            "NomUsuario": "avillarreal@sjl",
            "FechaEdicion": "25/08/2022 21:19:35",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "null",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "10/08/2010",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 398,
            "PadronUnidad": "335",
            "PlacaUnidad": "F3S-872",
            "CodEstado": 1,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "IVECO",
            "AñoUnidad": 2008,
            "CombustibleUnidad": "PETROLEO",
            "ModeloUnidad": "CC 170E22",
            "AñoUnidad1": 2008,
            "ClaseUnidad": "OMNIBUS",
            "CombustibleUnidad1": "PETROLEO",
            "MotorUnidad": "F4AE0681D6009802",
            "SerieUnidad": "8ATA1PF008X065318",
            "ColoresUnidad": "ROJO,CREMA,ANARANJADO,AMARILLO",
            "PesoBrutoUnidad": 17.00,
            "CargaUtilUnidad": 7.72,
            "PesoSecoUnidad": 9.28,
            "LongitudUnidad": 12.20,
            "AnchoUnidad": 2.53,
            "AlturaUnidad": 3.18,
            "NumAsientos": 48,
            "NumPasajeros": 49,
            "NumEjes": 2,
            "NumRuedas": null,
            "NomUsuarioCreacion": "acliente@sjl",
            "FechaCreacion": "01/03/2022 17:17:44",
            "CodConcesionario": 1,
            "NomUsuario": "avillarreal@sjl",
            "FechaEdicion": "25/08/2022 21:19:44",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "1~2^2~2",
            "EstadoDeuda": "null",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 2,
            "FechaInscripcion": "10/08/2010",
            "EstadoGTU": "ACTIVO",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 400,
            "PadronUnidad": "336",
            "PlacaUnidad": "BCH-719",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLKSWAGEN",
            "AñoUnidad": 2019,
            "CombustibleUnidad": "DIESEL",
            "ModeloUnidad": "17.230 OD",
            "AñoUnidad1": 2019,
            "ClaseUnidad": "OMNIBUS URBANO",
            "CombustibleUnidad1": "DIESEL",
            "MotorUnidad": "0164974A364970",
            "SerieUnidad": "9532G82W9KR900085",
            "ColoresUnidad": "MORADO,CRIS METALICO",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "ryalta@sjl",
            "FechaCreacion": "25/08/2022 16:01:59",
            "CodConcesionario": 1,
            "NomUsuario": "avillarreal@sjl",
            "FechaEdicion": "25/08/2022 21:15:53",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "null",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 401,
            "PadronUnidad": "337",
            "PlacaUnidad": "BCH-847",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLKSWAGEN",
            "AñoUnidad": 2019,
            "CombustibleUnidad": "DIESEL",
            "ModeloUnidad": "17.230 OD",
            "AñoUnidad1": 2019,
            "ClaseUnidad": "OMNIBUS URBANO",
            "CombustibleUnidad1": "DIESEL",
            "MotorUnidad": "0164987A134976",
            "SerieUnidad": "9532G82W4KR9013399",
            "ColoresUnidad": "MORADO,CRIS METALICO",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "ryalta@sjl",
            "FechaCreacion": "25/08/2022 16:17:35",
            "CodConcesionario": 1,
            "NomUsuario": "avillarreal@sjl",
            "FechaEdicion": "25/08/2022 21:28:13",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "null",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 402,
            "PadronUnidad": "338",
            "PlacaUnidad": "BCH-923",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLKSWAGEN",
            "AñoUnidad": 2019,
            "CombustibleUnidad": "DIESEL",
            "ModeloUnidad": "17.230 OD",
            "AñoUnidad1": 2019,
            "ClaseUnidad": "OMNIBUS URBANO",
            "CombustibleUnidad1": "DIESEL",
            "MotorUnidad": "0164980A164976",
            "SerieUnidad": "9532G82W7KR901123",
            "ColoresUnidad": "MORADO,CRIS METALICO",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "ryalta@sjl",
            "FechaCreacion": "25/08/2022 16:19:30",
            "CodConcesionario": 1,
            "NomUsuario": "avillarreal@sjl",
            "FechaEdicion": "25/08/2022 21:17:29",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "null",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 403,
            "PadronUnidad": "339",
            "PlacaUnidad": "BCH-734",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLKSWAGEN",
            "AñoUnidad": 2019,
            "CombustibleUnidad": "DIESEL",
            "ModeloUnidad": "17.230 OD",
            "AñoUnidad1": 2019,
            "ClaseUnidad": "OMNIBUS URBANO",
            "CombustibleUnidad1": "DIESEL",
            "MotorUnidad": "0164974A394970",
            "SerieUnidad": "9532G82W1KR900596",
            "ColoresUnidad": "MORADO,CRIS METALICO",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "ryalta@sjl",
            "FechaCreacion": "25/08/2022 16:22:00",
            "CodConcesionario": 1,
            "NomUsuario": "avillarreal@sjl",
            "FechaEdicion": "25/08/2022 21:25:01",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "null",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 404,
            "PadronUnidad": "340",
            "PlacaUnidad": "BCH-925",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., FUTURO EXPRESS S.A",
            "CodPersonaPropietario": 336,
            "MarcaUnidad": "VOLKSWAGEN",
            "AñoUnidad": 2019,
            "CombustibleUnidad": "DIESEL",
            "ModeloUnidad": "17.230 OD",
            "AñoUnidad1": 2019,
            "ClaseUnidad": "OMNIBUS URBANO",
            "CombustibleUnidad1": "DIESEL",
            "MotorUnidad": "0164974A434970",
            "SerieUnidad": "9532G82W1KR900680",
            "ColoresUnidad": "MORADO,CRIS METALICO",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "ryalta@sjl",
            "FechaCreacion": "25/08/2022 16:32:19",
            "CodConcesionario": 1,
            "NomUsuario": "avillarreal@sjl",
            "FechaEdicion": "25/08/2022 21:30:14",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "null",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 406,
            "PadronUnidad": "341",
            "PlacaUnidad": "B3K-712",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": "",
            "CodPersonaPropietario": null,
            "MarcaUnidad": "HYUNDAI",
            "AñoUnidad": 2010,
            "CombustibleUnidad": "GNV",
            "ModeloUnidad": "SUPER AERO CITY ARTICULATED BUS",
            "AñoUnidad1": 2010,
            "ClaseUnidad": "ARTICULADO",
            "CombustibleUnidad1": "GNV",
            "MotorUnidad": "C6AE186604",
            "SerieUnidad": "KMJRK18EPAC999901",
            "ColoresUnidad": "GRIS METALICO MORADO",
            "PesoBrutoUnidad": 29.50,
            "CargaUtilUnidad": 10.44,
            "PesoSecoUnidad": 19.06,
            "LongitudUnidad": 18.40,
            "AnchoUnidad": 2.50,
            "AlturaUnidad": 3.25,
            "NumAsientos": 43,
            "NumPasajeros": 42,
            "NumEjes": 3,
            "NumRuedas": null,
            "NomUsuarioCreacion": "ypolanco@cna",
            "FechaCreacion": "13/02/2024 16:22:21",
            "CodConcesionario": 1,
            "NomUsuario": null,
            "FechaEdicion": null,
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0^0~0^0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "27/12/2011",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 83,
            "PadronUnidad": "4051",
            "PlacaUnidad": "AB1-405",
            "CodEstado": 2,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": "RICRA DELZO, BERNABE",
            "CodPersonaPropietario": 18,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "rsegura@sjl",
            "FechaCreacion": "01/01/2017 00:00:00",
            "CodConcesionario": 1,
            "NomUsuario": null,
            "FechaEdicion": null,
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": ""
        },
        {
            "CodUnidad": 330,
            "PadronUnidad": "800",
            "PlacaUnidad": "AYX-786",
            "CodEstado": 1,
            "Codigo": 6,
            "Nombre": "406",
            "CodValidador": null,
            "NombrePersonaConductor": "PEREZ SALAS JAVIERN CARLOS, JAVIERN CARLOS",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "03/08/2019 11:18:38",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "03/08/2019 11:23:14",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 328,
            "PadronUnidad": "801",
            "PlacaUnidad": "AYX-787",
            "CodEstado": 1,
            "Codigo": 6,
            "Nombre": "406",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "03/08/2019 10:40:17",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "03/08/2019 11:23:41",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 331,
            "PadronUnidad": "802",
            "PlacaUnidad": "AYX-945",
            "CodEstado": 1,
            "Codigo": 9,
            "Nombre": "SE06",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "03/08/2019 11:20:12",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "03/08/2019 11:24:01",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 332,
            "PadronUnidad": "803",
            "PlacaUnidad": "AYX-946",
            "CodEstado": 1,
            "Codigo": 6,
            "Nombre": "406",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "03/08/2019 11:21:34",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "03/08/2019 11:24:29",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 333,
            "PadronUnidad": "804",
            "PlacaUnidad": "AYX-947",
            "CodEstado": 1,
            "Codigo": 6,
            "Nombre": "406",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "03/08/2019 11:35:07",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "03/08/2019 11:35:41",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 334,
            "PadronUnidad": "805",
            "PlacaUnidad": "AYX-948",
            "CodEstado": 1,
            "Codigo": 6,
            "Nombre": "406",
            "CodValidador": null,
            "NombrePersonaConductor": "AUDANTE GARCIA, JONATHAN ROY",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "03/08/2019 11:54:01",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "03/08/2019 11:54:47",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 335,
            "PadronUnidad": "806",
            "PlacaUnidad": "AYY-714",
            "CodEstado": 1,
            "Codigo": 9,
            "Nombre": "SE06",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "03/08/2019 11:56:01",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "03/08/2019 11:56:59",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 336,
            "PadronUnidad": "807",
            "PlacaUnidad": "AYY-715",
            "CodEstado": 1,
            "Codigo": 6,
            "Nombre": "406",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "03/08/2019 11:59:23",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "03/08/2019 11:59:54",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 337,
            "PadronUnidad": "808",
            "PlacaUnidad": "AYY-716",
            "CodEstado": 1,
            "Codigo": 6,
            "Nombre": "406",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "03/08/2019 12:01:44",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "03/08/2019 12:01:59",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 338,
            "PadronUnidad": "809",
            "PlacaUnidad": "AYY-739",
            "CodEstado": 1,
            "Codigo": 6,
            "Nombre": "406",
            "CodValidador": null,
            "NombrePersonaConductor": "ORCOTUMA TORRES, JUAN ROBERT",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "03/08/2019 12:03:31",
            "CodConcesionario": 2,
            "NomUsuario": "mquispe@sjl",
            "FechaEdicion": "22/08/2023 10:29:22",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 329,
            "PadronUnidad": "810",
            "PlacaUnidad": "AYY-740",
            "CodEstado": 1,
            "Codigo": 9,
            "Nombre": "SE06",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "03/08/2019 10:45:07",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "03/08/2019 12:04:57",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 339,
            "PadronUnidad": "811",
            "PlacaUnidad": "AYY-791",
            "CodEstado": 1,
            "Codigo": 6,
            "Nombre": "406",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "03/08/2019 12:06:25",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "03/08/2019 12:06:58",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 340,
            "PadronUnidad": "812",
            "PlacaUnidad": "AYY-792",
            "CodEstado": 1,
            "Codigo": 6,
            "Nombre": "406",
            "CodValidador": null,
            "NombrePersonaConductor": "PRINCIPE TIMO, MILLER MAURO",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "03/08/2019 12:09:33",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "03/08/2019 12:10:18",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 341,
            "PadronUnidad": "813",
            "PlacaUnidad": "AYY-793",
            "CodEstado": 1,
            "Codigo": 6,
            "Nombre": "406",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "03/08/2019 12:14:42",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "03/08/2019 12:14:56",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 342,
            "PadronUnidad": "814",
            "PlacaUnidad": "AYY-794",
            "CodEstado": 1,
            "Codigo": 9,
            "Nombre": "SE06",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "03/08/2019 12:16:42",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "03/08/2019 12:17:07",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 343,
            "PadronUnidad": "815",
            "PlacaUnidad": "AYY-795",
            "CodEstado": 1,
            "Codigo": 9,
            "Nombre": "SE06",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "03/08/2019 12:18:52",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "03/08/2019 12:19:21",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 344,
            "PadronUnidad": "816",
            "PlacaUnidad": "AYY-851",
            "CodEstado": 1,
            "Codigo": 6,
            "Nombre": "406",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "03/08/2019 12:21:17",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "03/08/2019 12:21:52",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 345,
            "PadronUnidad": "817",
            "PlacaUnidad": "AYY-871",
            "CodEstado": 1,
            "Codigo": 6,
            "Nombre": "406",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "03/08/2019 12:25:13",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "03/08/2019 12:26:39",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 346,
            "PadronUnidad": "818",
            "PlacaUnidad": "AYY-874",
            "CodEstado": 1,
            "Codigo": 6,
            "Nombre": "406",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "03/08/2019 12:27:48",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "03/08/2019 12:28:17",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 327,
            "PadronUnidad": "819",
            "PlacaUnidad": "AYY-876",
            "CodEstado": 1,
            "Codigo": 6,
            "Nombre": "406",
            "CodValidador": null,
            "NombrePersonaConductor": "MAVILA GALVEZ, LUIS",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "03/08/2019 10:27:36",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "03/08/2019 12:29:23",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 347,
            "PadronUnidad": "820",
            "PlacaUnidad": "AYY-877",
            "CodEstado": 1,
            "Codigo": 6,
            "Nombre": "406",
            "CodValidador": null,
            "NombrePersonaConductor": "RODRIGO MONROY, ENRIQUE",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "03/08/2019 12:30:19",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "03/08/2019 12:30:47",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 348,
            "PadronUnidad": "821",
            "PlacaUnidad": "AYZ-760",
            "CodEstado": 1,
            "Codigo": 9,
            "Nombre": "SE06",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "03/08/2019 12:31:48",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "03/08/2019 12:32:25",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 349,
            "PadronUnidad": "822",
            "PlacaUnidad": "AZB-752",
            "CodEstado": 1,
            "Codigo": 6,
            "Nombre": "406",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "03/08/2019 12:33:34",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "03/08/2019 12:34:01",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 350,
            "PadronUnidad": "823",
            "PlacaUnidad": "AZB-779",
            "CodEstado": 1,
            "Codigo": 6,
            "Nombre": "406",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "03/08/2019 12:35:11",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "03/08/2019 12:35:37",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 351,
            "PadronUnidad": "824",
            "PlacaUnidad": "AZB-822",
            "CodEstado": 1,
            "Codigo": 6,
            "Nombre": "406",
            "CodValidador": null,
            "NombrePersonaConductor": "ESCALANTE CALDERON, MIGUEL ANGEL",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "03/08/2019 12:37:46",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "03/08/2019 12:38:07",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 352,
            "PadronUnidad": "825",
            "PlacaUnidad": "AZC-705",
            "CodEstado": 1,
            "Codigo": 9,
            "Nombre": "SE06",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "03/08/2019 12:39:23",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "03/08/2019 12:39:50",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 353,
            "PadronUnidad": "826",
            "PlacaUnidad": "AZC-865",
            "CodEstado": 1,
            "Codigo": 6,
            "Nombre": "406",
            "CodValidador": null,
            "NombrePersonaConductor": "MARTINEZ MUÑOZ, SELAHS",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "03/08/2019 12:41:09",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "03/08/2019 12:41:36",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 354,
            "PadronUnidad": "827",
            "PlacaUnidad": "AZD-868",
            "CodEstado": 1,
            "Codigo": 6,
            "Nombre": "406",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "03/08/2019 12:42:48",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "03/08/2019 12:43:14",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 355,
            "PadronUnidad": "828",
            "PlacaUnidad": "AZE-872",
            "CodEstado": 1,
            "Codigo": 6,
            "Nombre": "406",
            "CodValidador": null,
            "NombrePersonaConductor": "PEREZ SOTO, TEOFILO",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "03/08/2019 12:44:19",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "03/08/2019 12:44:47",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 356,
            "PadronUnidad": "829",
            "PlacaUnidad": "AZE-878",
            "CodEstado": 1,
            "Codigo": 6,
            "Nombre": "406",
            "CodValidador": null,
            "NombrePersonaConductor": "Arellano Hidalgo, Ronald",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "03/08/2019 12:46:26",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "03/08/2019 12:46:46",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 357,
            "PadronUnidad": "830",
            "PlacaUnidad": "AZF-776",
            "CodEstado": 1,
            "Codigo": 9,
            "Nombre": "SE06",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "03/08/2019 12:47:48",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "03/08/2019 12:48:10",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 358,
            "PadronUnidad": "831",
            "PlacaUnidad": "AZF-882",
            "CodEstado": 1,
            "Codigo": 6,
            "Nombre": "406",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "03/08/2019 12:49:39",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "03/08/2019 12:50:03",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 359,
            "PadronUnidad": "832",
            "PlacaUnidad": "AZF-909",
            "CodEstado": 1,
            "Codigo": 9,
            "Nombre": "SE06",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "03/08/2019 12:51:25",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "03/08/2019 12:51:49",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 360,
            "PadronUnidad": "833",
            "PlacaUnidad": "AZF-941",
            "CodEstado": 1,
            "Codigo": 6,
            "Nombre": "406",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "03/08/2019 13:03:16",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "03/08/2019 13:03:39",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 361,
            "PadronUnidad": "834",
            "PlacaUnidad": "AZO-920",
            "CodEstado": 1,
            "Codigo": 6,
            "Nombre": "406",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "03/08/2019 13:04:56",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "03/08/2019 13:05:25",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 362,
            "PadronUnidad": "835",
            "PlacaUnidad": "AZP-729",
            "CodEstado": 1,
            "Codigo": 9,
            "Nombre": "SE06",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "03/08/2019 13:06:39",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "03/08/2019 13:07:17",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 363,
            "PadronUnidad": "836",
            "PlacaUnidad": "AZP-801",
            "CodEstado": 1,
            "Codigo": 6,
            "Nombre": "406",
            "CodValidador": null,
            "NombrePersonaConductor": "SANTOS CAPERA, WILSON ALDERMAR",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "03/08/2019 13:08:24",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "03/08/2019 13:08:56",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 364,
            "PadronUnidad": "837",
            "PlacaUnidad": "AZP-809",
            "CodEstado": 1,
            "Codigo": 6,
            "Nombre": "406",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "03/08/2019 13:10:49",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "03/08/2019 13:17:25",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 365,
            "PadronUnidad": "838",
            "PlacaUnidad": "AZQ-729",
            "CodEstado": 1,
            "Codigo": 6,
            "Nombre": "406",
            "CodValidador": null,
            "NombrePersonaConductor": "RUBIO REYNOSO, MIGUEL ANGEL",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "03/08/2019 13:20:08",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "03/08/2019 13:20:52",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 366,
            "PadronUnidad": "839",
            "PlacaUnidad": "AZS-759",
            "CodEstado": 1,
            "Codigo": 6,
            "Nombre": "406",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "03/08/2019 13:22:14",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "03/08/2019 13:22:39",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 367,
            "PadronUnidad": "840",
            "PlacaUnidad": "AZV-900",
            "CodEstado": 1,
            "Codigo": 6,
            "Nombre": "406",
            "CodValidador": null,
            "NombrePersonaConductor": "VILLACORTA ALEJANDRO, DANILO MANUEL",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 1,
            "Produccion": null,
            "Estado": 1,
            "Propietario": ". ., NUEVA ALTERNATIVA S.A",
            "CodPersonaPropietario": 337,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "jmeneses@sjl",
            "FechaCreacion": "03/08/2019 13:35:01",
            "CodConcesionario": 2,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "03/08/2019 13:35:35",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 405,
            "PadronUnidad": "841",
            "PlacaUnidad": "BCH-866",
            "CodEstado": 1,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": "",
            "CodPersonaPropietario": null,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "SCHAVEZ@SJL",
            "FechaCreacion": "03/09/2022 09:11:30",
            "CodConcesionario": 1,
            "NomUsuario": null,
            "FechaEdicion": null,
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "01/01/1900",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 52,
            "PadronUnidad": "900",
            "PlacaUnidad": "AMA-000",
            "CodEstado": 2,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": "RICRA DELZO, BERNABE",
            "CodPersonaPropietario": 18,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "rsegura@sjl",
            "FechaCreacion": "01/01/2017 00:00:00",
            "CodConcesionario": 1,
            "NomUsuario": "jmeneses@sjl",
            "FechaEdicion": "26/01/2018 15:39:15",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": ""
        },
        {
            "CodUnidad": 325,
            "PadronUnidad": "999",
            "PlacaUnidad": "ELI-NAR",
            "CodEstado": 2,
            "Codigo": 1,
            "Nombre": "412",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 2,
            "Propietario": "PAZ VALLE, JORGE",
            "CodPersonaPropietario": 3,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "02/01/2019 11:52:53",
            "CodConcesionario": 1,
            "NomUsuario": "Admin@sjl",
            "FechaEdicion": "02/01/2019 11:53:21",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": ""
        },
        {
            "CodUnidad": 49,
            "PadronUnidad": "AB1405",
            "PlacaUnidad": "ALCAZAR B",
            "CodEstado": 1,
            "Codigo": 2,
            "Nombre": "999",
            "CodValidador": null,
            "NombrePersonaConductor": "CALDAS VERGARAY, JULISSA ESTHER",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": "RICRA DELZO, BERNABE",
            "CodPersonaPropietario": 18,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "23/04/2016 00:00:00",
            "CodConcesionario": 1,
            "NomUsuario": null,
            "FechaEdicion": null,
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 51,
            "PadronUnidad": "AB2402",
            "PlacaUnidad": "SAN CARLOS B",
            "CodEstado": 1,
            "Codigo": 2,
            "Nombre": "999",
            "CodValidador": null,
            "NombrePersonaConductor": "GOMEZ LUNA, JACKIE MELINA",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": "RICRA DELZO, BERNABE",
            "CodPersonaPropietario": 18,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "23/04/2016 00:00:00",
            "CodConcesionario": 1,
            "NomUsuario": null,
            "FechaEdicion": null,
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 84,
            "PadronUnidad": "AB2403",
            "PlacaUnidad": "AB2-402",
            "CodEstado": 1,
            "Codigo": 3,
            "Nombre": "PISO",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": "RICRA DELZO, BERNABE",
            "CodPersonaPropietario": 18,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "rsegura@sjl",
            "FechaCreacion": "01/01/2017 00:00:00",
            "CodConcesionario": 1,
            "NomUsuario": null,
            "FechaEdicion": null,
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 1,
            "PadronUnidad": "P11",
            "PlacaUnidad": "PL01",
            "CodEstado": 2,
            "Codigo": 5,
            "Nombre": "405",
            "CodValidador": 1,
            "NombrePersonaConductor": null,
            "CajaAperturada": 1,
            "Apertura": "13:08",
            "Sesion": 0,
            "Produccion": 7.50,
            "Estado": 2,
            "Propietario": "",
            "CodPersonaPropietario": null,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "23/04/2016 00:00:00",
            "CodConcesionario": 1,
            "NomUsuario": null,
            "FechaEdicion": null,
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": ""
        },
        {
            "CodUnidad": 46,
            "PadronUnidad": "PI01",
            "PlacaUnidad": "PIZARRO A",
            "CodEstado": 1,
            "Codigo": 3,
            "Nombre": "PISO",
            "CodValidador": null,
            "NombrePersonaConductor": "FALCON AYALA, FELIX",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": "RICRA DELZO, BERNABE",
            "CodPersonaPropietario": 18,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "23/04/2016 00:00:00",
            "CodConcesionario": 1,
            "NomUsuario": "VHUARCAYA@SJL",
            "FechaEdicion": "13/07/2018 12:45:39",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 47,
            "PadronUnidad": "PI02",
            "PlacaUnidad": "PIZARRO B",
            "CodEstado": 1,
            "Codigo": 3,
            "Nombre": "PISO",
            "CodValidador": null,
            "NombrePersonaConductor": "FALCON AYALA, FELIX",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": "RICRA DELZO, BERNABE",
            "CodPersonaPropietario": 18,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "23/04/2016 00:00:00",
            "CodConcesionario": 1,
            "NomUsuario": null,
            "FechaEdicion": null,
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 48,
            "PadronUnidad": "PI03",
            "PlacaUnidad": "ALCAZAR A",
            "CodEstado": 1,
            "Codigo": 3,
            "Nombre": "PISO",
            "CodValidador": null,
            "NombrePersonaConductor": "MESTANZA ALCANTARA, JOSE",
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": "RICRA DELZO, BERNABE",
            "CodPersonaPropietario": 18,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": null,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "23/04/2016 00:00:00",
            "CodConcesionario": 1,
            "NomUsuario": null,
            "FechaEdicion": null,
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        },
        {
            "CodUnidad": 50,
            "PadronUnidad": "PI05",
            "PlacaUnidad": "SAN CARLOS A",
            "CodEstado": 1,
            "Codigo": 4,
            "Nombre": "404",
            "CodValidador": null,
            "NombrePersonaConductor": null,
            "CajaAperturada": 0,
            "Apertura": "",
            "Sesion": 0,
            "Produccion": null,
            "Estado": 1,
            "Propietario": "LUNA QUISPE, RONALD E",
            "CodPersonaPropietario": 2,
            "MarcaUnidad": "",
            "AñoUnidad": 0,
            "CombustibleUnidad": "",
            "ModeloUnidad": "",
            "AñoUnidad1": null,
            "ClaseUnidad": "",
            "CombustibleUnidad1": "",
            "MotorUnidad": "",
            "SerieUnidad": "",
            "ColoresUnidad": "",
            "PesoBrutoUnidad": null,
            "CargaUtilUnidad": null,
            "PesoSecoUnidad": null,
            "LongitudUnidad": 12.00,
            "AnchoUnidad": null,
            "AlturaUnidad": null,
            "NumAsientos": null,
            "NumPasajeros": null,
            "NumEjes": 0,
            "NumRuedas": null,
            "NomUsuarioCreacion": "Admin@sjl",
            "FechaCreacion": "23/04/2016 00:00:00",
            "CodConcesionario": 1,
            "NomUsuario": "kbaltazar@sjl",
            "FechaEdicion": "03/12/2018 11:40:43",
            "CodPersonaAccionista": null,
            "NomPersonaAccionista": "",
            "UnidadNeumaticoEje": "0~0",
            "EstadoDeuda": "0",
            "CodCupo": 0,
            "NroCupo": "",
            "NroPuertas": 0,
            "FechaInscripcion": "",
            "EstadoGTU": "",
            "NomSituacion": "De Empresa"
        }
    ]

    const empresas = [
        {
            "codEmpresa": 30,
            "nomEmpresa": "CAMINOS DEL INCA",
            "baseDatos": "AGPS_CaminoInca"
          },
          {
            "codEmpresa": 15,
            "nomEmpresa": "CHAMA",
            "baseDatos": "AGPS_Chama"
          },
          {
            "codEmpresa": 26,
            "nomEmpresa": "CRUZ DEL CENTRO S.A",
            "baseDatos": "AGPS_Alfa"
          },
          {
            "codEmpresa": 14,
            "nomEmpresa": "EL RAPIDO",
            "baseDatos": "AGPS_Rapido"
          },
          {
            "codEmpresa": 54,
            "nomEmpresa": "EMP.TRANSP.VIRGEN DE LA PUERTA SA - ETVPSA",
            "baseDatos": "AGPS_ETVPSA"
          },
    ]
    const classNameInput = "caret-input text-white h-[30px] rounded px-4 w-full bg-zinc-800 text-xs focus:outline-none focus:ring-1 focus:ring-blue-500"

  return (
    <>
        <ContenedorParametros titulo="Flota vehicular">
            <div className='flex gap-5 items-center'>
                <select className={`${classNameInput} w-[300px]`}><option>Empresa</option></select>
                <button className='p-2 rounded-md border fa fa-filter'></button>
                <BotonNuevo></BotonNuevo>
                <BotonProcesar></BotonProcesar>
                <BotonExcel></BotonExcel>
            </div>
        </ContenedorParametros>
        <div className='containerScroll mt-7 relative top-0 bottom-0 left-0 right-0 contenedor-tabla'>
            <table className='table'>
                <thead>
                    <tr>
                        <th rowspan="2">N°</th>
                        <th rowspan="2">EMPRESA</th>
                        <th rowspan="2">COD</th>
                        <th rowspan="2">PLACA</th>
                        <th colspan="4">ESPECIFICACIONES</th>
                    </tr>
                    <tr>
                        <th>MARCA</th>
                        <th>MODELO</th>
                        <th>AÑO</th>
                        <th>COMBUSTIBLE</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((data,i) => {
                            return(
                                <tr>
                                    <td>{i+1}</td>
                                    <td>{empresas[i%5].nomEmpresa}</td>
                                    <td className='text-center'>{data.Codigo}</td>
                                    <td className='text-center'>{data.PlacaUnidad}</td>
                                    <td className='text-center'>{data.MarcaUnidad}</td>
                                    <td className='text-center'>{data.ModeloUnidad}</td>
                                    <td className='text-center'>{data.AñoUnidad}</td>
                                    <td className='text-center'>{data.CombustibleUnidad}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    </>
  )
}
