import React from 'react';
import { createRoot } from 'react-dom/client';
// import * as serviceWorkerRegistration from './serviceWorkerRegistration'; pwa
import reportWebVitals from './reportWebVitals';
import ReactDOM from 'react-dom';
import { App } from './app/App';
import './assets/css/views/index.css';

import 'leaflet/dist/leaflet.css';      // ESTILOS PARA EL MAPA DE REACT-LEAFLET


createRoot(
    document.getElementById('root')
).render(
    // <React.StrictMode>
        <App></App>
    // </React.StrictMode>
);
// serviceWorkerRegistration.register();pwa

reportWebVitals();
