import React, { useContext } from 'react'
import {
    Switch,
    Route,
    Redirect,
    useLocation
} from "react-router-dom";
import { Login } from '../pages/Login'
import { PantallaCarga } from '../pages/AuthorizeWs02IS/Authorization';

export function PublicRoutes() {
    return (
        <>
            <Switch>             
                <Route exact path='/' component ={Login}/>
                <Route exact path='/login' component ={Login}/>

                <Route exact path='/PantallaCarga' component ={PantallaCarga}/>

            </Switch>
        </>
    )
}