import React from 'react';
import '../../../assets/css/components/botonnuevo.css'


export const BotonNuevo = (props) => {
    return (
        <button {...props} className=" btn-circle btn-xl botonNuevo flex items-center">
            <i className="fa fa-plus text-[#FFFFFF] text-lg"></i> 
            <span className='text-sm text-[#FFFFFF] ml-1'>{props.children || 'Nuevo'}</span>
        </button>
    )
}

