import React from 'react';
import { saveAs } from 'file-saver';
import Excel from 'exceljs';

const BotonExcelTiempos = ({ tableId , nombreArchivo , parametrosCabecera }) => {
    const handleExportClick = () => {
        const workbook = new Excel.Workbook();
        const worksheet = workbook.addWorksheet('Sheet 1');

        const table = document.getElementById(tableId);
        const rows = table.querySelectorAll('tr');

        let rowIndex = 1;

        // Obtener el número de celdas en la primera fila (considerando los colspans)
        let totalCells = 0;
        const firstRowCells = rows[0].querySelectorAll('th, td');
        firstRowCells.forEach((cell) => {
            const colspan = parseInt(cell.getAttribute('colspan')) || 1;
            totalCells += colspan;
        });
        
        // Agregar celdas adicionales antes de la tabla
        const additionalRow = worksheet.addRow([]);
        const additionalCell = additionalRow.getCell(totalCells + 1);
        additionalCell.value = `Nombre Empresa: ${parametrosCabecera.nomEmpresa}`;
        // additionalCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFFFF' } }; // Color de fondo blanco

        const additionalCellMiddle = additionalRow.getCell(1);
        additionalCellMiddle.value = nombreArchivo
        additionalCellMiddle.alignment = {horizontal: 'center' , vertical:'middle'};

        rowIndex += 1;

        const additionalRow2 = worksheet.addRow([]);
        const additionalCell2 = additionalRow2.getCell(totalCells + 1);
        additionalCell2.value = `Ruta: ${parametrosCabecera.nomRuta}`
        rowIndex += 1;

        const additionalRow3 = worksheet.addRow([]);
        const additionalCell3 = additionalRow3.getCell(totalCells + 1);
        additionalCell3.value = `Recorrido: ${parametrosCabecera.nomRecorrido}`
        rowIndex += 1;

        worksheet.addRow([])
        rowIndex += 1;

        rows.forEach((row) => {
            const cells = row.querySelectorAll('th, td');
            let colIndex = 1;

            cells.forEach((cell) => {
                const cellData = {
                        text: cell.innerText,
                        colspan: parseInt(cell.getAttribute('colspan')) || 1,
                        rowspan: parseInt(cell.getAttribute('rowspan')) || 1,
                        isHeader: cell.tagName === 'TH',
                };

                const excelCell = worksheet.getCell(rowIndex, colIndex);
                excelCell.value = cellData.text;
                excelCell.alignment = { horizontal: 'center' };
                    
                if (cellData.isHeader) {
                    // Aplicar estilo de color de fondo a las celdas de encabezado
                    const headerFill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FF2e5289' }, // Color de fondo rojo (cambiar según sea necesario)
                    };
                    excelCell.fill = headerFill;

                    const fontColor = {
                        color: { argb: 'FFFFFFFF' }, // Color de fuente blanco
                    };
                    excelCell.font = fontColor;

                    // Centrar el texto dentro de las celdas de encabezado
                    excelCell.alignment = { horizontal: 'center' };
                    
                }

                if (cellData.colspan > 1 || cellData.rowspan > 1) {
                    worksheet.mergeCells(
                        rowIndex,
                        colIndex,
                        rowIndex + cellData.rowspan - 1,
                        colIndex + cellData.colspan - 1
                    );
                }

                colIndex += cellData.colspan;
            });

            rowIndex += 1;
        });

        worksheet.columns.forEach((column) => {
            let maxCellWidth = 0;
            column.eachCell({ includeEmpty: true }, (cell) => {
                const cellWidth = cell.value ? String(cell.value).length : 0;
                if (cellWidth > maxCellWidth) {
                    maxCellWidth = cellWidth;
                }
            });
            column.width = maxCellWidth + 2; // Agregar un margen adicional
        });

        worksheet.mergeCells(1, 1 , 3, totalCells); // Combinar celdas para abarcar todo el ancho de la tabla    //para que no se modifique el ancho de las demas celdas se esta uniendo al final
        /*
        La función mergeCells() se utiliza para combinar celdas en una hoja de cálculo de Excel. Acepta cuatro parámetros:
            1er elemento -> (índice de fila inicial): representa el número de la fila donde comienza el rango de celdas que se desea combinar.
            2do elemento -> (índice de columna inicial): representa el número de columna donde comienza el rango de celdas que se desea combinar.
            3er elemento -> (índice de fila final): representa el número de la fila donde termina el rango de celdas que se desea combinar.
            4to elemento -> (índice de columna final): representa el número de columna donde termina el rango de celdas que se desea combinar.
        */


        // Guardar el archivo Excel
        workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, `${nombreArchivo}.xlsx`);
        });
    };

    return (
        <button className='bg-green-800 rounded text-[#ffff]' onClick={handleExportClick}>
        <i className=' fa fa-file-excel-o bg-green-800  px-4 py-1 text-2xl rounded hover:bg-[#155bb5]'></i></button>
    );
};

export default BotonExcelTiempos;