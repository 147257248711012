import { useState, useEffect, useContext } from "react";

export const useDataIGO = (props) => {

    const modificadorDatos = (array, condAgrupado) => {

        if(condAgrupado == 0){
            return array
        }else{
            let arrayRutasDif = []
            arrayRutasDif.push(array[0]?.nomRuta)

            array.map(function(data){
                if(!arrayRutasDif.some(elem => data.nomRuta == elem)){
                    arrayRutasDif.push(data.nomRuta)
                }
            })

            let arrayDatosSeparados = []
            arrayRutasDif.map(function(data){
                arrayDatosSeparados.push(array.filter(element => element.nomRuta == data))
            })
    
            let arrayDatosTrabajados = []
            arrayDatosSeparados.map(function(data){
                let flotaTotal = 0
                let flotaOperativaTotal = 0
                let viajesTotal = 0
                let kmTotal = 0
                let pasajerosTotales = 0
                let produccionXBus = 0
    
                data.map(function(data2){
                    flotaTotal += data2.numFlota
                    flotaOperativaTotal += data2.numOperativa
                    viajesTotal += data2.numViajes
                    kmTotal += data2.kilometros
                    pasajerosTotales += data2.totalPasajeros
                    produccionXBus += Number(data2.totalProduccion)
                })

                produccionXBus = (produccionXBus / flotaOperativaTotal)

                arrayDatosTrabajados.push({
                    nomEmpresa: data[0].nomEmpresa,
                    dia: '-',     //son dias distintos
                    fechaSalida: '-',     //son fechas distintas
                    nomRuta: data[0].nomRuta,
                    numFlota: flotaTotal,
                    numOperativa: flotaOperativaTotal,
                    porcentOperativa: `${((flotaOperativaTotal * 100) / flotaTotal).toFixed(2)} %`,
                    numViajes: viajesTotal,
                    kilometros: kmTotal,
                    totalPasajeros:pasajerosTotales,
                    pb: produccionXBus,
                    ip: pasajerosTotales / kmTotal,
                    ipb: pasajerosTotales / flotaOperativaTotal,
                    pp: '-',    //no se que datos tomar para el pasaje promedio
                    ikb: Math.round(kmTotal / flotaOperativaTotal)
                })
            })

            return arrayDatosTrabajados
        }
    }

    return {modificadorDatos};
}