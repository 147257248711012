import React, { useEffect, useState } from 'react'

export const TabsABX = ({
	nameTabs,
	contentTabs,
	tabActivaOpcional
}) => {
	const [tabActiva,setTabActiva] = useState(tabActivaOpcional || 0)

	return (
		<div className='w-full h-full max-h-full flex flex-col'>
			<div className='w-full flex'>
				{
					nameTabs.map((data,i) => (
						<div 
							key={i+1}
							onClick={() => setTabActiva(i)}
							className={`${tabActiva==i ? 'bg-[#003478]' : 'hover:bg-[#eeeeee] hover:text-black'} text-sm flex py-1 border w-[110px] items-center justify-center text-center cursor-pointer rounded-t-lg`}
						>
							{data}
						</div>
					))
				}
			</div>
			{
				contentTabs.map((data,i) => (
					<div
						key={i+1} 
						className={`${tabActiva != i ? 'hidden' : ''} flex flex-grow overflow-auto border containerScroll`}
					>
						{
							tabActiva == i ?
								data 
							: 	<></>
						}
					</div>
				))
			}
		</div>
	)
}
