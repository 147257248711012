import { useState, useEffect, useContext } from "react";
import { notify } from "../../utils/utils";
import { formatDate } from "../../utils/utils";
import { AuthFetch, Fetch } from "../../services/api";
import { useSpinner } from "../useSpinner";
import { UserContext } from "../../context/provider/UserProvider";

const urlBaseAtu = process.env.REACT_APP_ATU_API + "/api/ATU";

export const useATU = () => {
    const [ empresas, setEmpresas ] = useState([]);
    const [ rutas, setRutas ] = useState([]);
    const [ subsidios, setSubsidios ] = useState([]);

    const [ recorridos, setRecorridos ] = useState([]);

    const [ igo, setIgo ] = useState([]);
    const [ igoGeneral, setIgoGeneral ] = useState([]);

    const [ fechas, setFechas ] = useState([]);

    const [ tiempoControles, setTiempoControles ] = useState([]);
    const [ tiempoParaderos, setTiempoParaderos ] = useState([]);
    const [ demandaParaderos, setDemandaParaderos ] = useState([]);

    const [ polilineas, setPolilineas ] = useState([]);
    const [ controles, setControles ] = useState([]);
    const [ paraderos, setParaderos ] = useState([])

    const [tramos,setTramos] = useState([])
    const [cumplimiento,setCumplimiento] = useState([])

    const [unidadesPorRuta,setUnidadesPorRuta] = useState([])
    
    const [ spinner, mostrarSpinner, ocultarSpinner ] = useSpinner();

    useEffect(() => {
        listarEmpresas();
    }, []);

    const listarEmpresas = async () => {        //0
        mostrarSpinner();

        const response = await Fetch({url : urlBaseAtu + '/listaEmpresas'})

        if (response.isValid) {
            setEmpresas(response.content);
        } else {
            notify(response.exceptions.description ,'error');
        }
        ocultarSpinner()
    }

    const listarRutas = async (codEmpresa) => {     //0|0
        // const response = await Fetch({url : urlBaseAtu + '/listaRutas?'+ new URLSearchParams({codEmpresaAtu: codEmpresa||1,}),})    //cuando es 0 manda la opcion 1
        const response = await Fetch({url : urlBaseAtu + '/listaRutas?'+ new URLSearchParams({codEmpresaAtu: codEmpresa}),})   

        if(response.isValid){
            setRutas(response.content)
        }else{
            notify(response.content,'error');
        }
    }

    const listarRecorridos = async (parametros) => {    //0|0|0
        mostrarSpinner()
        const codEmpresa = parametros.split("|")[0]
        const codRuta = parametros.split("|")[1]

        const response = await Fetch({url : urlBaseAtu + '/listaRecorridos?'+ new URLSearchParams({
            codEmpresaAtu: codEmpresa || 0,
            codRuta:codRuta ||0 
        }),})
        
        if(response.isValid){
            setRecorridos(response.content)
        }else{
            setRecorridos([])
            notify(response.content,'error');
        }
        ocultarSpinner()
    }

    const listarSubsidios = async (ruta, fechaInicio, ejecutado ,empresa) => {
        mostrarSpinner()

        const response = await Fetch({url : urlBaseAtu + '/listaSubsidio?'+ new URLSearchParams({
            CodRuta: ruta,
            FechaInicio: formatDate(fechaInicio,23),
            ViajeEjecutado: ejecutado,
            CodEmpresa: empresa
        }),})
        if(response.isValid){
            setSubsidios(response.content.subsidioGeneral)
        }else{
            notify(response.content,'error');
        }
        ocultarSpinner()

    }

    const listarIgo = async (codEmpresa, fechaInicio, fechaFin) => {
        mostrarSpinner()
        const response = await Fetch({url : urlBaseAtu + '/listaIGO?'+ new URLSearchParams({
            CodEmpresa: codEmpresa,
            FechaInicio: formatDate(fechaInicio,23),
            FechaFin: formatDate(fechaFin,23),
        }),})
        if(response.isValid){
            setIgo(response.content)
        }else{
            notify(response.content,'error');
        }
        ocultarSpinner()
    }

    const listarIgoGeneral = async (codEmpresa, fechaInicio, fechaFin,lado,codRuta) => {
        mostrarSpinner()
        const response = await Fetch({url : urlBaseAtu + '/listaIGOGeneral?'+ new URLSearchParams({
            CodEmpresa: codEmpresa||0,
            FechaInicio: formatDate(fechaInicio,23),
            FechaFin: formatDate(fechaFin,23),
            Lado: lado,
            CodRuta: codRuta,
        }),})
        if(response.isValid){
            setIgoGeneral(response.content)
        }else{

        }
        ocultarSpinner()
    }

    const listarTiempoControles = async (codEmpresa,codRecorrido,minutos,diaLetras) => {
        mostrarSpinner()
        const response = await Fetch({url: urlBaseAtu + '/obtenerHorasAtu?' + new URLSearchParams({

            CodEmpresaAtu: codEmpresa,
            CodRecorrido: codRecorrido,
            Minutos:minutos,
            DiaLetras:diaLetras
        }),})
        if(response.isValid){
            setTiempoControles(response.content.rangosHorariosControl)
        }else{
            if(response.exceptions[0].description == 'Cannot find table 1.'){
                notify("La empresa seleccionada no posee GPS'S",'warn')
            }
            setTiempoControles([])
        }
        ocultarSpinner()
    }

    const listarTiempoParaderos = async (codEmpresa,codRecorrido,minutos,diaLetras) => {
        mostrarSpinner()
        const response = await Fetch({url: urlBaseAtu + '/obtenerHorasParaderos?' + new URLSearchParams({

            CodEmpresaAtu: codEmpresa,
            CodRecorrido: codRecorrido,
            Minutos:minutos,
            DiaLetras:diaLetras

            //--PARA DESARROLLO--
            // CodEmpresaAtu: 1,
            // CodRecorrido: 1,
            // Minutos:60,
            // DiaLetras: '03/07/2020'
        }),})
        if(response.isValid){
            setTiempoParaderos(response.content.rangosHorariosParaderos)
        }else{
            setTiempoParaderos([])

            const partesFecha = diaLetras.split('/'); // Dividir la cadena en partes: día, mes, año
            const fechaObj = new Date(`${partesFecha[2]}-${partesFecha[1]}-${partesFecha[0]}`);
            const fechaActual = new Date(); // Fecha actual

            if (fechaObj > fechaActual) {
                notify('No hay información para fechas posteriores a la fecha de hoy','error')
            }
        }
        ocultarSpinner()
    }

    const listarDemandaParaderos = async (codEmpresa,codRecorrido,minutos,diaLetras) => {
        mostrarSpinner()
        const response = await Fetch({url: urlBaseAtu + '/demandaPorParadero?' + new URLSearchParams({

            CodEmpresaAtu: codEmpresa,
            CodRecorrido: codRecorrido,
            Minutos:minutos,
            DiaLetras:diaLetras

            //--PARA DESARROLLO--
            // CodEmpresaAtu: 34,
            // CodRecorrido: 1,
            // Minutos:60,
            // DiaLetras: '01/08/2023'
        }),})
        if(response.isValid){
            setDemandaParaderos(response.content.rangosHorariosDemandas)
        }else{
            setDemandaParaderos([])
        }
        ocultarSpinner()
    }

    const listarRecorridosControles = async (parametros) => {
        mostrarSpinner()
        await funcioncitaTest(parametros)
        ocultarSpinner()
    }
    
    const funcioncitaTest = async (parametros) => {

        const codEmpresa = parametros?.split("|")[0]
        const codRuta = parametros?.split("|")[1] 
        const listadoRecorridos = parametros?.split("|")[2] 

        const response = await Fetch({url : urlBaseAtu + '/listaControles?'+ new URLSearchParams({
            codEmpresaAtu: codEmpresa || 0, 
            codRuta:codRuta || 0,
            codRecorrido: listadoRecorridos
        }),})

        if(response.isValid){
            setPolilineas(response.content.listadoRecorridos)
            setControles(response.content.listadoControles)
            setParaderos(response.content.listadoParaderos)
        }else{
            setPolilineas([])
            setControles([])
            setParaderos([])
            notify(response.content,'error');
        }
    }

    const obtenerFechas = async (semana,anio) => {
        const response = await Fetch({url: urlBaseAtu + '/obtenerFechas?' + new URLSearchParams({
            Semana:semana,
            Anio:anio
        }),})
        if(response.isValid){
            setFechas(response.content)
        }else{

        }
    }

    const listarTramos = async (fechaConsulta,codEmpresa,codRuta,codRecorrido) => {
        const response = await Fetch({url:urlBaseAtu + '/listarTramosAtu?' + new URLSearchParams({
            FechaConsulta:formatDate(fechaConsulta,103),
            CodEmpresa:codEmpresa,
            CodRecorrido:codRecorrido
            // FechaConsulta:'06/06/2023',
            // CodEmpresa:'8',
            // CodRecorrido:'1'
        })})
        if(response.isValid){
            setTramos(response.content)
            const response2 = await Fetch({
                url:    urlBaseAtu + '/cumplimientoRecorrido?' +
                        new URLSearchParams({
                            CodEmpresa: codEmpresa,
                            CodRuta: codRuta,
                            CodRecorrido: codRecorrido
                        })
            })
            if(response.isValid){
                setCumplimiento(response2.content)
            }else{
                setCumplimiento([])
            }
        }else{
            setCumplimiento([])

        }
    }

    const listaMapaRutaATU = async (fechaConsulta,codEmpresa,codRecorrido) => {
        const response = await Fetch({
            url: urlBaseAtu + '/listaMapaRutaAtu?' + new URLSearchParams({
                fechaConsulta: fechaConsulta,
                codEmpresa: codEmpresa,
                codRecorrido: codRecorrido
            })
        })
        if(response.isValid){
            setUnidadesPorRuta(response.content)
        }else{
            notify(response.exceptions[0].description,'error')
        }
    }

    return  {listarEmpresas,
            empresas,
            listarRutas,
            rutas,
            listarRecorridos,
            recorridos,
            listarSubsidios,
            subsidios,
            listarIgo,
            igo,
            listarIgoGeneral,
            igoGeneral,
            listarTiempoControles,
            tiempoControles,
            setTiempoControles,
            listarTiempoParaderos,
            tiempoParaderos,
            listarRecorridosControles,
            polilineas,
            controles,
            paraderos,
            spinner,
            obtenerFechas,
            fechas,
            listarTramos,
            tramos,
            cumplimiento,
            setCumplimiento,
            listarDemandaParaderos,
            demandaParaderos,

            listaMapaRutaATU,
            unidadesPorRuta
    };
}

