import React, { useEffect, useRef, useState } from "react";
import { ContenedorParametros } from "../../components/utils/ContenedorParametros";
import { BotonProcesar } from "../../components/buttons/BotonProcesar";
import BotonExcel from "../../components/buttons/BotonExcel";
import { classNameInput, notify } from "../../../utils/utils";
import { BotonNuevo } from "../../components/buttons/BotonNuevo";
import { Modal } from "../../components/modal/Modal";
import { GoogleMap } from "../../../utils/googlemaps";
import { useModal } from "../../../hooks/useModal";
import { useFlota } from "../../../hooks/ATU/useFlota";
import iconoNuevo from '../../../assets/images/marcadorNuevo.png'

const googlemap = new GoogleMap()
const googlemap2 = new GoogleMap()


export const ProcesoCertificacion = () => {
    const mapRef = useRef()
    const divFlotaRef = useRef()
    const parametrosRegistroDefault = {
        longitudTotal:      0,
        velocidadComercial: 0,
        intervaloPaso:      0,
        cargaMaxima:        0,
        cargaTeorica:       0,
        
        flotaOperativa:     0,
        flotaReten:         0,
        flotaRequerida:     0
    }
    const jsonEspecificaciones = [
        {
            Nombre: 'Descripcion de la ruta',
            Menus: [
                {
                    Nombre: 'Detalles de la ruta',
                    Menus: [
                        {
                            Nombre: 'Longitud total de la ruta (ida y vuelta)'
                        },
                        {
                            Nombre: 'Principales puntos de origen y destino'
                        },
                        {
                            Nombre: 'Descripción de las caracteristicas de la ruta (urbanas, rurales, mixtas)'
                        },
                    ]
                },
                {
                    Nombre: 'Puntos de parada',
                    Menus: [
                        {
                            Nombre: 'Número de paradas y su ubicación'
                        },
                        {
                            Nombre: 'Duración promedio de las paradas'
                        },
                    ]
                }
            ]
        },
        {
            Nombre: 'Tipología de vehículos',
            Menus: [
                {
                    Nombre: 'Tipos de vehículos utilizados',
                    Menus: [
                        {
                            Nombre: 'Descripción de los diferentes tipos de autobuses (Combi, Coaster, Bus, Bus articulado)'
                        },
                    ]
                },
                {
                    Nombre: 'Capacidad de los vehículos',
                    Menus: [
                        {
                            Nombre: 'Capacidad de pasajeros de cada tipo de vehículo'
                        },
                    ]
                },
                {
                    Nombre: 'Especificaciones técnicas',
                    Menus: [
                        {
                            Nombre: 'Velocidad máxima, aceleración, eficiencia de combustible, etc'
                        },
                    ]
                }
            ]
        },
        {
            Nombre: 'Recopilación de datos',
            Menus: [
                {
                    Nombre: 'Fuentes de datos',
                    Menus: [
                        {
                            Nombre: 'Descripción de las funtes de datos utilizadas (GPS, encuestas, observaciones directas)'
                        },
                    ]
                },
                {
                    Nombre: 'Metodología de recopilación',
                    Menus: [
                        {
                            Nombre: 'Procedimientos y herramientas utilizadas para recolectar los datos necesarios'
                        },
                    ]
                },
            ]
        },
        {
            Nombre: 'Análisis de datos',
            Menus: [
                {
                    Nombre: 'Tráfico y condiciones de la ruta',
                    Menus: [
                        {
                            Nombre: 'Análisis del trafico en diferentes momentos del dia y condiciones de la carretera'
                        },
                    ]
                },
                {
                    Nombre: 'Velocidad promedio',
                    Menus: [
                        {
                            Nombre: 'Cálculo de la velocidad promedio en diferentes segmentos de la ruta'
                        },
                    ]
                },
                {
                    Nombre: 'Tiempo de viaje total',
                    Menus: [
                        {
                            Nombre: 'Cálculo del tiempo total de viaje considerando paradas, tiempos de espera, etc'
                        },
                    ]
                },
            ]
        }
    ]

    const [isOpenModalRegistro,openModalRegistro,closeModalRegistro] = useModal()
    const [isOpenModalFlota,openModalFlota,closeModalFlota] = useModal()
    const { listarEmpresasBDGeneral,
        empresasGeneral,
    } = useFlota()

    const [parametrosRegistro,setParametrosRegistro] = useState(parametrosRegistroDefault)
    // PARA EL MODAL DE CALCULO DE FLOTA
    const [isDrawing,setIsDrawing] = useState(false)
    const [coordenadasMarcadas,setCoordenadasMarcadas] = useState([])
    const [longitudPolilinea,setLongitudPolilinea] = useState(0)

    useEffect(() => {
        listarEmpresasBDGeneral()
    },[])

    // PARA EL MODAL DE PROCESO DE CERTIFICACION
    useEffect(() => {
        mapRef.current && googlemap.inicializarMapa(mapRef.current)
    },[isOpenModalRegistro])

    // PARA EL MODAL DE CALCULO DE FLOTA
    useEffect(() => {
        if(divFlotaRef.current){
            googlemap2.inicializarMapa(divFlotaRef.current,{
                cursor: 'pointer'
            })
            setCoordenadasMarcadas([])
            // googlemap2.map.event.clearListeners(googlemap2.map, 'click');
            googlemap2.map.addListener('click',(event) => {
                const latitud = event.latLng.lat()
                const longitud = event.latLng.lng()
                const marcadorCreado = 
                googlemap2.crearMarcador({
                    latLng: { lat: latitud, lng: longitud},
                    icon: {
                        url: iconoNuevo,
                        scaledSize: new window.google.maps.Size(20,20)
                    }
                })

                setCoordenadasMarcadas(valAnterior => {
                    return [...valAnterior,{
                        coordenadas: [latitud,longitud],
                        marcador: marcadorCreado
                    }]
                })
            })
        }
    },[isOpenModalFlota])

    // PARA EL GRAFICO DE LA POLILINEA CON CADA MARCADOR
    useEffect(() => {
        if(coordenadasMarcadas.lenght != 0){
            googlemap2.quitarTodosLasPolilineas()   // QUITA LAS POLILINEAS
            googlemap2.crearPolilinea({             // GRAFICA LA NUEVA POLILINEA
                listLatLng: coordenadasMarcadas.map(coord => ({lat: coord.coordenadas[0],lng: coord.coordenadas[1]})),
                color: '#0000FF',
                strokeWeight: 3,
                strokeOpacity: 0.5,
            })
            const distancia = coordenadasMarcadas.reduce((acum,elem,i) => {
                if(i != coordenadasMarcadas.length-1){
                    const punto1 = new window.google.maps.LatLng(elem.coordenadas[0],elem.coordenadas[1])
                    const punto2 = new window.google.maps.LatLng(coordenadasMarcadas[i+1].coordenadas[0],coordenadasMarcadas[i+1].coordenadas[1])
                    const distanciaEntreAmbos = window.google.maps.geometry.spherical.computeDistanceBetween(punto1,punto2)
                    acum += distanciaEntreAmbos
                }
                return acum
            },0)
            setLongitudPolilinea((distancia/1000).toFixed(2))
        }
    },[coordenadasMarcadas])

    const editarValorParametroRegistro = (key,value) => {
        setParametrosRegistro(jsonAnterior => (
            {
                ...jsonAnterior,
                [key]: value
            }
        ))
    }

    const funcionCalculoFlotaOperativa = () => {
        const {flotaOperativa,flotaReten,flotaRequerida,...parametrosRestantes} = parametrosRegistro
        if(Object.values(parametrosRestantes).includes(0)){
            notify('Llenar todos los campos','info')
            return
        }

        const flotaOperativaCalculada = Math.round((parametrosRegistro.longitudTotal * 60 * parametrosRegistro.cargaMaxima) / (parametrosRegistro.velocidadComercial * parametrosRegistro.intervaloPaso * parametrosRegistro.cargaTeorica))
        const flotaRetenCalculada = Math.round((0.05) * (flotaOperativaCalculada))
        const flotaRequeridaCalculada = flotaOperativaCalculada + flotaRetenCalculada

        editarValorParametroRegistro('flotaOperativa',flotaOperativaCalculada)
        editarValorParametroRegistro('flotaReten',flotaRetenCalculada)
        editarValorParametroRegistro('flotaRequerida',flotaRequeridaCalculada)
    }

    const funcionConfirmarMedidaMapa = () => {
        editarValorParametroRegistro('longitudTotal',longitudPolilinea)
        setIsDrawing(false)
    }

    const funcionCancelarMedidaMapa = () => {
        googlemap2.quitarTodosLosMarcadores()
        googlemap2.quitarTodosLasPolilineas()
        setCoordenadasMarcadas([])
        setIsDrawing(false)
    }

    return (
        <div className="w-full h-full flex flex-col">
            <ContenedorParametros
                titulo="Proceso de certificación"
            >
                <div className="flex gap-2 items-center w-[250px] lg:w-full">
                    <i title="Empresa" className="fa fa-building" aria-hidden="true"></i>
                    <select className={`${classNameInput} flex-grow`}>
                        {
                            empresasGeneral.map((data,i) => {
                                return <option key={i+1} value={data.codEmpresa}>{data.nomEmpresa}</option>
                            })
                        }
                    </select>
                </div>

                <div className="flex items-center gap-2"> 
                    <BotonNuevo
                        onClick={openModalFlota}
                    ></BotonNuevo>
                    <BotonProcesar></BotonProcesar>
                    <BotonExcel></BotonExcel>
                </div>
            </ContenedorParametros>

            <div className="flex-grow mt-4 overflow-auto">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Nº</th>
                            <th>RUC</th>
                            <th>EMPRESA</th>
                            <th>RUTA</th>
                            <th>FECHA</th>
                            <th>PROCESO Y SEGUIMIENTO</th>
                            <th>VALIDACIÓN</th>
                            <th>ESTADO</th>
                            <th>OPCIONES</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan={9} className="text-center">No hay información con los parámetros seleccionados</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <Modal
                title='Empresa ruta'
                isOpen={isOpenModalRegistro}
                closeModal={closeModalRegistro}
            >
                <div className="w-[80vw] flex flex-col gap-2">
                    <div className="flex gap-4 border">
                        <div className="w-3/5 h-[400px] border" ref={mapRef}></div>
                        <div className="w-2/5 flex-grow border p-2 flex flex-col gap-2">
                            <div className="">Detalles de la zona</div>
                            <hr />
                            <div className="flex-grow overflow-auto">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Deleniti illo quasi omnis doloribus! Debitis voluptate perspiciatis iste dolorem officia tempora dolore itaque cupiditate, facilis omnis sit soluta cumque sed voluptatem.
                            </div>
                        </div>
                    </div>
                    <div className="p-2 flex flex-col gap-2">
                        <span className="font-bold underline">Especificaciones técnicas de la ruta</span>
                        <div className={`grid grid-cols-${jsonEspecificaciones.length} gap-2 h-[200px] text-xs`}>
                            {
                                jsonEspecificaciones.map((data,i) => {
                                    return (
                                    <div key={i+1} className="flex flex-col gap-2 overflow-auto containerScroll">
                                        <span className="font-bold sticky top-0 bg-[#2f3134]">{i+1}. {data.Nombre}</span>
                                        <ul className="pl-5 flex flex-col gap-2">
                                            {
                                                data.Menus.map((data2,j) => {
                                                    return(
                                                        <li key={j+1} className="flex flex-col gap-2">
                                                            {i+1}.{j+1}. {data2.Nombre}
                                                            <ul className="pl-5 flex flex-col gap-2">
                                                                {
                                                                    data2.Menus.map((data3,k) => {
                                                                        return(
                                                                            <li key={k+1}>
                                                                                <a className="hover:underline hover:cursor-pointer hover:text-blue-600">{i+1}.{j+1}.{k+1}. {data3.Nombre}</a>
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </Modal>

            {/* MODAL DE CALCULO DE FLOTA OPERATIVA */}
            <Modal
                title='Dimensionamiento de operación en ruta'
                isOpen={isOpenModalFlota}
                closeModal={closeModalFlota}
            >
                <div className="flex gap-4 h-[600px]">
                    <div className="lg:w-full flex flex-col justify-between p-2 border border-gray-600 rounded">
                        <section className="grid grid-cols-2 lg:grid-cols-1 gap-2 lg:gap-1">
                            <span>Longitud total: (Km.)</span>
                            <div className="flex gap-2">
                                <input value={parametrosRegistro.longitudTotal} disabled={isDrawing} onChange={e => editarValorParametroRegistro('longitudTotal',Number(e.target.value))} className={`${classNameInput} flex-grow`} type="number" min={0} />
                                <button 
                                    onClick={() => {
                                        setIsDrawing(!isDrawing)
                                        if(!isDrawing && parametrosRegistro.longitudTotal != 0){

                                        }
                                    }} 
                                    className={`${parametrosRegistro.longitudTotal == 0 ? '' : 'hidden'} text-[10px] rounded  px-1 ${isDrawing ? 'bg-orange-600' : 'bg-blue-600'}`}
                                >
                                    {!isDrawing ? 'Dibujar en el mapa' : 'Ocultar mapa'}
                                </button>
                            </div>
                            
                            <span>Velocidad comercial: (Km/h)</span>
                            <input value={parametrosRegistro.velocidadComercial} onChange={e => editarValorParametroRegistro('velocidadComercial',Number(e.target.value))} className={`${classNameInput}`} type="number" min={0} />

                            <span>Intervalo de paso: (min.)</span>
                            <input value={parametrosRegistro.intervaloPaso} onChange={e => editarValorParametroRegistro('intervaloPaso',Number(e.target.value))} className={`${classNameInput}`} type="number" min={0} />

                            <span>Carga máxima:</span>
                            <input value={parametrosRegistro.cargaMaxima} onChange={e => editarValorParametroRegistro('cargaMaxima',Number(e.target.value))} className={`${classNameInput}`} type="number" min={0} />

                            <span>Carga teórica:</span>
                            <input value={parametrosRegistro.cargaTeorica} onChange={e => editarValorParametroRegistro('cargaTeorica',Number(e.target.value))} className={`${classNameInput}`} type="number" min={0} />
                        </section>

                        <div className="flex flex-col gap-4">
                            <button onClick={funcionCalculoFlotaOperativa} className="rounded bg-green-600 py-2 hover:scale-105 font-semibold shadow-sm shadow-black">Obtener flota</button>
                            
                            <section className="border border-dashed grid grid-cols-2 gap-2 p-4">
                                <span className="font-semibold">Flota operativa:</span>
                                <span className="text-right border-b border-dashed">{parametrosRegistro.flotaOperativa}</span>
                                <span className="font-semibold">Flota retén:</span>
                                <span className="text-right border-b border-dashed">{parametrosRegistro.flotaReten}</span>
                                <span className="font-semibold">Flota requerida:</span>
                                <span className="text-right border-b border-dashed">{parametrosRegistro.flotaRequerida}</span>
                            </section>
                        </div>
                    </div>
                    <div className={`h-auto flex flex-col gap-2 p-2 border border-gray-600 rounded ${isDrawing && parametrosRegistro.longitudTotal == 0 ? '' : 'hidden'}`}>
                        <div className="flex-grow flex gap-4 overflow-auto">
                            <div className="w-[500px] h-full" ref={divFlotaRef}></div>
                            <div className="w-[300px] h-full flex flex-col gap-4">
                                <div className="text-xs italic">
                                    * Hacer click en el mapa para seleccionar las coordenadas
                                </div>
                                <div className="flex-grow overflow-auto containerScroll">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Nº</th>
                                                <th>COORDENADAS</th>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                coordenadasMarcadas.length != 0 ? 
                                                    coordenadasMarcadas.map((data,i) => {
                                                        return(
                                                            <tr key={i+1}>
                                                                <td>{i+1}</td>
                                                                <td className="text-center">{data.coordenadas.join('\n')}</td>
                                                                <td>
                                                                    <i 
                                                                        onClick={e => {
                                                                            // ENFOCA EL 
                                                                            googlemap2.map.setCenter({lat: data.coordenadas[0],lng: data.coordenadas[1]})
                                                                            googlemap2.map.setZoom(15)
                                                                        }}
                                                                        className="text-blue-600 fas fa-search"
                                                                    ></i>
                                                                </td>
                                                                <td>
                                                                    <i 
                                                                        onClick={e => {
                                                                            googlemap2.quitarMarcador(data.marcador)
                                                                            setCoordenadasMarcadas(valAnterior => {
                                                                                const arrCopia = [...valAnterior]
                                                                                arrCopia.splice(i,1)
                                                                                return arrCopia
                                                                            })
                                                                        }}
                                                                        className="text-red-600 fas fa-trash"
                                                                    ></i>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                :
                                                    <tr>
                                                        <td colSpan={4} className="text-center">No hay coordenadas seleccionadas</td>
                                                    </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="flex justify-between items-center border border-gray-600 rounded p-2">
                                    <span className="font-bold">Longitud calculada:</span>
                                    <span>{longitudPolilinea} Km.</span>
                                </div>
                            </div>
                        </div>

                        <div className="flex items-center justify-start gap-2">
                            <button onClick={funcionConfirmarMedidaMapa} className="hover:scale-105 rounded text-xs p-2 bg-green-600">Confirmar</button>
                            <button onClick={funcionCancelarMedidaMapa} className="hover:scale-105 rounded text-xs p-2 bg-red-600">Cancelar</button>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={false}
            >
                <div className="flex gap-2 w-[60vw] h-[50vh] lg:w-full">
                    <div className="border w-1/4 flex flex-col gap-2 p-2">
                            <button className="w-full h-[100px] rounded bg-blue-600 hover:scale-105">Dimensionamiento flota</button>
                            <button className="w-full h-[100px] rounded bg-blue-600 hover:scale-105">Dimensionamiento ruta</button>
                    </div>
                    <div className="border w-3/4"></div>
                </div>
            </Modal>

        </div>
    )
};
