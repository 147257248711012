import React, { useEffect, useMemo, useRef, useReducer, useState } from 'react';
import { ContenedorParametros } from '../../components/utils/ContenedorParametros'; 
import { useIgo } from '../../../hooks/GestionSubsidio/useIgo'; 
import { useATU } from "../../../hooks/ATU/useATU";
import { BotonProcesar } from '../../components/buttons/BotonProcesar';
import { Tooltip } from '../../components/utils/Tooltip';
import { DatePickerABX } from '../../components/pickers/DatePicker';
import BotonExcel from '../../components/buttons/BotonExcel';
import { NuevoSelectAutocomplete } from '../../components/forms/NuevoSelectAutocomplete';
import { useDataIGO } from '../../../hooks/ATU/useDataIGO';
import { Modal } from '../../components/modal/Modal';
import  ReactTooltip  from 'react-tooltip';
import { useModal } from '../../../hooks/useModal';
import BotonExcelTest from '../../components/buttons/BotonExcelTest';
import { ComponenteCarga } from '../../components/forms/ComponenteCarga';
import BarsChart from '../../components/BarsChart' 

export const Igo = () => {

    const classNameInput = "containerScroll text-white h-[30px] rounded px-4 w-full bg-zinc-800 text-xs focus:outline-none focus:ring-1 focus:ring-blue-500"   

    const {empresas,listarRutas,rutas,listarRecorridos,recorridos,listarIgo,igo,listarIgoGeneral,igoGeneral} = useATU()
    const {modificadorDatos} = useDataIGO()
    
    const currentDate = new Date(); // Obtiene la fecha actual
    const [isOpenBars , openModalBars, closeModalBars ] = useModal()

    currentDate.setDate(currentDate.getDate() - 2); // Resta dos días
    
    const [ endDate, setEndDate ] = useState(new Date())
    const [ startDate, setStartDate ] = useState(currentDate)

    const [ empresaSeleccionada , setEmpresaSeleccionada ] = useState(0)
    const [ rutaSeleccionada, setRutaSeleccionada ] = useState('0|0');
    const [ recorridoSeleccionado, setRecorridoSeleccionado ] = useState(0);

    const [agrupado,setAgrupado] = useState(0)
    const [validarClick,setValidarClick] = useState(false)
    const [mostrarOcultarTipoVista,setMostrarOcultarTipoVista] = useState(true)
    const [mostrarOcultarLado,setMostrarOcultarLado] = useState(false)

    const [arrayListado,setArrayListado] = useState([])

    const [imagenCarga,setImagenCarga] = useState(false)


    useEffect(() => {
        listarRutas(empresaSeleccionada)
        setValidarClick(false)
    },[empresaSeleccionada])
    
    useEffect(() => {
        ReactTooltip.rebuild();
        setRutaSeleccionada(`${empresaSeleccionada}|0`)
    },[rutas])

    useEffect(() => {
        ReactTooltip.rebuild();
        if(rutaSeleccionada.split('|')[1] == 0){
            setMostrarOcultarLado(false)
            setMostrarOcultarTipoVista(true)
        }else{
            listarRecorridos(rutaSeleccionada)
            setMostrarOcultarLado(true)
            setMostrarOcultarTipoVista(false)
        }
    },[rutaSeleccionada])

    const cargarTabla = async ()=>{
        if (rutaSeleccionada.split('|')[1] == 0){
            let concatenadorTest = ''
            if(empresaSeleccionada == 0){
                empresas.map(function(data){
                    concatenadorTest += `${data.codEmpresa},`
                })
                concatenadorTest = concatenadorTest.slice(0,concatenadorTest.length - 1)
            }else{
                concatenadorTest = empresaSeleccionada
            }
            await listarIgo(concatenadorTest,startDate,endDate)
        }else{
            await listarIgoGeneral(empresaSeleccionada,startDate,endDate,recorridoSeleccionado,rutaSeleccionada.split('|')[1])
        }   
    }

    useEffect(() => {
        setArrayListado(modificadorDatos(igo,agrupado))
        setImagenCarga(false) // cambia a estado false cuando trae los datos
    },[igo])

    useEffect(() => {
        setImagenCarga(false)
    },[igoGeneral])

    const empresasConvertidas = empresas.map(option => ({
        value: option.codEmpresa,
        label: option.nomEmpresa
    }));

    const recibirValor = (valor) => {
        setEmpresaSeleccionada(valor)
    };

    
    return (
        <>
            <ComponenteCarga variableCarga={imagenCarga}></ComponenteCarga>

            <ContenedorParametros
                titulo='Indicador General de Operaciones' data-tip={'Indicador General de Operaciones'} data-for={'IGO'}
            >   
                <div className='flex gap-4 items-center justify-center'>
                    <i className="fa fa-bus" data-tip={'Empresas'} data-for={'Empresas'} aria-hidden="true"></i>
                    <div>
                        <NuevoSelectAutocomplete options={empresasConvertidas} valorInput={recibirValor} valorPorDefecto='0'></NuevoSelectAutocomplete>
                    </div>
                </div>

                <div className='flex gap-4 items-center justify-center'>
                    <i className="fa fa-calendar-alt" data-tip={'Fecha Inico'} data-for={'FechaInicio'} aria-hidden="true"></i> 
                    <div><DatePickerABX data-for={'FechaInicio'} date={startDate} setDate={setStartDate} clases='lg:!w-[250px]' /></div>
                </div>
                
                <div className='flex gap-4 items-center justify-center'>
                    <i className="fa fa-calendar-alt" data-tip={'Fecha Fin'} data-for={'FechaFin'} aria-hidden="true"></i>
                    <div><DatePickerABX data-tip data-for={'FechaFin'} date={endDate} setDate={setEndDate} clases='lg:!w-[250px]' /></div>
                </div>
                
                <div className='flex gap-4 items-center justify-center'>
                    <i className="fa fa-road" data-tip={'Ruta'} data-for={'Ruta'} aria-hidden="true"/>
                    <select value={rutaSeleccionada} className={`${classNameInput} w-[100px] lg:w-[250px]`} onChange={(e)=>{setRutaSeleccionada(e.target.value);setValidarClick(false)}}>
                        <option value={`${empresaSeleccionada}|0`}>- Todos -</option>
                        {
                            rutas.map(function(r,i){
                                if(empresaSeleccionada == 0){
                                    return
                                }else{
                                    return <option key={i+1} value={`${r.codEmpresaATU}|${r.codRuta}`}>{empresaSeleccionada == 0 ? `${r.nomRuta} | ${r.nomEmpresaATU}` : r.nomRuta}</option>
                                }
                            })
                        }
                    </select>
                </div>
                
                <div className={mostrarOcultarTipoVista ? 'flex gap-4 items-center justify-center' : 'hidden'}>
                    <i className=" fa fa-eye" data-tip={'Tipo de Vista'} data-for={'TipoVista'} aria-hidden="true"/>
                    <select className={`${classNameInput} w-[120px] lg:w-[250px]`} value={agrupado} onChange={(e) => setAgrupado(e.target.value)}>
                        <option value={0}>Detallado</option>
                        <option value={1}>Agrupado</option>
                    </select>
                </div>

                <div className={mostrarOcultarLado ? 'flex gap-4 items-center justify-center' : 'hidden'}>
                    <i className="fas fa-map-signs" data-tip={'Lado'} data-for={'Lado'} aria-hidden="true"/>
                    <select className={`${classNameInput} w-[200px] lg:w-[250px]`} onChange={(e) => {setRecorridoSeleccionado(e.target.value);setValidarClick(false)}}>
                        <option value="0">- Ambos -</option>
                        {
                            recorridos.map(function(data,i){
                                return  <option key={i+1} value={i == 0 ? 'A' : 'B'}>{data.nomRecorrido}</option>
                            })
                        }
                    </select>
                </div>

                <div className='flex flex-wrap gap-4 items-center justify-center'>
                    <BotonProcesar onClick={async() => {setImagenCarga(true);await cargarTabla();setValidarClick(true)}} ></BotonProcesar>       
                    <BotonExcelTest tableId="tableIGO" nombreArchivo="REPORTE IGO"></BotonExcelTest>

                    <Tooltip id={'IGO'}/>
                    <Tooltip id={'Empresas'}/>
                    <Tooltip id={'FechaInicio'}/>
                    <Tooltip id={'FechaFin'}/>
                    <Tooltip id={'Ruta'}/>
                    <Tooltip id={'Lado'}/>
                    <Tooltip id={'TipoVista'}/>
                </div>

            </ContenedorParametros>


            <div className="containerScroll mt-7 relative top-0 bottom-0 left-0 right-0 contenedor-tabla">
            {
                rutaSeleccionada.split('|')[1] == 0 ?
                <div>
                    <table className="table" id='tableIGO'>
                        <thead>
                            <tr>
                                <th className="text-center w-[20px]"></th>
                                <th className="text-center w-[400px]"></th>
                                <th className="text-center w-[80px]"></th>
                                <th className="text-center w-[80px]"></th>
                                <th className="text-center w-[80px]"></th>
                                <th colSpan={3} className="border-x border-b text-center w-[150px]">FLOTA</th>
                                <th colSpan={3} className="border-x border-b text-center w-[150px]">RECORRIDO</th>
                                <th colSpan={5} className="border-x border-b text-center w-[150px]">INDICADORES</th>
                            </tr>
                            <tr>
                                <th className="text-center w-[20px]">N°</th>
                                <th className="text-center w-[400px]">EMPRESA</th>
                                <th className="text-center w-[80px]">DIA</th>
                                <th className="text-center ">FECHA</th>
                                <th className="text-center w-[80px]">RUTA</th>
                                
                                <th className="text-center w-[120px] border-x">TOTAL</th>
                                <th className="text-center w-[120px] border-x" data-tip={'Flota Operativa'} data-for={'OPER'}>OPER</th>
                                <th className="text-center w-[120px] border-x" data-tip={'Porcentaje Operativo'} data-for={'OPERPORC'}>%OPER</th>
                                
                                <th className="text-center w-[120px] border-x">VUELTAS</th>
                                <th className="text-center w-[120px] border-x">KILOMETROS</th>
                                <th className="text-center w-[120px] border-x">PASAJEROS</th>

                                <th className="text-center w-[100px] border-x" data-tip={'<div style="text-align:center">Producción por Bus <br/> (TotalProducción / FlotaOperativa)</div>'} data-for={'PB'}>PB</th>
                                <th className="text-center w-[100px] border-x" data-tip={'Indice de Pasajeros por kilometros'} data-for={'IPK'}>IPK</th>
                                <th className="text-center w-[100px] border-x" data-tip={'Indice de Pasajeros por Bus'} data-for={'IPB'}>IPB</th>
                                <th className="text-center w-[100px] border-x" data-tip={'<div style="text-align:center">Pasaje promedio <br/> (TotalProduccion / TotalPasajeros)</div>'} data-for={'PP'}>PP</th>
                                <th className="text-center w-[100px] border-x" data-tip={'Indice de Kilometro por Bus'} data-for={'IKB'}>IKB</th>
                            </tr>
                        </thead>
            
                        <tbody>
                        {
                            validarClick ?
                                igo.length > 0 ?
                                    arrayListado.map((igo, i) => {
                                        return (
                                            <tr key={++i}>
                                                <td className="text-center">{++i}</td>
                                                <td className="text-center">{igo.nomEmpresa}</td>
                                                <td className="text-center">{igo.dia}</td>
                                                <td className="text-center">{igo.fechaSalida}</td>
                                                <td className="text-center">{igo.nomRuta}</td>

                                                <td className="text-center">{igo.numFlota}</td>
                                                <td className="text-center">{igo.numOperativa}</td>
                                                <td className="text-center">{igo.porcentOperativa}</td>

                                                <td className="text-center">{igo.numViajes}</td>
                                                <td className="text-center">{igo.kilometros}</td>
                                                <td className="text-center">{igo.totalPasajeros}</td>

                                                <td className="text-center">{igo.pb}</td>
                                                <td className="text-center">{igo.ip}</td>
                                                <td className="text-center">{igo.ipb}</td>
                                                <td className="text-center">{igo.pp}</td>
                                                <td className="text-center">{igo.ikb}</td>
                                            </tr>
                                        )
                                    })
                                :
                                    <tr><td colSpan='16' className='text-center'>No hay información para mostrar con los parametros seleccionados</td></tr>
                            : 
                                <tr><td colSpan='16' className='text-center'>Presione el botón procesar ...</td></tr>
                        }
                        </tbody>
                    </table>
                    {/* PARA LA TABLA DE IGOGENERAL */}
                    
                    <ReactTooltip id={'OPER'} type={'light'} effect={"solid"}/>
                    <ReactTooltip id={'OPERPORC'} type={'light'} effect={"solid"}/>
                    <ReactTooltip id={'PB'} html={true} type={'light'} effect={"solid"}/>
                    <ReactTooltip id={'IPK'} type={'light'} effect={"solid"}/>
                    <ReactTooltip id={'IPB'} type={'light'} effect={"solid"}/>
                    <ReactTooltip id={'PP'} html={true} type={'light'} effect={"solid"}/>
                    <ReactTooltip id={'IKB'} type={'light'} effect={"solid"}/>
                </div>

                :
                
                <div>
                    <table className="table" id='tableIGO'>
                        <thead>
                            <tr>
                                <th className="text-center w-[20px]"></th>
                                <th className="text-center w-[150px]"></th>
                                <th className="text-center w-[80px]"></th>
                                <th className="text-center w-[80px]"></th>
                                <th className="text-center w-[80px]"></th>
                                <th className="text-center w-[80px]"></th>
                                <th className="text-center w-[80px]"></th>
                                <th className="text-center w-[80px]"></th>
                                <th className="text-center w-[80px]"></th>
                                <th className="text-center w-[80px]"></th>
                                <th colSpan={5} className='border-x border-b text-center w-[50%]'>CONDUCTOR</th>
                            </tr>
                            <tr>
                                <th className="text-center w-[20px]">N°</th>
                                <th className="text-center w-[100px]">FECHA</th>
                                <th className="text-center w-[80px]">H. INICIO</th>
                                <th className="text-center w-[80px]">H. FIN</th>
                                <th className="text-center w-[80px]" data-tip={'Intervalo de Frecuencia'} data-for={'IFC'}>IFC</th>
                                <th className="text-center w-[80px]">PADRON</th>
                                <th className="text-center w-[80px]">PLACA</th>
                                <th className="text-center w-[80px]">RUTA</th>
                                <th className="text-center w-[80px]">LADO</th>
                                <th className="text-center w-[80px]" data-tip={'Numero de Servicio'} data-for={'SRV'}>SRV</th>
                                <th className="text-center w-[100px] border-x" data-tip={'Certificado de Autorización del Conductor '} data-for={'CAC'}>CAC</th>
                                <th className="text-center w-[400px] border-x">NOMBRE</th>
                                <th className="text-center w-[150px] border-x">DNI</th>
                                <th className="text-center w-[100px] border-x">ESTADO</th>
                                <th className="text-center w-[100px] border-x">COMENTARIO</th>
                            </tr>
                        </thead>
            
                        <tbody>
                        {
                            validarClick ?

                                igoGeneral.length > 0 ?
                                    igoGeneral.map((igoG, i) => {
                                        return (
                                            <tr key={++i}>
                                                <td className="text-center">{++i}</td>
                                                <td className="text-center">{igoG.fecha}</td>
                                                <td className="text-center">{igoG.horaSalida}</td>
                                                <td className="text-center">{igoG.horaFin}</td>
                                                <td className="text-center">{igoG.intervaloFrecuencia}</td>
                                                <td className="text-center">{igoG.padron}</td>
                                                <td className="text-center">{igoG.unidad}</td>
                                                <td className="text-center">{igoG.nomRuta}</td>
                                                <td className="text-center whitespace-nowrap">{igoG.lado == 'A' ? recorridos[0].nomRecorrido : recorridos[1].nomRecorrido}</td>
                                                <td className="text-center">{igoG.nroServicio}</td>
                                                <td className="text-center">{igoG.codigoPersona}</td>
                                                <td className="text-center">{igoG.conductor}</td>
                                                <td className="text-center">{igoG.nroDocumento}</td>                                    
                                                <td className="text-center">{igoG.nomEstadoSalida}</td>
                                                <td className="text-center">{igoG.comentarioTrunco}</td>
                                            </tr>
                                        )
                                    })
                                :
                                    <tr><td colSpan='14' className='text-center'>No hay información para mostrar con los parametros seleccionados</td></tr>
                            :
                                <tr><td colSpan='14' className='text-center'>Presione el botón procesar ...</td></tr>
                        }
                        </tbody>
                    </table>

                    <ReactTooltip id={'IFC'} type={'light'} effect={"solid"}/>
                    <ReactTooltip id={'SRV'} type={'light'} effect={"solid"}/>
                    <ReactTooltip id={'CAC'} type={'light'} effect={"solid"}/>
                </div>
            }
            </div>

        </>
    )
}