import { useState, useEffect, useContext } from "react";

export const useDataSubsidios = (props) => {

    const modificadorDatos = (array, condAgrupado) => {

        if(condAgrupado == 0){
            return array
        }else{
            let arrayPersonas = []
            let valorInicial = array[0]?.codigoPersona
            arrayPersonas.push(valorInicial)
            array.map(function(data){
                if(data.codigoPersona != valorInicial){
                    arrayPersonas.push(data.codigoPersona)
                    valorInicial = data.codigoPersona
                }
            })
            
            let arrayDatosSeparados = []
            arrayPersonas.map(function(data){
                arrayDatosSeparados.push(array.filter(element => element.codigoPersona == data))
            })
    
            let arrayDatosTrabajados = []
            arrayDatosSeparados.map(function(data){
                
                let sumaComercial = 0
                let sumaNoComercial = 0
                let sumaImportePropietario = 0
                let sumaImporteOperador = 0
                let sumaImporteTotal = 0
    
                data.map(function(data2){
                    sumaComercial += data2.distanciaComercial
                    sumaNoComercial += data2.distanciaNoComercial
                    sumaImportePropietario += data2.importePropietario
                    sumaImporteOperador += data2.importeOperador
                    sumaImporteTotal += data2.importe
                })
                
                arrayDatosTrabajados.push({
                    fecha: data[0].fecha,
                    claseUnidad: data[0].claseUnidad,
                    numAsientos: data[0].numAsientos,
                    padronUnidad: data[0].padronUnidad,
                    placaUnidad: data[0].placaUnidad,
                    codigoPersona: data[0].codigoPersona,
                    nomPersona: data[0].nomPersona,
                    nroServicio: data[0].nroServicio,
                    nomRecorrido: data[0].nomRecorrido,
                    horaInicio: data[0].horaInicio,
                    horaFin: data[data.length - 1].horaFin,
                    nroViaje: data[0].nroViaje,
                    distanciaComercial: sumaComercial.toFixed(2),
                    distanciaNoComercial: sumaNoComercial.toFixed(2),
                    montoFactor: data[0].montoFactor,
                    factorPropietario: data[0].factorPropietario,
                    importePropietario: sumaImportePropietario.toFixed(2),
                    factorOperador: data[0].factorOperador,
                    importeOperador: sumaImporteOperador.toFixed(2),
                    importe: sumaImporteTotal.toFixed(2),
                })
            })

            return arrayDatosTrabajados
        }
    }

    return {modificadorDatos};
}