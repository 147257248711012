import React, { useEffect, useRef, useState } from 'react'
import { ContenedorParametros } from '../../components/utils/ContenedorParametros'
import { DatePickerABX } from '../../components/pickers/DatePicker'
import { NuevoSelectAutocomplete } from '../../components/forms/NuevoSelectAutocomplete'
import { BotonProcesar } from '../../components/buttons/BotonProcesar'
import { useATU } from '../../../hooks/ATU/useATU'
import { GoogleMap } from '../../../utils/googlemaps'
import { formatDate } from '../../../utils/utils'
import { Select2 } from '../../components/forms/Select2'

const googleMap = new GoogleMap();
//
const google = window.google;
const infowindow = new google.maps.InfoWindow();

export const UnidadesPorRuta = () => {
    const [ empresaSeleccionada, setEmpresaSeleccionada ] = useState(0);
    const [ rutaSeleccionada, setRutaSeleccionada ] = useState('0|0');
    const [ recorridoSeleccionado,setRecorridoSeleccionado ] = useState('0|0|0');
    const fechaActual = new Date(); // Obtiene la fecha actual
    const [startDate,setStartDate] = useState(fechaActual)
    const mapDiv = useRef();

    const classNameInput = "containerScroll text-white h-[30px] rounded px-4  bg-zinc-800 text-xs focus:outline-none focus:ring-1 focus:ring-blue-500"   

    const { listarEmpresas,
            empresas,
            listarRutas,
            rutas,
            listarRecorridos,
            recorridos,
            listarSubsidios,
            subsidios,
            listarIgo,
            igo,
            listarIgoGeneral,
            igoGeneral,
            listarTiempoControles,
            tiempoControles,
            setTiempoControles,
            listarTiempoParaderos,
            tiempoParaderos,
            listarRecorridosControles,
            polilineas,
            controles,
            paraderos,
            spinner,
            obtenerFechas,
            fechas,
            listarTramos,
            tramos,
            cumplimiento,
            setCumplimiento,
            listarDemandaParaderos,
            demandaParaderos,

            listaMapaRutaATU,
            unidadesPorRuta
    } = useATU()

    const empresasConvertidas = empresas.map(option => ({
        value: option.codEmpresa,
        label: option.nomEmpresa
    }));

    useEffect(() => {
        googleMap.inicializarMapa(mapDiv.current, { zoom: 11 });
        document.addEventListener('mouseup', function(e) {
            document.getElementById('content-ruta')?.classList.remove('flex','flex-col')
            document.getElementById('content-ruta')?.classList.add('hidden')
        })
    }, [])

    useEffect(() => {
        listarRutas(empresaSeleccionada);
        setRutaSeleccionada(`${empresaSeleccionada}|0`)
    },[empresaSeleccionada])

    useEffect(() => {
        listarRecorridos(rutaSeleccionada)
        setRecorridoSeleccionado(`${rutaSeleccionada}|0`)
    },[rutaSeleccionada])

    useEffect(() => {
        if(unidadesPorRuta.length != 0){
            googleMap.quitarTodosLosMapasDeCalor()
            const unidadesTotales = unidadesPorRuta.map(e => e.numSalida)
            const unidadesMaximas = Math.max(...unidadesTotales)
            const puntosMapaDeCalor = unidadesPorRuta.map((data,i) => {
                const porcentaje = (Number(data.numSalida)/unidadesMaximas).toFixed(1)
                return (
                    data.puntosRecorridoLineaMapa.split('*').map((data2,j) => {
                        const [latitud,longitud] = data2.split(',').reverse()
                        return ({
                            location: new window.google.maps.LatLng(latitud,longitud),
                            weight: Number(porcentaje) == 0 ? 0.1 : Number(porcentaje)
                        })
                    })
                )
            }).flat()
            googleMap.crearMapaDeCalor({
                dataMapaCalor: puntosMapaDeCalor,
                radio: 10
            })
        }
    },[unidadesPorRuta])

    const functionListar = async () => {
        await listaMapaRutaATU(formatDate(fechaActual),empresas.find(e => e.codEmpresa == empresaSeleccionada)?.codEmpresaGeneral || 0,recorridoSeleccionado.split('|')[2])
    }


    return (
        <>
            <ContenedorParametros titulo="Afluencia de unidades por ruta">

                <i className="fa fa-calendar-alt" data-tip={'Fecha'} data-for={'Fecha'} aria-hidden="true"></i> 
                <div>
                    <DatePickerABX data-tip data-for={'Fecha'} date={startDate} setDate={setStartDate}/>
                </div>

                <i className="fa fa-map-o" data-tip={'Empresas'} data-for={'Empresas'} aria-hidden="true"></i>
                <NuevoSelectAutocomplete options={empresasConvertidas} valorInput={(valor) => setEmpresaSeleccionada(valor)} valorPorDefecto='0'></NuevoSelectAutocomplete>

                <i className="fa fa-road" data-tip={'Ruta'} data-for={'Ruta'} aria-hidden="true"/>
                <div>
                    <select className={`${classNameInput} w-[200px] `} value={rutaSeleccionada} onChange={(e) => setRutaSeleccionada(e.target.value)}>
                        <option key={0} value={empresaSeleccionada+'|0'}>-- Seleccione --</option>
                        {
                            rutas.map((ru ,i) => {
                                if(empresaSeleccionada == 0){
                                    return
                                }else{
                                    return <option key={i+1} value={ru.codEmpresaATU + '|' + ru.codRuta}>{ru.nomRuta} { empresaSeleccionada!=0? '' : ' | ' + ru.nomEmpresaATU}</option>
                                }
                            })
                        }
                    </select>
                </div>     

                <i className="fa fa-road" data-tip={'Recorrido'} data-for={'Recorrido'} aria-hidden="true"/>
                <div>
                    <select className={`${classNameInput} w-[200px]`} value={recorridoSeleccionado} onChange={(e) => setRecorridoSeleccionado(e.target.value)}>
                        <option key={0} value={rutaSeleccionada+"|0"}>-- Seleccione --</option>
                        {
                            recorridos.map((re,i) => {
                                if(rutaSeleccionada.split('|')[1] == '0'){
                                    return
                                }else{
                                    return <option key={i+1} value={re.codEmpresaATU + '|' + re.codRuta + '|' + re.codRecorrido}>{re.nomRecorrido} { rutaSeleccionada != '0|0'? '' : ' | ' + re.nomRuta}</option>
                                }
                            })
                        }
                    </select>
                </div>
                
                <BotonProcesar onClick={() => functionListar()}/>
            </ContenedorParametros>   

            <div className="relative mapa-incidentes h-[85%]" >
                <div ref={mapDiv} className='w-full h-full'></div>
            </div>
        </>
    )
}
