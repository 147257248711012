import React, { forwardRef } from 'react';

import DatePicker, { registerLocale, setDefaultLocale }  from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
registerLocale('es', es)

export const DateTimePicker = ({
    date,
    setDate,
    styles,
    disabled
}) => {
    const DateInput = forwardRef(({ value, onClick }, ref) => (
        <input 
            disabled={disabled || false}
            onClick={onClick} 
            ref={ref} 
            className="text-center text-white text-xs h-[30px] rounded px-4 lg:text-xs w-[150px] lg:w-auto bg-zinc-800  focus:outline-none focus:ring-1 focus:ring-blue-500 " 
            defaultValue={value} 
            style={styles} 
            readOnly
        />
    ));

    return (
        <DatePicker
            locale="es" 
            selected={date}
            onChange={(date) => setDate(date)}
            customInput={<DateInput/>} 
            showTimeSelect                      // HABILITA EL MOSTRADO DE LAS HORAS
            timeFormat="HH:mm"                  // FORMATO DE LAS HORAS 
            dateFormat="dd/MM/yyyy HH:mm"       // PARA MOSTRAR LAS HORAS Y MINUTOS EN EL FORMATO
            timeIntervals={15}                  // PARA EL RANGO ENTRE LAS HORAS A SELECCIONAR
        ></DatePicker>
    )
};
