import React, { useState } from 'react';
import { LabelTituloGeneral } from './LabelTituloGeneral';
import { SearchTable }  from '../../components/utils/SearchTable';

export const ContenedorParametros = ({
    titulo = '',
    children,
    tablaId = null
}) => {
    const [plegarParametros,setPlegarParametros] = useState(true)
    return (
        <>
            <div className="relative" style={{alignItems: 'center',boxShadow: '0 10px 10px 0 rgba(255,255,255,0.2)'}}>
                <div className={`transition-all duration-500 ease-in-out items-center px-[15px]  justify-between flex flex-wrap ${plegarParametros ? 'max-h-screen py-2 overflow-visible' : 'max-h-8 overflow-hidden'}`}>
                    <LabelTituloGeneral titulo={titulo} clases='hidden lg:block lg:text-lg lg:w-full lg:text-center'/>  
                    <div className='flex lg:flex-col gap-[14px] items-center lg:w-full flex-wrap lg:scale-90 z-20'>
                        {children}                        
                    </div>
                    <LabelTituloGeneral titulo={titulo} clases='lg:hidden' />  
                </div>
                {tablaId && <SearchTable tablaId={tablaId}  /> }
                <button className='absolute px-8 py-1 bg-[#003968] rounded right-1/2 translate-x-1/2 hidden lg:block shadow shadow-white text-xs z-10' onClick={() => setPlegarParametros(!plegarParametros)}>
                    <i className={`${plegarParametros ? 'fa fa-angle-double-up' : 'fa fa-angle-double-down'}`} aria-hidden="true"></i>
                </button>
            </div>
            <hr className='mt-4'/>
        </>
    )
}