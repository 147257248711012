import React from 'react'

export const convertDatetoTimeStamp = (fechahora) => { // dd/mm/YYYY hh:ii:ss return 5454451245 + (30)*60000
    const dateTimeParts = fechahora.split(' ')
    const timeParts = dateTimeParts[1].split(':')
    const dateParts = dateTimeParts[0].split('/')

    const date = new Date(dateParts[2], parseInt(dateParts[1], 10) - 1, dateParts[0], timeParts[0], timeParts[1], timeParts[2]);

    return date.getTime()
}

export const convertTimestampToDate = (timestamp) => { //dd/mm/aaaa hh:ii:sss
    const dia = new Date(timestamp);
    const dd = ((dia.getDate()).toString()).padStart(2,'0')
    const mm = ((dia.getMonth() + 1).toString()).padStart(2,'0')
    const yyyy = dia.getFullYear()

    const hh = ((dia.getHours()).toString()).padStart(2,'0')
    const ii = ((dia.getMinutes()).toString()).padStart(2,'0')
    const ss = ((dia.getSeconds()).toString()).padStart(2,'0')

    return (`${dd}/${mm}/${yyyy} ${hh}:${ii}:${ss}`);
}
