
import React, { useState, useEffect } from 'react';
import  cargandoAbejita  from '../../../assets/images/cargandoAbejita.gif'


export const ComponenteCarga = ({variableCarga}) => {

    return (
        <div className={`w-full h-screen fixed bg-black bg-opacity-60 items-center justify-center z-[1000000000] ${variableCarga ? 'flex' : 'hidden'}`}>
            <img className='bg-no-repeat bg-center w-20' src='https://www.doradoplaza.com/imagenes/cargando.gif'></img>
            {/* <img className='bg-no-repeat bg-center w-60' src='https://img.pikbest.com/png-images/20190918/cartoon-snail-loading-loading-gif-animation_2734139.png!bw700'></img>  */}
            {/* <img className='bg-no-repeat bg-center w-60' src={cargandoAbejita}></img> */}
        </div>
    );
};

