import React from "react";
import { Spinner } from '../utils/Spinner';
import { useSpinner } from "../../../hooks/useSpinner";
import Draggable from "react-draggable";

export const ModalSinRenderizacion = ({
    isOpen, 
    closeModal, 
    action, 
    width, 
    children, 
    title, 
    cerrarAlGuardar = false,
    textButtons = { confirm: 'Guardar', denied: 'Cancelar' }, 
    validButton = { confirm: true , denied: true},
    clasesModal
}) => {
    const [ spinner, mostrarSpinner, ocultarSpinner ] = useSpinner(); 

    return (
        <div className={isOpen ? '' : 'hidden'}>
            <Draggable handle="strong">
                <div className="justify-center items-center flex overflow-hidden fixed inset-0 z-[10000] outline-none focus:outline-none">
                    <div className={`${clasesModal ? clasesModal : ''} relative w-${width ? width : 'auto'} lg:w-full my-6 mx-auto max-w-[90%] max-h-[90vh] overflow-auto`} style={{boxShadow: 'rgb(0 0 0) 0px 0px 25px 8px'}}>
                    {/*content*/}
                        <div className="bg-[#2d2f30] border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            {/*header*/}
                            <strong className="sticky top-0 z-10">
                                <div className="divMovibleCabecera bg-[#003478]  flex items-start justify-between p-5 border-b border-solid border-[#4e4e4e] rounded-t">
                                    <h3 className="text-xl font-semibold">
                                        {title}
                                    </h3>
                                    <button
                                        className="cursor-pointer"
                                        onClick={closeModal}
                                        onTouchEnd={closeModal}         // PARA EL CLICK EN LA VISTA MOVIL
                                        >
                                        <i className="fas fa-times"></i>
                                    </button>
                                </div>
                            </strong>
                            {/*body*/}
                            <div className="relative p-6 flex-auto bg-[#2f3134]">
                                { children }
                            </div>
                            {/*footer*/}
                            <div className="bg-[#2d2f30] flex items-center justify-end p-6 rounded-b sticky bottom-0">
                            {validButton.confirm ?
                                <button
                                className="min-w-[100px] bg-blue-500 hover:bg-blue-600 text-white active:bg-blue-600 font-bold capitalize text-sm px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 text-xs"
                                type="button"
                                    onClick={async () => {
                                        mostrarSpinner();
                                        await action();
                                        ocultarSpinner();
                                        // cerrarAlGuardar && closeModal()
                                    }}
                                >
                                    {
                                        spinner 
                                        ? <Spinner spinner={spinner.toString()}></Spinner>
                                        : <span>{textButtons.confirm}</span>
                                    }
                                </button>
                                : ''
                            }
                            {validButton.denied ?
                                <button
                                className=" text-white-500 hover:bg-stone-600 rounded background-transparent font-bold capitalize px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 text-xs"
                                type="button"
                                onClick={closeModal}
                                >
                                    {textButtons.denied} 
                                </button>
                                : ''
                            }
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="opacity-25 fixed inset-0 z-[1040] bg-black"></div> */}
            </Draggable>
        </div>
    )
};
