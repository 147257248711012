import React, { useEffect, useState } from 'react'
import { Fetch } from '../../services/api'
import { notify } from '../../utils/utils';

const urlBaseAtu = process.env.REACT_APP_ATU_API + "/api/ATU";

export const useMonitoreo = () => {

    const [empresaSeleccionada,setEmpresaSeleccionada] = useState(0)
    const [rutaSeleccionada,setRutaSeleccionada] = useState(0)
    
    const [indicadores,setIndicadores] = useState([])
    const [trackUnidades,setTrackUnidades] = useState([])
    const [trackUnidadesA,setTrackUnidadesA] = useState([])
    const [trackUnidadesB,setTrackUnidadesB] = useState([])
    const [controles,setControles] = useState([])
    const [controlesA,setControlesA] = useState([])
    const [controlesB,setControlesB] = useState([])
    const [distanciaTotalA,setDistanciaTotalA] = useState(0)
    const [distanciaTotalB,setDistanciaTotalB] = useState(0)
    const [alertas,setAlertas] = useState([])
    const [polilineas,setPolilineas] = useState([])
    const [reportesInspectoria,setReportesInspectoria] = useState([])
    const [operatividad,setOperatividad] = useState({})
    const [alertasGeneral,setAlertasGeneral] = useState([])

    const hoy = new Date()
    const dia = (hoy.getDate().toString()).padStart(2,'0')
    const mes = ((hoy.getMonth()+1).toString()).padStart(2,'0')
    const anio = hoy.getFullYear()
    const hour = hoy.getHours().toString().padStart(2,'0')
    const minute = hoy.getMinutes().toString().padStart(2,'0')


    const listarTrackUnidades = async () => {
        const response = await Fetch({
            url:    urlBaseAtu + 
                    '/listaUnidadesTrackAlertas?' + 
                    new URLSearchParams({
                        codEmpresa: empresaSeleccionada,
                        idRuta: rutaSeleccionada,
                        codUsuario: 1,
                    })
        })

        if(response.isValid){
            setTrackUnidades(response.content.listadoTraksUnidades)
            setTrackUnidadesA(response.content.listadoTraksUnidades.filter(elem => elem.lado == 'A'))
            setTrackUnidadesB(response.content.listadoTraksUnidades.filter(elem => elem.lado == 'B'))
            setAlertas(response.content.listaAlertas)
        }else{
            notify(response.content,'error')
        }
    }

    const listarControles = async () => {
        const response = await Fetch({
            url:    urlBaseAtu +
                    '/listaControlesLineal?' +
                    new URLSearchParams({
                        codEmpresa: empresaSeleccionada,
                        rutas: rutaSeleccionada,
                    })
        })

        if(response.isValid){
            setControles(response.content)
            
            const controlesLadoA = response.content.filter(elem => elem.lado == 'A')
            setControlesA(controlesLadoA)
            setDistanciaTotalA(controlesLadoA.reduce((acumulador,elemento) => {
                return (acumulador + elemento.distanciaControlAnterior)
            },0))

            const controlesLadoB = response.content.filter(elem => elem.lado == 'B').reverse()
            setControlesB(controlesLadoB)
            setDistanciaTotalB(controlesLadoB.reduce((acumulador,elemento) => {
                return (acumulador + elemento.distanciaControlAnterior)
            },0))
        }else{
            notify(response.content,'error')
        }
    }

    const listarIndicadores = async () => {
        const response = await Fetch({
            url:    urlBaseAtu +
                    '/listaDetalladoEmpresas'
        })

        if(response.isValid){
            setIndicadores(response.content)
        }else{
            notify(response.content,'error')
        }
    }

    const listarPolilineasRecorridos = async () => {
        const response = await Fetch({
            url:    urlBaseAtu + 
                    '/listaRecorridosXB?' +
                    new URLSearchParams({
                        codEmpresa: empresaSeleccionada,
                        codRuta: rutaSeleccionada
                    })
        })

        if(response.isValid){
            setPolilineas(response.content)
        }else{
            notify(response.content,'error')
        }
    }

    const listarReportesInspectoria = async () => {
        const response = await Fetch({
            url:    urlBaseAtu +
                    '/listaIncidencias?' +
                    new URLSearchParams({
                        CodEmpresa: 0
                    })
        })

        if(response.isValid){
            setReportesInspectoria(response.content)
        }else{
            notify(response.content,'error')
        }
    }

    const listarOperatividad = async () => {
        const response = await Fetch({
            url:    urlBaseAtu +
                    '/listaOperatividad'
        })

        if(response.isValid){
            setOperatividad(response.content)
        }else{
            notify(response.content,'error')
        }
    }

    const listarAlertasGeneral = async () => {
        const response = await Fetch({
            url:    urlBaseAtu +
                    '/listaAlertasGeneral?' +
                    new URLSearchParams({
                        CodEmpresa: 0
                    })
        })

        if(response.isValid){
            setAlertasGeneral(response.content)
        }else{
            notify(response.content,'error')
        }
    }
    
  return {
    empresaSeleccionada,
    rutaSeleccionada,
    setEmpresaSeleccionada,
    setRutaSeleccionada,
    listarTrackUnidades,
    trackUnidades,
    trackUnidadesA,
    trackUnidadesB,
    alertas,
    listarControles,
    controlesA,
    distanciaTotalA,
    controlesB,
    distanciaTotalB,
    listarIndicadores,
    indicadores,
    listarPolilineasRecorridos,
    polilineas,
    listarReportesInspectoria,
    reportesInspectoria,
    listarOperatividad,
    operatividad,
    listarAlertasGeneral,
    alertasGeneral
  }
}