import React, { useState } from 'react'
import { AuthFetch, Fetch } from '../../services/api'
import { notify } from '../../utils/utils'

export const useFlota = () => {
    const urlBaseEmpresas = 'https://empresa.api.abexacloud.com/api/'

    const [empresasGeneral,setEmpresasGeneral] = useState([])
    const [rutasGeneral,setRutasGeneral] = useState([])

    const listarEmpresasBDGeneral = async () => {
        const response = await Fetch({url: urlBaseEmpresas + 'Empresa/listaEmpresa',})
        if(response.isValid){
            setEmpresasGeneral(response.content)
        }else{
            notify(response.content,'error')
        }
    }

    const listarRutasBDGeneral = async (codEmpresa) => {
        const response = await AuthFetch({
            url: process.env.REACT_APP_ATU_API + '/api/ATU/listaRutasXB?' + new URLSearchParams({codEmpresa: codEmpresa}),
        })

        if(response.isValid){
            setRutasGeneral(response.content)
        }else{
            notify(response.exceptions[0].description,'error')
        }
    }


    const listarFrecuencias = async (CodEmpresa,FechaInicio,CodRuta) => {
        const response = await AuthFetch({
            url: process.env.REACT_APP_ATU_API + '/api/Programacion/listaFrecuencia?' + new URLSearchParams({
                CodEmpresa: CodEmpresa,
                FechaInicio: FechaInicio,
                CodRuta: CodRuta
            })
        })

        if(response.isValid){
            return response.content
        }else {
            notify(response.exceptions[0].description,'error')
            return []
        }
    }

    const listarControlesTiempos = async (CodEmpresa,FechaInicio,CodRuta) => {
        const response = await AuthFetch({
            url: process.env.REACT_APP_ATU_API + '/api/Programacion/listaTiempoControles?' + new URLSearchParams({
                CodEmpresa: CodEmpresa,
                FechaInicio: FechaInicio,
                CodRuta: CodRuta
            })
        })

        if(response.isValid){
            return {
                controles:  response.content.listaControles,
                tiempos:    response.content.listaTiempos
            }
        }else{
            notify(response.exceptions[0],'error')
            return {
                controles:  [],
                tiempos:    []
            }
        }

    }

    const consultarRecorridosXRuta = async (CodEmpresa,CodRuta) => {
        const response = await AuthFetch({
            url: process.env.REACT_APP_ATU_API + '/api/Programacion/listaRecorrido?' + new URLSearchParams({
                CodEmpresa: CodEmpresa,
                CodRuta: CodRuta
            })
        })

        if(response.isValid){
            return response.content
        }else{
            notify(response.exceptions[0].description,'error')
            return []
        }
    }
    
    return ({
        listarEmpresasBDGeneral,
        empresasGeneral,
        listarFrecuencias,
        listarControlesTiempos,
        listarRutasBDGeneral,
        rutasGeneral,
        consultarRecorridosXRuta,
    })
}
