import { useState } from 'react'

export const useMenu = (initialValue = true) => {
    const [ isOpen, setIsOpen ] = useState(initialValue)

    const openMenu = (callback) => {
        setIsOpen(true);
    };
    const closeMenu = (callback) => {
        setIsOpen(false)
    };
    
    return [isOpen, openMenu, closeMenu]
}