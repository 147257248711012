import React, { useEffect, useReducer, useRef, useState } from 'react'
import { GoogleMap } from '../../utils/googlemaps';
import { Raphael,Image ,Path , Rect, Paper, Set, Circle, Ellipse, Text } from "react-raphael";
import { useMonitoreo } from '../../hooks/ATU/useMonitoreo';
import carroA from '../../assets/images/Unidad/BusesXLado/imgBusA.png'
import carroB from '../../assets/images/Unidad/BusesXLado/imgBusB.png'
import { Timer } from '../modules/layout/Timer';
import { useHeaderContext } from '../../context/provider/HeaderProvider';
import { Inspectoria } from './Inspectoria';
import sinImagen from '../../assets/images/sinImagen.jpg'
import { ComponenteCarga } from '../components/forms/ComponenteCarga';

import busPrueba from '../../assets/images/Unidad/BusPlomo11x11.gif'
import busRojo from '../../assets/images/Unidad/BusRojo16x16.gif'
import busAzul from '../../assets/images/Unidad/BusAzul16x16.gif'
import { MapContainer, Marker, Polyline, Popup, TileLayer, useMap } from 'react-leaflet';
import L from 'leaflet';


const googleMap = new GoogleMap();
const googleMapInspectoria = new GoogleMap();

export const Monitoreo = () => {

    const marcadoresLeaflet = useRef({})
    const dioClickMarcadorLeaflet = (placa) => {
        const marcador = marcadoresLeaflet.current[placa]
        if(marcador){
            marcador.openPopup()
        }
    }

    const iconoCustomizadoAzul = new L.icon({
        iconUrl: busAzul,
        iconSize: [20, 20], 
        // iconAnchor: [19, 38],
        // popupAnchor: [0, -38],
    })
    const iconoCustomizadoRojo = new L.icon({
        iconUrl: busRojo,
        iconSize: [20, 20], 
        // iconAnchor: [19, 38],
        // popupAnchor: [0, -38],
    })

    const mapDiv = useRef()
    const mapInspectoria = useRef()
    const rafaRef = useRef()
    const infoWindow = googleMap.crearInfowindow({});

    const {
            empresaSeleccionada,
            rutaSeleccionada,
            setEmpresaSeleccionada,
            setRutaSeleccionada,
            listarTrackUnidades,
            trackUnidades,
            trackUnidadesA,
            trackUnidadesB,
            alertas,
            listarControles,
            controlesA,
            distanciaTotalA,
            controlesB,
            distanciaTotalB,
            listarIndicadores,
            indicadores,
            listarPolilineasRecorridos,
            polilineas,
            listarReportesInspectoria,
            reportesInspectoria,
            listarOperatividad,
            operatividad,
            listarAlertasGeneral,
            alertasGeneral
    } = useMonitoreo()

    const [nombreEmpresaSeleccionada,setNombreEmpresaSeleccionada] = useState('')
    const [nombreRutaSeleccionada,setNombreRutaSeleccionada] = useState('')
    
    const [anchoRectPequenio,setAnchoRectPequenio] = useState(0)
    const [anchoRectGrande,setAnchoRectGrande] = useState(0)
    
    const [positionDivLeft,setPositionDivLeft] = useState(false)
    const [positionDivRight,setPositionDivRight] = useState(false)

    const [verControlCompletoA,setVerControlCompletoA] = useState(false)
    const [nombreControlCompletoA,setNombreControlCompletoA] = useState(false)

    const [verControlCompletoB,setVerControlCompletoB] = useState(false)
    const [nombreControlCompletoB,setNombreControlCompletoB] = useState(false)

    const [markerInfoWindowOpen,setMarkerInfoWindowOpen] = useState(0)

    const { showHeader, setShowHeader } = useHeaderContext();

    const [trSeleccionado,setTrSeleccionado] = useState(null)
    const [arrayMarcadores,setArrayMarcadores] = useState([])

    const [estaCargando,setEstaCargando] = useState(false)
    const [tbEmpresasFull,setTbEmpresasFull] = useState(false)

    const [mapaMostrado,setMapaMostrado] = useState(1)
    
    
    useEffect(() => {
        setShowHeader(false)    //PARA OCULTAR EL HEADER EN ESTE COMPONENTE

        googleMap.inicializarMapa(mapDiv.current, { zoom: 11 });
        googleMapInspectoria.inicializarMapa(mapInspectoria.current, { zoom: 11 });

        (async () => {
            setEstaCargando(true)
            await listarReportesInspectoria()
            await listarOperatividad()
            await listarAlertasGeneral()
            await listarIndicadores()
            setEstaCargando(false)
        })();

        setTimeout(() => {
            setAnchoRectPequenio(rafaRef.current.offsetWidth - 200)
            setAnchoRectGrande(rafaRef.current.offsetWidth - 85)
        }, 600);

        const timer20segundos = setInterval(async () => {
            await listarReportesInspectoria()
            await listarOperatividad()
            await listarAlertasGeneral()
            await listarIndicadores()
        }, 30000)

        return () => {
            clearInterval(timer20segundos)
            setShowHeader(true)     //PARA MOSTRAR EL HEADER NUEVAMENTE EN LOS OTROS MODULOS
        }
    }, [])

    useEffect(() => {
        if(reportesInspectoria.length != 0){
            googleMapInspectoria.quitarTodosLosMarcadores()
            const marcadorInpectoria = googleMapInspectoria.crearMarcador({
                latLng: {
                    lat: reportesInspectoria[0].latitudIncidencia,
                    lng: reportesInspectoria[0].longitudIncidencia
                }
            })
            googleMapInspectoria.map.setCenter(marcadorInpectoria.getPosition())
        }
    },[reportesInspectoria])

    useEffect(() => {
        if(empresaSeleccionada){
            const funcionReady = async () => {
                await listarControles()
                await listarTrackUnidades()
                await listarPolilineasRecorridos()
                setEstaCargando(false)
            }
            
            funcionReady()

            const timerTrackUnidades = setInterval(async () => {
                empresaSeleccionada && await listarTrackUnidades()
            }, 15000)

            return () => {
                clearInterval(timerTrackUnidades)
            }
        }
    },[empresaSeleccionada,rutaSeleccionada])

    useEffect(() => {
        if(trackUnidades.length != 0){
            const arrayLLenado = []
            googleMap.quitarTodosLosMarcadores()
            trackUnidades.forEach((data,i) => {
                // SE ESTA COMENTANDO PARA VER SI ESTO ESTA DEMORANDO EL CODIGO
                // const { default: icono } = await import(`../../assets/images/Unidad/${data.icoMarcador}`);

                // const marcadorCustomizado = {
                //     scaledSize: new window.google.maps.Size(20,20),
                //     url: icono,
                // }
                
                const marker = googleMap.crearMarcador({
                    latLng: {
                        lat: data.latitudRegistro,
                        lng: data.longitudRegistro
                    },
                    icon: data.lado=='A' ? busAzul : busRojo
                    // icon: marcadorCustomizado,
                })

                arrayLLenado.push({
                    padron: data.padron,
                    marcador: marker,
                })

                const contenidoInfoWindow = `
                                            <div style="display:grid;grid-template-columns:1fr 2fr;gap:6px;">
                                                <div>Posteo:</div>
                                                <div>${data.fechaHoraPaso}</div>
                                                <div>Padrón:</div>
                                                <div>${data.padron} ${data.placa}</div>
                                                <div>Velocidad:</div>
                                                <div>${data.velocidadRegistro} Km/h</div>
                                                <div>Sentido:</div>
                                                <div>${data.predeterminado}</div>
                                                <div>Conductor:</div>
                                                <div>${data.conductor}</div>
                                                <div>F.Despacho:</div>
                                                <div>${data.fechaSalida || '-'}</div>
                                                <div>Orientación:</div>
                                                <div>${data.rumbo}</div>
                                            </div>
                                            `

                const abrirInfoWindow = () => {
                    infoWindow.setContent(contenidoInfoWindow);
                    infoWindow.open({
                        anchor: marker,
                        map: googleMap.map,
                    })
                }

                if(data.padron == markerInfoWindowOpen){        //PARA ABRIR EL INFO WINDOW DEL MARCADOR QUE SE ESTABA VIENDO
                    abrirInfoWindow()
                    infoWindow.addListener('closeclick', () => {    //PARA QUE CUANDO SE CIERRE YA NO SE ABRA AUTOMATICAMENTE
                        setMarkerInfoWindowOpen('')
                    });
                }

                marker.addListener('click', () => {
                    setMarkerInfoWindowOpen(data.padron)
                    abrirInfoWindow()
                })
            })
            setArrayMarcadores(arrayLLenado)
        }
    },[trackUnidades])

    useEffect(() => {
        if(polilineas.length != 0){
            googleMap.quitarTodosLasPolilineas()
            polilineas.forEach((data,i) => {
                const puntosRecorrido = data.puntosRecorridoLineaMapa.split('*').map((data2,j) => {
                    return({
                        lat:Number(data2.split(',')[1]),
                        lng:Number(data2.split(',')[0])
                    })
                })
                googleMap.crearPolilinea({
                    listLatLng: puntosRecorrido,
                    color: i%2==0 ? '#0000FF' : '#FF0000',
                    strokeWeight: 3,
                    strokeOpacity: 0.5,
                })
                if(i == 0){
                    const bounds = new window.google.maps.LatLngBounds();
                    puntosRecorrido.forEach(coord => bounds.extend(coord));
                    googleMap.map.fitBounds(bounds);
                }
            })
        }

    },[polilineas])

    const distanciaEntrePuntos = (lat1, lon1, lat2, lon2) => {
        const toRadians = (grados) => {
            return grados * (Math.PI / 180);
        }
        const radioTierra = 6371000.0;  //RADIO DE LA TIERRA EN METROS
        const lat1Rad = toRadians(lat1);
        const lon1Rad = toRadians(lon1);
        const lat2Rad = toRadians(lat2);
        const lon2Rad = toRadians(lon2);
        const dLat = lat2Rad - lat1Rad;
        const dLon = lon2Rad - lon1Rad;
        const a = Math.sin(dLat / 2) ** 2 + Math.cos(lat1Rad) * Math.cos(lat2Rad) * Math.sin(dLon / 2) ** 2;
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distancia = radioTierra * c;
        return distancia;   //RETORNA DISTANCIA ENTRE PUNTOS EN METROS
    }

    const obtenerFechaConFormato = () => {
        const opcionesFecha = { day: 'numeric', month: 'long', year: 'numeric' };
        const fechaActual = new Date();
        const formatoFecha = new Intl.DateTimeFormat('es-ES', opcionesFecha);
        const fechaFormateada = formatoFecha.format(fechaActual);
        return fechaFormateada;
    };

    const FitBoundsToPolyline = (positions) => {
        const map = useMap();
        
        useEffect(() => {
            const bounds = positions.map(pos => [pos[0], pos[1]]);
            map.fitBounds(bounds);
        }, [positions]);
        
        return null;
    };

    return (
        <>  
            <ComponenteCarga variableCarga={estaCargando}></ComponenteCarga>
            <div className='flex flex-row h-screen overflow-auto'>
                <div className={`${positionDivLeft ? '' : 'w-0'} transition-all flex lg:hidden flex-col items-center w-[350px] relative`}>
                    <div className='w-full text-center py-2 bg-[#037afc] overflow-hidden'>INSPECTORIA EN RUTA</div>
                    <div className='w-full h-[73px] text-center mt-5 text-2xl overflow-hidden'>
                        <div>Información de campo<br/>Control de inspectoría</div>
                    </div>
                    <div className='w-full h-full flex items-center justify-center overflow-hidden'>
                        <div className='mx-5 px-2 h-auto py-5 border rounded-tr-2xl rounded-bl-2xl flex flex-col items-center gap-3 shadow-md shadow-white'>
                        {
                            reportesInspectoria.map((data,i) => {
                                if(i==0){
                                    return(
                                        <React.Fragment key={i+1}>
                                            <img 
                                                className='w-[250px] max-h-[350px]' 
                                                src={
                                                    data.imagen? 
                                                        `https://minio.api.abexacloud.com/api/Archivo/verImagen?NombreCarpeta=photos-incidents-instra&NombreImagen=${(data.imagen).split('*')[0]}`
                                                        :
                                                        sinImagen
                                                }
                                            />
                                            <div className='grid grid-cols-2 gap-2 text-xs w-full'>
                                                <div className='font-bold'>EMPRESA:</div>
                                                <div>{data.nomEmpresa}</div>
                                                <div className='font-bold'>UBICACIÓN:</div>
                                                <div>{data.nomControl}</div>
                                                <div className='font-bold'>RUTA:</div>
                                                <div>{data.nomRuta}</div>
                                                <div className='font-bold'>PADRÓN:</div>
                                                <div>{data.padronUnidad}</div>
                                                <div className='font-bold'>F.REGISTRO:</div>
                                                <div>{data.fechaHoraRegistro}</div>
                                                <div className='font-bold'>OBSERVACIÓN:</div>
                                            </div>
                                            <div className='text-xs'>{data.observacion ||'-'}</div>
                                        </React.Fragment>
                                    )}
                            })
                        }
                            <div ref={mapInspectoria} className='w-full h-[200px]'></div>
                        </div>
                    </div>
                    <button onClick={() => setPositionDivLeft(!positionDivLeft)} className={`fa fa-angle-${positionDivLeft ? 'left' : 'right'} w-7 h-10 rounded-r-2xl bg-[#017eff] absolute z-20 right-[-28px] top-1/2`}></button>
                </div>
                
                <div className='flex flex-col flex-grow relative lg:max-w-full'>
                    <div className='containerScroll flex-grow overflow-auto'>
                        <table className='table lg:text-[10px]'>
                            <thead>
                                <tr>
                                    <th rowSpan={2}>Nº</th>
                                    <th rowSpan={2}>EMPRESA</th>
                                    <th rowSpan={2}>RUTA</th>
                                    <th colSpan={3}>FLOTA VEHICULAR</th>
                                    <th colSpan={3}>OPERACION</th>
                                    <th colSpan={5}>INDICADORES</th>
                                    <th rowSpan={2}>INCIDENCIAS</th>
                                    <th rowSpan={2}></th>
                                </tr>
                                <tr>
                                    <th>TOTAL</th>
                                    <th>OPERATIVA</th>
                                    <th>PORCENTAJE</th>
                                    <th>VIAJES</th>
                                    <th>KM</th>
                                    <th>PASAJEROS</th>
                                    <th>PB</th>
                                    <th>IPK</th>
                                    <th>IPB</th>
                                    <th>PP</th>
                                    <th>IKB</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    indicadores.length != 0 ? 
                                        indicadores.map((data,i) => {
                                            return(
                                                <tr key={i+1} 
                                                    onClick={() => {
                                                        setEstaCargando(true)
                                                        setEmpresaSeleccionada(data.codEmpresa)
                                                        setRutaSeleccionada(data.codRuta)
                                                        setNombreEmpresaSeleccionada(data.nomEmpresa)
                                                        setNombreRutaSeleccionada(data.nomRuta)
                                                        setTrSeleccionado(i)
                                                        setTbEmpresasFull(false)
                                                    }}
                                                    className={`${trSeleccionado == i ? '!bg-[#017eff]' : ''}`}
                                                >
                                                    <td className='text-center'>{i+1}</td>
                                                    <td className='text-center'>{data.nomEmpresa}</td>
                                                    <td className='text-center'>{data.nomRuta}</td>
                                                    <td className='text-center'>{data.numFlota}</td>
                                                    <td className='text-center'>{data.numOperativa}</td>
                                                    <td className='text-center'>{data.porcentOperativa}</td>
                                                    <td className='text-center'>{data.numViajes}</td>
                                                    <td className='text-center'>{data.kilometros}</td>
                                                    <td className='text-center'>{data.totalPasajeros}</td>
                                                    <td className='text-center'>{data.pb}</td>
                                                    <td className='text-center'>{data?.ip || '0'}</td>
                                                    <td className='text-center'>{data.ipb}</td>
                                                    <td className='text-center'>{data.pp}</td>
                                                    <td className='text-center'>{data.ikb}</td>
                                                    <td className='text-center'></td>
                                                    <td className='text-center'></td>
                                                </tr>
                                            )
                                        })
                                    :   <tr><td colSpan="15" className='text-center'>Cargando...</td></tr>
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className='h-1 justify-center text-center' style={{zIndex:'100000'}}>
                        <button 
                            title='Expandir/Contraer' 
                            className={`${tbEmpresasFull? 'fa fa-angle-double-up': 'fa fa-angle-double-down'} -top-5 relative w-[50px] text-xs text-center align-center rounded z-50 bg-gray-500 text-white shadow-lg shadow-black`} 
                            onClick={() => setTbEmpresasFull(!tbEmpresasFull)}
                        ></button>
                    </div>
    
                    <div ref={rafaRef} className={`${tbEmpresasFull ? 'hidden' : ''} lg:hidden w-full border border-white h-[350px] relative bg-black`}>
                        <div className='w-[150px] h-[20px] rounded-[50px] absolute animated-element-A bg-gradient-to-r from-black to-red-700 left-[100px] top-[320px]'></div>
                        <div className='w-[150px] h-[20px] rounded-[50px] absolute animated-element-B bg-gradient-to-l to-red-700 from-black left-[100px] top-[10px]'></div>

                        <Paper width={'100%'} height={350} attr={{'fill':'black'}}>
                            <Set>
                                <Rect x={40} y={65} width={anchoRectGrande} height={225} attr={{"fill":"black","stroke":"white","stroke-width":2,"r":10}}/>
                                <Rect x={90} y={140} width={anchoRectPequenio} height={75} attr={{"fill":"black","stroke":"white","stroke-width":2,"r":10}}/>
                                <Ellipse x={40} y={180} ry={13} rx={13} attr={{"fill":"#0f74ef","stroke":"white"}} />
                                <Ellipse x={anchoRectGrande + 40} y={180} ry={13} rx={13} attr={{"fill":"#884dff","stroke":"white"}} />
                                <Text x={40} y={180} text="A" attr={{ 'fill': 'white', 'font-size': 12, 'text-anchor': 'middle', 'alignment-baseline': 'middle' }} />
                                <Text x={anchoRectGrande + 40} y={180} text="B" attr={{ 'fill': 'white', 'font-size': 12, 'text-anchor': 'middle', 'alignment-baseline': 'middle' }}/>
                                
                                {
                                    empresaSeleccionada ? 
                                        <Text x={anchoRectPequenio/2 + 100} y={175} text={`" ${nombreEmpresaSeleccionada} - ${nombreRutaSeleccionada} "`} attr={{ "fill": 'white', "font-size": 22, "font-family": "Arial, Helvetica, sans-serif",}}/>
                                    :
                                        <Text x={anchoRectPequenio/2 + 100} y={175} text="Seleccionar una empresa" attr={{ "fill": 'white', "font-size": 20, "font-family": "Arial, Helvetica, sans-serif",}}/>
                                }
                                
                                {
                                    verControlCompletoA && <Text x={anchoRectPequenio/2 + 100} y={335} text={nombreControlCompletoA} attr={{ "fill": 'green', "font-size": 13, "font-family": "Arial, Helvetica, sans-serif",}}/>
                                }
                                {
                                    verControlCompletoB && <Text x={anchoRectPequenio/2 + 100} y={15} text={nombreControlCompletoB} attr={{ "fill": 'green', "font-size": 13, "font-family": "Arial, Helvetica, sans-serif",}}/>
                                }
                                

                                {
                                    controlesA.map((data,i) => {
                                        const sumaHasta = controlesA.slice(0, i+1).reduce((acumulador,elem) => {
                                            return (acumulador + elem.distanciaControlAnterior)
                                        },0)

                                        const posicionX = ((anchoRectPequenio * sumaHasta)/distanciaTotalA) + 100

                                        if(isNaN(posicionX))    return
                                        
                                        return(
                                            <React.Fragment key={i+1}>
                                                <Path d={[`M${posicionX} ${315}L${posicionX} ${291}`]} attr={{ 'stroke': 'white' }} />
                                                <Text x={posicionX - 8} y={304} text={data.abrev} attr={{ "fill": 'white', "font-size": 10, "font-family": "Arial, Helvetica, sans-serif", "transform": "r-90", 'cursor': 'pointer' }} mouseover={() => {setVerControlCompletoA(true);setNombreControlCompletoA(data.nomControl)}} mouseout={() => {setVerControlCompletoA(false)}}/>
                                            </React.Fragment>
                                        )
                                    })
                                }

                                {
                                    controlesB.map((data,i) => {
                                        const sumaHasta = controlesB.slice(0,i).reduce((acumulador,elem) => {
                                            return (acumulador + elem.distanciaControlAnterior)
                                        },0)

                                        const posicionX = ((anchoRectPequenio * sumaHasta)/distanciaTotalB) + 100

                                        if(isNaN(posicionX))    return

                                        return(
                                            <React.Fragment key={i+1}>
                                                <Path d={[`M${posicionX} ${64}L${posicionX} ${38}`]} attr={{ 'stroke': 'white' }} />
                                                <Text x={posicionX - 8} y={50} text={data.abrev} attr={{ "fill": 'white', "font-size": 10, "font-family": "Arial, Helvetica, sans-serif", "transform": "r-90", 'cursor': 'pointer' }} mouseover={() => {setVerControlCompletoB(true);setNombreControlCompletoB(data.nomControl)}} mouseout={() => {setVerControlCompletoB(false)}}/>
                                            </React.Fragment>
                                        )
                                    })
                                }

                                {
                                    trackUnidadesA.map((data,i) => {
                                        const controlAnterior = data.codControl
                                        
                                        if((controlesA.length != 0) && (controlesA.some(elem => elem.codControl == controlAnterior) || controlAnterior == 0)){
                                            const indiceControlAnterior = controlAnterior == 0 ? 0 : controlesA.findIndex(elem => elem.codControl == controlAnterior)
                                            const distanciaControlAnterior = distanciaEntrePuntos(data.latitudRegistro,data.longitudRegistro,controlesA[indiceControlAnterior].latitud,controlesA[indiceControlAnterior].longitud)
                                            const distanciaHastaControlAnterior = controlesA.slice(0,indiceControlAnterior + 1).reduce((acumulador,elem) => {
                                                return (acumulador + elem.distanciaControlAnterior)
                                            },0)

                                            const diferenciaPosicionAnterior = distanciaEntrePuntos(data.latitudRegistro,data.longitudRegistro,data.latitudRegistroAnterior,data.longitudRegistroAnterior)
                                            const posicionXAnterior = (((distanciaHastaControlAnterior + (distanciaControlAnterior - diferenciaPosicionAnterior)) * anchoRectPequenio) / distanciaTotalA) + 100 - 12
                                            const posicionX = (((distanciaHastaControlAnterior + distanciaControlAnterior) * anchoRectPequenio) / distanciaTotalA) + 100 - 12

                                            const posicionY = 250
                                            return(
                                                <React.Fragment key={i+1}>
                                                    <Rect x={posicionXAnterior} y={posicionY} width={25} height={25} attr={{ "fill":"#032cfc" }} animate={Raphael.animation({"x": posicionX},2000,"<>")} />
                                                    <Image src={carroA} x={posicionXAnterior} y={posicionY} width={25} height={25} attr={{'cursor':'pointer'}} animate={Raphael.animation({"x": posicionX},2000,"<>")} click={() => {dioClickMarcadorLeaflet(data.placa);setMarkerInfoWindowOpen(data.padron);window.google.maps.event.trigger(arrayMarcadores.find(elem => elem.padron == data.padron).marcador,'click')}}/>
                                                    <Text x={posicionXAnterior + 8} y={posicionY - 15} text={data.padron} attr={{ fill: 'white', "font-size": 11, 'cursor': 'pointer', "font-family": "Arial, Helvetica, sans-serif" , "transform": "r-90" }} animate={Raphael.animation({"y": (posicionY - 15 + (posicionX-posicionXAnterior))},2000,"<>")}/>
                                                </React.Fragment>
                                            )
                                        }
                                    })
                                }

                                {
                                    trackUnidadesB.map((data,i) => {
                                        const controlAnterior = data.codControl
                                        
                                        if((controlesB.length != 0) && (controlesB.some(elem => elem.codControl == controlAnterior || controlAnterior == 0))){
                                            const controlesB_reversa = controlesB.slice().reverse()
                                            const indiceControlAnterior = controlAnterior == 0 ? 0 : controlesB_reversa.findIndex(elem => elem.codControl == controlAnterior)
                                            const distanciaControlAnterior = distanciaEntrePuntos(data.latitudRegistro,data.longitudRegistro,controlesB_reversa[indiceControlAnterior].latitud,controlesB_reversa[indiceControlAnterior].longitud)
                                            const distanciaHastaControlAnterior = controlesB_reversa.slice(0,indiceControlAnterior + 1).reduce((acumulador,elem) => {
                                                return (acumulador + elem.distanciaControlAnterior)
                                            },0)

                                            const diferenciaPosicionAnterior = distanciaEntrePuntos(data.latitudRegistro,data.longitudRegistro,data.latitudRegistroAnterior,data.longitudRegistroAnterior)
                                            const posicionXAnterior = ((Math.max((distanciaTotalB - (distanciaControlAnterior + (distanciaHastaControlAnterior - diferenciaPosicionAnterior))),0) * anchoRectPequenio) / distanciaTotalB) + 100 - 12
                                            const posicionX = ((Math.max((distanciaTotalB - (distanciaControlAnterior + distanciaHastaControlAnterior)),0) * anchoRectPequenio) / distanciaTotalB) + 100 - 12
                                            
                                            const posicionY = 100
                                            return(
                                                <React.Fragment key={i+1}>
                                                    <Rect x={posicionXAnterior} y={posicionY} width={25} height={25} attr={{ "fill": "#d90007" }} animate={Raphael.animation({"x": posicionX},2000,"<>")}/>
                                                    <Image src={carroB} x={posicionXAnterior} y={posicionY} width={25} height={25} attr={{'cursor':'pointer'}} animate={Raphael.animation({"x": posicionX},2000,"<>")} click={() => {setMarkerInfoWindowOpen(data.padron);window.google.maps.event.trigger(arrayMarcadores.find(elem => elem.padron == data.padron).marcador,'click')}} />
                                                    <Text x={posicionXAnterior + 8} y={posicionY - 15} text={data.padron} attr={{ fill: 'white', "font-size": 11, 'cursor': 'pointer', "font-family": "Arial, Helvetica, sans-serif", "transform": "r-90" }} animate={Raphael.animation({"y": posicionY - 15 - (posicionXAnterior - posicionX)},2000,"<>")}/>
                                                </React.Fragment>
                                            )
                                        }
                                    })
                                }

                            </Set>
                        </Paper>
                    </div>

                    <div className={`${tbEmpresasFull ? 'hidden' : 'flex'} flex-none h-[37%] lg:h-1/2`}>
                        <div className='containerScroll w-1/2 overflow-auto lg:hidden'>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>N°</th>
                                        <th>HORA</th>
                                        <th>UNIDAD</th>
                                        <th>ALERTA</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        alertas.length != 0 ?
                                            alertas.map((data,i) => {
                                                return (
                                                    <tr key={i+1}>
                                                        <td className='text-center'>{i+1}</td>
                                                        <td className='text-center'>{data.hora}</td>
                                                        <td className='text-center'>{data.padron}</td>
                                                        <td className='flex justify-center'>
                                                            <div className='flex items-center gap-3 w-full'>
                                                                <i className='fa fa-circle' style={{color:`${data.color}`}} aria-hidden="true"></i>
                                                                <div className=''>{data.alerta}</div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        :   <tr><td colSpan="4" className='text-center'>No hay información para mostrar...</td></tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className='flex-grow relative'>
                            <div ref={mapDiv} className={`w-full h-full`}></div>
                            
                            {/* <MapContainer center={[51.505, -0.09]} zoom={13} style={{ height: '100%', width: '100%', display:`${mapaMostrado == 2 ? '' : 'none'}` }}>
                                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'/>
                                {
                                    trackUnidades.map((data,i) => {
                                        return(
                                            <React.Fragment key={i+1}>
                                                <Marker 
                                                    position={[data.latitudRegistro,data.longitudRegistro]} 
                                                    icon={data.lado == 'A' ? iconoCustomizadoAzul : iconoCustomizadoRojo}
                                                    ref={el => (marcadoresLeaflet.current[data.placa] = el)}
                                                >
                                                    <Popup>
                                                        <div style={{display:'grid',gridTemplateColumns:'1fr 2fr',gap:'6px',padding:'10px',border:'1px dashed black',borderRadius:'10px'}}>
                                                            <div>Posteo:</div>
                                                            <div>{data.fechaHoraPaso}</div>
                                                            <div>Padrón:</div>
                                                            <div>{data.padron} {data.placa}</div>
                                                            <div>Velocidad:</div>
                                                            <div>{data.velocidadRegistro} Km/h</div>
                                                            <div>Sentido:</div>
                                                            <div>{data.predeterminado}</div>
                                                            <div>Conductor:</div>
                                                            <div>{data.conductor}</div>
                                                            <div>F.Despacho:</div>
                                                            <div>{data.fechaSalida || '-'}</div>
                                                            <div>Orientación:</div>
                                                            <div>{data.rumbo}</div>
                                                        </div>
                                                    </Popup>
                                                </Marker>
                                            </React.Fragment>
                                        )
                                    })
                                }
                                {
                                    polilineas.map((data,i) => {
                                        const polylinePositions = data.puntosRecorridoLineaMapa.split('*').map(e => ([e.split(',')[1],e.split(',')[0]]))
                                        return(
                                            <React.Fragment key={i+1}>
                                                <Polyline positions={polylinePositions} color={i%2 == 0 ? 'red' : 'blue'}></Polyline>
                                                <FitBoundsToPolyline positions={polylinePositions}></FitBoundsToPolyline>
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </MapContainer> */}

                            {/* PARA ALTERNAR ENTRE GOOGLEMAPS Y LEAFLET */}
                            {/* <div className='absolute w-[200px] top-0 right-0 text-xs' style={{'zIndex':100000}}>
                                <button className='w-1/2 h-full py-2 bg-gray-600 border' onClick={() => setMapaMostrado(1)}>GoogleMaps</button>
                                <button className='w-1/2 h-full py-2 bg-gray-600 border' onClick={() => setMapaMostrado(2)}>OpenStreetMap</button>
                            </div> */}
                        </div>
                    </div>

                    <div className='w-[165px] h-[60px] bg-[#007efe] absolute bottom-0 left-1/2 -translate-x-1/2 rounded-t-[45px] flex lg:hidden flex-col items-center justify-center z-[1000]'>
                        <Timer clases="text-white text-3xl"></Timer>
                        <div className='text-[10px]'>{obtenerFechaConFormato()}</div>
                    </div>
                </div>

                <div className={`${positionDivRight ? '' : 'w-0'} transition-all flex lg:hidden flex-col items-center w-[350px] relative`}>
                    <div className='w-full h-full overflow-hidden  flex-col'>
                        <div className='w-full py-2 bg-[#037afc] text-center'>INDICADORES DE OPERACIÓN</div>
                        <div className='w-full h-full flex items-start pt-4 justify-center px-2'>
                            <div className='px-2 flex flex-col gap-7 item-center justify-center'>
                                <div className='flex flex-col gap-5'>
                                    <div className='font-bold text-xl'>OPERACIÓN HOY:</div>
                                    <div className='grid grid-cols-4 gap-2 text-md'>
                                        <div>Empresas</div>
                                        <div>{operatividad.totalEmpresas}</div>
                                        <div>{operatividad.totalEmpresasOperativas}</div>
                                        <div>{operatividad.porcentajeEmpresasOperatividad || 0}%</div>
                                        <div>Rutas</div>
                                        <div>{operatividad.totalRutas}</div>
                                        <div>{operatividad.totalRutasOperativas}</div>
                                        <div>{operatividad.porcentajeTotalRutasOperativas || 0}%</div>
                                        <div>Unidades</div>
                                        <div>{operatividad.totalUnidades}</div>
                                        <div>{operatividad.totalUnidadesOperativas}</div>
                                        <div>{operatividad.porcentajeTotalUnidadesOperativas || 0}%</div>
                                    </div>
                                </div>
                                <div className='flex flex-col gap-5'>
                                    <div className='font-bold text-xl'>INCIDENCIAS HOY:</div>
                                    {/* <div className='flex flex-col gap-4 text-xs'>
                                        {
                                            Object.entries(Object.groupBy(alertasGeneral,(e) => e.codDispositivoTipo)).map(([key,values],i) => {
                                                return(
                                                    <React.Fragment key={i+1}>
                                                        <div className='font-bold underline'>{key == 1 ? 'GPS' : 'VALIDADOR'}:</div>
                                                        <div className='grid grid-cols-3 gap-2'>
                                                        {
                                                            values.map((data,j) => {
                                                                return(
                                                                    <React.Fragment key={j+1}>
                                                                        <div className="col-span-2">{data.nomAlerta}</div>
                                                                        <div>{data.cantidadAlertas}</div>
                                                                    </React.Fragment>
                                                                )
                                                            })
                                                        }
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                    </div> */}
                                    <div className='flex flex-col gap-4 text-xs'>
                                        {
                                            Object.entries(
                                                alertasGeneral.reduce((acumulador,elemento,indice) => {         // EN REEMPLAZO AL OBJECT.GROUPBY POR QUE ALGUNOS NAVEGADORES NO LO TIENEN
                                                    const key = elemento.codDispositivoTipo
                                                    !(acumulador[key]) && (acumulador[key] = [])
                                                    acumulador[key].push(elemento)
                                                    return acumulador
                                                },{})
                                            ).map(([key,values],i) => {
                                                return(
                                                    <React.Fragment key={i+1}>
                                                        <div className='font-bold underline'>{key == 1 ? 'OPERACIÓN' : 'PRODUCCIÓN'}:</div>
                                                        <div className='grid grid-cols-3 gap-2'>
                                                        {
                                                            values.map((data,j) => {
                                                                return(
                                                                    <React.Fragment key={j+1}>
                                                                        <div className="col-span-2">{data.nomAlerta}</div>
                                                                        <div>{data.cantidadAlertas}</div>
                                                                    </React.Fragment>
                                                                )
                                                            })
                                                        }
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button onClick={() => setPositionDivRight(!positionDivRight)} className={`fa fa-angle-${positionDivRight ? 'right' : 'left'} w-7 h-10 rounded-l-2xl bg-[#017eff] absolute z-20 left-[-28px] top-1/2`}></button>
                </div>
            </div>
        </>
    )
}

