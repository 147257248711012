import React from "react";
import logoAtu from '../../assets/images/LogoATU-MuniCIP .png'
import packageJson from '../../../package.json'


const principal = () => {
    const version = packageJson.version

    return (
        <section className="w-full h-full flex justify-center items-center">
            <div className="flex flex-col opacity-60">
                <img src={logoAtu} alt="" className="" />
                <span className="text-right text-xs">v. {version}</span>
            </div>
        </section>
    )
};

export default principal;
