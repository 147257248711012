import React from 'react'
import { ContenedorParametros } from '../../components/utils/ContenedorParametros'
import { BotonNuevo } from '../../components/buttons/BotonNuevo'
import { BotonProcesar } from '../../components/buttons/BotonProcesar'
import BotonExcel from '../../components/buttons/BotonExcel'

export const ProcesosProgramacionGeneral = () => {
    const data = [
        {
            "IdProgramacion": 11041,
            "CodigoRuta": "404",
            "NomNombreProgramacion": "POD 404 56 BUSES  12448_prog18_02_2024_10_58_27.xlsx",
            "FechaProgramacion": "23/02/2024",
            "FechaRegistro": "19/02/2024 23:03:29",
            "NomUsuarioRegistro": "gcarrascoa@juntaoperadora",
            "Estado": "PENDIENTE",
            "NomUrlProgramacion": "POD 404 56 BUSES  12448_prog18_02_2024_10_58_27.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 56,
            "nomArchivoOriginal": "POD 404 56 BUSES  12448.309 KM HABIL.xlsx",
            "nomArvhicoInterno": "POD 404 56 BUSES  12448_prog18_02_2024_10_58_27.xlsx",
            "NomFecProgramacion": "23/02/2024",
            "NomFecRegistro": "19/02/2024 23:03:29",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 56,
            "CantidadServiciosEjecut": null,
            "CantidadViajesProgra": 514,
            "CantidadViajesEjecut": null,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 11040,
            "CodigoRuta": "404",
            "NomNombreProgramacion": "POD 404 56 BUSES  12448_prog18_02_2024_10_58_27.xlsx",
            "FechaProgramacion": "22/02/2024",
            "FechaRegistro": "19/02/2024 23:03:29",
            "NomUsuarioRegistro": "gcarrascoa@juntaoperadora",
            "Estado": "PENDIENTE",
            "NomUrlProgramacion": "POD 404 56 BUSES  12448_prog18_02_2024_10_58_27.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 56,
            "nomArchivoOriginal": "POD 404 56 BUSES  12448.309 KM HABIL.xlsx",
            "nomArvhicoInterno": "POD 404 56 BUSES  12448_prog18_02_2024_10_58_27.xlsx",
            "NomFecProgramacion": "22/02/2024",
            "NomFecRegistro": "19/02/2024 23:03:29",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 56,
            "CantidadServiciosEjecut": null,
            "CantidadViajesProgra": 514,
            "CantidadViajesEjecut": null,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 11039,
            "CodigoRuta": "404",
            "NomNombreProgramacion": "POD 404 56 BUSES  12448_prog18_02_2024_10_58_27.xlsx",
            "FechaProgramacion": "21/02/2024",
            "FechaRegistro": "19/02/2024 23:03:29",
            "NomUsuarioRegistro": "gcarrascoa@juntaoperadora",
            "Estado": "PENDIENTE",
            "NomUrlProgramacion": "POD 404 56 BUSES  12448_prog18_02_2024_10_58_27.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 56,
            "nomArchivoOriginal": "POD 404 56 BUSES  12448.309 KM HABIL.xlsx",
            "nomArvhicoInterno": "POD 404 56 BUSES  12448_prog18_02_2024_10_58_27.xlsx",
            "NomFecProgramacion": "21/02/2024",
            "NomFecRegistro": "19/02/2024 23:03:29",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 56,
            "CantidadServiciosEjecut": null,
            "CantidadViajesProgra": 514,
            "CantidadViajesEjecut": null,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 11038,
            "CodigoRuta": "404",
            "NomNombreProgramacion": "POD 404 56 BUSES  12448_prog18_02_2024_10_58_27.xlsx",
            "FechaProgramacion": "20/02/2024",
            "FechaRegistro": "19/02/2024 23:03:29",
            "NomUsuarioRegistro": "gcarrascoa@juntaoperadora",
            "Estado": "PENDIENTE",
            "NomUrlProgramacion": "POD 404 56 BUSES  12448_prog18_02_2024_10_58_27.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 56,
            "nomArchivoOriginal": "POD 404 56 BUSES  12448.309 KM HABIL.xlsx",
            "nomArvhicoInterno": "POD 404 56 BUSES  12448_prog18_02_2024_10_58_27.xlsx",
            "NomFecProgramacion": "20/02/2024",
            "NomFecRegistro": "19/02/2024 23:03:29",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 56,
            "CantidadServiciosEjecut": null,
            "CantidadViajesProgra": 514,
            "CantidadViajesEjecut": null,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 11037,
            "CodigoRuta": "405",
            "NomNombreProgramacion": "POD 405 40 BUSES  HABIL_prog18_02_2024_10_59_19.xlsx",
            "FechaProgramacion": "23/02/2024",
            "FechaRegistro": "19/02/2024 23:01:45",
            "NomUsuarioRegistro": "gcarrascoa@juntaoperadora",
            "Estado": "PENDIENTE",
            "NomUrlProgramacion": "POD 405 40 BUSES  HABIL_prog18_02_2024_10_59_19.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 40,
            "nomArchivoOriginal": "POD 405 40 BUSES  HABIL.xlsx",
            "nomArvhicoInterno": "POD 405 40 BUSES  HABIL_prog18_02_2024_10_59_19.xlsx",
            "NomFecProgramacion": "23/02/2024",
            "NomFecRegistro": "19/02/2024 23:01:45",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 40,
            "CantidadServiciosEjecut": null,
            "CantidadViajesProgra": 382,
            "CantidadViajesEjecut": null,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 11036,
            "CodigoRuta": "405",
            "NomNombreProgramacion": "POD 405 40 BUSES  HABIL_prog18_02_2024_10_59_19.xlsx",
            "FechaProgramacion": "22/02/2024",
            "FechaRegistro": "19/02/2024 23:01:45",
            "NomUsuarioRegistro": "gcarrascoa@juntaoperadora",
            "Estado": "PENDIENTE",
            "NomUrlProgramacion": "POD 405 40 BUSES  HABIL_prog18_02_2024_10_59_19.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 40,
            "nomArchivoOriginal": "POD 405 40 BUSES  HABIL.xlsx",
            "nomArvhicoInterno": "POD 405 40 BUSES  HABIL_prog18_02_2024_10_59_19.xlsx",
            "NomFecProgramacion": "22/02/2024",
            "NomFecRegistro": "19/02/2024 23:01:45",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 40,
            "CantidadServiciosEjecut": null,
            "CantidadViajesProgra": 382,
            "CantidadViajesEjecut": null,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 11035,
            "CodigoRuta": "405",
            "NomNombreProgramacion": "POD 405 40 BUSES  HABIL_prog18_02_2024_10_59_19.xlsx",
            "FechaProgramacion": "21/02/2024",
            "FechaRegistro": "19/02/2024 23:01:45",
            "NomUsuarioRegistro": "gcarrascoa@juntaoperadora",
            "Estado": "PENDIENTE",
            "NomUrlProgramacion": "POD 405 40 BUSES  HABIL_prog18_02_2024_10_59_19.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 40,
            "nomArchivoOriginal": "POD 405 40 BUSES  HABIL.xlsx",
            "nomArvhicoInterno": "POD 405 40 BUSES  HABIL_prog18_02_2024_10_59_19.xlsx",
            "NomFecProgramacion": "21/02/2024",
            "NomFecRegistro": "19/02/2024 23:01:45",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 40,
            "CantidadServiciosEjecut": null,
            "CantidadViajesProgra": 382,
            "CantidadViajesEjecut": null,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 11034,
            "CodigoRuta": "405",
            "NomNombreProgramacion": "POD 405 40 BUSES  HABIL_prog18_02_2024_10_59_19.xlsx",
            "FechaProgramacion": "20/02/2024",
            "FechaRegistro": "19/02/2024 23:01:45",
            "NomUsuarioRegistro": "gcarrascoa@juntaoperadora",
            "Estado": "PENDIENTE",
            "NomUrlProgramacion": "POD 405 40 BUSES  HABIL_prog18_02_2024_10_59_19.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 40,
            "nomArchivoOriginal": "POD 405 40 BUSES  HABIL.xlsx",
            "nomArvhicoInterno": "POD 405 40 BUSES  HABIL_prog18_02_2024_10_59_19.xlsx",
            "NomFecProgramacion": "20/02/2024",
            "NomFecRegistro": "19/02/2024 23:01:45",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 40,
            "CantidadServiciosEjecut": null,
            "CantidadViajesProgra": 382,
            "CantidadViajesEjecut": null,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 11033,
            "CodigoRuta": "406",
            "NomNombreProgramacion": "POD 406 20 BUSES 3,473_prog18_02_2024_10_59_41.xlsx",
            "FechaProgramacion": "23/02/2024",
            "FechaRegistro": "19/02/2024 23:00:59",
            "NomUsuarioRegistro": "gcarrascoa@juntaoperadora",
            "Estado": "PENDIENTE",
            "NomUrlProgramacion": "POD 406 20 BUSES 3,473_prog18_02_2024_10_59_41.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 20,
            "nomArchivoOriginal": "POD 406 20 BUSES 3,473.10 HABIL.xlsx",
            "nomArvhicoInterno": "POD 406 20 BUSES 3,473_prog18_02_2024_10_59_41.xlsx",
            "NomFecProgramacion": "23/02/2024",
            "NomFecRegistro": "19/02/2024 23:00:59",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 20,
            "CantidadServiciosEjecut": null,
            "CantidadViajesProgra": 208,
            "CantidadViajesEjecut": null,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 11032,
            "CodigoRuta": "406",
            "NomNombreProgramacion": "POD 406 20 BUSES 3,473_prog18_02_2024_10_59_41.xlsx",
            "FechaProgramacion": "22/02/2024",
            "FechaRegistro": "19/02/2024 23:00:59",
            "NomUsuarioRegistro": "gcarrascoa@juntaoperadora",
            "Estado": "PENDIENTE",
            "NomUrlProgramacion": "POD 406 20 BUSES 3,473_prog18_02_2024_10_59_41.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 20,
            "nomArchivoOriginal": "POD 406 20 BUSES 3,473.10 HABIL.xlsx",
            "nomArvhicoInterno": "POD 406 20 BUSES 3,473_prog18_02_2024_10_59_41.xlsx",
            "NomFecProgramacion": "22/02/2024",
            "NomFecRegistro": "19/02/2024 23:00:59",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 20,
            "CantidadServiciosEjecut": null,
            "CantidadViajesProgra": 208,
            "CantidadViajesEjecut": null,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 11031,
            "CodigoRuta": "406",
            "NomNombreProgramacion": "POD 406 20 BUSES 3,473_prog18_02_2024_10_59_41.xlsx",
            "FechaProgramacion": "21/02/2024",
            "FechaRegistro": "19/02/2024 23:00:59",
            "NomUsuarioRegistro": "gcarrascoa@juntaoperadora",
            "Estado": "PENDIENTE",
            "NomUrlProgramacion": "POD 406 20 BUSES 3,473_prog18_02_2024_10_59_41.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 20,
            "nomArchivoOriginal": "POD 406 20 BUSES 3,473.10 HABIL.xlsx",
            "nomArvhicoInterno": "POD 406 20 BUSES 3,473_prog18_02_2024_10_59_41.xlsx",
            "NomFecProgramacion": "21/02/2024",
            "NomFecRegistro": "19/02/2024 23:00:59",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 20,
            "CantidadServiciosEjecut": null,
            "CantidadViajesProgra": 208,
            "CantidadViajesEjecut": null,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 11030,
            "CodigoRuta": "406",
            "NomNombreProgramacion": "POD 406 20 BUSES 3,473_prog18_02_2024_10_59_41.xlsx",
            "FechaProgramacion": "20/02/2024",
            "FechaRegistro": "19/02/2024 23:00:59",
            "NomUsuarioRegistro": "gcarrascoa@juntaoperadora",
            "Estado": "PENDIENTE",
            "NomUrlProgramacion": "POD 406 20 BUSES 3,473_prog18_02_2024_10_59_41.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 20,
            "nomArchivoOriginal": "POD 406 20 BUSES 3,473.10 HABIL.xlsx",
            "nomArvhicoInterno": "POD 406 20 BUSES 3,473_prog18_02_2024_10_59_41.xlsx",
            "NomFecProgramacion": "20/02/2024",
            "NomFecRegistro": "19/02/2024 23:00:59",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 20,
            "CantidadServiciosEjecut": null,
            "CantidadViajesProgra": 208,
            "CantidadViajesEjecut": null,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 11029,
            "CodigoRuta": "412",
            "NomNombreProgramacion": "POD 412 36 BUSES 6954_prog18_02_2024_10_59_54.xlsx",
            "FechaProgramacion": "23/02/2024",
            "FechaRegistro": "19/02/2024 22:57:36",
            "NomUsuarioRegistro": "pcaldas2@juntaoperadora",
            "Estado": "PENDIENTE",
            "NomUrlProgramacion": "POD 412 36 BUSES 6954_prog18_02_2024_10_59_54.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 36,
            "nomArchivoOriginal": "POD 412 36 BUSES 6954.90 HABIL.xlsx",
            "nomArvhicoInterno": "POD 412 36 BUSES 6954_prog18_02_2024_10_59_54.xlsx",
            "NomFecProgramacion": "23/02/2024",
            "NomFecRegistro": "19/02/2024 22:57:36",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 36,
            "CantidadServiciosEjecut": null,
            "CantidadViajesProgra": 394,
            "CantidadViajesEjecut": null,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 11028,
            "CodigoRuta": "412",
            "NomNombreProgramacion": "POD 412 36 BUSES 6954_prog18_02_2024_10_59_54.xlsx",
            "FechaProgramacion": "22/02/2024",
            "FechaRegistro": "19/02/2024 22:57:36",
            "NomUsuarioRegistro": "pcaldas2@juntaoperadora",
            "Estado": "PENDIENTE",
            "NomUrlProgramacion": "POD 412 36 BUSES 6954_prog18_02_2024_10_59_54.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 36,
            "nomArchivoOriginal": "POD 412 36 BUSES 6954.90 HABIL.xlsx",
            "nomArvhicoInterno": "POD 412 36 BUSES 6954_prog18_02_2024_10_59_54.xlsx",
            "NomFecProgramacion": "22/02/2024",
            "NomFecRegistro": "19/02/2024 22:57:36",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 36,
            "CantidadServiciosEjecut": null,
            "CantidadViajesProgra": 394,
            "CantidadViajesEjecut": null,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 11027,
            "CodigoRuta": "412",
            "NomNombreProgramacion": "POD 412 36 BUSES 6954_prog18_02_2024_10_59_54.xlsx",
            "FechaProgramacion": "21/02/2024",
            "FechaRegistro": "19/02/2024 22:57:36",
            "NomUsuarioRegistro": "pcaldas2@juntaoperadora",
            "Estado": "PENDIENTE",
            "NomUrlProgramacion": "POD 412 36 BUSES 6954_prog18_02_2024_10_59_54.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 36,
            "nomArchivoOriginal": "POD 412 36 BUSES 6954.90 HABIL.xlsx",
            "nomArvhicoInterno": "POD 412 36 BUSES 6954_prog18_02_2024_10_59_54.xlsx",
            "NomFecProgramacion": "21/02/2024",
            "NomFecRegistro": "19/02/2024 22:57:36",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 36,
            "CantidadServiciosEjecut": null,
            "CantidadViajesProgra": 394,
            "CantidadViajesEjecut": null,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 11026,
            "CodigoRuta": "412",
            "NomNombreProgramacion": "POD 412 36 BUSES 6954_prog18_02_2024_10_59_54.xlsx",
            "FechaProgramacion": "20/02/2024",
            "FechaRegistro": "19/02/2024 22:57:36",
            "NomUsuarioRegistro": "pcaldas2@juntaoperadora",
            "Estado": "PENDIENTE",
            "NomUrlProgramacion": "POD 412 36 BUSES 6954_prog18_02_2024_10_59_54.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 36,
            "nomArchivoOriginal": "POD 412 36 BUSES 6954.90 HABIL.xlsx",
            "nomArvhicoInterno": "POD 412 36 BUSES 6954_prog18_02_2024_10_59_54.xlsx",
            "NomFecProgramacion": "20/02/2024",
            "NomFecRegistro": "19/02/2024 22:57:36",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 36,
            "CantidadServiciosEjecut": null,
            "CantidadViajesProgra": 394,
            "CantidadViajesEjecut": null,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 11025,
            "CodigoRuta": "412",
            "NomNombreProgramacion": "POD 412 36 BUSES 6954_prog18_02_2024_10_59_54.xlsx",
            "FechaProgramacion": "19/02/2024",
            "FechaRegistro": "18/02/2024 22:59:54",
            "NomUsuarioRegistro": "gcisneros@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 412 36 BUSES 6954_prog18_02_2024_10_59_54.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 36,
            "nomArchivoOriginal": "POD 412 36 BUSES 6954.90 HABIL.xlsx",
            "nomArvhicoInterno": "POD 412 36 BUSES 6954_prog18_02_2024_10_59_54.xlsx",
            "NomFecProgramacion": "19/02/2024",
            "NomFecRegistro": "18/02/2024 22:59:54",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 36,
            "CantidadServiciosEjecut": 36,
            "CantidadViajesProgra": 394,
            "CantidadViajesEjecut": 362,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 11024,
            "CodigoRuta": "406",
            "NomNombreProgramacion": "POD 406 20 BUSES 3,473_prog18_02_2024_10_59_41.xlsx",
            "FechaProgramacion": "19/02/2024",
            "FechaRegistro": "18/02/2024 22:59:41",
            "NomUsuarioRegistro": "gcisneros@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 406 20 BUSES 3,473_prog18_02_2024_10_59_41.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 20,
            "nomArchivoOriginal": "POD 406 20 BUSES 3,473.10 HABIL.xlsx",
            "nomArvhicoInterno": "POD 406 20 BUSES 3,473_prog18_02_2024_10_59_41.xlsx",
            "NomFecProgramacion": "19/02/2024",
            "NomFecRegistro": "18/02/2024 22:59:41",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 20,
            "CantidadServiciosEjecut": 20,
            "CantidadViajesProgra": 208,
            "CantidadViajesEjecut": 182,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 11023,
            "CodigoRuta": "405",
            "NomNombreProgramacion": "POD 405 40 BUSES  HABIL_prog18_02_2024_10_59_19.xlsx",
            "FechaProgramacion": "19/02/2024",
            "FechaRegistro": "18/02/2024 22:59:20",
            "NomUsuarioRegistro": "gcisneros@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 405 40 BUSES  HABIL_prog18_02_2024_10_59_19.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 40,
            "nomArchivoOriginal": "POD 405 40 BUSES  HABIL.xlsx",
            "nomArvhicoInterno": "POD 405 40 BUSES  HABIL_prog18_02_2024_10_59_19.xlsx",
            "NomFecProgramacion": "19/02/2024",
            "NomFecRegistro": "18/02/2024 22:59:20",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 40,
            "CantidadServiciosEjecut": 35,
            "CantidadViajesProgra": 382,
            "CantidadViajesEjecut": 246,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 11022,
            "CodigoRuta": "404",
            "NomNombreProgramacion": "POD 404 56 BUSES  12448_prog18_02_2024_10_58_27.xlsx",
            "FechaProgramacion": "19/02/2024",
            "FechaRegistro": "18/02/2024 22:58:36",
            "NomUsuarioRegistro": "gcisneros@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 404 56 BUSES  12448_prog18_02_2024_10_58_27.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 56,
            "nomArchivoOriginal": "POD 404 56 BUSES  12448.309 KM HABIL.xlsx",
            "nomArvhicoInterno": "POD 404 56 BUSES  12448_prog18_02_2024_10_58_27.xlsx",
            "NomFecProgramacion": "19/02/2024",
            "NomFecRegistro": "18/02/2024 22:58:36",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 56,
            "CantidadServiciosEjecut": 56,
            "CantidadViajesProgra": 514,
            "CantidadViajesEjecut": 460,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 11021,
            "CodigoRuta": "412",
            "NomNombreProgramacion": "POD 412 16 BUSES 3549_prog15_02_2024_2_07_08.xlsx",
            "FechaProgramacion": "18/02/2024",
            "FechaRegistro": "15/02/2024 02:07:08",
            "NomUsuarioRegistro": "gcisneros@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 412 16 BUSES 3549_prog15_02_2024_2_07_08.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 16,
            "nomArchivoOriginal": "POD 412 16 BUSES 3549.150 KM DOMINGO.xlsx",
            "nomArvhicoInterno": "POD 412 16 BUSES 3549_prog15_02_2024_2_07_08.xlsx",
            "NomFecProgramacion": "18/02/2024",
            "NomFecRegistro": "15/02/2024 02:07:08",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 16,
            "CantidadServiciosEjecut": 16,
            "CantidadViajesProgra": 198,
            "CantidadViajesEjecut": 191,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 11020,
            "CodigoRuta": "406",
            "NomNombreProgramacion": "POD 406 8 BUSES 2199_prog15_02_2024_2_06_53.xlsx",
            "FechaProgramacion": "18/02/2024",
            "FechaRegistro": "15/02/2024 02:06:53",
            "NomUsuarioRegistro": "gcisneros@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 406 8 BUSES 2199_prog15_02_2024_2_06_53.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 8,
            "nomArchivoOriginal": "POD 406 8 BUSES 2199.630 DOMINGO.xlsx",
            "nomArvhicoInterno": "POD 406 8 BUSES 2199_prog15_02_2024_2_06_53.xlsx",
            "NomFecProgramacion": "18/02/2024",
            "NomFecRegistro": "15/02/2024 02:06:53",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 8,
            "CantidadServiciosEjecut": 8,
            "CantidadViajesProgra": 120,
            "CantidadViajesEjecut": 105,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 11019,
            "CodigoRuta": "405",
            "NomNombreProgramacion": "POD 405 10 BUSES 2736 KM DOMINGO_prog15_02_2024_2_06_41.xlsx",
            "FechaProgramacion": "18/02/2024",
            "FechaRegistro": "15/02/2024 02:06:41",
            "NomUsuarioRegistro": "gcisneros@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 405 10 BUSES 2736 KM DOMINGO_prog15_02_2024_2_06_41.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 10,
            "nomArchivoOriginal": "POD 405 10 BUSES 2736 KM DOMINGO.xlsx",
            "nomArvhicoInterno": "POD 405 10 BUSES 2736 KM DOMINGO_prog15_02_2024_2_06_41.xlsx",
            "NomFecProgramacion": "18/02/2024",
            "NomFecRegistro": "15/02/2024 02:06:41",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 10,
            "CantidadServiciosEjecut": 9,
            "CantidadViajesProgra": 120,
            "CantidadViajesEjecut": 82,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 11018,
            "CodigoRuta": "404",
            "NomNombreProgramacion": "POD 404 20 BUSES  5473_prog15_02_2024_2_06_13.xlsx",
            "FechaProgramacion": "18/02/2024",
            "FechaRegistro": "15/02/2024 02:06:22",
            "NomUsuarioRegistro": "gcisneros@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 404 20 BUSES  5473_prog15_02_2024_2_06_13.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 20,
            "nomArchivoOriginal": "POD 404 20 BUSES  5473.381 KM DOMINGO.xlsx",
            "nomArvhicoInterno": "POD 404 20 BUSES  5473_prog15_02_2024_2_06_13.xlsx",
            "NomFecProgramacion": "18/02/2024",
            "NomFecRegistro": "15/02/2024 02:06:22",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 20,
            "CantidadServiciosEjecut": 20,
            "CantidadViajesProgra": 226,
            "CantidadViajesEjecut": 206,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 11017,
            "CodigoRuta": "412",
            "NomNombreProgramacion": "POD 412 32 BUSES 6,883_prog15_02_2024_1_52_41.xlsx",
            "FechaProgramacion": "17/02/2024",
            "FechaRegistro": "15/02/2024 01:52:41",
            "NomUsuarioRegistro": "gcisneros@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 412 32 BUSES 6,883_prog15_02_2024_1_52_41.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 32,
            "nomArchivoOriginal": "POD 412 32 BUSES 6,883.20 HABIL.xlsx",
            "nomArvhicoInterno": "POD 412 32 BUSES 6,883_prog15_02_2024_1_52_41.xlsx",
            "NomFecProgramacion": "17/02/2024",
            "NomFecRegistro": "15/02/2024 01:52:41",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 32,
            "CantidadServiciosEjecut": 32,
            "CantidadViajesProgra": 382,
            "CantidadViajesEjecut": 331,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 11016,
            "CodigoRuta": "406",
            "NomNombreProgramacion": "POD 406 15 BUSES 3,473_prog15_02_2024_1_52_27.xlsx",
            "FechaProgramacion": "17/02/2024",
            "FechaRegistro": "15/02/2024 01:52:27",
            "NomUsuarioRegistro": "gcisneros@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 406 15 BUSES 3,473_prog15_02_2024_1_52_27.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 15,
            "nomArchivoOriginal": "POD 406 15 BUSES 3,473.10KM SABADO.xlsx",
            "nomArvhicoInterno": "POD 406 15 BUSES 3,473_prog15_02_2024_1_52_27.xlsx",
            "NomFecProgramacion": "17/02/2024",
            "NomFecRegistro": "15/02/2024 01:52:27",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 15,
            "CantidadServiciosEjecut": 15,
            "CantidadViajesProgra": 180,
            "CantidadViajesEjecut": 156,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 11015,
            "CodigoRuta": "405",
            "NomNombreProgramacion": "POD 405 34 BUSES 7478_prog15_02_2024_1_52_09.xlsx",
            "FechaProgramacion": "17/02/2024",
            "FechaRegistro": "15/02/2024 01:52:09",
            "NomUsuarioRegistro": "gcisneros@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 405 34 BUSES 7478_prog15_02_2024_1_52_09.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 34,
            "nomArchivoOriginal": "POD 405 34 BUSES 7478.4 KM SABADO (1).xlsx",
            "nomArvhicoInterno": "POD 405 34 BUSES 7478_prog15_02_2024_1_52_09.xlsx",
            "NomFecProgramacion": "17/02/2024",
            "NomFecRegistro": "15/02/2024 01:52:09",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 34,
            "CantidadServiciosEjecut": 27,
            "CantidadViajesProgra": 322,
            "CantidadViajesEjecut": 201,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 11014,
            "CodigoRuta": "404",
            "NomNombreProgramacion": "POD 404 55 BUSES  11915_prog15_02_2024_1_51_32.xlsx",
            "FechaProgramacion": "17/02/2024",
            "FechaRegistro": "15/02/2024 01:51:41",
            "NomUsuarioRegistro": "gcisneros@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 404 55 BUSES  11915_prog15_02_2024_1_51_32.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 55,
            "nomArchivoOriginal": "POD 404 55 BUSES  11915.502 KM SABADO.xlsx",
            "nomArvhicoInterno": "POD 404 55 BUSES  11915_prog15_02_2024_1_51_32.xlsx",
            "NomFecProgramacion": "17/02/2024",
            "NomFecRegistro": "15/02/2024 01:51:41",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 55,
            "CantidadServiciosEjecut": 55,
            "CantidadViajesProgra": 492,
            "CantidadViajesEjecut": 391,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 11013,
            "CodigoRuta": "412",
            "NomNombreProgramacion": "POD 412 34 BUSES 6954_prog10_02_2024_2_11_10.xlsx",
            "FechaProgramacion": "16/02/2024",
            "FechaRegistro": "12/02/2024 23:48:29",
            "NomUsuarioRegistro": "gcisneros@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 412 34 BUSES 6954_prog10_02_2024_2_11_10.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 34,
            "nomArchivoOriginal": "POD 412 34 BUSES 6954.90 HABIL.xlsx",
            "nomArvhicoInterno": "POD 412 34 BUSES 6954_prog10_02_2024_2_11_10.xlsx",
            "NomFecProgramacion": "16/02/2024",
            "NomFecRegistro": "12/02/2024 23:48:29",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 34,
            "CantidadServiciosEjecut": 34,
            "CantidadViajesProgra": 388,
            "CantidadViajesEjecut": 365,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 11012,
            "CodigoRuta": "406",
            "NomNombreProgramacion": "POD 406 15 BUSES 3,473_prog10_02_2024_2_10_18.xlsx",
            "FechaProgramacion": "16/02/2024",
            "FechaRegistro": "12/02/2024 23:48:23",
            "NomUsuarioRegistro": "gcisneros@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 406 15 BUSES 3,473_prog10_02_2024_2_10_18.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 18,
            "nomArchivoOriginal": "POD 406 15 BUSES 3,473.10 HABIL.xlsx",
            "nomArvhicoInterno": "POD 406 15 BUSES 3,473_prog10_02_2024_2_10_18.xlsx",
            "NomFecProgramacion": "16/02/2024",
            "NomFecRegistro": "12/02/2024 23:48:23",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 18,
            "CantidadServiciosEjecut": 18,
            "CantidadViajesProgra": 198,
            "CantidadViajesEjecut": 189,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 11011,
            "CodigoRuta": "404",
            "NomNombreProgramacion": "POD 404 56 BUSES  12448_prog10_02_2024_2_02_11.xlsx",
            "FechaProgramacion": "16/02/2024",
            "FechaRegistro": "12/02/2024 23:48:10",
            "NomUsuarioRegistro": "gcisneros@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 404 56 BUSES  12448_prog10_02_2024_2_02_11.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 56,
            "nomArchivoOriginal": "POD 404 56 BUSES  12448.309 KM HABIL.xlsx",
            "nomArvhicoInterno": "POD 404 56 BUSES  12448_prog10_02_2024_2_02_11.xlsx",
            "NomFecProgramacion": "16/02/2024",
            "NomFecRegistro": "12/02/2024 23:48:10",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 56,
            "CantidadServiciosEjecut": 55,
            "CantidadViajesProgra": 514,
            "CantidadViajesEjecut": 406,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 11010,
            "CodigoRuta": "405",
            "NomNombreProgramacion": "POD 405 40 BUSES  HABIL_prog10_02_2024_2_04_47.xlsx",
            "FechaProgramacion": "16/02/2024",
            "FechaRegistro": "12/02/2024 23:47:54",
            "NomUsuarioRegistro": "gcisneros@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 405 40 BUSES  HABIL_prog10_02_2024_2_04_47.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 40,
            "nomArchivoOriginal": "POD 405 40 BUSES  HABIL.xlsx",
            "nomArvhicoInterno": "POD 405 40 BUSES  HABIL_prog10_02_2024_2_04_47.xlsx",
            "NomFecProgramacion": "16/02/2024",
            "NomFecRegistro": "12/02/2024 23:47:54",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 40,
            "CantidadServiciosEjecut": 35,
            "CantidadViajesProgra": 382,
            "CantidadViajesEjecut": 280,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 11009,
            "CodigoRuta": "412",
            "NomNombreProgramacion": "POD 412 34 BUSES 6954_prog10_02_2024_2_11_10.xlsx",
            "FechaProgramacion": "15/02/2024",
            "FechaRegistro": "12/02/2024 23:47:43",
            "NomUsuarioRegistro": "gcisneros@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 412 34 BUSES 6954_prog10_02_2024_2_11_10.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 34,
            "nomArchivoOriginal": "POD 412 34 BUSES 6954.90 HABIL.xlsx",
            "nomArvhicoInterno": "POD 412 34 BUSES 6954_prog10_02_2024_2_11_10.xlsx",
            "NomFecProgramacion": "15/02/2024",
            "NomFecRegistro": "12/02/2024 23:47:43",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 34,
            "CantidadServiciosEjecut": 34,
            "CantidadViajesProgra": 388,
            "CantidadViajesEjecut": 373,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 11008,
            "CodigoRuta": "406",
            "NomNombreProgramacion": "POD 406 15 BUSES 3,473_prog10_02_2024_2_10_18.xlsx",
            "FechaProgramacion": "15/02/2024",
            "FechaRegistro": "12/02/2024 23:47:36",
            "NomUsuarioRegistro": "gcisneros@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 406 15 BUSES 3,473_prog10_02_2024_2_10_18.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 18,
            "nomArchivoOriginal": "POD 406 15 BUSES 3,473.10 HABIL.xlsx",
            "nomArvhicoInterno": "POD 406 15 BUSES 3,473_prog10_02_2024_2_10_18.xlsx",
            "NomFecProgramacion": "15/02/2024",
            "NomFecRegistro": "12/02/2024 23:47:36",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 18,
            "CantidadServiciosEjecut": 18,
            "CantidadViajesProgra": 198,
            "CantidadViajesEjecut": 182,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 11007,
            "CodigoRuta": "404",
            "NomNombreProgramacion": "POD 404 56 BUSES  12448_prog10_02_2024_2_02_11.xlsx",
            "FechaProgramacion": "15/02/2024",
            "FechaRegistro": "12/02/2024 23:47:24",
            "NomUsuarioRegistro": "gcisneros@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 404 56 BUSES  12448_prog10_02_2024_2_02_11.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 56,
            "nomArchivoOriginal": "POD 404 56 BUSES  12448.309 KM HABIL.xlsx",
            "nomArvhicoInterno": "POD 404 56 BUSES  12448_prog10_02_2024_2_02_11.xlsx",
            "NomFecProgramacion": "15/02/2024",
            "NomFecRegistro": "12/02/2024 23:47:24",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 56,
            "CantidadServiciosEjecut": 56,
            "CantidadViajesProgra": 514,
            "CantidadViajesEjecut": 425,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 11006,
            "CodigoRuta": "405",
            "NomNombreProgramacion": "POD 405 40 BUSES  HABIL_prog10_02_2024_2_04_47.xlsx",
            "FechaProgramacion": "15/02/2024",
            "FechaRegistro": "12/02/2024 23:46:57",
            "NomUsuarioRegistro": "gcisneros@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 405 40 BUSES  HABIL_prog10_02_2024_2_04_47.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 40,
            "nomArchivoOriginal": "POD 405 40 BUSES  HABIL.xlsx",
            "nomArvhicoInterno": "POD 405 40 BUSES  HABIL_prog10_02_2024_2_04_47.xlsx",
            "NomFecProgramacion": "15/02/2024",
            "NomFecRegistro": "12/02/2024 23:46:57",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 40,
            "CantidadServiciosEjecut": 32,
            "CantidadViajesProgra": 382,
            "CantidadViajesEjecut": 210,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 11005,
            "CodigoRuta": "412",
            "NomNombreProgramacion": "POD 412 34 BUSES 6954_prog10_02_2024_2_11_10.xlsx",
            "FechaProgramacion": "14/02/2024",
            "FechaRegistro": "12/02/2024 23:46:43",
            "NomUsuarioRegistro": "gcisneros@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 412 34 BUSES 6954_prog10_02_2024_2_11_10.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 34,
            "nomArchivoOriginal": "POD 412 34 BUSES 6954.90 HABIL.xlsx",
            "nomArvhicoInterno": "POD 412 34 BUSES 6954_prog10_02_2024_2_11_10.xlsx",
            "NomFecProgramacion": "14/02/2024",
            "NomFecRegistro": "12/02/2024 23:46:43",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 34,
            "CantidadServiciosEjecut": 34,
            "CantidadViajesProgra": 388,
            "CantidadViajesEjecut": 357,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 11004,
            "CodigoRuta": "406",
            "NomNombreProgramacion": "POD 406 15 BUSES 3,473_prog10_02_2024_2_10_18.xlsx",
            "FechaProgramacion": "14/02/2024",
            "FechaRegistro": "12/02/2024 23:46:35",
            "NomUsuarioRegistro": "gcisneros@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 406 15 BUSES 3,473_prog10_02_2024_2_10_18.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 18,
            "nomArchivoOriginal": "POD 406 15 BUSES 3,473.10 HABIL.xlsx",
            "nomArvhicoInterno": "POD 406 15 BUSES 3,473_prog10_02_2024_2_10_18.xlsx",
            "NomFecProgramacion": "14/02/2024",
            "NomFecRegistro": "12/02/2024 23:46:35",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 18,
            "CantidadServiciosEjecut": 18,
            "CantidadViajesProgra": 198,
            "CantidadViajesEjecut": 157,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 11003,
            "CodigoRuta": "404",
            "NomNombreProgramacion": "POD 404 56 BUSES  12448_prog10_02_2024_2_02_11.xlsx",
            "FechaProgramacion": "14/02/2024",
            "FechaRegistro": "12/02/2024 23:46:16",
            "NomUsuarioRegistro": "gcisneros@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 404 56 BUSES  12448_prog10_02_2024_2_02_11.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 56,
            "nomArchivoOriginal": "POD 404 56 BUSES  12448.309 KM HABIL.xlsx",
            "nomArvhicoInterno": "POD 404 56 BUSES  12448_prog10_02_2024_2_02_11.xlsx",
            "NomFecProgramacion": "14/02/2024",
            "NomFecRegistro": "12/02/2024 23:46:16",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 56,
            "CantidadServiciosEjecut": 55,
            "CantidadViajesProgra": 514,
            "CantidadViajesEjecut": 359,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 11002,
            "CodigoRuta": "405",
            "NomNombreProgramacion": "POD 405 40 BUSES  HABIL_prog10_02_2024_2_04_47.xlsx",
            "FechaProgramacion": "14/02/2024",
            "FechaRegistro": "12/02/2024 23:46:05",
            "NomUsuarioRegistro": "gcisneros@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 405 40 BUSES  HABIL_prog10_02_2024_2_04_47.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 40,
            "nomArchivoOriginal": "POD 405 40 BUSES  HABIL.xlsx",
            "nomArvhicoInterno": "POD 405 40 BUSES  HABIL_prog10_02_2024_2_04_47.xlsx",
            "NomFecProgramacion": "14/02/2024",
            "NomFecRegistro": "12/02/2024 23:46:05",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 40,
            "CantidadServiciosEjecut": 32,
            "CantidadViajesProgra": 382,
            "CantidadViajesEjecut": 195,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 11001,
            "CodigoRuta": "412",
            "NomNombreProgramacion": "POD 412 34 BUSES 6954_prog10_02_2024_2_11_10.xlsx",
            "FechaProgramacion": "13/02/2024",
            "FechaRegistro": "12/02/2024 23:45:54",
            "NomUsuarioRegistro": "gcisneros@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 412 34 BUSES 6954_prog10_02_2024_2_11_10.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 34,
            "nomArchivoOriginal": "POD 412 34 BUSES 6954.90 HABIL.xlsx",
            "nomArvhicoInterno": "POD 412 34 BUSES 6954_prog10_02_2024_2_11_10.xlsx",
            "NomFecProgramacion": "13/02/2024",
            "NomFecRegistro": "12/02/2024 23:45:54",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 34,
            "CantidadServiciosEjecut": 34,
            "CantidadViajesProgra": 388,
            "CantidadViajesEjecut": 366,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 11000,
            "CodigoRuta": "406",
            "NomNombreProgramacion": "POD 406 15 BUSES 3,473_prog10_02_2024_2_10_18.xlsx",
            "FechaProgramacion": "13/02/2024",
            "FechaRegistro": "12/02/2024 23:45:42",
            "NomUsuarioRegistro": "gcisneros@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 406 15 BUSES 3,473_prog10_02_2024_2_10_18.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 18,
            "nomArchivoOriginal": "POD 406 15 BUSES 3,473.10 HABIL.xlsx",
            "nomArvhicoInterno": "POD 406 15 BUSES 3,473_prog10_02_2024_2_10_18.xlsx",
            "NomFecProgramacion": "13/02/2024",
            "NomFecRegistro": "12/02/2024 23:45:42",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 18,
            "CantidadServiciosEjecut": 18,
            "CantidadViajesProgra": 198,
            "CantidadViajesEjecut": 161,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10999,
            "CodigoRuta": "404",
            "NomNombreProgramacion": "POD 404 56 BUSES  12448_prog10_02_2024_2_02_11.xlsx",
            "FechaProgramacion": "13/02/2024",
            "FechaRegistro": "12/02/2024 23:45:30",
            "NomUsuarioRegistro": "gcisneros@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 404 56 BUSES  12448_prog10_02_2024_2_02_11.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 56,
            "nomArchivoOriginal": "POD 404 56 BUSES  12448.309 KM HABIL.xlsx",
            "nomArvhicoInterno": "POD 404 56 BUSES  12448_prog10_02_2024_2_02_11.xlsx",
            "NomFecProgramacion": "13/02/2024",
            "NomFecRegistro": "12/02/2024 23:45:30",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 56,
            "CantidadServiciosEjecut": 54,
            "CantidadViajesProgra": 514,
            "CantidadViajesEjecut": 356,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10998,
            "CodigoRuta": "405",
            "NomNombreProgramacion": "POD 405 40 BUSES  HABIL_prog10_02_2024_2_04_47.xlsx",
            "FechaProgramacion": "13/02/2024",
            "FechaRegistro": "12/02/2024 23:45:13",
            "NomUsuarioRegistro": "gcisneros@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 405 40 BUSES  HABIL_prog10_02_2024_2_04_47.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 40,
            "nomArchivoOriginal": "POD 405 40 BUSES  HABIL.xlsx",
            "nomArvhicoInterno": "POD 405 40 BUSES  HABIL_prog10_02_2024_2_04_47.xlsx",
            "NomFecProgramacion": "13/02/2024",
            "NomFecRegistro": "12/02/2024 23:45:13",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 40,
            "CantidadServiciosEjecut": 33,
            "CantidadViajesProgra": 382,
            "CantidadViajesEjecut": 231,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10997,
            "CodigoRuta": "412",
            "NomNombreProgramacion": "POD 412 34 BUSES 6954_prog10_02_2024_2_11_10.xlsx",
            "FechaProgramacion": "12/02/2024",
            "FechaRegistro": "10/02/2024 02:11:10",
            "NomUsuarioRegistro": "gcisneros@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 412 34 BUSES 6954_prog10_02_2024_2_11_10.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 34,
            "nomArchivoOriginal": "POD 412 34 BUSES 6954.90 HABIL.xlsx",
            "nomArvhicoInterno": "POD 412 34 BUSES 6954_prog10_02_2024_2_11_10.xlsx",
            "NomFecProgramacion": "12/02/2024",
            "NomFecRegistro": "10/02/2024 02:11:10",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 34,
            "CantidadServiciosEjecut": 34,
            "CantidadViajesProgra": 388,
            "CantidadViajesEjecut": 361,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10996,
            "CodigoRuta": "406",
            "NomNombreProgramacion": "POD 406 15 BUSES 3,473_prog10_02_2024_2_10_18.xlsx",
            "FechaProgramacion": "12/02/2024",
            "FechaRegistro": "10/02/2024 02:10:18",
            "NomUsuarioRegistro": "gcisneros@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 406 15 BUSES 3,473_prog10_02_2024_2_10_18.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 18,
            "nomArchivoOriginal": "POD 406 15 BUSES 3,473.10 HABIL.xlsx",
            "nomArvhicoInterno": "POD 406 15 BUSES 3,473_prog10_02_2024_2_10_18.xlsx",
            "NomFecProgramacion": "12/02/2024",
            "NomFecRegistro": "10/02/2024 02:10:18",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 18,
            "CantidadServiciosEjecut": 18,
            "CantidadViajesProgra": 198,
            "CantidadViajesEjecut": 163,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10995,
            "CodigoRuta": "412",
            "NomNombreProgramacion": "POD 412 34 BUSES 6954_prog10_02_2024_2_06_16.xlsx",
            "FechaProgramacion": "12/02/2024",
            "FechaRegistro": "10/02/2024 02:06:16",
            "NomUsuarioRegistro": "gcisneros@juntaoperadora",
            "Estado": "PENDIENTE",
            "NomUrlProgramacion": "POD 412 34 BUSES 6954_prog10_02_2024_2_06_16.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 34,
            "nomArchivoOriginal": "POD 412 34 BUSES 6954.90 HABIL.xlsx",
            "nomArvhicoInterno": "POD 412 34 BUSES 6954_prog10_02_2024_2_06_16.xlsx",
            "NomFecProgramacion": "12/02/2024",
            "NomFecRegistro": "10/02/2024 02:06:16",
            "EstadoRegistro": "ANULADO",
            "CantidadServiciosProgra": 34,
            "CantidadServiciosEjecut": null,
            "CantidadViajesProgra": 388,
            "CantidadViajesEjecut": null,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10994,
            "CodigoRuta": "405",
            "NomNombreProgramacion": "POD 405 40 BUSES  HABIL_prog10_02_2024_2_04_47.xlsx",
            "FechaProgramacion": "12/02/2024",
            "FechaRegistro": "10/02/2024 02:04:47",
            "NomUsuarioRegistro": "gcisneros@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 405 40 BUSES  HABIL_prog10_02_2024_2_04_47.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 40,
            "nomArchivoOriginal": "POD 405 40 BUSES  HABIL.xlsx",
            "nomArvhicoInterno": "POD 405 40 BUSES  HABIL_prog10_02_2024_2_04_47.xlsx",
            "NomFecProgramacion": "12/02/2024",
            "NomFecRegistro": "10/02/2024 02:04:47",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 40,
            "CantidadServiciosEjecut": 34,
            "CantidadViajesProgra": 382,
            "CantidadViajesEjecut": 271,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10993,
            "CodigoRuta": "404",
            "NomNombreProgramacion": "POD 404 56 BUSES  12448_prog10_02_2024_2_02_11.xlsx",
            "FechaProgramacion": "12/02/2024",
            "FechaRegistro": "10/02/2024 02:02:21",
            "NomUsuarioRegistro": "gcisneros@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 404 56 BUSES  12448_prog10_02_2024_2_02_11.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 56,
            "nomArchivoOriginal": "POD 404 56 BUSES  12448.309 KM HABIL.xlsx",
            "nomArvhicoInterno": "POD 404 56 BUSES  12448_prog10_02_2024_2_02_11.xlsx",
            "NomFecProgramacion": "12/02/2024",
            "NomFecRegistro": "10/02/2024 02:02:21",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 56,
            "CantidadServiciosEjecut": 56,
            "CantidadViajesProgra": 514,
            "CantidadViajesEjecut": 345,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10992,
            "CodigoRuta": "412",
            "NomNombreProgramacion": "POD 412 16 BUSES 3549_prog08_02_2024_4_19_53.xlsx",
            "FechaProgramacion": "11/02/2024",
            "FechaRegistro": "08/02/2024 04:19:53",
            "NomUsuarioRegistro": "gcisneros@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 412 16 BUSES 3549_prog08_02_2024_4_19_53.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 16,
            "nomArchivoOriginal": "POD 412 16 BUSES 3549.150 KM DOMINGO.xlsx",
            "nomArvhicoInterno": "POD 412 16 BUSES 3549_prog08_02_2024_4_19_53.xlsx",
            "NomFecProgramacion": "11/02/2024",
            "NomFecRegistro": "08/02/2024 04:19:53",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 16,
            "CantidadServiciosEjecut": 16,
            "CantidadViajesProgra": 198,
            "CantidadViajesEjecut": 190,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10991,
            "CodigoRuta": "406",
            "NomNombreProgramacion": "POD 406 8 BUSES 2199_prog08_02_2024_4_19_40.xlsx",
            "FechaProgramacion": "11/02/2024",
            "FechaRegistro": "08/02/2024 04:19:40",
            "NomUsuarioRegistro": "gcisneros@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 406 8 BUSES 2199_prog08_02_2024_4_19_40.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 8,
            "nomArchivoOriginal": "POD 406 8 BUSES 2199.630 DOMINGO.xlsx",
            "nomArvhicoInterno": "POD 406 8 BUSES 2199_prog08_02_2024_4_19_40.xlsx",
            "NomFecProgramacion": "11/02/2024",
            "NomFecRegistro": "08/02/2024 04:19:40",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 8,
            "CantidadServiciosEjecut": 8,
            "CantidadViajesProgra": 114,
            "CantidadViajesEjecut": 103,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10990,
            "CodigoRuta": "405",
            "NomNombreProgramacion": "POD 405 10 BUSES 2736 KM DOMINGO_prog08_02_2024_4_19_19.xlsx",
            "FechaProgramacion": "11/02/2024",
            "FechaRegistro": "08/02/2024 04:19:20",
            "NomUsuarioRegistro": "gcisneros@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 405 10 BUSES 2736 KM DOMINGO_prog08_02_2024_4_19_19.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 10,
            "nomArchivoOriginal": "POD 405 10 BUSES 2736 KM DOMINGO.xlsx",
            "nomArvhicoInterno": "POD 405 10 BUSES 2736 KM DOMINGO_prog08_02_2024_4_19_19.xlsx",
            "NomFecProgramacion": "11/02/2024",
            "NomFecRegistro": "08/02/2024 04:19:20",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 10,
            "CantidadServiciosEjecut": 10,
            "CantidadViajesProgra": 120,
            "CantidadViajesEjecut": 80,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10989,
            "CodigoRuta": "404",
            "NomNombreProgramacion": "POD 404 20 BUSES  5473_prog08_02_2024_4_18_51.xlsx",
            "FechaProgramacion": "11/02/2024",
            "FechaRegistro": "08/02/2024 04:19:01",
            "NomUsuarioRegistro": "gcisneros@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 404 20 BUSES  5473_prog08_02_2024_4_18_51.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 20,
            "nomArchivoOriginal": "POD 404 20 BUSES  5473.381 KM DOMINGO.xlsx",
            "nomArvhicoInterno": "POD 404 20 BUSES  5473_prog08_02_2024_4_18_51.xlsx",
            "NomFecProgramacion": "11/02/2024",
            "NomFecRegistro": "08/02/2024 04:19:01",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 20,
            "CantidadServiciosEjecut": 20,
            "CantidadViajesProgra": 226,
            "CantidadViajesEjecut": 219,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10988,
            "CodigoRuta": "406",
            "NomNombreProgramacion": "POD 406 15 BUSES 3,473_prog08_02_2024_4_07_10.xlsx",
            "FechaProgramacion": "10/02/2024",
            "FechaRegistro": "08/02/2024 04:07:10",
            "NomUsuarioRegistro": "gcisneros@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 406 15 BUSES 3,473_prog08_02_2024_4_07_10.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 15,
            "nomArchivoOriginal": "POD 406 15 BUSES 3,473.10KM SABADO.xlsx",
            "nomArvhicoInterno": "POD 406 15 BUSES 3,473_prog08_02_2024_4_07_10.xlsx",
            "NomFecProgramacion": "10/02/2024",
            "NomFecRegistro": "08/02/2024 04:07:10",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 15,
            "CantidadServiciosEjecut": 15,
            "CantidadViajesProgra": 180,
            "CantidadViajesEjecut": 147,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10987,
            "CodigoRuta": "405",
            "NomNombreProgramacion": "POD 405 34 BUSES 7478_prog08_02_2024_4_06_50.xlsx",
            "FechaProgramacion": "10/02/2024",
            "FechaRegistro": "08/02/2024 04:06:51",
            "NomUsuarioRegistro": "gcisneros@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 405 34 BUSES 7478_prog08_02_2024_4_06_50.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 34,
            "nomArchivoOriginal": "POD 405 34 BUSES 7478.4 KM SABADO.xlsx",
            "nomArvhicoInterno": "POD 405 34 BUSES 7478_prog08_02_2024_4_06_50.xlsx",
            "NomFecProgramacion": "10/02/2024",
            "NomFecRegistro": "08/02/2024 04:06:51",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 34,
            "CantidadServiciosEjecut": 31,
            "CantidadViajesProgra": 328,
            "CantidadViajesEjecut": 210,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10986,
            "CodigoRuta": "412",
            "NomNombreProgramacion": "POD 412 32 BUSES 6,883_prog08_02_2024_4_06_30.xlsx",
            "FechaProgramacion": "10/02/2024",
            "FechaRegistro": "08/02/2024 04:06:30",
            "NomUsuarioRegistro": "gcisneros@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 412 32 BUSES 6,883_prog08_02_2024_4_06_30.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 32,
            "nomArchivoOriginal": "POD 412 32 BUSES 6,883.20 HABIL.xlsx",
            "nomArvhicoInterno": "POD 412 32 BUSES 6,883_prog08_02_2024_4_06_30.xlsx",
            "NomFecProgramacion": "10/02/2024",
            "NomFecRegistro": "08/02/2024 04:06:30",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 32,
            "CantidadServiciosEjecut": 31,
            "CantidadViajesProgra": 382,
            "CantidadViajesEjecut": 296,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10985,
            "CodigoRuta": "404",
            "NomNombreProgramacion": "POD 404 55 BUSES  11915_prog08_02_2024_4_06_00.xlsx",
            "FechaProgramacion": "10/02/2024",
            "FechaRegistro": "08/02/2024 04:06:11",
            "NomUsuarioRegistro": "gcisneros@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 404 55 BUSES  11915_prog08_02_2024_4_06_00.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 55,
            "nomArchivoOriginal": "POD 404 55 BUSES  11915.502 KM SABADO.xlsx",
            "nomArvhicoInterno": "POD 404 55 BUSES  11915_prog08_02_2024_4_06_00.xlsx",
            "NomFecProgramacion": "10/02/2024",
            "NomFecRegistro": "08/02/2024 04:06:11",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 55,
            "CantidadServiciosEjecut": 55,
            "CantidadViajesProgra": 492,
            "CantidadViajesEjecut": 459,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10984,
            "CodigoRuta": "405",
            "NomNombreProgramacion": "POD 405 40 BUSES  HABIL_prog04_02_2024_1_05_29.xlsx",
            "FechaProgramacion": "07/02/2024",
            "FechaRegistro": "07/02/2024 03:54:06",
            "NomUsuarioRegistro": "gcisneros@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 405 40 BUSES  HABIL_prog04_02_2024_1_05_29.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 40,
            "nomArchivoOriginal": "POD 405 40 BUSES  HABIL.xlsx",
            "nomArvhicoInterno": "POD 405 40 BUSES  HABIL_prog04_02_2024_1_05_29.xlsx",
            "NomFecProgramacion": "07/02/2024",
            "NomFecRegistro": "07/02/2024 03:54:06",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 40,
            "CantidadServiciosEjecut": 36,
            "CantidadViajesProgra": 382,
            "CantidadViajesEjecut": 267,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10983,
            "CodigoRuta": "412",
            "NomNombreProgramacion": "POD 412 34 BUSES 6954_prog04_02_2024_1_06_38.xlsx",
            "FechaProgramacion": "09/02/2024",
            "FechaRegistro": "05/02/2024 22:19:46",
            "NomUsuarioRegistro": "gcisneros@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 412 34 BUSES 6954_prog04_02_2024_1_06_38.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 34,
            "nomArchivoOriginal": "POD 412 34 BUSES 6954.90 HABIL.xlsx",
            "nomArvhicoInterno": "POD 412 34 BUSES 6954_prog04_02_2024_1_06_38.xlsx",
            "NomFecProgramacion": "09/02/2024",
            "NomFecRegistro": "05/02/2024 22:19:46",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 34,
            "CantidadServiciosEjecut": 34,
            "CantidadViajesProgra": 388,
            "CantidadViajesEjecut": 317,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10982,
            "CodigoRuta": "406",
            "NomNombreProgramacion": "POD 406 15 BUSES 3,473_prog04_02_2024_1_06_04.xlsx",
            "FechaProgramacion": "09/02/2024",
            "FechaRegistro": "05/02/2024 22:19:40",
            "NomUsuarioRegistro": "gcisneros@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 406 15 BUSES 3,473_prog04_02_2024_1_06_04.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 15,
            "nomArchivoOriginal": "POD 406 15 BUSES 3,473.10 HABIL.xlsx",
            "nomArvhicoInterno": "POD 406 15 BUSES 3,473_prog04_02_2024_1_06_04.xlsx",
            "NomFecProgramacion": "09/02/2024",
            "NomFecRegistro": "05/02/2024 22:19:40",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 15,
            "CantidadServiciosEjecut": 15,
            "CantidadViajesProgra": 180,
            "CantidadViajesEjecut": 148,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10981,
            "CodigoRuta": "405",
            "NomNombreProgramacion": "POD 405 40 BUSES  HABIL_prog04_02_2024_1_05_29.xlsx",
            "FechaProgramacion": "09/02/2024",
            "FechaRegistro": "05/02/2024 22:19:29",
            "NomUsuarioRegistro": "gcisneros@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 405 40 BUSES  HABIL_prog04_02_2024_1_05_29.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 40,
            "nomArchivoOriginal": "POD 405 40 BUSES  HABIL.xlsx",
            "nomArvhicoInterno": "POD 405 40 BUSES  HABIL_prog04_02_2024_1_05_29.xlsx",
            "NomFecProgramacion": "09/02/2024",
            "NomFecRegistro": "05/02/2024 22:19:29",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 40,
            "CantidadServiciosEjecut": 36,
            "CantidadViajesProgra": 382,
            "CantidadViajesEjecut": 246,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10980,
            "CodigoRuta": "404",
            "NomNombreProgramacion": "POD 404 56 BUSES  12448_prog04_02_2024_1_04_57.xlsx",
            "FechaProgramacion": "09/02/2024",
            "FechaRegistro": "05/02/2024 22:19:11",
            "NomUsuarioRegistro": "gcisneros@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 404 56 BUSES  12448_prog04_02_2024_1_04_57.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 56,
            "nomArchivoOriginal": "POD 404 56 BUSES  12448.309 KM HABIL.xlsx",
            "nomArvhicoInterno": "POD 404 56 BUSES  12448_prog04_02_2024_1_04_57.xlsx",
            "NomFecProgramacion": "09/02/2024",
            "NomFecRegistro": "05/02/2024 22:19:11",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 56,
            "CantidadServiciosEjecut": 56,
            "CantidadViajesProgra": 514,
            "CantidadViajesEjecut": 487,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10979,
            "CodigoRuta": "412",
            "NomNombreProgramacion": "POD 412 34 BUSES 6954_prog04_02_2024_1_06_38.xlsx",
            "FechaProgramacion": "08/02/2024",
            "FechaRegistro": "05/02/2024 22:18:42",
            "NomUsuarioRegistro": "gcisneros@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 412 34 BUSES 6954_prog04_02_2024_1_06_38.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 34,
            "nomArchivoOriginal": "POD 412 34 BUSES 6954.90 HABIL.xlsx",
            "nomArvhicoInterno": "POD 412 34 BUSES 6954_prog04_02_2024_1_06_38.xlsx",
            "NomFecProgramacion": "08/02/2024",
            "NomFecRegistro": "05/02/2024 22:18:42",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 34,
            "CantidadServiciosEjecut": 32,
            "CantidadViajesProgra": 388,
            "CantidadViajesEjecut": 314,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10978,
            "CodigoRuta": "406",
            "NomNombreProgramacion": "POD 406 15 BUSES 3,473_prog04_02_2024_1_06_04.xlsx",
            "FechaProgramacion": "08/02/2024",
            "FechaRegistro": "05/02/2024 22:10:34",
            "NomUsuarioRegistro": "gcisneros@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 406 15 BUSES 3,473_prog04_02_2024_1_06_04.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 15,
            "nomArchivoOriginal": "POD 406 15 BUSES 3,473.10 HABIL.xlsx",
            "nomArvhicoInterno": "POD 406 15 BUSES 3,473_prog04_02_2024_1_06_04.xlsx",
            "NomFecProgramacion": "08/02/2024",
            "NomFecRegistro": "05/02/2024 22:10:34",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 15,
            "CantidadServiciosEjecut": 15,
            "CantidadViajesProgra": 180,
            "CantidadViajesEjecut": 162,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10977,
            "CodigoRuta": "405",
            "NomNombreProgramacion": "POD 405 40 BUSES  HABIL_prog04_02_2024_1_05_29.xlsx",
            "FechaProgramacion": "08/02/2024",
            "FechaRegistro": "05/02/2024 22:10:21",
            "NomUsuarioRegistro": "gcisneros@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 405 40 BUSES  HABIL_prog04_02_2024_1_05_29.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 40,
            "nomArchivoOriginal": "POD 405 40 BUSES  HABIL.xlsx",
            "nomArvhicoInterno": "POD 405 40 BUSES  HABIL_prog04_02_2024_1_05_29.xlsx",
            "NomFecProgramacion": "08/02/2024",
            "NomFecRegistro": "05/02/2024 22:10:21",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 40,
            "CantidadServiciosEjecut": 35,
            "CantidadViajesProgra": 382,
            "CantidadViajesEjecut": 240,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10976,
            "CodigoRuta": "404",
            "NomNombreProgramacion": "POD 404 56 BUSES  12448_prog04_02_2024_1_04_57.xlsx",
            "FechaProgramacion": "08/02/2024",
            "FechaRegistro": "05/02/2024 22:10:06",
            "NomUsuarioRegistro": "gcisneros@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 404 56 BUSES  12448_prog04_02_2024_1_04_57.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 56,
            "nomArchivoOriginal": "POD 404 56 BUSES  12448.309 KM HABIL.xlsx",
            "nomArvhicoInterno": "POD 404 56 BUSES  12448_prog04_02_2024_1_04_57.xlsx",
            "NomFecProgramacion": "08/02/2024",
            "NomFecRegistro": "05/02/2024 22:10:06",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 56,
            "CantidadServiciosEjecut": 56,
            "CantidadViajesProgra": 514,
            "CantidadViajesEjecut": 476,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10975,
            "CodigoRuta": "412",
            "NomNombreProgramacion": "POD 412 34 BUSES 6954_prog04_02_2024_1_06_38.xlsx",
            "FechaProgramacion": "07/02/2024",
            "FechaRegistro": "05/02/2024 22:09:49",
            "NomUsuarioRegistro": "gcisneros@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 412 34 BUSES 6954_prog04_02_2024_1_06_38.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 34,
            "nomArchivoOriginal": "POD 412 34 BUSES 6954.90 HABIL.xlsx",
            "nomArvhicoInterno": "POD 412 34 BUSES 6954_prog04_02_2024_1_06_38.xlsx",
            "NomFecProgramacion": "07/02/2024",
            "NomFecRegistro": "05/02/2024 22:09:49",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 34,
            "CantidadServiciosEjecut": 34,
            "CantidadViajesProgra": 388,
            "CantidadViajesEjecut": 315,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10974,
            "CodigoRuta": "406",
            "NomNombreProgramacion": "POD 406 15 BUSES 3,473_prog04_02_2024_1_06_04.xlsx",
            "FechaProgramacion": "07/02/2024",
            "FechaRegistro": "05/02/2024 22:09:32",
            "NomUsuarioRegistro": "gcisneros@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 406 15 BUSES 3,473_prog04_02_2024_1_06_04.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 15,
            "nomArchivoOriginal": "POD 406 15 BUSES 3,473.10 HABIL.xlsx",
            "nomArvhicoInterno": "POD 406 15 BUSES 3,473_prog04_02_2024_1_06_04.xlsx",
            "NomFecProgramacion": "07/02/2024",
            "NomFecRegistro": "05/02/2024 22:09:32",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 15,
            "CantidadServiciosEjecut": 15,
            "CantidadViajesProgra": 180,
            "CantidadViajesEjecut": 158,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10973,
            "CodigoRuta": "405",
            "NomNombreProgramacion": "POD 405 40 BUSES  HABIL_prog04_02_2024_1_05_29.xlsx",
            "FechaProgramacion": "07/02/2024",
            "FechaRegistro": "05/02/2024 22:09:20",
            "NomUsuarioRegistro": "gcisneros@juntaoperadora",
            "Estado": "PENDIENTE",
            "NomUrlProgramacion": "POD 405 40 BUSES  HABIL_prog04_02_2024_1_05_29.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 40,
            "nomArchivoOriginal": "POD 405 40 BUSES  HABIL.xlsx",
            "nomArvhicoInterno": "POD 405 40 BUSES  HABIL_prog04_02_2024_1_05_29.xlsx",
            "NomFecProgramacion": "07/02/2024",
            "NomFecRegistro": "05/02/2024 22:09:20",
            "EstadoRegistro": "ANULADO",
            "CantidadServiciosProgra": 40,
            "CantidadServiciosEjecut": null,
            "CantidadViajesProgra": 382,
            "CantidadViajesEjecut": null,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10972,
            "CodigoRuta": "404",
            "NomNombreProgramacion": "POD 404 56 BUSES  12448_prog04_02_2024_1_04_57.xlsx",
            "FechaProgramacion": "07/02/2024",
            "FechaRegistro": "05/02/2024 22:09:07",
            "NomUsuarioRegistro": "gcisneros@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 404 56 BUSES  12448_prog04_02_2024_1_04_57.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 56,
            "nomArchivoOriginal": "POD 404 56 BUSES  12448.309 KM HABIL.xlsx",
            "nomArvhicoInterno": "POD 404 56 BUSES  12448_prog04_02_2024_1_04_57.xlsx",
            "NomFecProgramacion": "07/02/2024",
            "NomFecRegistro": "05/02/2024 22:09:07",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 56,
            "CantidadServiciosEjecut": 56,
            "CantidadViajesProgra": 514,
            "CantidadViajesEjecut": 478,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10971,
            "CodigoRuta": "412",
            "NomNombreProgramacion": "POD 412 34 BUSES 6954_prog04_02_2024_1_06_38.xlsx",
            "FechaProgramacion": "06/02/2024",
            "FechaRegistro": "05/02/2024 22:08:55",
            "NomUsuarioRegistro": "gcisneros@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 412 34 BUSES 6954_prog04_02_2024_1_06_38.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 34,
            "nomArchivoOriginal": "POD 412 34 BUSES 6954.90 HABIL.xlsx",
            "nomArvhicoInterno": "POD 412 34 BUSES 6954_prog04_02_2024_1_06_38.xlsx",
            "NomFecProgramacion": "06/02/2024",
            "NomFecRegistro": "05/02/2024 22:08:55",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 34,
            "CantidadServiciosEjecut": 33,
            "CantidadViajesProgra": 388,
            "CantidadViajesEjecut": 307,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10970,
            "CodigoRuta": "406",
            "NomNombreProgramacion": "POD 406 15 BUSES 3,473_prog04_02_2024_1_06_04.xlsx",
            "FechaProgramacion": "06/02/2024",
            "FechaRegistro": "05/02/2024 22:08:42",
            "NomUsuarioRegistro": "gcisneros@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 406 15 BUSES 3,473_prog04_02_2024_1_06_04.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 15,
            "nomArchivoOriginal": "POD 406 15 BUSES 3,473.10 HABIL.xlsx",
            "nomArvhicoInterno": "POD 406 15 BUSES 3,473_prog04_02_2024_1_06_04.xlsx",
            "NomFecProgramacion": "06/02/2024",
            "NomFecRegistro": "05/02/2024 22:08:42",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 15,
            "CantidadServiciosEjecut": 15,
            "CantidadViajesProgra": 180,
            "CantidadViajesEjecut": 160,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10969,
            "CodigoRuta": "405",
            "NomNombreProgramacion": "POD 405 40 BUSES  HABIL_prog04_02_2024_1_05_29.xlsx",
            "FechaProgramacion": "06/02/2024",
            "FechaRegistro": "05/02/2024 22:08:28",
            "NomUsuarioRegistro": "gcisneros@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 405 40 BUSES  HABIL_prog04_02_2024_1_05_29.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 40,
            "nomArchivoOriginal": "POD 405 40 BUSES  HABIL.xlsx",
            "nomArvhicoInterno": "POD 405 40 BUSES  HABIL_prog04_02_2024_1_05_29.xlsx",
            "NomFecProgramacion": "06/02/2024",
            "NomFecRegistro": "05/02/2024 22:08:28",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 40,
            "CantidadServiciosEjecut": 39,
            "CantidadViajesProgra": 382,
            "CantidadViajesEjecut": 293,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10968,
            "CodigoRuta": "404",
            "NomNombreProgramacion": "POD 404 56 BUSES  12448_prog04_02_2024_1_04_57.xlsx",
            "FechaProgramacion": "06/02/2024",
            "FechaRegistro": "05/02/2024 22:08:13",
            "NomUsuarioRegistro": "gcisneros@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 404 56 BUSES  12448_prog04_02_2024_1_04_57.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 56,
            "nomArchivoOriginal": "POD 404 56 BUSES  12448.309 KM HABIL.xlsx",
            "nomArvhicoInterno": "POD 404 56 BUSES  12448_prog04_02_2024_1_04_57.xlsx",
            "NomFecProgramacion": "06/02/2024",
            "NomFecRegistro": "05/02/2024 22:08:13",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 56,
            "CantidadServiciosEjecut": 56,
            "CantidadViajesProgra": 514,
            "CantidadViajesEjecut": 482,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10967,
            "CodigoRuta": "412",
            "NomNombreProgramacion": "POD 412 34 BUSES 6954_prog04_02_2024_1_06_38.xlsx",
            "FechaProgramacion": "05/02/2024",
            "FechaRegistro": "04/02/2024 13:06:38",
            "NomUsuarioRegistro": "jparedes@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 412 34 BUSES 6954_prog04_02_2024_1_06_38.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 34,
            "nomArchivoOriginal": "POD 412 34 BUSES 6954.90 HABIL.xlsx",
            "nomArvhicoInterno": "POD 412 34 BUSES 6954_prog04_02_2024_1_06_38.xlsx",
            "NomFecProgramacion": "05/02/2024",
            "NomFecRegistro": "04/02/2024 13:06:38",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 34,
            "CantidadServiciosEjecut": 33,
            "CantidadViajesProgra": 388,
            "CantidadViajesEjecut": 297,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10966,
            "CodigoRuta": "406",
            "NomNombreProgramacion": "POD 406 15 BUSES 3,473_prog04_02_2024_1_06_04.xlsx",
            "FechaProgramacion": "05/02/2024",
            "FechaRegistro": "04/02/2024 13:06:04",
            "NomUsuarioRegistro": "jparedes@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 406 15 BUSES 3,473_prog04_02_2024_1_06_04.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 15,
            "nomArchivoOriginal": "POD 406 15 BUSES 3,473.10 HABIL.xlsx",
            "nomArvhicoInterno": "POD 406 15 BUSES 3,473_prog04_02_2024_1_06_04.xlsx",
            "NomFecProgramacion": "05/02/2024",
            "NomFecRegistro": "04/02/2024 13:06:04",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 15,
            "CantidadServiciosEjecut": 15,
            "CantidadViajesProgra": 180,
            "CantidadViajesEjecut": 156,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10965,
            "CodigoRuta": "405",
            "NomNombreProgramacion": "POD 405 40 BUSES  HABIL_prog04_02_2024_1_05_29.xlsx",
            "FechaProgramacion": "05/02/2024",
            "FechaRegistro": "04/02/2024 13:05:29",
            "NomUsuarioRegistro": "jparedes@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 405 40 BUSES  HABIL_prog04_02_2024_1_05_29.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 40,
            "nomArchivoOriginal": "POD 405 40 BUSES  HABIL.xlsx",
            "nomArvhicoInterno": "POD 405 40 BUSES  HABIL_prog04_02_2024_1_05_29.xlsx",
            "NomFecProgramacion": "05/02/2024",
            "NomFecRegistro": "04/02/2024 13:05:29",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 40,
            "CantidadServiciosEjecut": 39,
            "CantidadViajesProgra": 382,
            "CantidadViajesEjecut": 298,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10964,
            "CodigoRuta": "404",
            "NomNombreProgramacion": "POD 404 56 BUSES  12448_prog04_02_2024_1_04_57.xlsx",
            "FechaProgramacion": "05/02/2024",
            "FechaRegistro": "04/02/2024 13:05:06",
            "NomUsuarioRegistro": "jparedes@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 404 56 BUSES  12448_prog04_02_2024_1_04_57.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 56,
            "nomArchivoOriginal": "POD 404 56 BUSES  12448.309 KM HABIL.xlsx",
            "nomArvhicoInterno": "POD 404 56 BUSES  12448_prog04_02_2024_1_04_57.xlsx",
            "NomFecProgramacion": "05/02/2024",
            "NomFecRegistro": "04/02/2024 13:05:06",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 56,
            "CantidadServiciosEjecut": 56,
            "CantidadViajesProgra": 514,
            "CantidadViajesEjecut": 485,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10963,
            "CodigoRuta": "412",
            "NomNombreProgramacion": "POD 412 16 BUSES 3549_prog02_02_2024_2_23_32.xlsx",
            "FechaProgramacion": "04/02/2024",
            "FechaRegistro": "02/02/2024 14:23:32",
            "NomUsuarioRegistro": "jparedes@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 412 16 BUSES 3549_prog02_02_2024_2_23_32.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 16,
            "nomArchivoOriginal": "POD 412 16 BUSES 3549.150 KM DOMINGO.xlsx",
            "nomArvhicoInterno": "POD 412 16 BUSES 3549_prog02_02_2024_2_23_32.xlsx",
            "NomFecProgramacion": "04/02/2024",
            "NomFecRegistro": "02/02/2024 14:23:32",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 16,
            "CantidadServiciosEjecut": 16,
            "CantidadViajesProgra": 198,
            "CantidadViajesEjecut": 195,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10962,
            "CodigoRuta": "406",
            "NomNombreProgramacion": "POD 406 8 BUSES 2199_prog02_02_2024_2_22_43.xlsx",
            "FechaProgramacion": "04/02/2024",
            "FechaRegistro": "02/02/2024 14:22:43",
            "NomUsuarioRegistro": "jparedes@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 406 8 BUSES 2199_prog02_02_2024_2_22_43.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 8,
            "nomArchivoOriginal": "POD 406 8 BUSES 2199.630 DOMINGO.xlsx",
            "nomArvhicoInterno": "POD 406 8 BUSES 2199_prog02_02_2024_2_22_43.xlsx",
            "NomFecProgramacion": "04/02/2024",
            "NomFecRegistro": "02/02/2024 14:22:43",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 8,
            "CantidadServiciosEjecut": 8,
            "CantidadViajesProgra": 114,
            "CantidadViajesEjecut": 107,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10961,
            "CodigoRuta": "405",
            "NomNombreProgramacion": "POD 405 10 BUSES 2736 KM DOMINGO_prog02_02_2024_2_20_05.xlsx",
            "FechaProgramacion": "04/02/2024",
            "FechaRegistro": "02/02/2024 14:20:06",
            "NomUsuarioRegistro": "jparedes@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 405 10 BUSES 2736 KM DOMINGO_prog02_02_2024_2_20_05.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 10,
            "nomArchivoOriginal": "POD 405 10 BUSES 2736 KM DOMINGO.xlsx",
            "nomArvhicoInterno": "POD 405 10 BUSES 2736 KM DOMINGO_prog02_02_2024_2_20_05.xlsx",
            "NomFecProgramacion": "04/02/2024",
            "NomFecRegistro": "02/02/2024 14:20:06",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 10,
            "CantidadServiciosEjecut": 10,
            "CantidadViajesProgra": 120,
            "CantidadViajesEjecut": 101,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10960,
            "CodigoRuta": "404",
            "NomNombreProgramacion": "POD 404 20 BUSES  5473_prog02_02_2024_2_19_37.xlsx",
            "FechaProgramacion": "04/02/2024",
            "FechaRegistro": "02/02/2024 14:19:46",
            "NomUsuarioRegistro": "jparedes@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 404 20 BUSES  5473_prog02_02_2024_2_19_37.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 20,
            "nomArchivoOriginal": "POD 404 20 BUSES  5473.381 KM DOMINGO.xlsx",
            "nomArvhicoInterno": "POD 404 20 BUSES  5473_prog02_02_2024_2_19_37.xlsx",
            "NomFecProgramacion": "04/02/2024",
            "NomFecRegistro": "02/02/2024 14:19:46",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 20,
            "CantidadServiciosEjecut": 20,
            "CantidadViajesProgra": 226,
            "CantidadViajesEjecut": 207,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10959,
            "CodigoRuta": "412",
            "NomNombreProgramacion": "POD 412 32 BUSES 6,847_prog02_02_2024_2_16_36.xlsx",
            "FechaProgramacion": "03/02/2024",
            "FechaRegistro": "02/02/2024 14:16:36",
            "NomUsuarioRegistro": "jparedes@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 412 32 BUSES 6,847_prog02_02_2024_2_16_36.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 32,
            "nomArchivoOriginal": "POD 412 32 BUSES 6,847.350 SABADO.xlsx",
            "nomArvhicoInterno": "POD 412 32 BUSES 6,847_prog02_02_2024_2_16_36.xlsx",
            "NomFecProgramacion": "03/02/2024",
            "NomFecRegistro": "02/02/2024 14:16:36",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 32,
            "CantidadServiciosEjecut": 32,
            "CantidadViajesProgra": 382,
            "CantidadViajesEjecut": 349,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10958,
            "CodigoRuta": "406",
            "NomNombreProgramacion": "POD 406 15 BUSES 3,473_prog02_02_2024_2_16_11.xlsx",
            "FechaProgramacion": "03/02/2024",
            "FechaRegistro": "02/02/2024 14:16:11",
            "NomUsuarioRegistro": "jparedes@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 406 15 BUSES 3,473_prog02_02_2024_2_16_11.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 15,
            "nomArchivoOriginal": "POD 406 15 BUSES 3,473.10KM SABADO.xlsx",
            "nomArvhicoInterno": "POD 406 15 BUSES 3,473_prog02_02_2024_2_16_11.xlsx",
            "NomFecProgramacion": "03/02/2024",
            "NomFecRegistro": "02/02/2024 14:16:11",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 15,
            "CantidadServiciosEjecut": 15,
            "CantidadViajesProgra": 180,
            "CantidadViajesEjecut": 162,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10957,
            "CodigoRuta": "405",
            "NomNombreProgramacion": "POD 405 34 BUSES 7478_prog02_02_2024_2_15_15.xlsx",
            "FechaProgramacion": "03/02/2024",
            "FechaRegistro": "02/02/2024 14:15:15",
            "NomUsuarioRegistro": "jparedes@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 405 34 BUSES 7478_prog02_02_2024_2_15_15.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 34,
            "nomArchivoOriginal": "POD 405 34 BUSES 7478.4 KM SABADO.xlsx",
            "nomArvhicoInterno": "POD 405 34 BUSES 7478_prog02_02_2024_2_15_15.xlsx",
            "NomFecProgramacion": "03/02/2024",
            "NomFecRegistro": "02/02/2024 14:15:15",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 34,
            "CantidadServiciosEjecut": 34,
            "CantidadViajesProgra": 328,
            "CantidadViajesEjecut": 250,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10956,
            "CodigoRuta": "404",
            "NomNombreProgramacion": "POD 404 55 BUSES  11915_prog02_02_2024_2_14_27.xlsx",
            "FechaProgramacion": "03/02/2024",
            "FechaRegistro": "02/02/2024 14:14:36",
            "NomUsuarioRegistro": "jparedes@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 404 55 BUSES  11915_prog02_02_2024_2_14_27.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 55,
            "nomArchivoOriginal": "POD 404 55 BUSES  11915.502 KM SABADO.xlsx",
            "nomArvhicoInterno": "POD 404 55 BUSES  11915_prog02_02_2024_2_14_27.xlsx",
            "NomFecProgramacion": "03/02/2024",
            "NomFecRegistro": "02/02/2024 14:14:36",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 55,
            "CantidadServiciosEjecut": 55,
            "CantidadViajesProgra": 492,
            "CantidadViajesEjecut": 408,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10955,
            "CodigoRuta": "412",
            "NomNombreProgramacion": "POD 412 32 BUSES 6,883_prog28_01_2024_6_18_15.xlsx",
            "FechaProgramacion": "02/02/2024",
            "FechaRegistro": "30/01/2024 03:44:42",
            "NomUsuarioRegistro": "jparedes@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 412 32 BUSES 6,883_prog28_01_2024_6_18_15.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 32,
            "nomArchivoOriginal": "POD 412 32 BUSES 6,883.20 HABIL.xlsx",
            "nomArvhicoInterno": "POD 412 32 BUSES 6,883_prog28_01_2024_6_18_15.xlsx",
            "NomFecProgramacion": "02/02/2024",
            "NomFecRegistro": "30/01/2024 03:44:42",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 32,
            "CantidadServiciosEjecut": 32,
            "CantidadViajesProgra": 384,
            "CantidadViajesEjecut": 364,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10954,
            "CodigoRuta": "412",
            "NomNombreProgramacion": "POD 412 32 BUSES 6,883_prog28_01_2024_6_18_15.xlsx",
            "FechaProgramacion": "01/02/2024",
            "FechaRegistro": "30/01/2024 03:44:42",
            "NomUsuarioRegistro": "jparedes@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 412 32 BUSES 6,883_prog28_01_2024_6_18_15.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 32,
            "nomArchivoOriginal": "POD 412 32 BUSES 6,883.20 HABIL.xlsx",
            "nomArvhicoInterno": "POD 412 32 BUSES 6,883_prog28_01_2024_6_18_15.xlsx",
            "NomFecProgramacion": "01/02/2024",
            "NomFecRegistro": "30/01/2024 03:44:42",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 32,
            "CantidadServiciosEjecut": 32,
            "CantidadViajesProgra": 384,
            "CantidadViajesEjecut": 363,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10953,
            "CodigoRuta": "406",
            "NomNombreProgramacion": "POD 406 15 BUSES 3,473_prog28_01_2024_6_52_30.xlsx",
            "FechaProgramacion": "02/02/2024",
            "FechaRegistro": "30/01/2024 03:44:23",
            "NomUsuarioRegistro": "jparedes@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 406 15 BUSES 3,473_prog28_01_2024_6_52_30.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 15,
            "nomArchivoOriginal": "POD 406 15 BUSES 3,473.10 HABIL.xlsx",
            "nomArvhicoInterno": "POD 406 15 BUSES 3,473_prog28_01_2024_6_52_30.xlsx",
            "NomFecProgramacion": "02/02/2024",
            "NomFecRegistro": "30/01/2024 03:44:23",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 15,
            "CantidadServiciosEjecut": 15,
            "CantidadViajesProgra": 180,
            "CantidadViajesEjecut": 164,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10952,
            "CodigoRuta": "406",
            "NomNombreProgramacion": "POD 406 15 BUSES 3,473_prog28_01_2024_6_52_30.xlsx",
            "FechaProgramacion": "01/02/2024",
            "FechaRegistro": "30/01/2024 03:44:23",
            "NomUsuarioRegistro": "jparedes@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 406 15 BUSES 3,473_prog28_01_2024_6_52_30.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 15,
            "nomArchivoOriginal": "POD 406 15 BUSES 3,473.10 HABIL.xlsx",
            "nomArvhicoInterno": "POD 406 15 BUSES 3,473_prog28_01_2024_6_52_30.xlsx",
            "NomFecProgramacion": "01/02/2024",
            "NomFecRegistro": "30/01/2024 03:44:23",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 15,
            "CantidadServiciosEjecut": 15,
            "CantidadViajesProgra": 180,
            "CantidadViajesEjecut": 165,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10951,
            "CodigoRuta": "405",
            "NomNombreProgramacion": "POD 405 40 BUSES  HABIL_prog28_01_2024_6_42_01.xlsx",
            "FechaProgramacion": "02/02/2024",
            "FechaRegistro": "30/01/2024 03:44:01",
            "NomUsuarioRegistro": "jparedes@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 405 40 BUSES  HABIL_prog28_01_2024_6_42_01.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 40,
            "nomArchivoOriginal": "POD 405 40 BUSES  HABIL.xlsx",
            "nomArvhicoInterno": "POD 405 40 BUSES  HABIL_prog28_01_2024_6_42_01.xlsx",
            "NomFecProgramacion": "02/02/2024",
            "NomFecRegistro": "30/01/2024 03:44:01",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 40,
            "CantidadServiciosEjecut": 31,
            "CantidadViajesProgra": 382,
            "CantidadViajesEjecut": 231,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10950,
            "CodigoRuta": "405",
            "NomNombreProgramacion": "POD 405 40 BUSES  HABIL_prog28_01_2024_6_42_01.xlsx",
            "FechaProgramacion": "01/02/2024",
            "FechaRegistro": "30/01/2024 03:44:01",
            "NomUsuarioRegistro": "jparedes@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 405 40 BUSES  HABIL_prog28_01_2024_6_42_01.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 40,
            "nomArchivoOriginal": "POD 405 40 BUSES  HABIL.xlsx",
            "nomArvhicoInterno": "POD 405 40 BUSES  HABIL_prog28_01_2024_6_42_01.xlsx",
            "NomFecProgramacion": "01/02/2024",
            "NomFecRegistro": "30/01/2024 03:44:01",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 40,
            "CantidadServiciosEjecut": 37,
            "CantidadViajesProgra": 382,
            "CantidadViajesEjecut": 251,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10949,
            "CodigoRuta": "404",
            "NomNombreProgramacion": "POD 404 55 BUSES  11915_prog28_01_2024_6_23_28.xlsx",
            "FechaProgramacion": "02/02/2024",
            "FechaRegistro": "30/01/2024 03:43:16",
            "NomUsuarioRegistro": "jparedes@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 404 55 BUSES  11915_prog28_01_2024_6_23_28.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 55,
            "nomArchivoOriginal": "POD 404 55 BUSES  11915.502 KM HABIL.xlsx",
            "nomArvhicoInterno": "POD 404 55 BUSES  11915_prog28_01_2024_6_23_28.xlsx",
            "NomFecProgramacion": "02/02/2024",
            "NomFecRegistro": "30/01/2024 03:43:16",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 55,
            "CantidadServiciosEjecut": 55,
            "CantidadViajesProgra": 492,
            "CantidadViajesEjecut": 468,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10948,
            "CodigoRuta": "404",
            "NomNombreProgramacion": "POD 404 55 BUSES  11915_prog28_01_2024_6_23_28.xlsx",
            "FechaProgramacion": "01/02/2024",
            "FechaRegistro": "30/01/2024 03:43:16",
            "NomUsuarioRegistro": "jparedes@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 404 55 BUSES  11915_prog28_01_2024_6_23_28.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 55,
            "nomArchivoOriginal": "POD 404 55 BUSES  11915.502 KM HABIL.xlsx",
            "nomArvhicoInterno": "POD 404 55 BUSES  11915_prog28_01_2024_6_23_28.xlsx",
            "NomFecProgramacion": "01/02/2024",
            "NomFecRegistro": "30/01/2024 03:43:16",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 55,
            "CantidadServiciosEjecut": 55,
            "CantidadViajesProgra": 492,
            "CantidadViajesEjecut": 445,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10947,
            "CodigoRuta": "412",
            "NomNombreProgramacion": "POD 412 32 BUSES 6,883_prog28_01_2024_6_18_15.xlsx",
            "FechaProgramacion": "31/01/2024",
            "FechaRegistro": "30/01/2024 03:42:44",
            "NomUsuarioRegistro": "jparedes@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 412 32 BUSES 6,883_prog28_01_2024_6_18_15.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 32,
            "nomArchivoOriginal": "POD 412 32 BUSES 6,883.20 HABIL.xlsx",
            "nomArvhicoInterno": "POD 412 32 BUSES 6,883_prog28_01_2024_6_18_15.xlsx",
            "NomFecProgramacion": "31/01/2024",
            "NomFecRegistro": "30/01/2024 03:42:44",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 32,
            "CantidadServiciosEjecut": 32,
            "CantidadViajesProgra": 384,
            "CantidadViajesEjecut": 364,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10946,
            "CodigoRuta": "406",
            "NomNombreProgramacion": "POD 406 15 BUSES 3,473_prog28_01_2024_6_52_30.xlsx",
            "FechaProgramacion": "31/01/2024",
            "FechaRegistro": "30/01/2024 03:42:24",
            "NomUsuarioRegistro": "jparedes@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 406 15 BUSES 3,473_prog28_01_2024_6_52_30.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 15,
            "nomArchivoOriginal": "POD 406 15 BUSES 3,473.10 HABIL.xlsx",
            "nomArvhicoInterno": "POD 406 15 BUSES 3,473_prog28_01_2024_6_52_30.xlsx",
            "NomFecProgramacion": "31/01/2024",
            "NomFecRegistro": "30/01/2024 03:42:24",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 15,
            "CantidadServiciosEjecut": 15,
            "CantidadViajesProgra": 180,
            "CantidadViajesEjecut": 162,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10945,
            "CodigoRuta": "405",
            "NomNombreProgramacion": "POD 405 40 BUSES  HABIL_prog28_01_2024_6_42_01.xlsx",
            "FechaProgramacion": "31/01/2024",
            "FechaRegistro": "30/01/2024 03:42:12",
            "NomUsuarioRegistro": "jparedes@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 405 40 BUSES  HABIL_prog28_01_2024_6_42_01.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 40,
            "nomArchivoOriginal": "POD 405 40 BUSES  HABIL.xlsx",
            "nomArvhicoInterno": "POD 405 40 BUSES  HABIL_prog28_01_2024_6_42_01.xlsx",
            "NomFecProgramacion": "31/01/2024",
            "NomFecRegistro": "30/01/2024 03:42:12",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 40,
            "CantidadServiciosEjecut": 35,
            "CantidadViajesProgra": 382,
            "CantidadViajesEjecut": 233,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10944,
            "CodigoRuta": "404",
            "NomNombreProgramacion": "POD 404 55 BUSES  11915_prog28_01_2024_6_23_28.xlsx",
            "FechaProgramacion": "31/01/2024",
            "FechaRegistro": "30/01/2024 03:41:44",
            "NomUsuarioRegistro": "jparedes@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 404 55 BUSES  11915_prog28_01_2024_6_23_28.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 55,
            "nomArchivoOriginal": "POD 404 55 BUSES  11915.502 KM HABIL.xlsx",
            "nomArvhicoInterno": "POD 404 55 BUSES  11915_prog28_01_2024_6_23_28.xlsx",
            "NomFecProgramacion": "31/01/2024",
            "NomFecRegistro": "30/01/2024 03:41:44",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 55,
            "CantidadServiciosEjecut": 55,
            "CantidadViajesProgra": 492,
            "CantidadViajesEjecut": 436,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10943,
            "CodigoRuta": "412",
            "NomNombreProgramacion": "POD 412 32 BUSES 6,883_prog28_01_2024_6_18_15.xlsx",
            "FechaProgramacion": "30/01/2024",
            "FechaRegistro": "29/01/2024 19:47:05",
            "NomUsuarioRegistro": "jparedes@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 412 32 BUSES 6,883_prog28_01_2024_6_18_15.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 32,
            "nomArchivoOriginal": "POD 412 32 BUSES 6,883.20 HABIL.xlsx",
            "nomArvhicoInterno": "POD 412 32 BUSES 6,883_prog28_01_2024_6_18_15.xlsx",
            "NomFecProgramacion": "30/01/2024",
            "NomFecRegistro": "29/01/2024 19:47:05",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 32,
            "CantidadServiciosEjecut": 32,
            "CantidadViajesProgra": 384,
            "CantidadViajesEjecut": 349,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10942,
            "CodigoRuta": "406",
            "NomNombreProgramacion": "POD 406 15 BUSES 3,473_prog28_01_2024_6_52_30.xlsx",
            "FechaProgramacion": "30/01/2024",
            "FechaRegistro": "29/01/2024 19:46:40",
            "NomUsuarioRegistro": "jparedes@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 406 15 BUSES 3,473_prog28_01_2024_6_52_30.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 15,
            "nomArchivoOriginal": "POD 406 15 BUSES 3,473.10 HABIL.xlsx",
            "nomArvhicoInterno": "POD 406 15 BUSES 3,473_prog28_01_2024_6_52_30.xlsx",
            "NomFecProgramacion": "30/01/2024",
            "NomFecRegistro": "29/01/2024 19:46:40",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 15,
            "CantidadServiciosEjecut": 15,
            "CantidadViajesProgra": 180,
            "CantidadViajesEjecut": 165,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10941,
            "CodigoRuta": "405",
            "NomNombreProgramacion": "POD 405 40 BUSES  HABIL_prog28_01_2024_6_42_01.xlsx",
            "FechaProgramacion": "30/01/2024",
            "FechaRegistro": "29/01/2024 19:46:21",
            "NomUsuarioRegistro": "jparedes@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 405 40 BUSES  HABIL_prog28_01_2024_6_42_01.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 40,
            "nomArchivoOriginal": "POD 405 40 BUSES  HABIL.xlsx",
            "nomArvhicoInterno": "POD 405 40 BUSES  HABIL_prog28_01_2024_6_42_01.xlsx",
            "NomFecProgramacion": "30/01/2024",
            "NomFecRegistro": "29/01/2024 19:46:21",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 40,
            "CantidadServiciosEjecut": 35,
            "CantidadViajesProgra": 382,
            "CantidadViajesEjecut": 254,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10940,
            "CodigoRuta": "404",
            "NomNombreProgramacion": "POD 404 55 BUSES  11915_prog28_01_2024_6_23_28.xlsx",
            "FechaProgramacion": "30/01/2024",
            "FechaRegistro": "29/01/2024 19:45:42",
            "NomUsuarioRegistro": "jparedes@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 404 55 BUSES  11915_prog28_01_2024_6_23_28.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 55,
            "nomArchivoOriginal": "POD 404 55 BUSES  11915.502 KM HABIL.xlsx",
            "nomArvhicoInterno": "POD 404 55 BUSES  11915_prog28_01_2024_6_23_28.xlsx",
            "NomFecProgramacion": "30/01/2024",
            "NomFecRegistro": "29/01/2024 19:45:42",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 55,
            "CantidadServiciosEjecut": 55,
            "CantidadViajesProgra": 492,
            "CantidadViajesEjecut": 435,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10939,
            "CodigoRuta": "406",
            "NomNombreProgramacion": "POD 406 15 BUSES 3,473_prog28_01_2024_6_52_30.xlsx",
            "FechaProgramacion": "29/01/2024",
            "FechaRegistro": "28/01/2024 18:52:30",
            "NomUsuarioRegistro": "pcaldas2@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 406 15 BUSES 3,473_prog28_01_2024_6_52_30.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 15,
            "nomArchivoOriginal": "POD 406 15 BUSES 3,473.10 HABIL.xlsx",
            "nomArvhicoInterno": "POD 406 15 BUSES 3,473_prog28_01_2024_6_52_30.xlsx",
            "NomFecProgramacion": "29/01/2024",
            "NomFecRegistro": "28/01/2024 18:52:30",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 15,
            "CantidadServiciosEjecut": 15,
            "CantidadViajesProgra": 180,
            "CantidadViajesEjecut": 164,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10938,
            "CodigoRuta": "405",
            "NomNombreProgramacion": "POD 405 40 BUSES  HABIL_prog28_01_2024_6_42_01.xlsx",
            "FechaProgramacion": "29/01/2024",
            "FechaRegistro": "28/01/2024 18:42:01",
            "NomUsuarioRegistro": "pcaldas2@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 405 40 BUSES  HABIL_prog28_01_2024_6_42_01.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 40,
            "nomArchivoOriginal": "POD 405 40 BUSES  HABIL.xlsx",
            "nomArvhicoInterno": "POD 405 40 BUSES  HABIL_prog28_01_2024_6_42_01.xlsx",
            "NomFecProgramacion": "29/01/2024",
            "NomFecRegistro": "28/01/2024 18:42:01",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 40,
            "CantidadServiciosEjecut": 36,
            "CantidadViajesProgra": 382,
            "CantidadViajesEjecut": 248,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10937,
            "CodigoRuta": "404",
            "NomNombreProgramacion": "POD 404 55 BUSES  11915_prog28_01_2024_6_23_28.xlsx",
            "FechaProgramacion": "29/01/2024",
            "FechaRegistro": "28/01/2024 18:23:37",
            "NomUsuarioRegistro": "pcaldas2@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 404 55 BUSES  11915_prog28_01_2024_6_23_28.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 55,
            "nomArchivoOriginal": "POD 404 55 BUSES  11915.502 KM HABIL.xlsx",
            "nomArvhicoInterno": "POD 404 55 BUSES  11915_prog28_01_2024_6_23_28.xlsx",
            "NomFecProgramacion": "29/01/2024",
            "NomFecRegistro": "28/01/2024 18:23:37",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 55,
            "CantidadServiciosEjecut": 55,
            "CantidadViajesProgra": 492,
            "CantidadViajesEjecut": 448,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10936,
            "CodigoRuta": "412",
            "NomNombreProgramacion": "POD 412 32 BUSES 6,883_prog28_01_2024_6_18_15.xlsx",
            "FechaProgramacion": "29/01/2024",
            "FechaRegistro": "28/01/2024 18:18:15",
            "NomUsuarioRegistro": "pcaldas2@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 412 32 BUSES 6,883_prog28_01_2024_6_18_15.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 32,
            "nomArchivoOriginal": "POD 412 32 BUSES 6,883.20 HABIL.xlsx",
            "nomArvhicoInterno": "POD 412 32 BUSES 6,883_prog28_01_2024_6_18_15.xlsx",
            "NomFecProgramacion": "29/01/2024",
            "NomFecRegistro": "28/01/2024 18:18:15",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 32,
            "CantidadServiciosEjecut": 32,
            "CantidadViajesProgra": 384,
            "CantidadViajesEjecut": 365,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10935,
            "CodigoRuta": "409",
            "NomNombreProgramacion": "POD 409 08 BUSES domingo 2060_100 Km D_prog24_01_2024_11_16_08.xlsx",
            "FechaProgramacion": "28/01/2024",
            "FechaRegistro": "24/01/2024 23:16:08",
            "NomUsuarioRegistro": "pcaldas2@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 409 08 BUSES domingo 2060_100 Km D_prog24_01_2024_11_16_08.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 8,
            "nomArchivoOriginal": "POD 409 08 BUSES domingo 2060_100 Km D.xlsx",
            "nomArvhicoInterno": "POD 409 08 BUSES domingo 2060_100 Km D_prog24_01_2024_11_16_08.xlsx",
            "NomFecProgramacion": "28/01/2024",
            "NomFecRegistro": "24/01/2024 23:16:08",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 8,
            "CantidadServiciosEjecut": 6,
            "CantidadViajesProgra": 90,
            "CantidadViajesEjecut": 41,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10934,
            "CodigoRuta": "406",
            "NomNombreProgramacion": "POD 406 08 BUSES domingo  2199_630 Km_prog24_01_2024_11_13_54.xlsx",
            "FechaProgramacion": "28/01/2024",
            "FechaRegistro": "24/01/2024 23:13:54",
            "NomUsuarioRegistro": "pcaldas2@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 406 08 BUSES domingo  2199_630 Km_prog24_01_2024_11_13_54.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 8,
            "nomArchivoOriginal": "POD 406 08 BUSES domingo  2199_630 Km.xlsx",
            "nomArvhicoInterno": "POD 406 08 BUSES domingo  2199_630 Km_prog24_01_2024_11_13_54.xlsx",
            "NomFecProgramacion": "28/01/2024",
            "NomFecRegistro": "24/01/2024 23:13:54",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 8,
            "CantidadServiciosEjecut": 8,
            "CantidadViajesProgra": 114,
            "CantidadViajesEjecut": 104,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10933,
            "CodigoRuta": "412",
            "NomNombreProgramacion": "POD 412 16 BUSES domingo 3549_150 Km_prog24_01_2024_11_09_12.xlsx",
            "FechaProgramacion": "28/01/2024",
            "FechaRegistro": "24/01/2024 23:09:12",
            "NomUsuarioRegistro": "pcaldas2@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 412 16 BUSES domingo 3549_150 Km_prog24_01_2024_11_09_12.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 16,
            "nomArchivoOriginal": "POD 412 16 BUSES domingo 3549_150 Km.xlsx",
            "nomArvhicoInterno": "POD 412 16 BUSES domingo 3549_150 Km_prog24_01_2024_11_09_12.xlsx",
            "NomFecProgramacion": "28/01/2024",
            "NomFecRegistro": "24/01/2024 23:09:12",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 16,
            "CantidadServiciosEjecut": 16,
            "CantidadViajesProgra": 198,
            "CantidadViajesEjecut": 192,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10932,
            "CodigoRuta": "405",
            "NomNombreProgramacion": "POD 405 8 BUSES domingo 2263_404 Km_prog24_01_2024_11_01_52.xlsx",
            "FechaProgramacion": "28/01/2024",
            "FechaRegistro": "24/01/2024 23:01:52",
            "NomUsuarioRegistro": "pcaldas2@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 405 8 BUSES domingo 2263_404 Km_prog24_01_2024_11_01_52.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 8,
            "nomArchivoOriginal": "POD 405 8 BUSES domingo 2263_404 Km.xlsx",
            "nomArvhicoInterno": "POD 405 8 BUSES domingo 2263_404 Km_prog24_01_2024_11_01_52.xlsx",
            "NomFecProgramacion": "28/01/2024",
            "NomFecRegistro": "24/01/2024 23:01:52",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 8,
            "CantidadServiciosEjecut": 8,
            "CantidadViajesProgra": 104,
            "CantidadViajesEjecut": 101,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10931,
            "CodigoRuta": "404",
            "NomNombreProgramacion": "POD 404 20 BUSES domingo 5473_381 Km_prog24_01_2024_10_57_45.xlsx",
            "FechaProgramacion": "28/01/2024",
            "FechaRegistro": "24/01/2024 22:57:54",
            "NomUsuarioRegistro": "pcaldas2@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 404 20 BUSES domingo 5473_381 Km_prog24_01_2024_10_57_45.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 20,
            "nomArchivoOriginal": "POD 404 20 BUSES domingo 5473_381 Km.xlsx",
            "nomArvhicoInterno": "POD 404 20 BUSES domingo 5473_381 Km_prog24_01_2024_10_57_45.xlsx",
            "NomFecProgramacion": "28/01/2024",
            "NomFecRegistro": "24/01/2024 22:57:54",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 20,
            "CantidadServiciosEjecut": 20,
            "CantidadViajesProgra": 226,
            "CantidadViajesEjecut": 216,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10930,
            "CodigoRuta": "406",
            "NomNombreProgramacion": "POD 406 15 BUSES sabado 3473_100_prog24_01_2024_10_52_33.xlsx",
            "FechaProgramacion": "27/01/2024",
            "FechaRegistro": "24/01/2024 22:52:33",
            "NomUsuarioRegistro": "pcaldas2@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 406 15 BUSES sabado 3473_100_prog24_01_2024_10_52_33.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 15,
            "nomArchivoOriginal": "POD 406 15 BUSES sabado 3473_100.xlsx",
            "nomArvhicoInterno": "POD 406 15 BUSES sabado 3473_100_prog24_01_2024_10_52_33.xlsx",
            "NomFecProgramacion": "27/01/2024",
            "NomFecRegistro": "24/01/2024 22:52:33",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 15,
            "CantidadServiciosEjecut": 15,
            "CantidadViajesProgra": 180,
            "CantidadViajesEjecut": 157,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10929,
            "CodigoRuta": "409",
            "NomNombreProgramacion": "POD 409 15 BUSES sabado 4074_prog24_01_2024_10_50_04.xlsx",
            "FechaProgramacion": "27/01/2024",
            "FechaRegistro": "24/01/2024 22:50:04",
            "NomUsuarioRegistro": "pcaldas2@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 409 15 BUSES sabado 4074_prog24_01_2024_10_50_04.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 15,
            "nomArchivoOriginal": "POD 409 15 BUSES sabado 4074.42.xlsx",
            "nomArvhicoInterno": "POD 409 15 BUSES sabado 4074_prog24_01_2024_10_50_04.xlsx",
            "NomFecProgramacion": "27/01/2024",
            "NomFecRegistro": "24/01/2024 22:50:04",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 15,
            "CantidadServiciosEjecut": 8,
            "CantidadViajesProgra": 178,
            "CantidadViajesEjecut": 43,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10928,
            "CodigoRuta": "405",
            "NomNombreProgramacion": "POD 405 28 BUSES sabado  6529050_prog24_01_2024_10_45_48.xlsx",
            "FechaProgramacion": "27/01/2024",
            "FechaRegistro": "24/01/2024 22:45:48",
            "NomUsuarioRegistro": "pcaldas2@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 405 28 BUSES sabado  6529050_prog24_01_2024_10_45_48.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 28,
            "nomArchivoOriginal": "POD 405 28 BUSES sabado  6529050.xlsx",
            "nomArvhicoInterno": "POD 405 28 BUSES sabado  6529050_prog24_01_2024_10_45_48.xlsx",
            "NomFecProgramacion": "27/01/2024",
            "NomFecRegistro": "24/01/2024 22:45:48",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 28,
            "CantidadServiciosEjecut": 27,
            "CantidadViajesProgra": 300,
            "CantidadViajesEjecut": 227,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10927,
            "CodigoRuta": "412",
            "NomNombreProgramacion": "POD 412 32 BUSES sabado  6847_350 Km_prog24_01_2024_10_38_51.xlsx",
            "FechaProgramacion": "27/01/2024",
            "FechaRegistro": "24/01/2024 22:38:52",
            "NomUsuarioRegistro": "pcaldas2@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 412 32 BUSES sabado  6847_350 Km_prog24_01_2024_10_38_51.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 32,
            "nomArchivoOriginal": "POD 412 32 BUSES sabado  6847_350 Km.xlsx",
            "nomArvhicoInterno": "POD 412 32 BUSES sabado  6847_350 Km_prog24_01_2024_10_38_51.xlsx",
            "NomFecProgramacion": "27/01/2024",
            "NomFecRegistro": "24/01/2024 22:38:52",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 32,
            "CantidadServiciosEjecut": 32,
            "CantidadViajesProgra": 382,
            "CantidadViajesEjecut": 337,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10926,
            "CodigoRuta": "404",
            "NomNombreProgramacion": "POD 404 55 BUSES SABADO 11915_prog24_01_2024_10_33_39.xlsx",
            "FechaProgramacion": "27/01/2024",
            "FechaRegistro": "24/01/2024 22:33:49",
            "NomUsuarioRegistro": "pcaldas2@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 404 55 BUSES SABADO 11915_prog24_01_2024_10_33_39.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 55,
            "nomArchivoOriginal": "POD 404 55 BUSES SABADO 11915.5.xlsx",
            "nomArvhicoInterno": "POD 404 55 BUSES SABADO 11915_prog24_01_2024_10_33_39.xlsx",
            "NomFecProgramacion": "27/01/2024",
            "NomFecRegistro": "24/01/2024 22:33:49",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 55,
            "CantidadServiciosEjecut": 55,
            "CantidadViajesProgra": 492,
            "CantidadViajesEjecut": 472,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10925,
            "CodigoRuta": "406",
            "NomNombreProgramacion": "POD 406 15 BUSES 3,473_prog20_01_2024_2_01_15.xlsx",
            "FechaProgramacion": "26/01/2024",
            "FechaRegistro": "23/01/2024 01:02:32",
            "NomUsuarioRegistro": "pcaldas2@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 406 15 BUSES 3,473_prog20_01_2024_2_01_15.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 15,
            "nomArchivoOriginal": "POD 406 15 BUSES 3,473.10.xlsx",
            "nomArvhicoInterno": "POD 406 15 BUSES 3,473_prog20_01_2024_2_01_15.xlsx",
            "NomFecProgramacion": "26/01/2024",
            "NomFecRegistro": "23/01/2024 01:02:32",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 15,
            "CantidadServiciosEjecut": 15,
            "CantidadViajesProgra": 180,
            "CantidadViajesEjecut": 161,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10924,
            "CodigoRuta": "409",
            "NomNombreProgramacion": "POD 409 15 BUSES 4,074_prog20_01_2024_1_58_13.xlsx",
            "FechaProgramacion": "26/01/2024",
            "FechaRegistro": "23/01/2024 01:02:15",
            "NomUsuarioRegistro": "pcaldas2@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 409 15 BUSES 4,074_prog20_01_2024_1_58_13.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 15,
            "nomArchivoOriginal": "POD 409 15 BUSES 4,074.42 KM.xlsx",
            "nomArvhicoInterno": "POD 409 15 BUSES 4,074_prog20_01_2024_1_58_13.xlsx",
            "NomFecProgramacion": "26/01/2024",
            "NomFecRegistro": "23/01/2024 01:02:15",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 15,
            "CantidadServiciosEjecut": 6,
            "CantidadViajesProgra": 178,
            "CantidadViajesEjecut": 22,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10923,
            "CodigoRuta": "405",
            "NomNombreProgramacion": "POD 405 34 BUSES 8,487_prog20_01_2024_1_53_56.xlsx",
            "FechaProgramacion": "26/01/2024",
            "FechaRegistro": "23/01/2024 01:01:53",
            "NomUsuarioRegistro": "pcaldas2@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 405 34 BUSES 8,487_prog20_01_2024_1_53_56.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 34,
            "nomArchivoOriginal": "POD 405 34 BUSES 8,487.77 KM.xlsx",
            "nomArvhicoInterno": "POD 405 34 BUSES 8,487_prog20_01_2024_1_53_56.xlsx",
            "NomFecProgramacion": "26/01/2024",
            "NomFecRegistro": "23/01/2024 01:01:53",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 34,
            "CantidadServiciosEjecut": 34,
            "CantidadViajesProgra": 390,
            "CantidadViajesEjecut": 318,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10922,
            "CodigoRuta": "404",
            "NomNombreProgramacion": "POD 404 55 BUSES  11915_prog20_01_2024_1_46_09.xlsx",
            "FechaProgramacion": "26/01/2024",
            "FechaRegistro": "23/01/2024 01:01:24",
            "NomUsuarioRegistro": "pcaldas2@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 404 55 BUSES  11915_prog20_01_2024_1_46_09.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 55,
            "nomArchivoOriginal": "POD 404 55 BUSES  11915.502 KM.xlsx",
            "nomArvhicoInterno": "POD 404 55 BUSES  11915_prog20_01_2024_1_46_09.xlsx",
            "NomFecProgramacion": "26/01/2024",
            "NomFecRegistro": "23/01/2024 01:01:24",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 55,
            "CantidadServiciosEjecut": 55,
            "CantidadViajesProgra": 492,
            "CantidadViajesEjecut": 472,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10921,
            "CodigoRuta": "412",
            "NomNombreProgramacion": "POD 412 32 BUSES 6,883_prog20_01_2024_1_40_59.xlsx",
            "FechaProgramacion": "26/01/2024",
            "FechaRegistro": "23/01/2024 01:01:02",
            "NomUsuarioRegistro": "pcaldas2@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 412 32 BUSES 6,883_prog20_01_2024_1_40_59.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 32,
            "nomArchivoOriginal": "POD 412 32 BUSES 6,883.20.xlsx",
            "nomArvhicoInterno": "POD 412 32 BUSES 6,883_prog20_01_2024_1_40_59.xlsx",
            "NomFecProgramacion": "26/01/2024",
            "NomFecRegistro": "23/01/2024 01:01:02",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 32,
            "CantidadServiciosEjecut": 32,
            "CantidadViajesProgra": 384,
            "CantidadViajesEjecut": 345,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10920,
            "CodigoRuta": "406",
            "NomNombreProgramacion": "POD 406 15 BUSES 3,473_prog20_01_2024_2_01_15.xlsx",
            "FechaProgramacion": "25/01/2024",
            "FechaRegistro": "23/01/2024 00:59:06",
            "NomUsuarioRegistro": "pcaldas2@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 406 15 BUSES 3,473_prog20_01_2024_2_01_15.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 15,
            "nomArchivoOriginal": "POD 406 15 BUSES 3,473.10.xlsx",
            "nomArvhicoInterno": "POD 406 15 BUSES 3,473_prog20_01_2024_2_01_15.xlsx",
            "NomFecProgramacion": "25/01/2024",
            "NomFecRegistro": "23/01/2024 00:59:06",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 15,
            "CantidadServiciosEjecut": 15,
            "CantidadViajesProgra": 180,
            "CantidadViajesEjecut": 163,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10919,
            "CodigoRuta": "409",
            "NomNombreProgramacion": "POD 409 15 BUSES 4,074_prog20_01_2024_1_58_13.xlsx",
            "FechaProgramacion": "25/01/2024",
            "FechaRegistro": "23/01/2024 00:58:53",
            "NomUsuarioRegistro": "pcaldas2@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 409 15 BUSES 4,074_prog20_01_2024_1_58_13.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 15,
            "nomArchivoOriginal": "POD 409 15 BUSES 4,074.42 KM.xlsx",
            "nomArvhicoInterno": "POD 409 15 BUSES 4,074_prog20_01_2024_1_58_13.xlsx",
            "NomFecProgramacion": "25/01/2024",
            "NomFecRegistro": "23/01/2024 00:58:53",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 15,
            "CantidadServiciosEjecut": 10,
            "CantidadViajesProgra": 178,
            "CantidadViajesEjecut": 38,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10918,
            "CodigoRuta": "405",
            "NomNombreProgramacion": "POD 405 34 BUSES 8,487_prog20_01_2024_1_53_56.xlsx",
            "FechaProgramacion": "25/01/2024",
            "FechaRegistro": "23/01/2024 00:58:30",
            "NomUsuarioRegistro": "pcaldas2@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 405 34 BUSES 8,487_prog20_01_2024_1_53_56.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 34,
            "nomArchivoOriginal": "POD 405 34 BUSES 8,487.77 KM.xlsx",
            "nomArvhicoInterno": "POD 405 34 BUSES 8,487_prog20_01_2024_1_53_56.xlsx",
            "NomFecProgramacion": "25/01/2024",
            "NomFecRegistro": "23/01/2024 00:58:30",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 34,
            "CantidadServiciosEjecut": 34,
            "CantidadViajesProgra": 390,
            "CantidadViajesEjecut": 326,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10917,
            "CodigoRuta": "404",
            "NomNombreProgramacion": "POD 404 55 BUSES  11915_prog20_01_2024_1_46_09.xlsx",
            "FechaProgramacion": "25/01/2024",
            "FechaRegistro": "23/01/2024 00:57:59",
            "NomUsuarioRegistro": "pcaldas2@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 404 55 BUSES  11915_prog20_01_2024_1_46_09.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 55,
            "nomArchivoOriginal": "POD 404 55 BUSES  11915.502 KM.xlsx",
            "nomArvhicoInterno": "POD 404 55 BUSES  11915_prog20_01_2024_1_46_09.xlsx",
            "NomFecProgramacion": "25/01/2024",
            "NomFecRegistro": "23/01/2024 00:57:59",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 55,
            "CantidadServiciosEjecut": 55,
            "CantidadViajesProgra": 492,
            "CantidadViajesEjecut": 472,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10916,
            "CodigoRuta": "412",
            "NomNombreProgramacion": "POD 412 32 BUSES 6,883_prog20_01_2024_1_40_59.xlsx",
            "FechaProgramacion": "25/01/2024",
            "FechaRegistro": "23/01/2024 00:57:37",
            "NomUsuarioRegistro": "pcaldas2@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 412 32 BUSES 6,883_prog20_01_2024_1_40_59.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 32,
            "nomArchivoOriginal": "POD 412 32 BUSES 6,883.20.xlsx",
            "nomArvhicoInterno": "POD 412 32 BUSES 6,883_prog20_01_2024_1_40_59.xlsx",
            "NomFecProgramacion": "25/01/2024",
            "NomFecRegistro": "23/01/2024 00:57:37",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 32,
            "CantidadServiciosEjecut": 32,
            "CantidadViajesProgra": 384,
            "CantidadViajesEjecut": 369,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10915,
            "CodigoRuta": "406",
            "NomNombreProgramacion": "POD 406 15 BUSES 3,473_prog20_01_2024_2_01_15.xlsx",
            "FechaProgramacion": "24/01/2024",
            "FechaRegistro": "23/01/2024 00:57:01",
            "NomUsuarioRegistro": "pcaldas2@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 406 15 BUSES 3,473_prog20_01_2024_2_01_15.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 15,
            "nomArchivoOriginal": "POD 406 15 BUSES 3,473.10.xlsx",
            "nomArvhicoInterno": "POD 406 15 BUSES 3,473_prog20_01_2024_2_01_15.xlsx",
            "NomFecProgramacion": "24/01/2024",
            "NomFecRegistro": "23/01/2024 00:57:01",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 15,
            "CantidadServiciosEjecut": 15,
            "CantidadViajesProgra": 180,
            "CantidadViajesEjecut": 164,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10914,
            "CodigoRuta": "409",
            "NomNombreProgramacion": "POD 409 15 BUSES 4,074_prog20_01_2024_1_58_13.xlsx",
            "FechaProgramacion": "24/01/2024",
            "FechaRegistro": "23/01/2024 00:56:42",
            "NomUsuarioRegistro": "pcaldas2@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 409 15 BUSES 4,074_prog20_01_2024_1_58_13.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 15,
            "nomArchivoOriginal": "POD 409 15 BUSES 4,074.42 KM.xlsx",
            "nomArvhicoInterno": "POD 409 15 BUSES 4,074_prog20_01_2024_1_58_13.xlsx",
            "NomFecProgramacion": "24/01/2024",
            "NomFecRegistro": "23/01/2024 00:56:42",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 15,
            "CantidadServiciosEjecut": 9,
            "CantidadViajesProgra": 178,
            "CantidadViajesEjecut": 35,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10913,
            "CodigoRuta": "405",
            "NomNombreProgramacion": "POD 405 34 BUSES 8,487_prog20_01_2024_1_53_56.xlsx",
            "FechaProgramacion": "24/01/2024",
            "FechaRegistro": "23/01/2024 00:56:20",
            "NomUsuarioRegistro": "pcaldas2@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 405 34 BUSES 8,487_prog20_01_2024_1_53_56.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 34,
            "nomArchivoOriginal": "POD 405 34 BUSES 8,487.77 KM.xlsx",
            "nomArvhicoInterno": "POD 405 34 BUSES 8,487_prog20_01_2024_1_53_56.xlsx",
            "NomFecProgramacion": "24/01/2024",
            "NomFecRegistro": "23/01/2024 00:56:20",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 34,
            "CantidadServiciosEjecut": 34,
            "CantidadViajesProgra": 390,
            "CantidadViajesEjecut": 343,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10912,
            "CodigoRuta": "404",
            "NomNombreProgramacion": "POD 404 55 BUSES  11915_prog20_01_2024_1_46_09.xlsx",
            "FechaProgramacion": "24/01/2024",
            "FechaRegistro": "23/01/2024 00:56:02",
            "NomUsuarioRegistro": "pcaldas2@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 404 55 BUSES  11915_prog20_01_2024_1_46_09.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 55,
            "nomArchivoOriginal": "POD 404 55 BUSES  11915.502 KM.xlsx",
            "nomArvhicoInterno": "POD 404 55 BUSES  11915_prog20_01_2024_1_46_09.xlsx",
            "NomFecProgramacion": "24/01/2024",
            "NomFecRegistro": "23/01/2024 00:56:02",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 55,
            "CantidadServiciosEjecut": 55,
            "CantidadViajesProgra": 492,
            "CantidadViajesEjecut": 467,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10911,
            "CodigoRuta": "412",
            "NomNombreProgramacion": "POD 412 32 BUSES 6,883_prog20_01_2024_1_40_59.xlsx",
            "FechaProgramacion": "24/01/2024",
            "FechaRegistro": "23/01/2024 00:55:39",
            "NomUsuarioRegistro": "pcaldas2@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 412 32 BUSES 6,883_prog20_01_2024_1_40_59.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 32,
            "nomArchivoOriginal": "POD 412 32 BUSES 6,883.20.xlsx",
            "nomArvhicoInterno": "POD 412 32 BUSES 6,883_prog20_01_2024_1_40_59.xlsx",
            "NomFecProgramacion": "24/01/2024",
            "NomFecRegistro": "23/01/2024 00:55:39",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 32,
            "CantidadServiciosEjecut": 32,
            "CantidadViajesProgra": 384,
            "CantidadViajesEjecut": 357,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10910,
            "CodigoRuta": "406",
            "NomNombreProgramacion": "POD 406 15 BUSES 3,473_prog20_01_2024_2_01_15.xlsx",
            "FechaProgramacion": "23/01/2024",
            "FechaRegistro": "23/01/2024 00:54:19",
            "NomUsuarioRegistro": "pcaldas2@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 406 15 BUSES 3,473_prog20_01_2024_2_01_15.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 15,
            "nomArchivoOriginal": "POD 406 15 BUSES 3,473.10.xlsx",
            "nomArvhicoInterno": "POD 406 15 BUSES 3,473_prog20_01_2024_2_01_15.xlsx",
            "NomFecProgramacion": "23/01/2024",
            "NomFecRegistro": "23/01/2024 00:54:19",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 15,
            "CantidadServiciosEjecut": 15,
            "CantidadViajesProgra": 180,
            "CantidadViajesEjecut": 166,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10909,
            "CodigoRuta": "409",
            "NomNombreProgramacion": "POD 409 15 BUSES 4,074_prog20_01_2024_1_58_13.xlsx",
            "FechaProgramacion": "23/01/2024",
            "FechaRegistro": "23/01/2024 00:53:14",
            "NomUsuarioRegistro": "pcaldas2@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 409 15 BUSES 4,074_prog20_01_2024_1_58_13.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 15,
            "nomArchivoOriginal": "POD 409 15 BUSES 4,074.42 KM.xlsx",
            "nomArvhicoInterno": "POD 409 15 BUSES 4,074_prog20_01_2024_1_58_13.xlsx",
            "NomFecProgramacion": "23/01/2024",
            "NomFecRegistro": "23/01/2024 00:53:14",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 15,
            "CantidadServiciosEjecut": 10,
            "CantidadViajesProgra": 178,
            "CantidadViajesEjecut": 38,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10908,
            "CodigoRuta": "405",
            "NomNombreProgramacion": "POD 405 34 BUSES 8,487_prog20_01_2024_1_53_56.xlsx",
            "FechaProgramacion": "23/01/2024",
            "FechaRegistro": "23/01/2024 00:52:45",
            "NomUsuarioRegistro": "pcaldas2@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 405 34 BUSES 8,487_prog20_01_2024_1_53_56.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 34,
            "nomArchivoOriginal": "POD 405 34 BUSES 8,487.77 KM.xlsx",
            "nomArvhicoInterno": "POD 405 34 BUSES 8,487_prog20_01_2024_1_53_56.xlsx",
            "NomFecProgramacion": "23/01/2024",
            "NomFecRegistro": "23/01/2024 00:52:45",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 34,
            "CantidadServiciosEjecut": 34,
            "CantidadViajesProgra": 390,
            "CantidadViajesEjecut": 322,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10907,
            "CodigoRuta": "404",
            "NomNombreProgramacion": "POD 404 55 BUSES  11915_prog20_01_2024_1_46_09.xlsx",
            "FechaProgramacion": "23/01/2024",
            "FechaRegistro": "23/01/2024 00:52:26",
            "NomUsuarioRegistro": "pcaldas2@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 404 55 BUSES  11915_prog20_01_2024_1_46_09.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 55,
            "nomArchivoOriginal": "POD 404 55 BUSES  11915.502 KM.xlsx",
            "nomArvhicoInterno": "POD 404 55 BUSES  11915_prog20_01_2024_1_46_09.xlsx",
            "NomFecProgramacion": "23/01/2024",
            "NomFecRegistro": "23/01/2024 00:52:26",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 55,
            "CantidadServiciosEjecut": 55,
            "CantidadViajesProgra": 492,
            "CantidadViajesEjecut": 478,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10906,
            "CodigoRuta": "412",
            "NomNombreProgramacion": "POD 412 32 BUSES 6,883_prog20_01_2024_1_40_59.xlsx",
            "FechaProgramacion": "23/01/2024",
            "FechaRegistro": "23/01/2024 00:51:58",
            "NomUsuarioRegistro": "pcaldas2@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 412 32 BUSES 6,883_prog20_01_2024_1_40_59.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 32,
            "nomArchivoOriginal": "POD 412 32 BUSES 6,883.20.xlsx",
            "nomArvhicoInterno": "POD 412 32 BUSES 6,883_prog20_01_2024_1_40_59.xlsx",
            "NomFecProgramacion": "23/01/2024",
            "NomFecRegistro": "23/01/2024 00:51:58",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 32,
            "CantidadServiciosEjecut": 32,
            "CantidadViajesProgra": 384,
            "CantidadViajesEjecut": 346,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10905,
            "CodigoRuta": "406",
            "NomNombreProgramacion": "POD 406 15 BUSES 3,473_prog20_01_2024_2_01_15.xlsx",
            "FechaProgramacion": "22/01/2024",
            "FechaRegistro": "20/01/2024 02:01:16",
            "NomUsuarioRegistro": "pcaldas2@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 406 15 BUSES 3,473_prog20_01_2024_2_01_15.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 15,
            "nomArchivoOriginal": "POD 406 15 BUSES 3,473.10.xlsx",
            "nomArvhicoInterno": "POD 406 15 BUSES 3,473_prog20_01_2024_2_01_15.xlsx",
            "NomFecProgramacion": "22/01/2024",
            "NomFecRegistro": "20/01/2024 02:01:16",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 15,
            "CantidadServiciosEjecut": 15,
            "CantidadViajesProgra": 180,
            "CantidadViajesEjecut": 158,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10904,
            "CodigoRuta": "409",
            "NomNombreProgramacion": "POD 409 15 BUSES 4,074_prog20_01_2024_1_58_13.xlsx",
            "FechaProgramacion": "22/01/2024",
            "FechaRegistro": "20/01/2024 01:58:14",
            "NomUsuarioRegistro": "pcaldas2@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 409 15 BUSES 4,074_prog20_01_2024_1_58_13.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 15,
            "nomArchivoOriginal": "POD 409 15 BUSES 4,074.42 KM.xlsx",
            "nomArvhicoInterno": "POD 409 15 BUSES 4,074_prog20_01_2024_1_58_13.xlsx",
            "NomFecProgramacion": "22/01/2024",
            "NomFecRegistro": "20/01/2024 01:58:14",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 15,
            "CantidadServiciosEjecut": 5,
            "CantidadViajesProgra": 178,
            "CantidadViajesEjecut": 21,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10903,
            "CodigoRuta": "405",
            "NomNombreProgramacion": "POD 405 34 BUSES 8,487_prog20_01_2024_1_53_56.xlsx",
            "FechaProgramacion": "22/01/2024",
            "FechaRegistro": "20/01/2024 01:53:56",
            "NomUsuarioRegistro": "pcaldas2@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 405 34 BUSES 8,487_prog20_01_2024_1_53_56.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 34,
            "nomArchivoOriginal": "POD 405 34 BUSES 8,487.77 KM.xlsx",
            "nomArvhicoInterno": "POD 405 34 BUSES 8,487_prog20_01_2024_1_53_56.xlsx",
            "NomFecProgramacion": "22/01/2024",
            "NomFecRegistro": "20/01/2024 01:53:56",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 34,
            "CantidadServiciosEjecut": 33,
            "CantidadViajesProgra": 390,
            "CantidadViajesEjecut": 329,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10902,
            "CodigoRuta": "404",
            "NomNombreProgramacion": "POD 404 55 BUSES  11915_prog20_01_2024_1_46_09.xlsx",
            "FechaProgramacion": "22/01/2024",
            "FechaRegistro": "20/01/2024 01:46:18",
            "NomUsuarioRegistro": "pcaldas2@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 404 55 BUSES  11915_prog20_01_2024_1_46_09.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 55,
            "nomArchivoOriginal": "POD 404 55 BUSES  11915.502 KM.xlsx",
            "nomArvhicoInterno": "POD 404 55 BUSES  11915_prog20_01_2024_1_46_09.xlsx",
            "NomFecProgramacion": "22/01/2024",
            "NomFecRegistro": "20/01/2024 01:46:18",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 55,
            "CantidadServiciosEjecut": 55,
            "CantidadViajesProgra": 492,
            "CantidadViajesEjecut": 473,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        },
        {
            "IdProgramacion": 10901,
            "CodigoRuta": "412",
            "NomNombreProgramacion": "POD 412 32 BUSES 6,883_prog20_01_2024_1_40_59.xlsx",
            "FechaProgramacion": "22/01/2024",
            "FechaRegistro": "20/01/2024 01:41:00",
            "NomUsuarioRegistro": "pcaldas2@juntaoperadora",
            "Estado": "EJECUTADO",
            "NomUrlProgramacion": "POD 412 32 BUSES 6,883_prog20_01_2024_1_40_59.xlsx",
            "NumCumplimiento": 0,
            "NumServicios": 32,
            "nomArchivoOriginal": "POD 412 32 BUSES 6,883.20.xlsx",
            "nomArvhicoInterno": "POD 412 32 BUSES 6,883_prog20_01_2024_1_40_59.xlsx",
            "NomFecProgramacion": "22/01/2024",
            "NomFecRegistro": "20/01/2024 01:41:00",
            "EstadoRegistro": "ACTIVO",
            "CantidadServiciosProgra": 32,
            "CantidadServiciosEjecut": 31,
            "CantidadViajesProgra": 384,
            "CantidadViajesEjecut": 345,
            "CodProgramacionTipo": 0,
            "Formato": 1,
            "EstadoCarga": "VER"
        }
    ]
    const classNameInput = "containerScroll text-white h-[30px] rounded px-4 w-full bg-zinc-800 text-xs focus:outline-none focus:ring-1 focus:ring-blue-500"   

  return (
    <>
        <ContenedorParametros titulo="Programación general de flota">
            <div className='flex gap-5 items-center'>
                <select className={`${classNameInput} w-[300px]`}><option>Empresa</option></select>
                <button className='p-2 rounded-md border fa fa-filter'></button>
                <BotonNuevo></BotonNuevo>
                <BotonProcesar></BotonProcesar>
                <BotonExcel></BotonExcel>
            </div>
        </ContenedorParametros>
        <div className='containerScroll mt-7 relative top-0 bottom-0 left-0 right-0 contenedor-tabla'>
            <table className='table'>
                <thead>
                    <tr>
                        <th>N°</th>
                        <th>F.PROGRAMACIÓN</th>
                        <th>RUTA</th>
                        <th>C.SERV.</th>
                        <th>VIAJE</th>
                        <th>F.CREACIÓN</th>
                        <th>PROGRAMADOR</th>
                        <th>FORMATO</th>
                        <th></th>
                        <th></th>
                        <th>ESTADO CARGA</th>
                        <th>CURPLIMIENTO SERV</th>
                        <th>CUMPLIMIENTO VIAJE</th>
                        <th>TIPO</th>
                        <th>E.VIAJES</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((data,i) => {
                            return (
                                <tr key={i+1}>
                                    <td className='text-center'>{i+1}</td>
                                    <td className='text-center'>{data.FechaProgramacion}</td>
                                    <td className='text-center'>{data.CodigoRuta}</td>
                                    <td className='text-center'>{data.NumServicios}</td>
                                    <td className='text-center'></td>
                                    <td className='text-center'>{data.FechaRegistro}</td>
                                    <td className='text-center'>{data.NomUsuarioRegistro}</td>
                                    <td className='text-center'>{data.Formato}</td>
                                    <td className='text-center'><i className="fa fa-file-excel-o" aria-hidden="true"></i></td>
                                    <td className='text-center'><i className="fa fa-clone" aria-hidden="true"></i></td>
                                    <td className='text-center'><a>{data.EstadoCarga}</a></td>
                                    <td className='text-center'>
                                        <div className='flex items-center gap-2'>
                                            <progress max={data.CantidadServiciosProgra || 0} value={data.CantidadServiciosEjecut || 0} className='w-[150px] h-[20px] bg-red-700'></progress>
                                            <div>{(((data.CantidadServiciosEjecut || 0)*100)/(data.CantidadServiciosProgra || 0)).toFixed(2)}%</div>
                                        </div>
                                    </td>
                                    <td className='text-center'>
                                        <div className='flex items-center gap-2'>
                                            <progress max={data.CantidadViajesProgra || 0} value={data.CantidadViajesEjecut || 0} className='custom-progress w-[150px] h-[20px] text-blue-600'></progress>
                                            <div>{(((data.CantidadViajesEjecut || 0)*100)/(data.CantidadViajesProgra || 0)).toFixed(2)}%</div>
                                        </div>
                                    </td>
                                    <td className='text-center'><i className="fa fa-download" aria-hidden="true"></i></td>
                                    <td className='text-center'><i className="fa fa-refresh" aria-hidden="true"></i></td>
                                    <td className='text-center'><i className="fa fa-check text-green-600" aria-hidden="true"></i></td>
                                    <td className='text-center'><i className="fa fa-external-link" aria-hidden="true"></i></td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    </>
  )
}
