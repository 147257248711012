import React,{useState,useEffect,useRef} from 'react'
import { ContenedorParametros } from '../../components/utils/ContenedorParametros'
import { NuevoSelectAutocomplete } from '../../components/forms/NuevoSelectAutocomplete'
import { BotonProcesar } from '../../components/buttons/BotonProcesar'
import { useATU } from '../../../hooks/ATU/useATU'
import { GoogleMap } from '../../../utils/googlemaps'
import { Fetch } from '../../../services/api'
import { DatePickerABX } from '../../components/pickers/DatePicker'
import { formatDate, notify } from '../../../utils/utils'
import { Select2 } from '../../components/forms/Select2'

const urlBaseAtu = process.env.REACT_APP_ATU_API + "/api/ATU";
const googleMap = new GoogleMap();
const classNameInput = "containerScroll text-white h-[30px] rounded px-4  bg-zinc-800 text-xs focus:outline-none focus:ring-1 focus:ring-blue-500"   

export const MapaCalor = () => {
    const {empresas,listarRutas,rutas,listarRecorridos,recorridos,listarRecorridosControles,controles,paraderos,polilineas} = useATU()
    const [ empresaSeleccionada, setEmpresaSeleccionada ] = useState(0);
    const [ rutaSeleccionada, setRutaSeleccionada ] = useState('0|0');
    const [ recorridoSeleccionado,setRecorridoSeleccionado ] = useState('0|0|0');
    const [nombreEmpresaSeleccionada,setNombreEmpresaSeleccionada] = useState('')
    const [vistaParaderos,setVistaParaderos] = useState(1)
    const mapDiv = useRef();
    const fechaActual = new Date(); // Obtiene la fecha actual
    const [startDate,setStartDate] = useState(fechaActual)
    const empresasConvertidas = empresas.map(option => ({
        value: option.codEmpresa,
        label: option.nomEmpresa
    }));

    useEffect(() => {
        listarRutas(empresaSeleccionada);
        setRutaSeleccionada(`${empresaSeleccionada}|0`)
    },[empresaSeleccionada])

    useEffect(() => {
        listarRecorridos(rutaSeleccionada)
        setRecorridoSeleccionado(`${rutaSeleccionada}|0`)
    },[rutaSeleccionada])

    useEffect(() => {
        googleMap.inicializarMapa(mapDiv.current, { zoom: 11 });
    }, [])


    const listarBoletosPorParadero = async () => {
        googleMap.quitarTodosLosMarcadores()
        googleMap.quitarTodosLasPolilineas()
        googleMap.quitarTodosLosMapasDeCalor()
        
        if(recorridoSeleccionado.split('|')[2] == 0 && empresaSeleccionada != 0){
            notify("Seleccione todos los parametros","error")
            return
        }

        const response = await retornarValores()

        if(empresaSeleccionada == 0){
            //ACA HACER TODA LA LOGICA PARA EL TODOS
            const marcadoresMapaCalor = response.content.map((data,i) => {
                const boletosArray = response.content.map((data,i) => {
                    return(Number(data.numBoletos))
                })
                const valorMaximoDeBoletos = Math.max(...boletosArray)
                const porcentaje = (data.numBoletos == 0 ? 0.1 : (Number(data.numBoletos)/valorMaximoDeBoletos).toFixed(1))
    
                return({
                    location: new window.google.maps.LatLng(data.latitud,data.longitud),
                    weight: Number(porcentaje) == 0 ? 0.1 : Number(porcentaje)
                })
            })
    
            googleMap.crearMapaDeCalor({
                dataMapaCalor: marcadoresMapaCalor,
                radio: 20
            })
            return
        }
        
        const arrayPuntosRecorrido = response.content.polilineaRecorrido.split('*').map((data) => {
            return({
                lat:Number(data.split(',')[1]),
                lng:Number(data.split(',')[0])
            })
        })

        googleMap.crearPolilinea({
            listLatLng: arrayPuntosRecorrido,
            strokeWeight: 8,
            strokeOpacity: 0.1
        })
        
        const marcadoresBoletos = response.content.listaParaderos

        marcadoresBoletos.map((data,i) => {
            const marcadorTest = googleMap.crearMarcador({
                latLng: { lat: data.latitud, lng: data.longitud},
                icon: 'puntos',
                visibilityPunto: 'hidden',
            })
            const infoWindow = googleMap.crearInfowindow({});
            marcadorTest.addListener('click', () => {
                infoWindow.setContent(`
                    <div style="text-align:center; margin:5px"><h1 style="color:white"><b>${vistaParaderos == 1 ? 'Control: ' : 'Paradero: '}</b>${data.nomControl}</h1></div>
                    <hr>
                    <div style="text-align:center; margin:5px"><h1 style="color:white"><b>Nro.Boletos:</b> ${data.numBoletos}</h1></div>
                    <hr>
                    <div style="text-align:center; margin:5px"><h1 style="color:white"><b>Sub Total:</b> s/.${data.numSubTotal}</h1></div>
                    <hr>
                    <div style="color: #000"></div>
                `);
    
                infoWindow.open({
                    anchor: marcadorTest,
                    map: googleMap.map
                })
            })
        })

        const boletosArray = marcadoresBoletos.map((data,i) => {
            return(Number(data.numBoletos))
        })
        const valorMaximoDeBoletos = Math.max(...boletosArray)

        const marcadoresMapaCalor = marcadoresBoletos.map((data,i) => {
            const porcentaje = (data.numBoletos == 0 ? 0.1 : (Number(data.numBoletos)/valorMaximoDeBoletos).toFixed(1))




            return({
                location: new window.google.maps.LatLng(data.latitud,data.longitud),
                weight: (Number(porcentaje) == 0 ? 0.1 : Number(porcentaje))
            })
        })

        googleMap.crearMapaDeCalor({
            dataMapaCalor: marcadoresMapaCalor
        })
    }

    const retornarValores = async () => {
        let parametros = {}
        if(empresaSeleccionada == 0){
            parametros =    {
                                url: urlBaseAtu + '/listarBoletosTodasLasEmpresas?'+ new URLSearchParams({
                                    fechaInicio: formatDate(startDate,103),
                                    tipoConsulta: vistaParaderos
                                }),
                            }
        }else{
            parametros =    {
                                url : urlBaseAtu + '/listarBoletosPorParadero?'+ new URLSearchParams({
                                    codEmpresaAtu: empresaSeleccionada || 0,
                                    fechaInicio: formatDate(startDate,103),
                                    codRecorrido: recorridoSeleccionado.split('|')[2] || 0,
                                    tipoConsulta: vistaParaderos
                                }),
                            }
        }
        const response =  await Fetch(parametros) 
        return response
    }

    const agruparPorRecorrido = (array, campo) => {
        return array.reduce((resultado, objeto) => {
            const valorCampo = objeto[campo];
            if (!resultado[valorCampo]) {
                resultado[valorCampo] = [];
            }
            resultado[valorCampo].push(objeto);
            return resultado;
        }, {});
    }

    return (
        <>
            <ContenedorParametros titulo="Mapa de Calor">

                <div className='flex gap-4 items-center justify-center'>
                    <i className="fa fa-bus" data-tip={'Empresas'} data-for={'Empresas'} aria-hidden="true"></i>
                    {
                        empresas.length!= 0 &&
                        <Select2
                            jsonOpciones={empresas.map((data) => 
                                (
                                    {
                                        valor: data.codEmpresa,
                                        texto: data.nomEmpresa
                                    }
                                ))
                            }
                            textoPrimerValor={'Seleccione'}
                            recibirValor={(data) => setEmpresaSeleccionada(data)}
                            recibirTexto={(data) => setNombreEmpresaSeleccionada(data)}
                        />
                    }
                </div>

                <div className='flex gap-4 items-center justify-center'>
                    <i className="fa fa-road" data-tip={'Ruta'} data-for={'Ruta'} aria-hidden="true"/>
                    <select className={`${classNameInput} lg:w-[250px]  w-[200px] `} value={rutaSeleccionada} onChange={(e) => setRutaSeleccionada(e.target.value)}>
                    <option value={empresaSeleccionada+'|0'}>-- Seleccione --</option>
                        {
                            rutas.map((ru ,i) => {
                                if(empresaSeleccionada == 0){
                                    return
                                }else{
                                    return <option key={i+1} value={ru.codEmpresaATU + '|' + ru.codRuta}>{ru.nomRuta} { empresaSeleccionada!=0? '' : ' | ' + ru.nomEmpresaATU}</option>
                                }
                            })
                        }
                    </select>
                </div>

                <div className='flex gap-4 items-center justify-center'>
                    <i className="fa fa-map-o" data-tip={'Recorrido'} data-for={'Recorrido'} aria-hidden="true"/>
                    <select className={`${classNameInput} lg:w-[250px] w-[200px]`} value={recorridoSeleccionado} onChange={(e) => setRecorridoSeleccionado(e.target.value)}>
                        <option value={rutaSeleccionada+"|0"}>-- Seleccione --</option>
                            {
                                recorridos.map((re,i) => {
                                    if(rutaSeleccionada.split('|')[1] == 0){
                                        return
                                    }else{
                                        return <option key={i+1} value={re.codEmpresaATU + '|' + re.codRuta + '|' + re.codRecorrido}>{re.nomRecorrido} { rutaSeleccionada != '0|0'? '' : ' | ' + re.nomRuta}</option>
                                    }
                                })
                            }
                    </select>
                </div>

                <div className='flex gap-4 items-center justify-center'>
                    <i className="fa fa-calendar-alt" data-tip={'Fecha'} data-for={'Fecha'} aria-hidden="true"></i> 
                    <div>
                        <DatePickerABX data-tip data-for={'Fecha'} date={startDate} setDate={setStartDate} clases='lg:w-[250px]'/>
                    </div>
                </div>

                <div className='flex gap-4 items-center justify-center'>
                    <i className="fa fa-eye" data-tip={'Tipo de Vista'} data-for={'TipoVista'} aria-hidden="true"/>
                    <select className={`${classNameInput} lg:w-[250px] w-[120px]`} value={vistaParaderos} onChange={(e) => setVistaParaderos(e.target.value)}>
                        <option value={1}>Controles</option>
                        <option value={2}>Paraderos</option>
                    </select>
                </div>
                
                <div className='flex gap-4 items-center justify-center flex-wrap'>
                    <BotonProcesar onClick={listarBoletosPorParadero} />
                </div>

            </ContenedorParametros>   
            <div className="relative mapa-incidentes h-[85%]" >
                <div ref={mapDiv} className='w-full h-full'></div>
            </div> 
        </>
    )
}
